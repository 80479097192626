import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useTemplatesStore from './store';
import EmailEditor from './components/emailEditor';
import ModalEdit from './components/modalEdit';
import DetailTable from './components/detailTable';
import DuplicateLanguage from './components/duplicateLanguage';




function EmailTemplateDetail() {

  const { effects } = useTemplatesStore();
  const { namespace } = useParams();

  useEffect(() => {
    effects.loadTemplateByNamespace(namespace);
  }, []);

  return (
    <div style={{ padding: '0 1rem' }}>
      <DetailTable />
      <EmailEditor />
      <ModalEdit />
      <DuplicateLanguage />
    </div>
  );
}

export default EmailTemplateDetail;
