import datefns from 'date-fns'
import moment from 'moment'

function privateOperations(extendedFilters) {
    function string(field) {
      return { value: `"${field._text}"`, pureValue: field._text, name: field._text };
    }

    function simple(field) {
        return { value: field._text, pureValue: field._text, name: field._text };
    }

    function previous(field) {
        const val = field._text
        return { value: `previous:${val}`, pureValue: val, name: val };
    }

    function createdAt() {
        return { value: '$createdAt', pureValue: '$createdAt', name: 'createdAt' };
    }

    function dates(field) {
        let value = false
        let date = new Date(extendedFilters["fromDate"])
        if(field._text === 'utc') {
          value = Date.UTC(
            date.getFullYear(), 
            date.getMonth(), 
            date.getDate(), 
            date.getHours(), 
            date.getMinutes(), 
            date.getSeconds(),
            0
          );
        } else if(field._text === 'timestamp') {
          value = date.getTime()
        } else if(field._text === 'iso') {
          value = date.toISOString()
        } else {
          value =  moment(date).format("YYYY-MM-DD")
        }

        return { value, pureValue: value, name: 'dates' };
    }
  
    function valueFromFilter(field) {
      const filterName = field._text
      if(filterName === 'extraAttrsFilter') {
        const isActive = !!extendedFilters.extraAttr && !!extendedFilters.extraAttrValue
        if (isActive) {
            return { value: extendedFilters.extraAttrValue, pureValue: extendedFilters.extraAttrValue, key: extendedFilters.extraAttr };
        }
      }

      const isActive =
      filterName in extendedFilters &&
        (!!extendedFilters[filterName] || extendedFilters[filterName] === '');
      if (isActive) {
        return { value: `"${extendedFilters[filterName]}"`, pureValue: extendedFilters[filterName], name: filterName };
      }
      return { value: false, pureValue: false, name: filterName };
    }
  
    function valueFromDateFilter(field) {
      let value = false
      let pureValue = false
      let name = 'valueFromDateFilter'
  
      if(field && field.length) {
  
        const config = {
          format: 'date', 
          addOrLess: '+',
          timeQuantity: 0,
          time: 'seconds',
        }
  
        for(let i = 0; i < field.length; i++) {
          let { _attributes, _text } = field[i]
          if( _attributes ) config[_attributes.name] = _text
        }
  
        if(config.valueFromDateFilter && extendedFilters[config.valueFromDateFilter]) {
          let date = new Date(extendedFilters[config.valueFromDateFilter])
          const dateFnsFunctionName = 'add' + config.time.charAt(0).toUpperCase() + config.time.slice(1)
          const multiplicator = config.addOrLess === '+' ? 1 : -1
          date = datefns[dateFnsFunctionName](date, parseFloat(config.timeQuantity) * multiplicator)
          name = config.valueFromDateFilter

          if(config.format === 'utc') {
            value = Date.UTC(
                date.getFullYear(), 
                date.getMonth(), 
                date.getDate(), 
                date.getHours(), 
                date.getMinutes(), 
                date.getSeconds(),
                0
            );
          } else if(config.format === 'timestamp') {
            pureValue = date.getTime()
          } else if(config.format === 'iso') {
            pureValue = date.toISOString()
          } else {
            pureValue =  moment(date).format("YYYY-MM-DD")
          }

          value = `'${pureValue}'`
        }
      }
      
      return { name, value, pureValue }
    }

    function index(fields) {
        const index = fields.find(field => field._attributes.name === 'index')
        return (statements, callback) => {
            const { andStatement, betweenStatement } = statements.reduce((initial, { _attributes, block }) => ({ ...initial, [_attributes.name]: block }), {})
            const betweenValue = callback(betweenStatement)
            const andValue = callback(andStatement)
            
            function mergeValues(arr) {
                return Object.keys(arr).reduce((initial, key) => {
                    const values = arr[key].reduce((initial, item) => {
                        const values = item.values.reduce((initial, val) => ([...initial, val.value]), [])
                        return [...initial, ...values]
                    }, [])
                    return [...initial, ...values]
                }, [])
            }

            return { index: index._text, conditional: 'between', values: [mergeValues(betweenValue), mergeValues(andValue)] }
        }
    }

    return {
        valueFromDateFilter,
        valueFromFilter,
        string,
        simple,
        previous,
        index,
        createdAt,
        dates
    };
}


export default privateOperations




  