import privateOperations from './private'

const mongoEquivalences = {
  'in': '$in',
  'includes': '$elemMatch',
  '>=': '$gte',
  '>': '$gt',
  '<': '$lt',
  '<=': '$lte',
  '==': '$eq',
  '!=': '$ne',
};

function findFilter({ showReport, extendedFilters, computeOp }) {
  const privateFunctions = privateOperations(extendedFilters)
  function buildFullNameFilter(value) {

    const fullNameKeys = ['name', 'lastname']

    const $or = String(value).split(' ').reduce((initial, result) => {
        const val = result.trim().toLowerCase()
        if(val) {
            // const regex =  //{ $regex: val, $options: 'i' }
            const $or = fullNameKeys.map(key => ({ [key]: val }))
            return [...initial, { $or }]
        }
        return [...initial]
    }, [])

    return $or.length ? { $or } : {}
  }

  function getConditional(block) {
    if (block && block.field) {
      return mongoEquivalences[block.field._text] || '$eq'
    }

    return '$eq'
  }

  function getValueFromFilter(block) {
    const functionName = block._attributes.type
    if (privateFunctions[functionName]) {
      return privateFunctions[functionName](block.field)
    }

    return privateFunctions.simple(block.field);
  }

  function getValues(block, filter = {}) {
    const conditional = getConditional(block)
    if (Array.isArray(block.value)) {
      const [firstResult, secondResult] = block.value.map(item => getValueFromFilter(item.block))
      const firstValue = firstResult.pureValue
      const secondValue = secondResult.pureValue

      if (firstValue && secondValue) {
        if (conditional === '$elemMatch') {
          filter = { ...filter, [firstValue]: { [conditional]: { $eq: secondValue } } }
        } else {
          filter = { ...filter, [firstValue]: { [conditional]: secondValue } }
        }
      }
    }

    if (block.value && block.value.block) {
        const localBlock = block.value
        const result = getValueFromFilter(localBlock.block)
        const key = result.key || result.pureValue
        const value = result.key ? result.pureValue : false
        if(key) {
            if(key === 'fullName') {
                filter = {...filter, ...buildFullNameFilter(value) }
            } else {
                filter = { ...filter, [key]: {[conditional]: value } }
            }
        }
    }

    if (block.next) return getValues(block.next.block, filter)

    return filter
  }

  return async function (block, filterByBHAccount = true) {
    const [account = false] = showReport.customCenters || []
    const isFrontend = !!window.sessionUser
    const _idBHAccount = isFrontend ? window.sessionUser.account : account
    const defaultFilter = _idBHAccount && filterByBHAccount ? { _idBHAccount } : {}
    const values = getValues(block)
    return { ...defaultFilter, ...values }
  }
}

export default findFilter