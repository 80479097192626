import axios from '../utils/axios';


export const auth = {
  getToken(){
    return window.localStorage.getItem('@token') || '';
  },
  getUser(){
    try {
      return JSON.parse(localStorage.getItem("@user") || {});
    } catch (error) {
      return {}
    }
  },
  async loginGoogle(response) {
    if (!response) return { error: { message: 'Fail request Google' } };

    const token = response.tokenId;
    const email = (response.profileObj && response.profileObj.email) || '@';
    if (email.split('@')[1] !== 'bewe.io') {
      // https://accounts.google.com/o/oauth2/revoke?token={token}
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 != null) {
        auth2.signOut().then(
          auth2.disconnect().then(e => {
            console.log('eeeouteE:', e);
          })
        );
      }

      return { error: { message: 'Only bewe email' } };
    }

    const payload = await axios.post('/account/loginGoogle', { gtoken: token } );
    const user = {
      email: payload.user.email,
      name: `${payload.user.name} ${payload.user.lastname || ''}`,
      picture: payload.user.picture,
    };
    window.localStorage.setItem('@picture', payload.user.picture);
    window.localStorage.setItem('@user', JSON.stringify(user));
    window.localStorage.setItem('@token', payload.token);

    return user;

  },

  async login(data) {
    const user = {
      email: data.email,
      name: `${data.firstName} ${data.lastName}`,
    };
    user.token = window.btoa(`${user.email}:${data.password}`);
    window.localStorage.setItem('logued', JSON.stringify(user));

    this.currentUser = data;
  },
  logout() {
    window.localStorage.clear();
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 != null) {
        auth2.signOut().then(auth2.disconnect());
      }
    }
  },
};

export default auth;
