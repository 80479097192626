/* eslint-disable no-console */
import React, { useState, useRef, useEffect } from 'react';
import Blockly from './blockly/override';
import { xml2js, json2xml } from 'xml-js';
import usePipelineStore from '../store';
import JSONTree from './JSONTree';

import BlocklyComponent, {
  Block,
  Value,
  Mutator,
  Shadow,
  Category,
} from './blockly';

import './blocks/customblocks';
import './generator/generator';

const Pipeline = () => {
  const simpleWorkspace = useRef(null);
  const [initialXml, setInitialXml] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const { effects, ...state } = usePipelineStore();

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { blocks } = state.showReport;
    if (firstLoad && blocks && blocks.pipeline) {
      setInitialXml(
        json2xml(state.showReport.blocks.pipeline, {
          compact: true,
          ignoreComment: true,
          spaces: 4,
        }),
      );
      setFirstLoad(false);
    } else if (state.showReport) {
      setInitialXml(
        '<xml xmlns="http://www.w3.org/1999/xhtml"><block type="pipeline" x="250" y="50"></block></xml>',
      );
    }
  }, [state.showReport]);

  if (!initialXml) {
    return null;
  }

  const generateCode = async (event) => {
    if (simpleWorkspace && simpleWorkspace.current) {
      const { workspace } = simpleWorkspace.current;
      const dom = Blockly.Xml.workspaceToDom(workspace);
      const xml = Blockly.Xml.domToText(dom);
      try {
        const pipelineJSON = xml2js(xml, { compact: true });
        effects.setBlock('pipeline', pipelineJSON);

        const block = workspace.getBlockById(event.blockId);
        if (block) {
          const rootBlock = block.getRootBlock();
          if (rootBlock.type === 'pipeline') {
            if (pipelineJSON.xml) {
              effects.setResults('pipeline');
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <BlocklyComponent
          ref={simpleWorkspace}
          readOnly={false}
          initialXml={initialXml}
          onMove={generateCode}
          onChange={generateCode}
          move={{
            scrollbars: true,
            drag: false,
            wheel: true,
            trashcan: false,
          }}
        >
          <Category name="Bucles" colour={300}>
            <Block type="each" />
          </Category>
          <Category name="Consulta" colour={20}>
            <Block type="find">
              <Value name="where">
                <Shadow type="queryNone" />
              </Value>
            </Block>
            <Block type="aggregate">
              <Value name="where">
                <Shadow type="queryNone" />
              </Value>
            </Block>
            <Block type="get" />
            <Block type="debug" />
            <Block type="queryNone" />
          </Category>
          <Category name="Matemáticas" colour={220}>
            <Block type="query:count" />
            <Block type="query:sum" />
            <Block type="query:avg" />
            <Block type="math_arithmetic" />
            <Block type="math_single" />
          </Category>
          <Category name="Asignación" colour={120}>
            <Block type="set" />
            <Block type="globalVariable" />
          </Category>
          <Category name="Formato" colour={0}>
            <Block type="toArray" />
            <Block type="orderBy" />
            <Block type="groupBy" />
          </Category>
          <Category name="Valores" colour={195}>
            <Block type="valueFromFilter" />
            <Block type="valueFromDateFilter" />
            <Block type="string" />
            <Block type="number" />
            <Block type="null" />
            <Block type="undefined" />
            <Block type="boolean" />
            <Block type="previous" />
            <Block type="createdAt" />
            <Block type="dates" />
          </Category>
          <Category name="Logica" colour={165}>
            <Block type="compare" />
            <Block type="where" />
            <Block type="attribute" />
            <Block type="and" />
            <Block type="or" />
            <Block type="index" />
          </Category>
          <Category name="Json" colour={45}>
            <Block type="jsonStart" />
            <Block type="jsonDictionary">
                <Mutator items="0"></Mutator>
            </Block>
            <Block type="jsonArray" >
                <Mutator items="0"></Mutator>
            </Block>
            <Block type="jsonNumber" />
            <Block type="jsonString" />
            <Block type="jsonObjectId" />
            <Block type="jsonFalse" />
            <Block type="jsonTrue" />
            <Block type="jsonSpace" />
          </Category>
        </BlocklyComponent>
        <JSONTree data={state.results.pipeline} />
      </header>
    </div>
  );
};

export default Pipeline;
