import React from 'react';
import { Icon } from 'rsuite';
import Users from '../components/Users';
import ErrorPage from '../components/ErrorPage';
import Dashboard from '../components/Dashboard';
import Reports from '../components/Reports';
import ReportSteps from '../components/Reports/reportSteps';
import EmailTemplates from '../components/EmailTemplates';
import EmailTemplateDetail from '../components/EmailTemplates/detail';




export const routes = [
  // {
  //   keyn: '1',
  //   icon: <Icon icon="dashboard" />,
  //   text: 'Dashboard',
  //   link: '/backoffice/dashboard',
  //   component: () => <Dashboard />,
  // },
  // {
  //   keyn: '2',
  //   icon: <Icon icon="group" />,
  //   text: 'Members',
  //   link: '/backoffice/list',
  //   component: () => <Users />,
  // },
  // {
  //   keyn: '3',
  //   text: 'Errors',
  //   icon: <Icon icon="exclamation-triangle" />,
  //   children: [
  //     {
  //       keyn: '3-1',
  //       text: '404',
  //       link: '/error/404',
  //       component: () => <ErrorPage />,
  //     },
  //     {
  //       keyn: '3-2',
  //       text: '500',
  //       link: '/error/500',
  //       component: () => <ErrorPage code={500} />,
  //     },
  //   ],
  // },
  {
    keyn: '4',
    icon: <Icon icon="group" />,
    text: 'Reportes',
    link: '/backoffice/reports',
    component: () => <Reports />,
  },
  {
    keyn: '5',
    link: '/backoffice/report/:id', //text null to detail component
    component: () => <ReportSteps />,
  },
  {
    keyn: '6',
    icon: <Icon icon="group" />,
    text: 'Plantillas email',
    link: '/backoffice/email-templates',
    component: () => <EmailTemplates />,
  },
  {
    keyn: '7',
    link: '/backoffice/email-template/:namespace',
    component: () => <EmailTemplateDetail />,
  },
];
