import React from 'react';
import { Alert } from 'buwi';
import useTemplatesStore from '../store';

function DeleteTemplateModal() {
  const { effects, deleteModal } = useTemplatesStore();

  const handleModalOnClose = () => {
    effects.toggleDeleteModal(false);
  };

  const handleDelete = () => {
    effects.deleteTemplateLanguage();
  }

  const buttons = [
    {
      text: 'Cancelar',
      type: 'cancel',
      onClick: handleModalOnClose,
    },
    {
      text: 'Eliminar',
      type: 'submit',
      onClick: handleDelete,
    },
  ];

  return (
    <Alert
      buttons={buttons}
      isOpen={deleteModal}
      onClose={handleModalOnClose}
      title="Atención!"
    >
      ¡Cuidado! Si eliminas este template, vas a perder toda su configuración y
      diseño
    </Alert>
  );
}

export default DeleteTemplateModal;
