import * as Blockly from 'blockly/core';
import { formats, simpleFormats } from './utils';

const color = 210;

// Blockly.Blocks.filter = {
//   init() {
//     this.appendDummyInput()
//       .appendField('filter by')
//       .appendField(new Blockly.FieldTextInput('[idClient+day]'), 'dexie_field')
//       .appendField('operator_field')
//       .appendField(
//         new Blockly.FieldDropdown(dexieWhereClauses),
//         'operator_field',
//       );
//     this.appendStatementInput('filter_value');
//     this.setOutput(true, 'value');
//     this.setColour(color);
//     this.setTooltip('');
//     this.setHelpUrl('');
//   },
// };

Blockly.Blocks.overviewLabel = {
  init() {
    this.appendDummyInput()
      .appendField('label')
      .appendField(new Blockly.FieldTextInput(''), 'name')
      .appendField(
        new Blockly.FieldDropdown(simpleFormats),
        'option',
      );
    this.appendStatementInput('pipeline')
      .setCheck(['Array', 'Where'])
      .appendField('');

    this.setPreviousStatement(true, ['Overview', 'Label']);
    this.setNextStatement(true, 'Label');
    this.setColour(color);
  },
};

Blockly.Blocks.column = {
    init() {
      this.appendDummyInput()
        .appendField('Texto')
        .appendField(new Blockly.FieldTextInput(''), 'label');
  
      this.appendDummyInput()
        .appendField('Campo')
        .appendField(new Blockly.FieldTextInput(''), 'field');
      
      this.appendDummyInput()
        .appendField('Formato')
        .appendField(
          new Blockly.FieldDropdown(formats),
          'format'
        );
  
      this.setPreviousStatement(true, ['Overview', 'Label']);
      this.setNextStatement(true, 'Label');
      this.setColour(color);
    },
  };

Blockly.Blocks.overviewWhere = {
  init() {
    this.appendDummyInput().appendField('where');
    this.appendStatementInput('Where').setCheck('logic');
    this.setPreviousStatement(true, 'Where');
    this.setNextStatement(true, 'Array');
    this.setColour(color);
  },
};
