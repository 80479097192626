import React, { useState, useContext, useEffect } from 'react'
import {auth } from '../services/auth';


const SessionContext = React.createContext()

const SessionProvider = ({ children }) => {
  const user = auth.getUser();
  const [currentUser, setCurrentUser] = useState(_=>user.email ? user : {})
  const logged = !!currentUser.email;

  function logout(){
    setCurrentUser({});
    auth.logout();
  }

  return <SessionContext.Provider value={{ logged, currentUser, setCurrentUser, logout }}>{children}</SessionContext.Provider>
}

export const useSession = () => {
  return useContext(SessionContext)
}


export default SessionProvider;