import * as Blockly from 'blockly/core';
import { compareOperators } from './utils';

const color = 165;

Blockly.Blocks.compare = {
  init() {
    this.appendValueInput('first_expresion').setCheck('value');
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown(compareOperators),
      'operator',
    );
    this.appendValueInput('second_expresion').setCheck('value');
    this.setInputsInline(true);
    this.setPreviousStatement(true, ['where', 'logic', 'find-logic']);
    this.setNextStatement(true, 'logic');
    this.setColour(color);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks.logicOperation = {
  init() {
    this.appendValueInput('first_expresion').setCheck('value');
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ['and', 'and'],
        ['or', 'or'],
      ]),
      'operator',
    );
    this.appendValueInput('second_expresion').setCheck('value');
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(color);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks.and = {
  init() {
    this.appendStatementInput('and').setCheck('logic').appendField('and');
    this.setPreviousStatement(true, ['where', 'logic', 'find-logic']);
    this.setNextStatement(true, 'logic');
    this.setColour(color);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks.or = {
  init() {
    this.appendStatementInput('or').setCheck('logic').appendField('or');
    this.setPreviousStatement(true, ['where', 'logic', 'find-logic']);
    this.setNextStatement(true, 'logic');
    this.setColour(color);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks.attribute = {
  init() {
    this.appendValueInput('value').setCheck(['value', 'Number']);
    this.setInputsInline(true);
    this.setPreviousStatement(true, ['where', 'logic', 'find-logic', 'Fetch']);
    this.setNextStatement(true, ['logic', 'value']);
    this.setColour(color);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks.index = {
    init: function() {
        this.appendDummyInput()
            .appendField(new Blockly.FieldLabelSerializable("index"), "title")
            .appendField(new Blockly.FieldTextInput("[day+idClient]"), "index");
        this.appendDummyInput()
            .appendField(new Blockly.FieldLabelSerializable("between"), "betweenTitle");
        this.appendStatementInput("betweenStatement")
            .setCheck('logic');
        this.appendDummyInput()
            .appendField(new Blockly.FieldLabelSerializable("and"), "andTitle");
        this.appendStatementInput("andStatement")
            .setCheck('logic');
        this.setPreviousStatement(true, ['where', 'logic']);
        this.setNextStatement(true, ['logic']);
        this.setColour(color);
      }
  };

  Blockly.Blocks.where = {
    init: function() {
        this.appendDummyInput()
        .appendField(new Blockly.FieldLabelSerializable("where"), "NAME");
        this.appendStatementInput("whereStatement")
            .setCheck('logic');
        this.setPreviousStatement(true, ['Array']);
        this.setNextStatement(true, ['Array']);
        this.setColour(color);
    }
  }