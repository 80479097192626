define([], function() {
    return {
        "reports": "Informes",
        "reports_description": "Los informes te servirán para analizar tu negocio al detalle. Si hay algún informe que falta, <a class=\"chat-session section-link\">haz tu petición por chat</a> y lo añadiremos si es posible.",
        "iniHour": "Hora inicial",
        "fiHour": "Hora final",
        "billing": "Facturación",
        "price_per_visit": "Gasto por visita",
        "visits": "Visitas",
        "avg_visits": "Promedio de visitas por cliente",
        "avg_price": "Gasto medio",
        "r_hour-interval_title": "Facturación por franja horaria",
        "r_hour-interval_desc": "Analiza lo que factura el negocio en unas horas determinadas. Te servirá para saber de un vistazo qué horas son buenas y qué horas no, así sabrás por ejemplo si merece la pena abrir de 7 a 8 de la tarde.",
        "r_top-treatments_title": "Servicios que más facturan",
        "r_top-treatments_desc": "Descubre los servicios o categorías de servicios que más facturan y los servicios que no están funcionando.",
        "reports_description_disabled": "Por el momento tienes esta sección deshabilitada. Comunícate con soporte para poder activarla. Disculpa las molestias.",
        "cashed_by": "Cobrado",
        "products_bought": "Prod. comprados",
        "products_sold": "Prod. vendidos",
        "advanced_salary": "Sueldo anticip.",
        "net_cash": "Neto a favor",
        "r_employees-accounting_title": "Rendición de cuentas",
        "r_employees-accounting_desc": "Informe sobre los cobros de cada empleado, los productos vendidos y comprados y sus anticipos.",
        "participes_soc": "Participa sociedad",
        "unitary_pvp": "PVP unidad",
        "total_pvp": "PVP total",
        "total_cost": "Coste total",
        "r_stock-cost_title": "Informe de Stock",
        "r_stock-cost_desc": "Informe de coste y precio según el stock actual de productos",
        "sold": "Vendidos",
        "profit_margin": "Margen de beneficios",
        "r_product-profit_title": "Margen de beneficios de productos",
        "r_product-profit_desc": "Informe sobre los beneficios de las ventas de productos según su margen de beneficios",
        "r_most-sold-products_title": "Productos más vendidos",
        "r_most-sold-products_desc": "Descubre los productos más vendidos y los que son menos populares.",
        "r_used-giftcards_title": "Uso de Tarjetas regalo",
        "r_used-giftcards_desc": "Infórmate sobre el uso de las Tarjetas regalo de tu centro.",
        "r_total-product-sales_title": "Total ventas de Productos",
        "r_total-product-sales_desc": "Compara las ventas de productos en los diferentes meses",
        "r_total-treat-sales_title": "Total ventas de Tratamientos",
        "r_total-treat-sales_desc": "Compara las ventas de tratamientos en los diferentes meses",
        "loss": "Pérdida",
        "r_discount-loss_title": "Importe en descuentos",
        "r_discount-loss_desc": "Analiza cuánto no se ha cobrado al hacer descuentos los diferentes meses o años.",
        "r_non-active-products_title": "Productos inactivos",
        "r_non-active-products_desc": "Infórmate sobre qué productos no han sido comprados el último mes",
        "r_non-active-clients_title": "Clientes Inactivos",
        "r_employees-work_title": "Citas Empleados",
        "r_employees-work_desc": "Infórmate sobre cuantas citas ha hecho cada empleado",
        "r_product-analysis_desc": "Analiza todos los movimientos de un producto concreto.",
        "Descargar en PDF": "Descargar en PDF",
        "Filtar por suscripción": "Filtar por suscripción",
        "Intenta cambiando los filtros del reporte.": "Intenta cambiando los filtros del informe.",
        "Agrupar por": "Agrupar por",
        "Cantidad": "Cantidad",
        "Facturado": "Facturado",
        "Hora": "Hora",
        "No hay información disponible para este reporte.": "No hay información disponible para este informe.",
        "Realizados": "Realizados",
        "Reportes": "Informes",
        "Tratamientos activos": "Tratamientos activos",
        "Ventas": "Ventas",
        "spent": "Gastado",
        "spentColumn": "Gastado",
        "Subscribed since": "Suscripción desde",
        "Subscription": "Suscripción",
        "Subscriptions": "Suscripciones",
        "Next payment": "Siguiente pago",
        "Charge": "Cargo",
        "Subscription charge": "Precio suscripción",
        "Creditcard linked": "Tarjeta vinculada",
        "Subscribed partners": "Clientes con suscripción",
        "Active clients": "Clientes activos",
        "Inactive partners": "Suscripciones no utilizadas",
        "subscriptionsCountColumn": "Suscripciones activas",
        "chargesAvg": "Cargos promedio",
        "Total charged": "Total suscripciones cobradas",
        "Email": "Correo electrónico",
        "Average charge": "Gasto promedio",
        "Client": "Cliente",
        "Work's date": "Fecha de la cita",
        "Employee": "Empleado",
        "Work count": "Citas registradas",
        "Recopilando información...": "Recopilando información...",
        "Espera un momento mientras recopilamos y analizamos toda la información necesaria para este informe.": "Espera un momento mientras recopilamos y analizamos toda la información necesaria para este informe.",
        "Clientes": "Clientes",
        "Grupo de clientes": "Grupo de clientes",
        "Filtrar por atributo": "Filtrar por atributo",
        "Profesión": "Profesión",
        "Tintes": "Tintes",
        "Alergias": "Alergias",
        "Peso": "Peso",
        "Altura": "Altura",
        "Citas": "Citas",
        "Cobros": "Cobros",
        "Última visita/cobro": "Última visita/cobro",
        "Filtrar por": "Filtrar por",
        "Todas los productos": "Todas los productos",
        "Todos los tratamientos": "Todos los tratamientos",
        "Todos los servicios": "Todos los servicios",
        "Filtrar por suscripción": "Filtrar por suscripción",
        "Todas las suscripciones": "Todas las suscripciones",
        "Nuevas": "Nuevas",
        "Bajas": "Bajas",
        "Visitas / Limite": "Visitas / Limite",
        "Todos los empleados": "Todos los empleados",
        "Buscar por cliente...": "Buscar por cliente...",
        "Clases en total": "Clases en total",
        "Asistidas": "Asistidas",
        "Cancelaciones tardías": "Cancelaciones tardías",
        "de asistencia": "de asistencia",
        "Todos los productos": "Todos los productos",
        "Clases del día": "Clases del día",
        "No se impartieron clases el día seleccionado, elige otro día para continuar": "No se impartieron clases el día seleccionado, elige otro día para continuar",
        "Visitas en el mes": "Visitas en el mes",
        "Coste relativo": "Coste relativo",
        "Promedio de coste relativo": "Promedio de coste relativo",
        "Clase": "Clase",
        "Suscripción": "Suscripción",
        "Visitas en el periodo": "Visitas en el periodo",
        "Coste de la suscripción": "Coste de la suscripción",
        "Tratamientos": "Tratamientos",
        "Pack": "Bono",
        "Date": "Fecha",
        "Subscribed date": "Inicio de suscripción",
        "Sold by": "Vendido por",
        "Phone": "Teléfono",
        "Este informe sólo permite visualizar fechas del pasado.": "Este informe sólo permite visualizar fechas del pasado.",
        "Este informe no permite visualizar fechas futuras.": "Este informe no permite visualizar fechas futuras.",
        "report-active-clients-desc": "Analiza cuántos clientes activos tienes en el negocio. Un cliente activo es aquel que ha asistido al menos una vez en los últimos 30 días. Hay que tener en cuenta que los últimos 30 días son los 30 días pasados desde la fecha que marques en el informe, así puedes ver los clientes activos que tenías ayer, hace una semana o incluso hace 3 meses.",
        "report-appointments": "Análisis de ocupación",
        "report-appointments-desc": "<p>Analiza la ocupación de las clases. Puedes agrupar las clases por día, semana o mes.</p><p>Presta atención al porcentaje de ocupación y al número de clientes en lista de espera para decidir si abrir nuevas clases o cerrar otras.</p><p>Descubre si en alguna clase o con algún profesor en concreto la cancelación es más alta de lo habitual.</p>",
        "report-best-hours": "Análisis de horario",
        "report-clientsProductivityReport-desc": "<p>Analiza cuánto utilizan las suscripciones los clientes. Fíjate sobre todo en los extremos:</p><p>- Si hay clientes que no utilizan mucho la suscripción, te proporcionan un precio por clase muy alto pero son los clientes menos fidelizados y que podrían causar baja.</p><p>- Si hay muchos clientes que consumen al máximo la suscripción tendrás clientes muy fidelizados pero vigila que no caiga demasiado el precio por clase por que podría no ser rentable.</p>",
        "report-employeeAppointments": "Citas creadas por empleado",
        "report-employeeAppointments-desc": "Muestra el desglose de citas creadas por cada empleado de tu negocio.",
        "report-employees-accounting": "Rendición de cuentas",
        "report-employees-accounting-desc": "Informe sobre los cobros de cada empleado, los productos vendidos y comprados y sus anticipos.",
        "report-inactive-clients-desc": "Conoce a tus clientes inactivos en un periodo determinado.",
        "report-inactive-clients-2": "Clientes inactivos con suscripción o bono",
        "report-profitable-clients": "Clientes que más gastan",
        "report-pack-sales-desc": "Analiza el flujo de ventas de tus bonos a través del paso del tiempo.",
        "report-product-sales-desc": "Analiza el flujo de ventas de tus productos a través del paso del tiempo.",
        "report-stock-cost": "Informe de inventario",
        "report-stock-cost-desc": "Informe de coste y precio según el inventario actual de productos",
        "report-sub-progress": "Evolución de suscripciones",
        "report-sub-progress-desc": "<p>Analiza los movimientos que han ocurrido en las suscripciones en un periodo de tiempo.</p><p>Un cambio positivo ocurre cuando un cliente cambia a una suscripción de un precio más elevado, sin embargo un cambio negativo ocurre cuando el cliente cambia a una suscripción de precio inferior.</p><p>Un alta es cuando un cliente adquiere una suscripción sin tener otra suscripción previamente, en cambio una baja ocurre cuando un cliente cancela su suscripción y no adquiere ninguna otra.</p>",
        "report-subscriptions-sales": "Venta de suscripciones",
        "report-subscriptions-sales-desc": "Analiza el flujo de ventas de tus suscripciones a través del paso del tiempo.",
        "report-subscriptions": "Suscripciones",
        "report-subscriptions-desc": "Visualiza rápidamente todas las suscripciones activas en un periodo de tiempo. Puedes ver de forma rápida los que están pagando la suscripción de forma automática, el uso que el dan a la suscripción, etc.",
        "report-top-treatments": "Servicios que más facturan",
        "report-top-treatments-desc": "Descubre los servicios que más facturan y los servicios que no están funcionando. En la tabla aparecen todos los tipos de servicio que se han hecho en el periodo seleccionado, ordenados para ver los que más facturan en primer lugar.Eov",
        "report-treatment-sales": "Ventas de Servicios",
        "report-treatment-sales-desc": "Analiza el flujo de ventas de tus servicios a través del paso del tiempo.",
        "% Visitas del día": "% Visitas del día",
        "% de ocupación": "% de ocupación",
        "Asistencias": "Asistencias",
        "Asistencias en total": "Asistencias en total",
        "Bono o suscripción": "Bono o suscripción",
        "Bonos vendidos": "Bonos vendidos",
        "Cancelaciones tempranas": "Cancelaciones tempranas",
        "En lista de espera": "En lista de espera",
        "Media de asistencia": "Media de asistencia",
        "Media de asistencias por cada clase": "Media de asistencias por cada clase",
        "Media de cancelaciones": "Media de cancelaciones",
        "Media de facturación por clase": "Media de facturación por clase",
        "Nuevos clientes": "Nuevos clientes",
        "Plazas máximas": "Capacidad máxima",
        "Precio medio pagado por el cliente": "Precio medio pagado por el cliente",
        "Precio mínimo pagado por el cliente": "Precio mínimo pagado por el cliente",
        "Servicios utilizados": "Servicios utilizados",
        "Todos las categorias": "Todos las categorias",
        "Todos los bonos": "Todos los bonos",
        "Total de asistencias": "Total de asistencias",
        "Visita cobrada": "Visita cobrada",
        "de ocupación": "de ocupación",
        "Última asistencia": "Última asistencia",
        "report-punctuality": "Puntualidad de tus clientes",
        "report-punctuality-desc": "En este informe se analiza la puntualidad de tus clientes en base a los estados de las citas. Si un cliente tiene una cita a las 12:00 y se marca \"En proceso\" a las 12:10, el informe considera que el cliente ha llegado 10 minutos tarde. ",
        "report-future-incomes": "Ingresos previsionales",
        "report-future-incomes-desc": "Descubre cuál es la previsión de ingresos en tu negocio en base a las citas futuras que hay agendadas. Si tus clientes suelen reservar sus citas muy a futuro con este informe podrás preveer cuánto vas a ingresar.",
        "report-deleted-works": "Citas eliminadas",
        "report-deleted-works-desc": "Aquí puedes descubrir las citas de la agenda que han sido eliminadas por los empleados del negocio. Pudiendo ver por quién y cuándo se ha hecho.",
        "Altas": "Altas",
        "Cambio negativo": "Cambio negativo",
        "Cambio positivo": "Cambio positivo",
        "Clientes en total": "Clientes en total",
        "Fecha de eliminación": "Fecha de eliminación",
        "Fecha de la Cita": "Fecha de la Cita",
        "Quien eliminó": "Quien eliminó",
        "old-subscriptions": "Suscripciones antiguas pagadas",
        "pending-old-subscriptions": "Suscripciones pendientes de pago",
        "new-subscriptions": "Suscripciones nuevas",
        "total_clients": "Clientes",
        "r_active-clients_title": "Clientes activos",
        "r_active-clients_desc": "¿Cuántos clientes vinieron la semana pasada? ¿Y el mes anterior? Aquí podrás saberlo",
        "r_non-active-clients_desc": "Aprende qué clientes no han venido al centro desde el último mes.",
        "Clients with packs": "Clientes con bono",
        "report-active-clients": "Clientes activos",
        "report-clientsProductivityReport": "Productividad por cliente",
        "report-inactive-clients": "Clientes inactivos",
        "report-inactive-clients-2-desc": "Vigila qué clientes hace más de 30 días que no asisten al centro pero tienen un bono o una suscripción activa.",
        "report-profitable-clients-desc": "Aquí puedes visualizar los clientes que han generado un ingreso al negocio en un periodo de tiempo. Está ordenado de manera que se vean en primer lugar los clientes que más han gastado.",
        "report-new-clients": "Clientes nuevos",
        "report-new-clients-desc": "Analiza cuántos clientes nuevos tiene el negocio en un periodo de tiempo. Puedes compara periodos de tiempo para ver si estás captando clientes de forma más rápida que antes.",
        "Transactions made": "Transacciones en el periodo",
        "report-product-sales": "Venta de productos",
        "report-pack-sales": "Venta de bonos",
        "report-subscriptionProductionReport": "Análisis de suscripciones",
        "report-subscriptionProductionReport-desc": "Analiza el rendimiento de cada tipo de suscripción. Permitiéndote ver el funcionamiento de cada suscripción en cuanto a las visitas que genera y su rendimiento económico.",
        "created-by-user": "Creado por el usuario",
        "report-daily-close": "Cierre diario",
        "total_works": "Total citas",
        "soldProducts": "Productos vendidos",
        "cash_charge": "Efectivo",
        "clientcreditcard_charge": "Tarjeta vinculada",
        "treat": "Servicios",
        "bajas": "Bajas del mes",
        "report-best-hours-desc": "Análisis de horario",
        "purchase-analysis-report": "Informe de análisis de compras",
        "purchase-analysis-report-desc": "Informe de análisis de compras",
        "Buscar reporte por nombre...": "Buscar informe por nombre...",
        "report-daily-close-desc": "Reporte Cierre diario",
        "total_sales": "Total ventas",
        "total_sold_products": "Productos vendidos",
        "total_clients_by_treatment": "Clientes servicios",
        "average_tickets": "Tickets promedio por cita",
        "charges": "Cobros",
        "sales": "Total ventas",
        "by_day": "Por día",
        "clientsTreatment": "Clientes por servicio",
        "averageTickets": "Tickets promedio por cita",
        "creditcard_charge": "Tarjeta de credito",
        "prepaid_charge": "Con saldo",
        "todebt_charge": "A deber",
        "insurance_charge": "Empresa pagadora",
        "giftcard_charge": "Tarjeta de regalo",
        "Compras_Totales": "Compras Totales",
        "número_insumos_productos_comprados": "Número de insumos y productos comprados",
        "número_servicios": "Número de servicios",
        "Altas del mes": "Altas del mes",
        "Bajas del mes": "Bajas del mes",
        "Citas por empleado": "Citas creadas por empleado",
        "Ingreso recurrente anual": "Ingreso recurrente anual",
        "altas": "Altas del mes",
        "show_reports": "Ver Informes",
        "finalize": "Terminar",
        "aggregated": "Agregado",
        "r_non-active-customers_title": "Clientes Inactivos",
        "Active customers": "Clientes activos",
        "customers with packs": "Clientes con bono",
        "report-active-customers-desc": "Analiza cuántos clientes activos tienes en el negocio. Un cliente activo es aquel que ha asistido al menos una vez en los últimos 30 días. Hay que tener en cuenta que los últimos 30 días son los 30 días pasados desde la fecha que marques en el informe, así puedes ver los clientes activos que tenías ayer, hace una semana o incluso hace 3 meses.",
        "report-customersProductivityReport-desc": "<p>Analiza cuánto utilizan las suscripciones los clientes. Fíjate sobre todo en los extremos:</p><p>- Si hay clientes que no utilizan mucho la suscripción, te proporcionan un precio por clase muy alto pero son los clientes menos fidelizados y que podrían causar baja.</p><p>- Si hay muchos clientes que consumen al máximo la suscripción tendrás clientes muy fidelizados pero vigila que no caiga demasiado el precio por clase por que podría no ser rentable.</p>",
        "report-inactive-customers-desc": "Conoce a tus clientes inactivos en un periodo determinado.",
        "report-inactive-customers-2": "Clientes inactivos con suscripción o bono",
        "report-profitable-customers": "Clientes que más gastan",
        "account_filter": "Filtro de cuentas",
        "all_child_accounts": "Todas las cuentas",
        "others": "Otros",
        "idEmployee": "Creado por",
        "appointmentDate": "Fecha de la cita",
        "dateFrom": "Fecha desde",
        "dateTo": "Fecha hasta",
        "pendingSessions": "Sesiones pendientes",
        "wayToPay": "Forma de pago",
        "count": "Pagos",
        "idResource": "Calendario",
        "totalPrice": "Total costo unidades facturadas",
        "totalUnits": "Total de servicios facturados",
        "totalUnitaryCost": "Total coste unidades facturadas",
        "totalMargin": "Margen beneficio, unidades facturadas",
        "idSubscription": "Suscripción",
        "eventClassName": "Nombre de la clase",
        "eventClassTicket": "Nombre del ticket",
        "your_business": "Tu negocio",
        "business_stages": "Etapas del negocio",
        "quotes": "Cotizaciones",
        "searched_by": "Te buscaron",
        "views": "Te vieron",
        "interacted_by": "Interactuaron",
        "purchases": "Compraron",
        "refresh": "Recargar",
        "refresh_without_cache": "Recargar sin cache",
        "clients_segmented_by_age": "CLIENTES POR SEGMENTOS DE EDADES",
        "gender": "Género",
        "scheduled_appointments": "CITAS AGENDADAS",
        "billing_summary": "Tu Resumen de cobros",
        "subtotal": "Importe",
        "taxes": "Impuestos",
        "classes_or_services": "CLASES O SERVICIOS",
        "employees": "EMPLEADOS",
        "service_qualification": "CALIFICACIÓN DE SERVICIOS",
        "standard_ticket": "TICKET PROMEDIO",
        "completed_appointments": "CITAS FINALIZADAS",
        "cancelled_appointments": "CITAS CANCELADAS",
        "coming_soon": "Próximamente",
        "loyalty_best_option": "Asegúrate de que tu estrategia de fidelización vaya por buen camino.",
        "wait_for": "Espera pronto esta nueva sección de fidelización.",
        "bewe_one_availability_title": "Disponible en plan de pago",
        "bewe_one_availability_subtitle": "Este panel te permite visualizar las principales métricas de captación de tu negocio",
        "bewe_one_availability_paragraph": "Agenda una demostración gratuita de MARKETING ONLINE o, date de alta para habilitarlo.",
        "book_demo": "Agendar demostración",
        "conversion_details": "DETALLES DE CONVERSIONES",
        "visiting_information": "INFORMACIÓN DE TUS VISITAS",
        "viewed_websites": "Páginas Vistas",
        "total_views": "Vistas totales",
        "unique_users": "Usuarios Únicos",
        "visit_length": "Tiempo Permanencia",
        "conversions": "Conversiones",
        "interaction": "INTERACCIÓN",
        "convert_yellow_pages": "TUS CONVERSIONES EN PAGINASAMARILLAS.COM",
        "first_age_range": "18-25 años ",
        "second_age_range": "26-35 años",
        "third_age_range": "36-46 años",
        "fourth_age_range": "46-60 años",
        "without_role": "sin rol",
        "without_name": "sin nombre",
        "without_email": "sin email",
        "occupancy_variable": "de ocupación",
        "employees_lowercase": "Empleados",
        "highlighted_actions": "ACCIONES DESTACADAS",
        "clients_qualification": "CALIFICACIÓN DE CLIENTES",
        "wait_for_caption": "Espera pronto esta sección de gestión",
        "caption_coming_soon": "Ten conocimiento de lo que sucede en tu negocio en tiempo real con informes personalizados.",
        "caption_best_option": "Asegúrate de que tu estrategia de captación vaya por buen camino.",
        "priceWithOutIva": "Facturado sin IVA",
        "debt_description_new_balance": "Clientes con deuda actual. Esta se crea a través de un cobro a deber a los clientes y para quitarla se debe hacer un nuevo cobro, seleccionar al cliente, añadir saldar deuda y finalizar el cobro.",
        "prepaid_description_new_balance": "Total de clientes que tienen saldo a su favor. El cual creas a través de un cobro de añadir saldo a los clientes y para quitar el saldo se debe hacer uso de ese dinero.",
        "alert_new_balance": "¿Quieres borrar la deuda guardada para el cliente seleccionado sin generar cobros? Esta acción no puede deshacerse.",
        "title_new_balance_section": "Saldos y deudas",
        "clients_with_app_installed": "Clientes con la App instalada",
        "uncollected_debt _balance": "Saldo de deuda sin cobro"
};
});