define([], function() {
    return {
        "to_employees": "a usuarios",
        "user_title": "Ficha de usuario/empleado",
        "write_name": "Escribe tu nombre",
        "write_lastname": "Escribe tus apellidos",
        "write_email": "Escribe tu email",
        "write_phone": "Escribe tu teléfono",
        "user_group": "Grupo de usuario",
        "admin": "Administrador",
        "extern_worker": "Trabajador externo",
        "new_group": "Nuevo grupo",
        "write_name_group": "Escribe el nombre del grupo",
        "back_groups": "Volver a los grupos",
        "casher_emp": "Usuario cobrador",
        "useremp": "usuario/empleado",
        "roles": "Especialidades",
        "reg_in": "Registrar entrada",
        "reg_out": "Registrar salida",
        "send_pass": "Enviar contraseña",
        "emp_activity": "Actividad de los usuarios",
        "reg_auto": "Registrar usuarios automáticamente",
        "down_excel": "Descargar en excel",
        "clear_list": "Borrar lista",
        "log_desc": "Habilita la actividad de usuarios y podrás ver cuándo se conectan y desconectan los usuarios/empleados. De esta manera sabrás en todo momento quién y cuándo ha entrado, ofreciéndote una mayor seguridad y control sobre tu información.",
        "see_conections": "Ver conexiones",
        "user_can_cash": "El usuario puede cobrar",
        "user_no_can_cash": "El usuario no puede cobrar",
        "access_program": "Acceso al programa",
        "can_access": "El usuario podrá acceder al programa",
        "cant_access": "El usuario no podrá acceder al programa",
        "general_p": "Permisos generales",
        "can_update": "Puede editarlos",
        "can_create": "Puede crearlos",
        "can_delete": "Puede eliminarlos",
        "gp_all": "El usuario tiene acceso a todas las secciones",
        "gp_ag_cli": "El usuario tiene acceso sólo a agendas y clientes",
        "gp_ag_cli_prod_tr": "El usuario tiene acceso sólo a agendas, clientes, productos y servicios",
        "gp_ag_cli_prod_tr_ma": "El usuario tiene acceso sólo a agendas, clientes, productos, servicios y marketing",
        "gp_ag_cli_prod_tr_ma_cont": "El usuario tiene acceso sólo a agendas, clientes, productos, servicios, marketing y contabilidad",
        "agendas_p": "Acceso del trabajador a las agendas",
        "agendas_p_all": "Puede ver y trabajar con la agenda.",
        "agendas_p_read": "Sólo puede ver la agenda, pero no trabajar con ella.",
        "agendas_p_none": "No puede ver la agenda.",
        "modclient_p": "Clientes que puede modificar",
        "modclient_p_all": "Puede modificar todos los clientes del centro",
        "modclient_p_group": "Puede modificar sólo los clientes de su grupo",
        "modclient_p_own": "Puede modificar sólo sus propios clientes",
        "modclient_p_none": "No puede modificar ningún cliente",
        "sclient_p": "Búsqueda de clientes",
        "sclient_p_all": "Puede buscar todos los clientes del centro",
        "sclient_p_group": "Puede buscar sólo los clientes de su grupo",
        "sclient_p_none": "Puede buscar sólo sus propios clientes",
        "cnotes_p": "Anotaciones del cliente",
        "cnotes_p_all": "Puede ver todas las anotaciones del cliente",
        "cnotes_p_group": "Puede ver sólo las anotaciones que su grupo añada",
        "cnotes_p_none": "Puede ver sólo las anotaciones que el propio trabajador añada",
        "access_worker_cash": "Acceso del trabajador a las cajas",
        "can_see_the_cash": "Puede ver la caja",
        "cant_see_the_cash": "No puede ver la caja",
        "delete_user": "Eliminar usuario",
        "search_label_employee": "Busca un empleado por nombre, email o teléfono",
        "pages_txt_employee": "Empleados en total",
        "dv_employee": "Ficha del empleado",
        "notifResource": "Notificaciones",
        "notifResourceCheck": "El usuario recibe una notificación cuando se le añade una cita en la agenda.",
        "notifResourceNo": "El usuario NO recibe una notificación cuando se le añade una cita en la agenda.",
        "shortcut": "Atajo numérico",
        "write_shortcut": "Asigna al empleado un número...",
        "next_page": "Siguiente Página",
        "prev_page": "Página anterior",
        "no_more_log": "No hay más registros de conexión",
        "delete_employee_warning": "¡Cuidado! Si eliminas este empleado, vas a perder toda su facturación, estadísticas y citas.",
        "resources_p": "Acceso del trabajador a los recursos",
        "resources_p_all": "Puedes ver y trabajar con el recurso.",
        "resources_p_read": "Sólo puedes ver el recurso, pero no trabajar con él.",
        "resources_p_none": "No puedes ver el recurso.",
        "eliminar": "Eliminar",
        "cancelar": "Cancelar",
        "write_dni": "Escribe su dni",
        "not_have": "No tiene",
        "not_have_privileges": "No tiene permisos",
        "priv_other_commerces": "Permisos en centros",
        "priv_other_commerces_yes": "El usuario puede ver recursos y cajas de otros centros",
        "priv_other_commerces_no": "El usuario sólo puede ver recursos y cajas del centro al que pertenece",
        "employees_p": "Acceso del trabajador a la sección Usuarios/Empleados",
        "visible_employees": "Empleados visibles",
        "employees_p_all": "Puede ver todos los empleados",
        "employees_p_commerce": "Puede ver todos los empleados de su sede",
        "employees_p_own": "Puede ver su ficha de empleado",
        "employees_p_none": "No puede acceder a ningún empleado",
        "modemployees_p": "Empleados que puede modificar",
        "modemployees_p_all": "Puede modificar los datos básicos de todos los empleados",
        "modemployees_p_commerce": "Puede modificar los datos básicos de todos los empleados de su sede",
        "modemployees_p_own": "Puede modificar los datos básicos de su ficha de empleado",
        "modemployees_p_none": "No puede modificar los datos básicos de ningún empleado",
        "new_employee": "Nuevo empleado",
        "Ficha del empleado": "Ficha del empleado",
        "Horarios y festivos": "Horarios y festivos",
        "Qué puede hacer": "Qué puede hacer",
        "El empleado necesita un email para enviarle la contraseña": "El empleado necesita un email para enviarle la contraseña",
        "Contraseña enviada por email": "Contraseña enviada por email",
        "Entrada del empleado registrada": "Entrada del empleado registrada",
        "Salida del empleado registrada": "Salida del empleado registrada",
        "pages_txt_employeelog": "Registros en total",
        "user_with_resource": "El usuario tiene agenda (es un recurso)",
        "user_without_resource": "El usuario no tiene agenda",
        "cant_collect_creditcard": "No puede cobrar con tarjeta vinculada del cliente",
        "can_collect_creditcard": "Puede cobrar con tarjeta vinculada del cliente",
        "gp_only_agenda": "El usuario tiene acceso sólo a agendas",
        "clients_p": "Acceso del trabajador a los clientes",
        "visible_clients": "Clientes visibles",
        "clients_p_all": "Puede ver todos los clientes del centro",
        "clients_p_group": "Puede ver sólo los clientes de su grupo",
        "clients_p_own": "Puede ver sólo sus propios clientes",
        "can_read": "Puede leerlos",
        "gp_ag_include_reports": "El usuario tiene acceso sólo a agendas, clientes, productos, servicios, marketing, contabilidad e informes",
        "sclient_p_fullmatch": "Puede buscar clientes entre todas las sedes con coincidencia exacta de email o teléfono",
        "can_login_checkin": "Puede entrar a la app de face check in",
        "cant_login_checkin": "No puede entrar a la app de face check in",
        "can_not_update": "No puede editarlos",
        "can_not_create": "No puede crearlos",
        "create_providers": "Crear proveedores",
        "download_clients": "Descargar clientes",
        "can_download": "Puede descargar",
        "can_not_download": "No puede descargar",
        "employee_can_access": "Puede acceder",
        "employee_can_not_access": "No puede acceder",
        "access_bewe_class": "Acceder a Clases",
        "modify_personalization": "Modificar personalización",
        "modify_bussiness_hours": "Modificar horario comercial",
        "delete_account_details": "Eliminar fidelizaciones",
        "can_delete_ad": "Puede eliminarlas",
        "can_not_delete_ac": "No puede eliminarlas",
        "accounting_visibility": "Visibilidad en contabilidad",
        "is_accounting_visible": "Es visible en contabilidad",
        "is_not_accounting_visible": "No es visible en contabilidad",
        "accounting_permission": "Permiso de contabilidad",
        "has_accounting_privileges": "Tiene permisos de contabilidad",
        "has_not_accounting_privileges": "No tiene permisos de contabilidad",
        "accounting_wages_and_commissions": "Sueldos y Comisiones",
        "can_setup_wages_commissions": "Puede configurar sueldos y comisiones",
        "can_not_setup_wages_commissions": "No puede configurar sueldos y comisiones",
        "phone_email_permission": "Ver email o telefono del cliente",
        "has_phone_email_permission": "Puede ver email o telefono del cliente",
        "has_not_phone_email_permission": "No puede ver email o telefono del cliente",
        "giftcard_permission": "Tarjetas de regalo",
        "has_giftcard_permission": "Puede ver y editar tarjetas de regalo",
        "has_not_giftcard_permission": "No puede ver ni editar tarjetas de regalo",
        "can_only_wages_commissions": "Puede ver únicamente su sueldo y comisiones",
        "clients_p_work": "Puede ver sólo sus clientes agendados",
        "has_not_permission_create": "No tienes permisos de crear",
        "has_not_permission_edit": "No tienes permisos de editar",
        "has_not_permission_delete": "No tienes permisos de eliminar",
        "can_see": "Puede veerlos",
        "cant_create": "No puede crearlos",
        "new_employees": "nuevos empleados",
        "can_edit_stock": "Puede editar el stock",
        "title_employee_main_demo": "Tu negocio sigue creciendo y lo sabemos",
        "subtitle_employee_demo": "Activa más agendas e involucra y controla a todo tu equipo de trabajo configurando los permisos",
        "google_calendar_description_employee": "El calendario de Google se sincronizará automáticamente con la agenda de este empleado.",
        "can_google_calendar_checkin": "El calendario del empleado se sincronizará con el calendario de Google",
        "cant_google_calendar_checkin": "El calendario del empleado no se sincronizará con el calendario de Google",
        "upload_signature": "Subir firma",
        "message_email_error": "El formato del email introducido no parece ser correcto. Por favor, inténtalo de nuevo.",
        "message_error_email_registered": "Lo sentimos, el correo electrónico ya está en uso. Por favor, pruebe con otro correo.",
        "message_update_success": "¡Genial! Los cambios en la información del empleado se han guardado correctamente.",
        "message_registered_success": "El nuevo empleado ha sido registrado correctamente.",
        "propagate_email": "Propagar email",
        "propagate_info": "El cambio del email se propagará en los empleados de tu multiCentro, si no deseas propagar el cambio y desasociar el empleado desmarca la casilla",
        "propagate_disabled": "Propagación desactivada",
        "email_already_exists": "Ya tienes un empleado registrado con este email, si necesitas ayuda contacta con soporte",
        "email_cannot_be_used": "El email no puede ser utilizado, ingresa un email diferente",
        "change_plan": "Cambiar plan",
        "change_Pwd": "Cambiar Contraseña",
        "discontinue_plan": "Desactivar plan",
        "activate_plan": "Activar plan",
        "supplant": "Suplantar",
        "integrations": "Integraciones",
        "info_account": "Información de la cuenta",
        "activePermissionsAgency": "Permiso activo",
        "notActivePermissionsAgency": "Permiso no activo",
        "permissions_agency": "Permisos de agencia",
        "title_warning_related_work": "El empleado tiene citas asignadas!",
        "description_warning_related_work": "No se permitirá eliminar al empleado sin reasignar sus citas a otro miembro del equipo",
        "go_to_agenda": "Ir a la agenda",
        "error_related_work": "Se ha producido un error. Vuelva a intentarlo más tarde",
        "marketing": "Marketing",
        "marketing_online": "Marketing online",
        "communications": "Comunicaciones",
        "media": "Medios"
};
});