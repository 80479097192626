import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Login from '../../components/Login'

const UnauthorizedLayout = (props) => {
return (
  <div className="app unauthorized-layout">
    <Switch>
      <Route path={'/login'} component={Login} />
    </Switch>
  </div>
  )
}

export default UnauthorizedLayout
