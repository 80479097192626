
const env = process.env.NODE_ENV || 'development';
const Package = require('./package.json')
const version = Package.version;

const config = {
    'development':{
        stargate: 'http://localhost:3000/v1',
        google: '396461675119-8udfidkve2epf1be8fiujb988rao1hna.apps.googleusercontent.com',
        secret: '2zgx0MSTn1Kme9Q6QsEbBeXt',
        version
    },
    'production':{
        stargate: 'https://api.bewe.co/v1',
        google: '289250999729-1mncodd5uu9ls2jf0lg073lsq04sl0ev.apps.googleusercontent.com',
        secret: 'bgxjIghlAv-HD4DPe13QVN6P',
        version
    }
}

export default config[env];