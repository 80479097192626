import privateOperations from './private'
import datefns from 'date-fns'
import moment from 'moment'

const mongoEquivalences = {
  'in': '$in',
  'includes': '$elemMatch',
  '>=': '$gte',
  '>': '$gt',
  '<': '$lt',
  '<=': '$lte',
  '==': '$eq',
  '!=': '$ne',
};

function aggregateFilter({ showReport, extendedFilters }) {

  const globalFunctions = privateOperations(extendedFilters)
  const privateFunctions = {
    jsonSpace() {
        return ' '
    },

    jsonObjectId(block) {
        const value = block.field._text
        return value ? `ObjectId:${value}` : ''
    },

    jsonTrue() {
        return true
    },

    jsonFalse() {
        return false
    },

    jsonNumber(block) {
        const number = block.field._text
        return !isNaN(number) ? parseInt(number) : 0
    },

    jsonString(block) {
        return block.field._text || ''
    },

    valueFromDateFilter(block) {
        const field = block.field
        let value = false
        let name = 'valueFromDateFilter'
    
        if(field && field.length) {
            const config = {
                format: 'date', 
                addOrLess: '+',
                timeQuantity: 0,
                time: 'seconds',
            }
        
            for(let i = 0; i < field.length; i++) {
                let { _attributes, _text } = field[i]
                if( _attributes ) config[_attributes.name] = _text
            }
           
            if(config.valueFromDateFilter && extendedFilters[config.valueFromDateFilter]) {
                let date = new Date(extendedFilters[config.valueFromDateFilter])
                const dateFnsFunctionName = 'add' + config.time.charAt(0).toUpperCase() + config.time.slice(1)
                const multiplicator = config.addOrLess === '+' ? 1 : -1
                date = datefns[dateFnsFunctionName](date, parseFloat(config.timeQuantity) * multiplicator)
                name = config.valueFromDateFilter
                if(config.format === 'utc') {
                    value = Date.UTC(
                        date.getFullYear(), 
                        date.getMonth(), 
                        date.getDate(), 
                        date.getHours(), 
                        date.getMinutes(), 
                        date.getSeconds(),
                        0
                    );
                } else if(config.format === 'timestamp') {
                    value = date.getTime()
                } else if(config.format === 'iso') {
                    value = date.toISOString()
                } else {
                    value =  moment(date).format("YYYY-MM-DD")
                }
            }
        }
        return value
    },

    jsonDictionary(block, filter = {}) {
        const functionName = block._attributes.type

        if(globalFunctions[functionName]) {
            const result = globalFunctions[functionName](block.field)
            return result.pureValue
        }

        if(!block.value || !block.field) {
            if(privateFunctions[functionName]) {
                return privateFunctions[functionName](block)
            }

            return []
        }


        const jsonBlock = block.value.block
        if(Array.isArray(block.field)) {
            const result = block.field.reduce((initial, field, key) => {
                const jsonKey = field._text
                const valueBlock = block.value[key].block
                const functionName = valueBlock._attributes.type

                if(globalFunctions[functionName]) {
                    const result = globalFunctions[functionName](valueBlock.field)
                    return {...initial, [jsonKey]: result.pureValue}
                }

                if(privateFunctions[functionName]) {
                    const jsonValue = privateFunctions[functionName](valueBlock)
                    return {...initial, [jsonKey]: jsonValue}
                }

                return initial
            }, {})

            filter = { ...filter, ...result }
        } else {
            if(privateFunctions[functionName]) {
                const jsonKey = block.field._text
                const jsonValue = privateFunctions[functionName](jsonBlock)
                filter = {...filter, [jsonKey]: jsonValue}
            }
        }
        

        return filter
    },

    jsonArray(block) {

        if(!block.value) return []
        const blockValue = Array.isArray(block.value) ? block.value : [block.value]
       
        return (blockValue).map(({ block }) => {
            const functionName = block._attributes.type
          
            if(privateFunctions[functionName]) {
                return privateFunctions[functionName](block)
            }
        })
    },

    jsonStart(block) {
        if(block.value && block.value.block ) {
            const jsonStartBlock = block.value.block
            const functionName = jsonStartBlock._attributes.type
            if(privateFunctions[functionName]) {
                const jsonResult = privateFunctions[functionName](jsonStartBlock)
                if(block.field._text === 'TRUE') {
                    console.log('********* JSON Result string ********')
                    console.log(JSON.stringify(jsonResult))
                    console.log('********* JSON Result parsed ********')
                    console.log(jsonResult)
                }
                return jsonResult
            }
        }

        return []
    }
  }



  async function getValues(block, filter = []) {

    const functionName = block._attributes.type
    if (privateFunctions[functionName]) {
      filter = [...filter, ...privateFunctions[functionName](block)]
    }

    return filter
  }

  return async function (block) {
    // const [account = false] = showReport.customCenters || []
    // const isFrontend = !!window.sessionUser
    // const _idBHAccount = isFrontend ? window.sessionUser.account : account
    // const defaultFilter = _idBHAccount ? [{ $match: { _idBHAccount: { $eq: _idBHAccount } } }] : []
    const values = await getValues(block)
    return values
  }
}

export default aggregateFilter