import React, { useState } from 'react';
import styled from 'styled-components';
import GoogleLogin from "react-google-login";
import config from '../../../config';

import { auth } from '../../services/auth'
import {  useSession  } from '../../routes/Session.js'
const version = config.version;

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;

  .form{
        background-color: white;
        border-radius: 10px;
        justify-content: center;
        padding: 80px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
  }

  .centerh{
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 550px;
    transform: translate(-50%, -50%);
  }
    
  .bewe-logo-form {
      max-width: 90px;
      justify-content: center;
      margin-top: -30px;
      margin-bottom: 10px;
      color: #082c42;
  }

  .welcome {
    color: lightgrey;
    font-weight: 100;
    letter-spacing: 15px;
    line-height: 25pt;
    font-size: 9px;
    text-align: center;
  }

.input-bewe {
  display: flex;
  justify-content: center;
  text-align: center;
}

.input-bewe-group{
  margin-top: 20px;
}

.input-group {
  margin-top: 20px;
  width: 250px;
  line-height: 1.5;
}


.form-control {
  border-color: #21a9af;
  border-radius: 10px;
  text-align: center;
  line-height: 2;
  width: 100%;
}


.btn-google{
  margin-right: 10px; background: rgb(255, 255, 255); padding: 10px; border-radius: 2px
}

`

const ButtonSubmit = styled.button`
  margin-top: 40px;
  border-color: #21a9af;
  border-radius: 15px;
  width: 140px;
  padding: 10px;
  background-color: #21a9af;
  color:white;
`


const ButtonGoogle = styled.a`
  margin-top: 50px;
  border-color: #21a9af;
  border-radius: 15px;
  width: 300px;
  background-color: #21a9af;
  color: black;

  display:flex;
  align-items: center;
  justify-content: center;
  border-color: #21a9af
  border-radius: 15px;
  background-color: white;
`
const Avatar = styled.picture`
  text-align: center;
  height: 100px;
  img{
    width: 100px;
    border-radius: 50%;
  }
`


function Input({ name, type, ph }) {

  return (<div className="input-bewe">
    <div className="input-group">
      <input
        required
        type={type}
        name={name}
        className="form-control"
        placeholder={ph}
      />

    </div>
  </div>)
}


function Login({history}) {
  const picture = window.localStorage.getItem('@picture') || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y';
  const [msgError, setMsgError] = useState("");
  const [avatar, setAvatar] = useState(()=>picture);
  const { setCurrentUser } = useSession();

  async function handleFailureGoogle(e) {
      setMsgError('failure sign up');
  }

  async function handleSuccessGoogle(e) {
    const user = await auth.loginGoogle(e);

    if (user && 'error' in user) {
      setMsgError(user.error.message);
    }else{
      setAvatar(user.picture);
      setCurrentUser(user);
      history.push('/bakoffice')
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation()
    return false;
  }


  return (
    <Container style={{ backgroundImage: 'url(https://images.prismic.io/beweio/d8093c37-da38-4279-96b3-bde1331f2230_backoffice-login-background.jpeg?auto=compress,format)' }}>


      <div className="form centerh" align="center">
        <img className="bewe-logo-form" src="https://images.prismic.io/beweio/b7585760-1b1d-455b-89c0-2d4569a04558_logoBeweLanding3.png?auto=compress,format" />
        <div className="welcome">
          <strong>BIENVENIDO </strong>
         
        </div>
        <Avatar>
            <img src={avatar}>
            </img>
        </Avatar>


        <form onSubmit={handleSubmit}>

          <div className="input-bewe-group">
            <Input type="text" name="email" ph="Usuario" />
            <Input type="password" name="paswword" ph="Contraseña" />
          </div>

          <ButtonSubmit type="submit">
            Entrar
          </ButtonSubmit>

          <div style={{ textAlign: 'center', color: 'white', marginTop: 50 }}>
            <GoogleLogin
              style={{ borderRadius: '20px !important' }}
              clientId={config.google}
              buttonText="Cuenta Bewe"
              onSuccess={handleSuccessGoogle}
              onFailure={handleFailureGoogle}
            />
          </div>
        </form>
        <div style={{color: '#efefef'}} >v.{version}</div>
      </div>
    </Container>
  )
}


export default Login;