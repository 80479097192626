const dateBoundsOptions = {
    all: 'Filtrar por todas las fechas',
    currentPeriod: 'Filtrar por fechas anteriores',
    previousPeriod: 'Filtrar por fechas anteriores a ayer',
};

const keys = Object.keys(dateBoundsOptions);
export default keys.map((value) => ({
    label: dateBoundsOptions[value],
    value,
}));

  
  