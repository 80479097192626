import $objeq from './libs/objeq';
import Search from '../bewelib/libs/search'
import compareOperation from './libs/operations/compare'
import findFilterOperation from './libs/operations/findFilter'
import aggregateFilterOperation from './libs/operations/aggregateFilter'


function pipeline() {
  var pipelineStartDate = false
  const VERSION = 1;

  const queryOp = {
    count: [':= count', 'number'],
    sum: [':= sum', 'number'],
  };

  function computeOp(state) {
    const { filters, webService } = state;

    const extendedFilters = {
      ...filters,
      fromDateTs: filters.fromDate.getTime(),
      toDateTs: filters.toDate.getTime(),
      fromDateISO: filters.fromDate.toISOString(),
      toDateISO: filters.toDate.toISOString(),
    };

    const compare = compareOperation({ ...state, extendedFilters })
    const findFilter = findFilterOperation({ ...state, extendedFilters })
    const aggregateFilter = aggregateFilterOperation({ ...state, extendedFilters })
    

    function each(data, previous, _attrs, _previousBash, computeOp) {
      return Promise.resolve(previous).then((results) => {
        const statementBlock = data.statement && data.statement.block;
        return Promise.all(
          results.map(result =>
            compute(statementBlock, computeOp, result, result)
          ),
        );
      });
    }

    function query(block, previous, attrs) {
      return Promise.resolve(previous).then((results) => {
        const [attr] = attrs;
        const [q, type] = queryOp[attr];
        let field = ''

        if(block.field && block.field._text) {
            field = '-> ' + block.field._text + ' '
        }

        const res = Array.isArray(results) ? results : []
        const q$ = $objeq(field + q);
        const newResults = q$(res);

        if (type === 'number') {
          return newResults[0];
        }

        return newResults;
      });
    }



    function queryFilter(data, previous) {
      return Promise.resolve(previous).then((results) => {
        const { field, value } = data;
        if (!value) return results;

        const mergeFields = ({ block }) => {
          const { field, value } = block;
          const { _text } = field;

          if (!value) return [_text];

          const previous = mergeFields(value);
          return [_text, ...previous];
        };

        const filterQ = field._text;
        const fields = mergeFields(value);
        const isActive = fields.every(
          (item) =>
            item in extendedFilters &&
            (!!extendedFilters[item] || extendedFilters[item] === ''),
        );

        if (isActive) {
          const withParam = fields.reduce((query, field) => {
            const match = new RegExp('\\$' + field, 'gi');
            const val = extendedFilters[field];
            const repl = Array.isArray(val) ? JSON.stringify(val) : val;
            return query.replace(match, repl);
          }, filterQ);

          const q$ = $objeq(withParam);
          return previous.then((results) => q$(results));
        }
        return results;
      });
    }

    function get(data, previous) {
      return Promise.resolve(previous).then((results) => {
        const { _text } = data.field;
        return results[_text];
      });
    }

    function set(data, previous, _, previousBash) {
      return Promise.resolve(previous).then((results) => {
        const { _text } = data.field;
        return { ...previousBash, [_text]: results };
      });
    }

    function toArray(_, previous) {
      return Promise.resolve(previous).then((results) => {
        return results.toArray();
      });
    }

    function extraAttrsFilter(from, previous) {

      const extraFilterActive = ['extraAttrValue', 'extraAttr'].every(item => extendedFilters[item] && extendedFilters[item].length > 0)

      return Promise.resolve(previous).then(results => {
        if (extraFilterActive) {
          return results.filter(item => {
            const obj = item;
            const attr = extendedFilters.extraAttr;
            const text = obj[attr] || (_.find(obj.extraAttributes, { name: attr }) || {}).value || '';
            return Search.findStartWord(text, extendedFilters.extraAttrValue)
          })
        }
        return results
      });
    }

    function orderBy(data, previous) {
      const { field } = data;
      const [orderBy, ordering] = field;

      if (!orderBy || !orderBy._text || !ordering || !ordering._text)
        return Promise.resolve(previous);

      return Promise.resolve(previous).then((results) => {
        const q$ = $objeq(`order by ${orderBy._text} ${ordering._text}`);
        return q$(results);
      });
    }

    function where({ statement }, previous, _attrs, _previousBash, computeOp) {
      return Promise.resolve(previous).then((results) => {
        return compare(statement.block, results, results, false, computeOp)
      })
    }

    async function debug(_block, previous, _attrs, previousBash) {    
        
        return Promise.resolve(previous).then((results) => {
            console.log('*************** DEBUG ***************')
            Object.is(previousBash) ? console.log(previousBash): console.log(results)
            console.log((new Date().getTime() - pipelineStartDate) / 1000 + ' execution seconds')
            console.log('*************** FIN DEBUG ***************')
            return results
        })
    }

    async function aggregate(block) {
        const { field, statement } = block
        const [_, collectionField] = field
        const { _text: collection } = collectionField
        if (webService) {
            const route = `models/${collection}/aggregate`;
            const query = statement ? await aggregateFilter(statement.block) : []
            const results = await webService(route, query, extendedFilters.bhaccount);
            return Promise.resolve(results || [])    
          }
    
          return Promise.resolve([])
    }

    async function find({ field, statement }) {
      const collection = field._text;

      if (webService) {
        const route = `models/${collection}/find`;
        const query = statement ? await findFilter(statement.block) : {}
        const results = await webService(route, query, extendedFilters.bhaccount)
        return Promise.resolve(results || [])
      }

      return Promise.resolve([])
    }

    function groupBy(block, previous) {
        return Promise.resolve(previous).then(results => {
            const key = block.field._text
            const groupedResults = results.reduce((initial, result) => {
                const keyName = result[key]
                const res = Object.keys(result).reduce((initialResult, resultKey) => {
                    const lastRes = result[resultKey]    

                    if(key === resultKey) return { ...initialResult, [key]: keyName }
                    if(!lastRes && lastRes !== 0) return initialResult

                    const initialKey = initial[keyName] || {}
                    const afterRes = initialKey[resultKey] || []

                    if(Array.isArray(lastRes)) {
                        return {...initialResult, [resultKey]: [...new Set(afterRes.concat(lastRes))]}
                    } else if(typeof lastRes === 'object') {
                        return {...initialResult, [resultKey]: [...afterRes, lastRes]}
                    } else if(!isNaN(lastRes)) {
                        const afterRes = initialKey[resultKey] || 0
                        const totalized = parseFloat(lastRes) + afterRes
                        return {...initialResult, [resultKey]: totalized }
                    }

                    return {...initialResult, [resultKey]: [...afterRes, lastRes]}

                }, {})
                return { ...initial, [keyName] : res}
            }, {})

            return Object.values(groupedResults)
        })
        // return results.reduce((m, item) => {
        //     const key = item[groupField];
        //     const itemGroup = m[key] || { [groupField]: key, id: key };
        //     const list = itemGroup.list || [];
        //     m[key] = {
        //         ...itemGroup,
        //         list: list.concat(item)
        //     }
        //     return m;
        // }, {})
    }

    return {
      toArray,
      each,
      query,
      queryFilter,
      set,
      get,
      orderBy,
      extraAttrsFilter,
      where,
      find,
      debug,
      aggregate,
      groupBy
    };
  }


  function compute(block, computeOp, previous = [], previousBash = []) {
    if (!block) return Promise.resolve(previous);

    const { _attributes, statement, next } = block;

    const containers = ['pipeline', 'overview', 'columns', 'dashboard'];
    if (containers.includes(_attributes.type)) {
      pipelineStartDate = new Date().getTime()
      const statementBlock = statement && statement.block;
      return compute(statementBlock, computeOp, previous, previous);
    }

    const [attrName, ...attrs] = _attributes.type.split(':');
    const results = computeOp[attrName]
      ? computeOp[attrName](block, previous, attrs, previousBash, computeOp)
      : previous;

    const nextBlock = next && next.block;
    return compute(nextBlock, computeOp, results, previousBash);
  }

  return {
    VERSION,
    computeOp,
    compute,
  };
}

export default pipeline;
