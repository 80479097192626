import * as Blockly from 'blockly/core';
import {
  dexieWhereClauses,
  dateFilters,
  filterList,
  filterDate,
  timeRanges,
  dateFormatList,
} from './utils';

const color = 195;

Blockly.Blocks.filter = {
  init() {
    this.appendDummyInput()
      .appendField('filter by')
      .appendField(new Blockly.FieldTextInput('[idClient+day]'), 'dexie_field')
      .appendField('operator_field')
      .appendField(
        new Blockly.FieldDropdown(dexieWhereClauses),
        'operator_field',
      );
    this.appendStatementInput('filter_value');
    this.setOutput(true, 'value');
    this.setColour(color);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks.date_filter = {
  init() {
    this.appendValueInput('date_filter')
      .appendField('date filter')
      .appendField(new Blockly.FieldDropdown(dateFilters), 'filter_value');
    this.setInputsInline(false);
    this.setOutput(true, 'value');
    this.setColour(color);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks.valueFromFilter = {
  init() {
    this.appendDummyInput()
      .appendField('filtro')
      .appendField(new Blockly.FieldDropdown(filterList), 'valueFromFilter');
    this.setOutput(true, 'value');
    this.setColour(color);
    this.setTooltip('Valor tomado del filtro');
    this.setHelpUrl('Valor tomado del filtro');
  },
};

Blockly.Blocks.valueFromDateFilter = {
  init() {
    this.appendDummyInput()
      .appendField('filtro fecha')
      .appendField(
        new Blockly.FieldDropdown(filterDate),
        'valueFromDateFilter',
      );
    this.appendDummyInput()
      .appendField('formato    ')
      .appendField(new Blockly.FieldDropdown(dateFormatList), 'format');
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldDropdown([
          ['+', '+'],
          ['-', '-'],
        ]),
        'addOrLess',
      )
      .appendField(new Blockly.FieldNumber(0), 'timeQuantity')
      .appendField(new Blockly.FieldDropdown(timeRanges), 'time');
    this.setOutput(true, 'value');
    this.setColour(color);
    this.setTooltip('Valor de fecha tomada del filtro');
    this.setHelpUrl('Valor de fecha tomada del filtro');
  },
};

Blockly.Blocks.string = {
  init() {
    this.appendDummyInput()
      .appendField('"')
      .appendField(new Blockly.FieldTextInput(''), 'field')
      .appendField('"');
    this.setOutput(true, 'value');
    this.setInputsInline(false);
    this.setColour(color);
  },
};

Blockly.Blocks.number = {
  init() {
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput(''),
      'field',
    );
    this.setOutput(true, ['value', 'Number']);
    this.setInputsInline(false);
    this.setColour(color);
  },
};

Blockly.Blocks.previous = {
    init() {
        this.appendDummyInput()
        .appendField('previous')
        .appendField(new Blockly.FieldTextInput(''), 'field')
        this.setOutput(true, 'value');
        this.setInputsInline(false);
        this.setColour(color);
    },
};

Blockly.Blocks.null = {
  init() {
    this.appendDummyInput().appendField('null');
    this.setOutput(true, 'value');
    this.setInputsInline(false);
    this.setColour(color);
  },
};

Blockly.Blocks.createdAt = {
    init() {
      this.appendDummyInput().appendField('createdAt');
      this.setOutput(true, 'value');
      this.setInputsInline(false);
      this.setColour(color);
    },
  };
  

Blockly.Blocks.undefined = {
  init() {
    this.appendDummyInput().appendField('undefined');
    this.setOutput(true, 'value');
    this.setInputsInline(false);
    this.setColour(color);
  },
};

Blockly.Blocks.dates = {
  init() {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown(dateFormatList), 'format'
    );
    this.setOutput(true, 'value');
    this.setColour(color);
    this.setTooltip('Valor tomado del filtro');
    this.setHelpUrl('Valor tomado del filtro');
  },
};

Blockly.Blocks.boolean = {
  init() {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ['true', 'true'],
        ['false', 'false'],
      ]),
      'valueFromFilter',
    );
    this.setOutput(true, 'value');
    this.setColour(color);
    this.setTooltip('Valor tomado del filtro');
    this.setHelpUrl('Valor tomado del filtro');
  },
};

// Blockly.Blocks['extraAttrsFilter'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField("filtro por atributo")
//         .appendField(new Blockly.FieldTextInput(""), "namespace");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(color);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };