define([], function() {
    return {
        "dv_client": "Ficha del cliente",
        "id": "ID",
        "dni": "DNI",
        "saldo": "Saldo",
        "debt": "Deuda",
        "pet_name": "Nombre de la Mascota",
        "pet_breed": "Raza de la Mascota",
        "new_pet": "Nueva mascota",
        "pets": "Mascotas",
        "attr_label_name": "Escribe tu nombre...",
        "attr_label_lastname": "Escribe tus apellidos...",
        "attr_label_pet_name": "Escribe el nombre de la mascota...",
        "attr_label_pet_breed": "Escribe la raza de la mascota...",
        "attr_label_dni": "Escribe tu DNI...",
        "attr_label_email": "Escribe tu email...",
        "attr_label_phone": "Escribe tu teléfono...",
        "attr_label_phone_2": "Escribe tu teléfono secundario...",
        "attr_label_address": "Escribe tu dirección postal...",
        "attr_label_postcode": "Escribe tu código postal...                ",
        "old_range_1": "0 - 10 años",
        "old_range_2": "11 - 20 años",
        "old_range_3": "21 - 40 años",
        "old_range_4": "41 o más",
        "message_type_1": "Recordatorios",
        "message_type_2": "Ofertas y promociones",
        "message_type_3": "Encuestas",
        "dont_send": "No enviar nada",
        "delete_client": "Eliminar cliente",
        "tab_notes_title": "Anotaciones",
        "tab_history_title": "Actividad",
        "tab_bonus_title": "Fidelizaciones",
        "tab_images_title": "Fotos",
        "new_note": "Nueva anotación",
        "delete_sel_notes": "Eliminar notas",
        "add_note": "Añadir nota",
        "empty_notes": "No hay anotaciones",
        "empty_bonus": "No hay bonos",
        "empty_disc": "No hay descuentos",
        "bonus_bought": "Bonos comprados y otros",
        "next_dates": "Próximas citas",
        "without_assist": "Sin asistir",
        "compact_dates": "Compactar por citas",
        "add_old_history": "Añadir historial antiguo",
        "goto_ticket": "Ir al cobro",
        "discount_by_poll": "Descuento por encuesta",
        "ccdi_str_1": "Descuento conseguido. Le quedan",
        "ccdi_str_2": "servicios",
        "ccdi_str_3": "de",
        "ccdi_str_4": "de",
        "ccdi_str_5": "al",
        "ccdi_str_6": "de descuento.",
        "ccdi_str_7": "gratis.",
        "ccdi_str_8": "Faltan",
        "ccdi_str_9": "servicios",
        "ccdi_str_10": "de",
        "ccdi_str_11": "de",
        "ccdi_str_12": "para conseguir el descuento.",
        "last_visit": "Última visita",
        "bt_visit": "Entre visitas",
        "total_expenses": "Gasto total",
        "not_visit": "No ha venido aún",
        "not_concr": "Sin concretar",
        "favorite_treat": "Sus servicios preferidos",
        "favorite_days": "Días habituales",
        "search_label_client": "Busca un cliente por nombre, email o teléfono",
        "download_txt_client": "Descargar clientes en Excel",
        "pages_txt_client": "clientes en total",
        "q_emailphone": "Estadísticas de teléfono y email de los clientes",
        "ep_emailandphone": "Con email y teléfono",
        "ep_onlyemail": "Sólo con email",
        "ep_onlyphone": "Sólo con teléfono",
        "ep_nothing": "Sin email ni teléfono",
        "visits_last_6": "Visitas en los últimos meses",
        "favorite_treats": "Servicios favoritos",
        "favorite_products": "Productos favoritos",
        "tab_budget_title": "Presupuestos",
        "new_budget": "Nuevo presupuesto",
        "delete_sel_budgets": "Eliminar presupuestos",
        "empty_budgets": "No hay presupuestos",
        "budget": "Presupuesto",
        "budget_treatments": "Tratamientos del presupuesto",
        "go_budget": "Ir al presupuesto",
        "clone_parcial_budget": "Clonar parcialmente",
        "clone_budget": "Clonar presupuesto",
        "avg_expenses": "Gasto medio",
        "tab_trackings_title": "Seguimientos",
        "print_consent": "Firmar consentimiento",
        "has_allergies": "Este cliente tiene alérgias. Tenlo en cuenta para futuras citas o presupuestos",
        "add_annotation": "Añadir anotación",
        "groups": "Grupos",
        "empty_trackings": "No hay seguimientos",
        "add_tracking": "Añadir seguimiento",
        "new_tracking": "Nuevo seguimiento",
        "delete_sel_trackings": "Eliminar seguimientos",
        "all_trackings": "Todos los seguimientos",
        "goto_budget": "Ir al presupuesto",
        "attr_label_cifnif": "CIF/NIF",
        "social_denom": "Razón Social",
        "attr_label_social": "Razón Social",
        "ccdi_str_2_sec": "Productos",
        "ccdi_str_9_sec": "Productos",
        "select_init_date": "Selecciona fecha de inicio",
        "no_treatments_assigned": "Sin servicios asignados",
        "ccdi_expired": "Este descuento expiró.",
        "document": "Documento",
        "tab_document_templates": "Documentos clinícos",
        "tab_document_files": "Archivos",
        "new_document": "Nuevo documento",
        "empty_documents": "No hay documentos clinícos",
        "selected_client": "cliente seleccionado",
        "remove_selected": "Quitar seleccionados",
        "all_selected": "Seleccionar todos",
        "send_message": "Enviar mensaje",
        "sortering": "Ordenar",
        "filtering": "Filtrar",
        "addrem_groups": "Modificar sus grupos",
        "send_message_to_all": "Enviar mensaje a todos",
        "send_message_to": "Enviar mensaje a",
        "group_select": "Selecciona grupos de clientes",
        "gender_select": "Selecciona el sexo de los clientes",
        "address_input": "Introduce la dirección de los clientes",
        "debt_input": "Escoge la deuda de los clientes",
        "greater_or_equal_than": "Mayor o igual que",
        "lesser_or_equal_than": "Menor o igual que",
        "equal_to": "Igual que",
        "prepaid_input": "Escoge el saldo de los clientes",
        "creation_date": "Fecha ingreso",
        "attributes": "Datos",
        "input_dataview": "Filtra por atributos de: ",
        "lastvisit_select": "Selecciona fecha de la última visita",
        "creation_select": "Selecciona fecha de ingreso",
        "from_day": "Del día",
        "to_day": "Al día",
        "groups_none": "Aún no has añadido ningún grupo",
        "active_filters": "Filtros activos",
        "filters": "Filtros",
        "age": "Edad",
        "age_input": "Introduce la edad",
        "postcode_input": "Introduce el código postal",
        "no_filters_active": "No tienes ningún filtro activo",
        "there_are_no_client_groups": "No hay ningún grupo de clientes",
        "select_groups": "Selecciona/Deselecciona grupos",
        "change_pic": "Cambiar foto",
        "Creado a las": "Creado a las",
        "App": "App",
        "¿Tiene app?": "¿Tiene app?",
        "empty_photos": "No hay fotos",
        "empty_subs": "No hay suscripciones",
        "add_subscription": "Añadir suscripción",
        "note_tag_none": "Sin aviso",
        "note_tag_yellow": "Aviso leve",
        "note_tag_red": "Aviso grave",
        "add_new_credit_card": "Añadir nueva tarjeta",
        "delete_credit_card": "Eliminar tarjeta",
        "Ningún archivo almacenado para este cliente.": "Ningún archivo almacenado para este cliente",
        "Producto adquirido": "Producto adquirido",
        "Bono adquirido": "Bono adquirido",
        "Pago de suscripción": "Pago de suscripción",
        "resta sesión del bono": "resta sesión del bono",
        "ha utilizado la fidelización": "ha utilizado la fidelización",
        "ha ganado la fidelización": "ha ganado la fidelización",
        "Tarjeta de regalo adquirida": "Tarjeta de regalo adquirida",
        "Cargo especial": "Cargo especial",
        "Deuda saldada por": "Deuda saldada por",
        "Cobro con deuda por": "Cobro con deuda por",
        "Saldo adquirido por": "Saldo adquirido por",
        "Valido hasta": "Válido hasta",
        "¿Estás seguro de querer eliminar este archivo?": "¿Estás seguro de querer eliminar este archivo?",
        "to_clients": "a clientes",
        "q_clientsmonth": "Clientes que han venido últimamente",
        "no_clients_notice1": "Gestiona tus clientes de forma fácil.",
        "no_clients_notice2": "Ahora mismo no hay ningún cliente añadido. Puedes añadir clientes desde el botón 'Nuevo cliente' o ",
        "selected_clients": "clientes seleccionados",
        "anotó": "anotó",
        "Nota guardada correctamente.": "Nota guardada correctamente.",
        "Nota eliminada correctamente.": "Nota eliminada correctamente.",
        "Cita actualizada correctamente.": "Cita actualizada correctamente.",
        "app": "App",
        "error_join_clients": "Error al intentar unir los clientes, porfavor intenta mas tarde.",
        "message_join_clients": "¿En qué cuenta deseas unificar, todos los clientes seleccionados?",
        "join_clients": "Unir Clientes",
        "continue": "Continuar",
        "cancel": "Cancelar",
        "error_request_join": "Error al realizar la solicitud",
        "joining": "Fusionando",
        "goto_work": "Ir a la agenda",
        "tab_future_title": "Futuro",
        "tab_pay_title": "Pagos",
        "tab_past_title": "Pasado",
        "delete_budget": "Eliminar presupuesto",
        "cancel_budget": "Cancelar presupuesto",
        "Evento adquirido": "Evento adquirido",
        "Curso adquirido": "Curso adquirido",
        "note_tag_vip": "Etiqueta VIP",
        "client_app_ios": "App en iOS",
        "client_app_android": "App en Android",
        "client_app_ios_android": "App en Android y iOS",
        "limit_merge_clients": "La fusión de clientes está limitada a un máximo de",
        "actividad": "Actividad",
        "configuracion": "Configuración",
        "contrato": "Contrato",
        "limites": "Limites",
        "mensual": "Mensual",
        "activos": "Activos",
        "completados": "Completados",
        "expirados": "Expirados",
        "fecha_de_expiracion": "Fecha de expiración",
        "bonos_activos": "Bonos Activos",
        "fecha_inicial": "Fecha Inicial",
        "expiracion_en": "Expiración en",
        "que_incluye": "Que incluye",
        "bono_activo": "Bono activo",
        "bono_inactivo": "Bono inactivo",
        "to": "a",
        "send_thru": "Enviar por",
        "title": "Título",
        "message": "Mensaje",
        "message_sent": "Mensaje enviado",
        "client_card": "Ficha",
        "client_main": "Principal",
        "no_credit_card": "Sin tarjeta vinculada",
        "notes_tag_yellow": "Avisos leves",
        "notes_tag_red": "Avisos graves",
        "Ficha de": "Ficha de",
        "Desde hace": "Desde hace",
        "bonus_detail": "Datos del bono",
        "suscription_detail": "Datos de la suscripción",
        "Autocomprará un nuevo bono el": "Autocomprará un nuevo bono el",
        "send_comunication": "Enviar comunicación",
        "Activas": "Activas",
        "Are you sure?": "¿Está seguro?",
        "messages": "Mensajes",
        "card_expiry": "Fecha de caducidad",
        "New_tag '{0}'": "Nueva categoría '{0}'",
        "Client_creditcard": "Tarjeta de crédito",
        "canceladas": "Canceladas",
        "el": "el",
        "fecha_alta": "Fecha de alta",
        "periocidad": "Periodicidad",
        "save_note": "Guardar nota",
        "subscriptions-detail": "Detalle de Subscripciones",
        "subscriptionSave": "Subscripción guardada",
        "packageSave": "Bono guardado",
        "Contenido del bono": "Contenido del bono",
        "Progeso actual": "Progreso actual",
        "pricing_contract": "El presente Contrato de Servicios tiene una duración de 12 meses a partir del día de hoy y su renovación será automática, mes a mes, siempre y cuando se esté al corriente de pago.",
        "pendientes": "Pendientes",
        "firmados": "Firmados",
        "emitido": "Emitido",
        "empty_cons": "No hay consentimientos",
        "mark_as_signed": "Marcar como firmado",
        "cancel_signature_request": "Cancelar petición de firma",
        "seek_consent": "Busca un consentimiento",
        "without": "Sin",
        "without_clinic_history": "Sin historia clínica",
        "visual_acuity": "Agudeza visual",
        "A.V_without_correction": "A.V Sin corrección",
        "Motor_exam": "Examen motor",
        "Images_or_files": "Imágenes o archivos",
        "Specialized_tests": "Test especializados",
        "Data_obtained_from_aparatologia": "Datos obtenidos de aparatología",
        "Sections": "Apartados",
        "To_attach": "Adjuntar",
        "Visual_control": "Control visual",
        "General": "General",
        "Save_history": "Guardar historia",
        "New_history": "Nueva historia",
        "Has_no_medical_history": "No tiene ninguna historia clínica",
        "Start_creating_your_first_one_in_the_button_above": "Empieza creando su primera en el botón de arriba",
        "To_print": "Imprimir",
        "Display_history": "Desplegar historia",
        "Historia escrita por": "Historia escrita por",
        "history_client": "Historias clínicas",
        "new_history": "Nueva historia",
        "Agudeza visual": "Agudeza visual",
        "Examen motor": "Examen motor",
        "Examen optométrico": "Examen optométrico",
        "empty_history": "Historia clinica en blanco",
        "linked_to_the_invoice": "Vinculado a la factura",
        "tab_bills": "Facturas",
        "Firmado_digitalmente": "Firmado digitalmente el",
        "huella_digital_navegacion": "Huella digital de navegación",
        "pendientes_firma": "Pendientes de firma",
        "inactivos": "Inactivos",
        "create_follow_up": "Crear seguimiento",
        "create_new_sheet_from_this": "Crear nueva hoja a partir de esta",
        "pagos_facturas": "Pagos y facturas",
        "clinic_history": "Historia clínica",
        "Examen Externo y Oftalmoscopia": "Examen externo y oftalmoscopia",
        "pack": "Bonos",
        "edit_activity": "Editar estado y forma de pago",
        "Apply_quota": "Aplicando Cuota",
        "Last_payment": "Último pago",
        "room_space": "Espacio en la clase",
        "work_state": "Estado de la cita",
        "pack_subscription": "Bono y Suscripción",
        "edit_appintment": "Editar cita",
        "applied_subs": "Bono o Suscripción aplicada",
        "appointment_date": "Fecha de aplicacion",
        "empty_subs_and_bonus": "No hay suscripciones ni bonos activos",
        "Editar cita": "Editar cita",
        "Enviarme una prueba": "Enviarme una prueba",
        "packages_and_memberships": "bonos y suscripciones",
        "files": "Archivos",
        "dataset": "Ficha de datos",
        "payments": "Pagos y facturas",
        "Optométricos": "Optométricos",
        "Motivo de la consulta": "Motivo de la consulta",
        "Antecedentes personales": "Antecedentes personales",
        "No refiere": "No refiere",
        "Antecedentes familiares": "Antecedentes familiares",
        "Otros síntomas": "Otros síntomas",
        "Objecto Real": "Objecto Real",
        "Metodo": "Método",
        "Luz": "Luz",
        "Filtro Rojo": "Filtro Rojo",
        "Otros Test": "Otros Test",
        "Sin Correción": "Sin Correción",
        "Con Correción": "Con Correción",
        "Campo Motor": "Campo Motor",
        "Queratometria": "Queratometría",
        "Eje": "Eje",
        "Observaciones": "Observaciones",
        "Refracción o Retinoscopía": "Refracción o Retinoscopía",
        "Esfera": "Esfera",
        "Cilindro": "Cilindro",
        "Con Ciclopegia": "Con Ciclopegia",
        "Subjetivo": "Subjetivo",
        "Av Cercana": "Av Cercana",
        "Av Lejana": "Av Lejana",
        "Tipo Escala": "Tipo Escala",
        "A.V sin correccion": "A.V sin corrección",
        "Visión Lejana": "Visión Lejana",
        "Distancia": "Distancia",
        "Visión Cercana": "Visión Cercana",
        "Lensometría: A.V con corrección": "Lensometría: A.V con corrección",
        "Adición": "Adición",
        "A.V lejana": "A.V lejana",
        "A.V cercana": "A.V cercana",
        "Escalas": "Escalas",
        "Examen Externo": "Examen Externo",
        "Oftalmoscopia": "Oftalmoscopía",
        "default": "Datos principales",
        "years": "Años",
        "Diagnóstico": "Diagnóstico",
        "New_price": "Nuevo precio",
        "add_new_price": "Agregar nuevo precio",
        "inactivas": "Inactivas",
        "course": "Curso",
        "special": "Cargo especial",
        "Distancia(cm)": "Distancia(valor en centímetros)",
        "Metros": "Metros",
        "Centrímetros": "Centrímetros",
        "Mas +": "Mas +",
        "V.L": "V.L",
        "V.C": "V.C",
        "Payments": "Pagos",
        "work": "Cita",
        "Packages_and_Memberships": "Bonos y Suscripciones",
        "Month_balance": "Saldo mensual",
        "visitas_mes": "Visitas al mes",
        "view_training_data": "Ver datos de entrenamiento",
        "training_data": "Datos de entrenamiento",
        "Select_date": "Seleccione la fecha",
        "Fórmula": "Fórmula",
        "Profesional": "Profesional",
        "Tipo de consulta": "Tipo de consulta",
        "Ultimo control visual": "Último control visual",
        "Corrección Actual": "Corrección actual",
        "Lente de uso": "Lente de uso",
        "Ducciones": "Ducciones",
        "Versiones": "Versiones",
        "Reflejos": "Reflejos",
        "Directo": "Directo",
        "Consensual": "Consensual",
        "Acomodativo": "Acomodativo",
        "Contactología": "Contactología",
        "Añadir prueba": "Añadir prueba",
        "Prueba": "Prueba",
        "Poder": "Poder",
        "Refracción": "Refracción",
        "Curva base": "Curva base",
        "Resultado": "Resultado",
        "Diámetro": "Diámetro",
        "Permeabilidad": "Permeabilidad",
        "Formula final": "Formula final",
        "Tipo de lente": "Tipo de lente",
        "Curva": "Curva",
        "Principal": "Principal",
        "Descripción": "Descripción",
        "Tipo de diagnostico": "Tipo de diagnóstico",
        "Causa Externa": "Causa externa",
        "Finalidad consulta": "Finalidad consulta",
        "Codigo consulta (Cups)": "Código consulta (Cups)",
        "Condición usuaria": "Condición usuaria",
        "Numero autorización": "Numero autorización",
        "Diagnosticos": "Diagnósticos",
        "Fracción": "Fracción",
        "Snellen Pies": "Snellen Pies",
        "Snellen Metros": "Snellen Metros",
        "Comea": "Comea",
        "Cristalino": "Cristalino",
        "Parpado": "Parpado",
        "Conjuntiva": "Conjuntiva",
        "Esclera": "Esclera",
        "Camara Anterior": "Camara Anterior",
        "Papila": "Papila",
        "Excavacion": "Excavación",
        "Brillo": "Brillo",
        "Fijación": "Fijación",
        "R Arterial/Vena": "R Arterial/Vena",
        "Biomicroscopia": "Biomicroscopia",
        "Presión intraocular": "Presión intraocular",
        "Ojo dominante": "Ojo dominante",
        "Hipermetropia": "Hipermetropia",
        "Miopia": "Miopía",
        "Astigmatismo": "Astigmatismo",
        "Presbicia": "Presbicia",
        "Accidente de trabajo": "Accidente de trabajo",
        "Accidente de transito": "Accidente de transito",
        "Accidente rabico": "Accidente rábico",
        "Accidente ofidico": "Accidente ofidico",
        "Otro tipo de accidente": "Otro tipo de accidente",
        "Evento catastrófico": "Evento catastrófico",
        "Lesión por agresión": "Lesión por agresión",
        "Lesión autoinflingida": "Lesión autoinflingida",
        "Sospecha de maltrato físico": "Sospecha de maltrato físico",
        "Sospecha de abuso sexual": "Sospecha de abuso sexual",
        "Sospecha de violencia sexual": "Sospecha de violencia sexual",
        "Sospecha de maltrato emocional": "Sospecha de maltrato emocional",
        "Enfermedad general": "Enfermedad general",
        "Enfermedad profecional": "Enfermedad profesional",
        "Otra": "Otra",
        "Atención del parto": "Atención del parto",
        "Atención de recien nacido": "Atención de recién nacido",
        "Atención en plinificacion familiar": "Atención en planificación familiar",
        "Detecciones de Alteraciones de Crecimineto y Desarrollo del menor de 10 años": "Detecciones de alteraciones de crecimiento y desarrollo del menor de 10 años",
        "Detecciones de Alteraciones del desarrollo del Joven (10 a 29 años)": "Detecciones de alteraciones del desarrollo del joven (10 a 29 años)",
        "Detecciones de Alteracion del Embarazo": "Detecciones de alteración del embarazo",
        "Detecciones de Alteraciones del Adulto (30 años en Adelante)": "Detecciones de alteraciones del adulto (30 años en Adelante)",
        "Detecciones de Alteraciones de Agudeza Visual": "Detecciones de alteraciones de agudeza visual",
        "Deteccion de enfermedad Profesional": "Deteccion de enfermedad profesional",
        "No Aplica": "No Aplica",
        "Consulta de primera vez por optometria": "Consulta de primera vez por optometría",
        "Consulta de control o de Seguimiento por optometria": "Consulta de control o de seguimiento por optometría",
        "Impresión diagnostica": "Impresión diagnóstica",
        "Confirmado nuevo": "Confirmado nuevo",
        "Confirmado repetido": "Confirmado repetido",
        "No limitaciones": "No limitaciones",
        "Paresia": "Paresia",
        "Paralisis": "Paralisis",
        "Centrado": "Centrado",
        "Endotropía": "Endotropia",
        "Hipertropía": "Hipertropia",
        "Cero": "Cero",
        "Positivo": "Positivo",
        "Negativo": "Negativo",
        "Copiar prueba": "Copiar prueba",
        "Conductas": "Conductas",
        "Control": "Control",
        "Dias": "Días",
        "Meses": "Meses",
        "Vigencia formula": "Vigencia fórmula",
        "Descripción conducta": "Descripción conducta",
        "Añadir conducta": "Añadir conducta",
        "Remisión Oftalmologo": "Remisión oftalmólogo",
        "Remisión otro especialista": "Remisión otro especialista",
        "Remisión otros examenes": "Remisión otros exámenes",
        "Derecho": "Derecho",
        "Izquierdo": "Izquierdo",
        "Consulta primera vez": "Consulta primera vez",
        "Consulta control": "Consulta control",
        "Acompañante/Responsable": "Acompañante/Responsable",
        "Nombre": "Nombre",
        "Teléfono": "Teléfono",
        "Cédula": "Cédula",
        "Parentesco": "Parentesco",
        "Número autorización": "Número autorización",
        "Número contrato": "Número contrato",
        "Plan beneficios": "Plan beneficios",
        "Número poliza": "Número poliza",
        "Remover fila": "Eliminar fila",
        "display_all": "Desplegar todo",
        "download_rips": "Descargar Rips",
        "Entidades Aseguradoras": "Entidades Aseguradoras",
        "tab_orders_title": "Pedidos",
        "tab_charges_title": "Cobros realizados",
        "Examen Externo/Oftalmoscopia": "Examen Externo/Oftalmoscopía",
        "Refracción/Retinoscopía": "Refracción/Retinoscopía",
        "There_is_no_formula_to_print": "No hay ninguna fórmula para imprimir",
        "Required_field_'Reason_for_consultation'": "Campo requerido 'Motivo de consulta'.",
        "print_history": "Imprimir historia",
        "Ocular": "Ocular",
        "Anamnesis": "Anamnesis",
        "Optical_Center": "Centro Óptico",
        "distance_av": "Distancia Av",
        "nearby_av": "Cercana Av",
        "FORMULA_OPHTHALMIC_LENSES": "Fórmula lentes oftalmicos",
        "FINAL_FORMULA": "Fórmula final",
        "past_activities": "Actividades pasadas",
        "to_select": "Seleccionar",
        "Relacionado No 1": "Relacionado N°1",
        "Relacionado No 2": "Relacionado N°2",
        "Relacionado No 3": "Relacionado N°3",
        "en_total": "en total",
        "lastnameColumn": "Apellido",
        "rips_needed": "Necesitas crear los campos RIPS para poder continuar",
        "fill_fields": "Llena todos los campos",
        "missin_fields": "Faltan por diligenciar estos datos:",
        "rips_missing": "Faltan campos Rips",
        "invalid_client_id": "El documento de identificación que intentas añadir ya pertenece a otro cliente",
        "service": "servicio(s)",
        "service_sec": "producto(s)",
        "cobro_realizado": "Cobro realizado",
        "cobro_generado": "Cobro generado",
        "cobro_deuda": "Cobro con deuda",
        "cita_impagada": "Cita impagada",
        "pending_to_apply_subscription": "Pendiente de aplicar suscripción",
        "pending_to_apply_package": "Pendiente de aplicar bono",
        "early_cancel_by": "Cancelación temprana por",
        "early_cancel_by_client": "Cancelación temprana por el cliente",
        "late_cancel_work_center": "Cancelación tardía por parte de",
        "late_cancel_work_client": "Cancelación tardía por parte del",
        "early_cancel_work_center": "Cancelación temprana por parte de",
        "early_cancel_work_client": "Cancelación temprana por parte del ",
        "the_day": "el día",
        "linked_to_the_order": "Vinculado al pedido",
        "Choose_file": "Elija el archivo",
        "pay_to_debt": "Pago a deber",
        "total_request": "Total pedido",
        "take": "Lleva",
        "for_win": "para ganar",
        "DF": "Astigmatismo",
        "value_seconds_bow": "Valor en segundos de arco",
        "Generate_token": "Generar token",
        "Save_token": "Guardar token",
        "Delete_token": "Eliminar token",
        "Time_of_token": "Tiempo de token",
        "Control de gafas": "Control de gafas",
        "Control preventivo": "Control preventivo",
        "Cuerpo extraño": "Cuerpo extraño",
        "Desviación ocular": "Desviación ocular",
        "Molestia a la luz": "Molestia a la luz",
        "Prurito": "Prurito",
        "Visometria": "Visometría",
        "Mala visión proxima": "Mala visión próxima",
        "Mala visión lejana": "Mala visión lejana",
        "Ardor ocular": "Ardor ocular",
        "Cefalea": "Cefalea",
        "Ojo rojo": "Ojo rojo",
        "Has_no_clinic_documents": "No tiene ningún documento clínico",
        "new_documents": "Nuevo documento",
        "Documento clínico escrito por": "Documento clínico escrito por",
        "Nuevo documento": "Nuevo documento",
        "documents": "Documentos",
        "cifnif": "CIF/NIF",
        "socialDenom": "Razón Social",
        "Token Guardado": "Token guardado",
        "credentials": "Credenciales",
        "IOS_App_Publishing": "Publicación de App IOS",
        "Essilor_Laboratory": "Laboratorio Essilor",
        "Zeiss_Laboratory": "Laboratorio Zeiss",
        "Saved_Credential": "Credencial guardada",
        "Delete_credential": "Eliminar credencial",
        "personales_oculares": "Antecedentes personales oculares",
        "familiares_oculares": "Antecedentes familiares oculares",
        "Delete_all_the_subscriptions": "Quitando Suscripción",
        "clientCanDeleteCreditCard": "Puede eliminar tarjeta de credito",
        "Incapacidad": "Incapacidad",
        "Con Cicloplegia": "Con Cicloplegia",
        "Plantillas de presupuestos": "Plantillas de presupuestos",
        "Sin fórmula": "Sin fórmula",
        "FINAL_FORMULA_CONTACTOLOGY": "Fórmula contactología",
        "print_formula_lens": "Imprimir fórmula contactología",
        "FORMULA_CONTACTOLOGY": "Fórmula contactología",
        "CONVENTIONAL_FORMULA": "Fórmula convencional",
        "formula_lenses": "Fórmula contactología",
        "Borrar_datos": "Borrar datos",
        "birthday_obligatory": "Fecha de cumpleaños obligatoria.",
        "Diseño de lente": "Diseño de lente",
        "Remover prueba": "Remover prueba",
        "go_to_signing": "Ir a la firma de consentimientos",
        "print_visual_aptitud": "Imprimir aptitud visual",
        "occupation": "Ocupación",
        "CERTIFICATE_OF_VISUAL_APTITUDE": "Certificado de aptitud visual",
        "ID": "Identificación",
        "exam_date": "Fecha examen",
        "optometrist": "Optometra",
        "END_RX": "RX FINAL",
        "color_test": "Otros test",
        "birthday_date": "Fecha de cumpleaños",
        "finished_work_center": "Cita finalizada por parte de",
        "finished_work_client": "Cita finalizada por parte del",
        "negative": "negativo",
        "empty_document": "Documento vacío",
        "print_document": "Imprimir Documento",
        "Prisma": "Prisma",
        "Músculos Rectos/Oblicuos": "Músculos Rectos/Oblicuos",
        "Temporal": "Temporal",
        "Inferior": "Inferior",
        "create_new_status": "Crea un nuevo estado",
        "client_status": "Estados del cliente",
        "status_description": "Crea estados y ordénalos para reflejar el avance de cada cliente en tu embudo de ventas. Recuerda, el movimiento lo haces en la ficha de cada cliente y es siempre hacia adelante.",
        "change_status": "Cambiar estado a",
        "select_status": "Seleciona un estado",
        "create": "Crear",
        "status_name": "Nombre del estado...",
        "potential_client": "Cliente potencial",
        "client": "Cliente",
        "lost_client": "Cliente perdido",
        "warnig_status_delete": "¡Cuidado! Estas eliminando un estado ¿deseas continuar?",
        "send_to_all_selected": "Enviar a todos los seleccionados",
        "Appointment_confirmed_by_the_client": "Cita confirmada por el cliente por parte de",
        "client_does_not_attend": "El cliente no asiste a su cita por parte de",
        "Appointment_pending_confirmation_by_the_center": "Cita pendiente de confirmar por el centro",
        "res_rejected_by": "Cancelada por el centro por parte de",
        "show_disabled": "Mostrar deshabilitados",
        "The_NIT_is_not_valid": "Formato de NIT no es válido, debe tener separado el dígito de verificación, ejemplo: 900111222-9",
        "filtered_empty_clients": "No hay clientes que coincidan con el filtro aplicado.",
        "enviar_a": "Enviar a",
        "my_calendar": "Mi calendario",
        "The_DNI_is_not_valid": "El documento de identificacion que intentas añadir ya pertenece a otro cliente",
        "prefix_mid_title": "Prefijo para el consecutivo",
        "status_prefix_mid": "Disponible para Ficha de cliente y Documentos clínicos",
        "prefix_mid": "Prefijo",
        "product_service_without_category": "Sin categoría",
        "recount": "Referidos",
        "gender": "Género",
        "alert_groups": "Debes agregar al menos un grupo",
        "title_clients_main_demo": "Un CRM potente para negocios en crecimiento",
        "subtitle_clients_demo": "Lleva el control de la captación, gestión y fidelización de clientes con un apartado avanzado",
        "plan_activate": "Actívalo en tu plan y disfruta",
        "new_lead": "Nuevo lead",
        "title_leads": "Personaliza los leads de cliente",
        "description_leads": "En esta sección podrás añadir los campos personalizados que se mostrarán en el widget del formulario de registro de leads (adicional a nombre, apellido, mail y teléfono)",
        "dob": "Dia de nacimiento",
        "month_of_birth": "Mes de nacimiento",
        "year_of_birth": "Año de nacimiento",
        "allergies": "Alergias",
        "imc": "imc",
        "weight": "Peso",
        "size": "Altura",
        "temperature": "Temperatura",
        "body_pressure": "Presión arterial",
        "packageError": "Intente más tarde",
        "gender_undefined": "No especificado",
        "mantatory_field_error": "Campo obligatorio",
        "customer_pays": "El cliente paga",
        "customer_owes": "El cliente deja a deber",
        "delete_client_future_activity": "Este cliente no pudo ser eliminado por tener citas futuras, es necesario desapuntarlo de la agenda futura para continuar.",
        "active_client": "Cliente activo",
        "email_already_in_use": "El correo electronico ya esta en uso ",
        "select_configuration_again": "Selecciona nuevamente la configuración",
        "delete_client_message": "¡Cuidado! Si eliminas el cliente vas a perder toda su información y no será posible recuperarla.",
        "Añadir": "Añadir",
        "id_status": "Estado del cliente"
};
});