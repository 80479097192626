import { startOfDay, endOfDay, endOfMonth, startOfMonth, startOfYear, subYears, subMonths, subWeeks, subDays, addYears, endOfYear, endOfISOWeek, startOfISOWeek, addDays, addWeeks, addMonths } from 'date-fns'
import get from 'lodash/get';
import deburr from 'lodash/deburr';


/**
 * Timespan fn mutators
 *
 * @param {string} timespan lapse.
 * @returns {Object} date mutators for timespan.
 */
export function timespanDate(timespan) {
  switch (timespan) {
    case "day":
      return {
        from: startOfDay,
        to: endOfDay
      };
    case "week":
      return {
        from: startOfISOWeek,
        to: endOfISOWeek
      };
    case "month":
      return {
        from: startOfMonth,
        to: endOfMonth
      };
    case "year":
      return {
        from: startOfYear,
        to: endOfYear
      };
    case "range":
      return {
        from: startOfMonth,
        to: endOfMonth
      };
    default:
      return {
        from: pass => pass,
        to: pass => pass
      };
  }
}

export function round(n) {
  return Math.round(n * 100) / 100;
}



const setPropertyFromArgs = function (fnMemoize, args) {
  let propToCheck = [];
  const normalizeArgs = (args)=>{
    return args.map(arg=>{
      if(arg instanceof Date){
        return +new Date(arg);
      }else if(arg instanceof Object){
        if('loading' in args ){
          return 'state';
        }else{
          return JSON.stringify(arg).replace(/[^A-Za-z0-9]+/g,"");
        }
      }else{
        return String(arg)
      }
    })
  }
  propToCheck = propToCheck.concat( fnMemoize.name, normalizeArgs(args));
  const str = propToCheck.join('|');
  return str;
}


export function memoize(fnMemoize, options= {}) {
  const memoizedCache = {}  // A closeure Object  *is magic!!!
  const memoizedTime = {}
  const cacheTimeOption = !options.time ?0: +options.time*1000; 
  
  return async function(...args) {
    const propToCheck = setPropertyFromArgs(fnMemoize, args);
    const log = (msg)=> {
      if(location.href.includes('localhost')){
        return console.log('Develop detected: ',msg)
      }
    }
    const currentTime =new Date().getTime();
    const cachedTime = +(memoizedTime[propToCheck] || currentTime) + cacheTimeOption;
     
    if (!memoizedCache[propToCheck] || ((currentTime)>=cachedTime)  && cacheTimeOption>0) {
      log('From request ', propToCheck);
      const resp = await fnMemoize(...args);
      if(options.toJson){
        memoizedCache[propToCheck] =  resp.toJSON();
        memoizedTime[propToCheck] = new Date().getTime();
      }else{
        memoizedCache[propToCheck] =  resp
      }
      
    } else  {
      log('From Cache');
    }
    //log('inMemo:',memoizedCache)
    return memoizedCache[propToCheck];
  }
}


export function filterListByWords(list, q, fields) {
  function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  }
  const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
  const hasTrailingSpace = q.endsWith(' ');
  const searchRegex = new RegExp(
      words
          .map((word, i) => {
              if (i + 1 === words.length && !hasTrailingSpace) {
                  // The last word - ok with the word being "startswith"-like
                  return `(?=.*\\b${escapeRegExp(word)})`;
              } else {
                  // Not the last word - expect the whole word exactly
                  return `(?=.*\\b${escapeRegExp(word)}\\b)`;
              }
          })
          .join('') + '.+',
      'gi'
  );

  // eslint-disable-next-line no-unused-vars
  return list.filter(item => {
      return searchRegex.test(eval(fields));
  });
}

/**
*
*
* @export
* @param {*} enteredPhrase
* @param {*} list
* @param {*} keys
* @returns
* @example: filterListMultiWord('activos',listArray, ["name","description"])
*/


const preps = [
  'a',
  'ante',
  'con',
  'de',
  'en',
  'para',
  'por',
  'sin',
  'que',
  'tras',
  'la',
  'los',
  'un',
  'es',
  'pero',
];

/**
 * example: filterListMultiWord(value, state.options.products, [
        "name"
      ]).slice(0, 15)
 */
export function filterListMultiWord(enteredPhrase, list, keys) {
  
  function searchmultiWord(text, searchWords) {
      const resultMultiWord = new RegExp(searchWords.join('|'), 'gi');
      return !!resultMultiWord.test(text);
  }

  return list.filter(item => {
      const text = keys.map(value => deburr(get(item, value)));
      const searchWordsFiltered = enteredPhrase
          .split(' ')
          .filter(c => c !== '' && !preps.includes(c));
      return searchmultiWord(text, searchWordsFiltered);
  });
}

export function filterListMultiWordLevel(enteredPhrase,level, list, keys) {

  function searchmultiWord(text, searchWords) {
      const resultMultiWord = new RegExp(searchWords.join('|'), 'gi');
     return !!resultMultiWord.test(text);
  }

  return list.filter(itemq => {
     const ui = itemq[level].filter(item => {
          const text = keys.map(value => deburr(get(item, value)));
          const searchWordsFiltered = enteredPhrase
              .split(' ')
              .filter(c => c !== '' && !preps.includes(c)); 
          return searchmultiWord(text, searchWordsFiltered);
      })
      itemq.products = ui;
      return Object.keys(ui).length>0;
  });
}

export function arrayUnique (array,key) {
  return key
      ? array.map(e => e[key])
          .map((e, i, final) => final.indexOf(e) === i && i)
          .filter(e => array[e])
          .map(e => array[e])
      : [...new Set(array)];
  }


  export function forwardDate({ timespan, fromDate, toDate }) {
    switch (timespan) {
      case 'day':
        return { fromDate: addDays(fromDate, 1), toDate: addDays(toDate, 1) }
      case 'week':
        return { fromDate: addWeeks(fromDate, 1), toDate: addWeeks(toDate, 1) }
      case 'month':
        return { fromDate: startOfMonth(addMonths(fromDate, 1)), toDate: endOfMonth(addMonths(toDate, 1)) }
      case 'year':
        return { fromDate: addYears(fromDate, 1), toDate: addYears(toDate, 1) }
      case 'range':
        return { fromDate, toDate }
      default:
        return { fromDate, toDate }
    }
  }
  
  export function backwardDate({ timespan, fromDate, toDate }) {
    switch (timespan) {
      case 'day':
        return { fromDate: subDays(fromDate, 1), toDate: subDays(toDate, 1) }
      case 'week':
        return { fromDate: subWeeks(fromDate, 1), toDate: subWeeks(toDate, 1) }
      case 'month':
        return { fromDate: startOfMonth(subMonths(fromDate, 1)), toDate: endOfMonth(subMonths(toDate, 1)) }
      case 'year':
        return { fromDate: subYears(fromDate, 1), toDate: subYears(toDate, 1) }
      case 'range':
        return { fromDate, toDate }
      default:
        return { fromDate, toDate }
    }
  }