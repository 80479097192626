import styled from 'styled-components';


export const HeaderSiderBar = styled.div`
  padding: 18px;
  font-size: 16px;
  height: 56px;
  background: #63A6AD;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
`


export const Layout = styled.div` 
  height: 100vh;
  display: flex;

  .side-container{
    padding:1rem;
  }
  .rs-sidebar {
    min-height: 100vh;
    z-index: 3;
    
    background: #fff;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.02), 4px 0 10px rgba(0, 0, 0, 0.01);
  }
  .rs-sidenav {
    overflow-y: auto;
    flex: 1 1 auto;
    width: 100%;
  }

   

  .page-container {
    transition: padding 0.2s ease-in;
    padding-left: 56px;

    &-fill {
      padding-left: 250px;
    }
  }
`


