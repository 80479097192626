/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import {  Link} from 'react-router-dom';
import { Dropdown, Nav } from 'rsuite';
import { routes } from '../../routes/routes';

const NavLink = props => <Nav.Item componentClass={Link} {...props} />;

export function MainMenu() {
  return routes.filter(o=>o.text).map((item, i) => {
    if (item.children) {
      return (
        <Dropdown
          key={`dropdown-${String(item.keyn)}${String(i)}`}
          placement="rightStart"
          trigger="hover"
          title="Errors"
          icon={item.icon}
          eventKey={item.key}
        >
          {item.children.filter(o=>o.text).map((child, k) => {
            return (
              <NavLink
                key={`item-${child.keyn}${String(i)}${String(k)}`}
                to={child.link}
              >
                {child.text}
              </NavLink>
            );
          })}
        </Dropdown>
      );
    }

    return (
        <NavLink to={item.link} icon={item.icon} key={`main-${item.keyn}`}>
          {item.text}
        </NavLink>
    );
  });
}
