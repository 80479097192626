import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
body {
  /* color: ${(props) => (props.whiteColor ? 'white' : 'black')}; */
  /* font-family: ${(props) => props.theme.fontFamily}; */
}

.active{
  background: #e5e5ea;
}

.rs-content{
  overflow: auto;
}

.rs-sidebar{
  background: #f9f9f9 !important;
  .rs-sidenav-subtle, .rs-navbar.rs-navbar-subtle{
    background: transparent !important;
  } 
}

.dashboard {
  .rs-panel {
    background: #fff;
  }
  .chart-img {
    width: 100px;
    position: absolute;
    left: 26px;
    top: 34px;
  }
  .trend-box {
    .rs-panel-body {
      text-align: right;
      .value {
        font-size: 36px;
      }
    }
  }
}



`;
