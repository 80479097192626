export default [
  "down-open-mini",
  "right-open-mini",
  "up-open-mini",
  "left",
  "right",
  "down",
  "up",
  "phone-1",
  "left-open-mini",
  "ver",
  "filtro",
  "agenda",
  "air",
  "atencion",
  "ifo",
  "mail",
  "attach",
  "ayuda",
  "menu",
  "foto",
  "note",
  "notificaciones",
  "paciente",
  "papelera",
  "presupuesto",
  "print",
  "seguro-no",
  "seguro",
  "star",
  "tag",
  "tag2",
  "th",
  "tour",
  "user2",
  "favoritos",
  "estadisticas",
  "chat",
  "desplegable",
  "database",
  "editar",
  "chart-pie",
  "chart-line",
  "chart-bar",
  "configuracion2",
  "compartir",
  "cobrar",
  "cloud",
  "calendar",
  "buscar",
  "th-list",
  "chat1",
  "chat2",
  "down-micro",
  "up-micro",
  "chat2-1",
  "orto",
  "shuffle-1",
  "category",
  "dot-3",
  "erase",
  "block",
  "shuffle",
  "thumbs-up",
  "thumbs-down",
  "phone",
  "check_alt",
  "x",
  "plus",
  "dot-1",
  "name",
  "stock",
  "thumbs-up-1",
  "thumbs-down-1",
  "erase-1",
  "block-1",
  "category-1",
  "stock-1",
  "001-agenda",
  "002-clientes",
  "003-productos",
  "004-servicios",
  "005-contabilidad",
  "006-marketing",
  "007-negocioonline",
  "008-usuariosempleados",
  "009-configuracion",
  "010-infoayuda",
  "011-tienda",
  "012-descuentos",
  "013-planes",
  "014-cross",
  "015-menu"
]
