import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { renderRoutes } from '../../routes/renderRoutes';
import Layout from '../Layout';

const AuthorizedLayout = (props) => {
return (
  <Layout title="Backoffice">
    <Switch>
      {renderRoutes()}
    </Switch>
  </Layout>
)
}

export default AuthorizedLayout
