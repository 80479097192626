define([], function() {
    return {
        "stock": "Stock",
        "to_products": "a productos",
        "new_category": "Nueva categoría",
        "write_its_category": "Escribe tu categoría...",
        "back_to_categories": "Volver a categorías",
        "write_the_product": "Escribe el producto...",
        "new_subCategory": "Nueva sub-categoría",
        "write_its_subCategory": "Escribí tu sub-categoría...",
        "back_to_subCategories": "Volver a sub-categorías",
        "short_text_ticket": "Texto corto para el ticket de compra...",
        "max_10_char": "Máximo 10 letras",
        "write_barcode": "Escribe el código de barras...",
        "write_description": "Escribe una breve descripción...",
        "pvp_final": "PVP final",
        "revenue_margin": "Margen de beneficio",
        "delete_product": "Eliminar producto",
        "last_stock_changes": "Últimos cambios de stock por los empleados",
        "added": "Añadido",
        "expensed": "Gastado",
        "sold_products": "Productos vendidos",
        "bill_sold_products": "Facturado por productos vendidos",
        "products_in_treatments": "Productos gastados en servicios",
        "share_product": "Compartir producto",
        "share_product_info": "Si activas esta opción, se creará el producto en todos los centros vinculados.",
        "shared_product": "Este producto se comparte con los centros vinculados.",
        "not_shared_product": "Este producto no se comparte con otros centros.",
        "search_label_product": "Busca un producto por nombre, categoria y código de barras...",
        "download_txt_product": "Descargar productos en Excel",
        "pages_txt_product": "productos en total",
        "q_stock": "Productos con stock",
        "q_stock_with": "Con stock",
        "q_stock_without": "Sin stock",
        "add_stock": "Añadir stock",
        "rem_stock": "Descontar stock",
        "bought": "Comprado",
        "no_product_notice1": "Gestiona el stock de tus productos de venta y de consumo interno.",
        "add_stock_part": "Añadir partida de stock",
        "rem_stock_part": "Restar partida de stock",
        "choose_product": "Escoger producto",
        "no_product_selected": "Aún no se ha seleccionado ningún producto.",
        "employee_cost": "Coste para empleados",
        "provider": "Proveedor",
        "write_its_provider": "Escribe el proveedor",
        "new_provider": "Nuevo proveedor",
        "back_to_providers": "Volver a proveedores",
        "add_product": "Añadir producto",
        "unitaryCost": "Coste sin impuesto",
        "Ficha del producto": "Ficha del producto",
        "mass_editor": "Editor masivo",
        "no_provider": "Sin proveedor",
        "last_clients_bought": "Últimos clientes que lo han comprado",
        "new_prod": "Nuevo Producto",
        "empty_products": "No hay hay productos, agrega uno nuevo",
        "write_color_product": "Escriba el color",
        "write_material_product": "Escriba el materal",
        "write_mark_product": "Escriba la marca",
        "write_calubre_product": "Escriba el calibre",
        "write_puente_product": "Escriba el puente",
        "write_varilla_product": "Escriba la varilla",
        "write_reference_product": "Escriba la referencia",
        "write_type_product": "Escriba el tipo",
        "starting_from_the": "A partir del",
        "apply_a_calculation_of": "Aplicar un cálculo de",
        "and_apply_a_rounding": "Y aplicar un redondeo",
        "cost_price": "Precio de coste",
        "reference_price": "PVP de referencia",
        "current_price": "PVP actual",
        "sum": "Suma",
        "multiplication": "Multiplicación",
        "no_round": "Sin redondeo",
        "straight_up_to_the": "Hacia arriba hasta el",
        "down_to_the": "Hacia abajo hasta el",
        "Choose_the_type_of_data_you_want_to_change": "Elige el tipo de dato que quieres cambiar",
        "Choose_the": "Elija la",
        "to_change_the_text": "a cambiar el texto",
        "Write_the_new_text_for": "Escribe el nuevo texto para",
        "Text_to_be_searched_for_replacement": "Texto a buscar para reemplazar",
        "New_text": "Nuevo texto",
        "Change_texts": "Cambiar textos",
        "New_product_pack": "Nuevo pack de productos",
        "Add_stock": "Añadir stock",
        "Discount_stock": "Descontar stock",
        "Massive_text_changes": "Cambiar textos masivamente",
        "No_rounding": "Sin redondeo",
        "A_unique_price": "Un precio único",
        "BEWE_Internal_ID": "ID interno de BEWE",
        "Reference": "Referencia",
        "Import_Excel_file": "Importar archivo Excel",
        "Import_Excel": "Importar Excel",
        "You_have_several_options_to_mass_import_stock": "Tienes varias opciones para importar stock masivamente",
        "Search_for_products_in_the_search_engine": "Busca los productos en el buscador",
        "Uses_a_bar_code_reader": "Utiliza un lector de código de barras",
        "Import_an_Excel_file": "Importa un archivo Excel",
        "What_is_the_stock_column?": "¿Cuál es la columna de stock?",
        "Each_row_in_the_file_must_have_a_field_for": "Cada fila del archivo debe tener un campo para ",
        "uniquely_identify_the_product._What_is": "identificar el producto de forma única. ¿Cuál es ",
        "the_unique_data_used_in_the_Excel_file?": "el dato único utilizado en el archivo Excel?",
        "Search_for_a_product": "Busca un producto...",
        "St._product": "St. producto",
        "you_have_to_complete_the_fields": "Tienes que completar los campos",
        "What_is_the_column": "Cuál es la columna de",
        "confirm": "Confirmar",
        "price_before": "Precio antes",
        "price_after": "Precio después",
        "Column_not_found": "Columna no encontrada",
        "Choose": "Elija",
        "reference": "Referencia",
        "brand": "Marca",
        "write_the_new_amount": "Escribe el nuevo importe",
        "apply_filters": "Aplicar filtros",
        "stock_quantity": "Cantidad de stock",
        "Price_range": "Rango de precios",
        "since": "Desde",
        "until": "Hasta",
        "price_without_tax": "Precio sin IVA",
        "overorder": "Modalidad",
        "under-order": "Bajo pedido",
        "under-stock": "Bajo stock",
        "Nameless": "Sin nombre",
        "apply_stock": "Aplicar stock",
        "filtered_empty_products": "No hay productos que coincidan con el filtro aplicado",
        "Do_not_put": "No puedo ingresar un número mayor de tres",
        "change_products": "Cambiar productos de bajo stock a bajo pedido",
        "apply_change": "Aplicar cambio",
        "Change_products_to_order": "Cambiar productos a bajo pedido",
        "Change_products_to_stock": "Cambiar productos a bajo stock",
        "expirate_date": "Fecha de vencimiento",
        "not_possible_exchange_products_stock": "No es posible hacer el cambio debido a que hay productos seleccionados con unidades en stock.",
        "Change_saved": "Cambio guardado",
        "no_material": "Sin material",
        "new_material": "Nuevo material",
        "write_its_material": "Escriba un material",
        "back_to_materials": "Regresar a materiales",
        "no_type": "Sin tipo",
        "new_type": "Nuevo tipo",
        "write_its_type": "Escriba un tipo",
        "back_to_types": "Volver a tipos",
        "no_mark": "Sin marca",
        "new_mark": "Nueva marca",
        "write_its_mark": "Escriba una marca",
        "back_to_marks": "Volver a marcas",
        "search_center": "Buscar centro",
        "change_all_centers": "Cambiar todos los centros",
        "create_all_centers": "Crear en todos los centros",
        "center_selected": "Centro seleccionado",
        "What_is_the_price_column?": "¿Cuál es la columna de precio?",
        "What_is_the_unit_cost_column?": "¿Cuál es la columna de costo unitario?",
        "Import_products_at_all_locations": "Importar productos en todas las sedes",
        "Choose_a_location": "Elije una sede",
        "name_idCommerce": "La columna del id de la sede debe llamarse 'idCommerce'",
        "all_headquarters": "Todas las sedes",
        "headquarters": "sede",
        "clients_bought": "Clientes que lo han comprado",
        "stock_changes": "Cambios de stock internos",
        "tamaño_montura": "Tamaño de la montura",
        "No_storage_stores_created": "No hay almacenes creados",
        "Store_Name": "Nombre del almacén",
        "Units_and_price": "Unidades y precio",
        "move": "Mover",
        "no_recent_movements": "No hay movimientos recientes",
        "warehouse": "Almacenes",
        "form_products_print_barcode": "Imprimir código de barra",
        "You_cannot_add_a_stock_greater_than_the_warehouse.": "No puedes añadir un stock superior al del almacén.",
        "almacén": "almacén",
        "modify_prices_selected_products": "Modificar precio de productos seleccionados",
        "add_or_remove_stock": "Añadir o quitar stock",
        "You_must_choose_a_warehouse": "Debes elegir un almacén.",
        "are_you_sure_you_want_to_cancel_the_product_order": "¿ Esta seguro de cancelar la orden de producto ?",
        "no_discount_product": "No puedes descontar una cantidad mayor a la que hay en el producto o almacén",
        "write_dimensions": "Escriba las dimensiones",
        "dimensions": "Dimensiones",
        "businessUnits": "Unidades de negocio",
        "no_unit": "No unidad",
        "write_its_business_unit": "Escribe tu unidad de negocio",
        "new_business_unit": "Nueva unidad",
        "back_to_units": "Volver a unidades",
        "no_color": "Sin color",
        "new_color": "Nuevo color",
        "write_its_color": "Escriba el nuevo color",
        "back_to_colors": "Volver a colores",
        "product_creation_error": "El producto no pudo ser creado, debes ingresar un nombre para el producto",
        "subCategory": "Subcategoría",
        "kind": "Tipo",
        "selected_product": "seleccione un producto",
        "input_stock": "indique una cantidad del stock",
        "product_pack": "Paquetes de productos",
        "Add": "Añadir",
        "add_product_pack": "número de unidades",
        "unassigned": "Sin asignar",
        "can_be_sold_online": "Habilitado para compra online"
};
});