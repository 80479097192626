// @flow

import React, { useState } from 'react';
import {
  Input,
  InputGroup,
  // Breadcrumb,
  Table,
  Panel,
  Icon,
  ButtonToolbar,
  Button,
  // IconButton,
  // Navbar,
  DOMHelper,
  // Notification,
} from 'rsuite';

import data from './mockUsers';
import DrawerView from './DrawerView';
import {withRouter, useParams} from 'react-router-dom';


const { Column, HeaderCell, Cell } = Table;
const { getHeight } = DOMHelper;




function DataList(props) {
  // const { match: { params } } = props;
  let { id } = useParams();
  const [showDrawer, setShowDrawer] = useState(false);

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <div>
      <Panel header={<h3>Members</h3>}>
        <div className="table-toolbar">
          <ButtonToolbar className="inner-left">
            <Button
              appearance="primary"
              placement="left"
              onClick={handleShowDrawer}
            >
              New
            </Button>
          </ButtonToolbar>

          <div className="inner-right">
            <InputGroup inside>
              <Input placeholder="Search" />
              <InputGroup.Addon>
                <Icon icon="search" />
              </InputGroup.Addon>
            </InputGroup>
          </div>
        </div>

        <Table
          height={getHeight(window) - 216}
          data={data}
          onRowClick={(info) => {
            // eslint-disable-next-line no-console
            // console.log(info);
          }}
        >
          <Column width={70} align="center" fixed>
            <HeaderCell>Id</HeaderCell>
            <Cell dataKey="id" />
          </Column>

          <Column width={200} fixed>
            <HeaderCell>First Name</HeaderCell>
            <Cell dataKey="firstName" />
          </Column>

          <Column width={200}>
            <HeaderCell>Last Name</HeaderCell>
            <Cell dataKey="lastName" />
          </Column>

          <Column width={200}>
            <HeaderCell>City</HeaderCell>
            <Cell dataKey="city" />
          </Column>

          <Column width={200}>
            <HeaderCell>Street</HeaderCell>
            <Cell dataKey="street" />
          </Column>

          <Column width={300}>
            <HeaderCell>Company Name</HeaderCell>
            <Cell dataKey="companyName" />
          </Column>

          <Column width={300}>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey="email" />
          </Column>
        </Table>
      </Panel>
      <DrawerView show={showDrawer} onClose={handleCloseDrawer} />
    </div>
  );
}

export default (DataList);
