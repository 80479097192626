import $objeq from '../objeq';
import privateOperations from './private'
import ObjectId from '../../../bewelib/libs/objectId'

const dexieEquivalences = {
    in: 'anyOf',
    '>=': 'aboveOrEqual',
    '>': 'above',
    '<': 'below',
    '<=': 'belowOrEqual',
    '==': 'equals',
    '!=': 'notEqual',
};

function compare({ extendedFilters, collections }) {

  const privateFunctions = privateOperations(extendedFilters)

  function isIndex(block, results) {
      const indexName = block.field ? block.field._text : block.index
      if(indexName && results.schema) {
        const primaryKey = results.schema.primKey.name === indexName;
        const indexExists = results.schema.indexes.some((index) => index.name === indexName);
        return indexExists || primaryKey;
     }

     return false
  }

  function getConditional(block) {
    if(block && block.field) {
      return block.field._text || false;
    }

    return false
  }

  function getSimpleValues(block, computeOp) {
      if(Array.isArray(block.value)) {
          return block.value.reduce((initial, { block }) => {
              if(privateFunctions[block._attributes.type]) {
                  const calculatedValue = privateFunctions[block._attributes.type](block.field)
                  return [...initial, calculatedValue]
              }
              
              const calculatedValue = privateFunctions.simple(block.field);
              return [...initial, calculatedValue]
          }, [])
      } 
      
      if(block.value && block.value.block) {
          const localBlock = block.value.block
          const attribute = localBlock.field._text
          if(!computeOp[attribute]) {
            if(privateFunctions[localBlock._attributes.type]) {
                const calculatedValue = privateFunctions[localBlock._attributes.type](localBlock.field)
                return [calculatedValue]
            }

            const calculatedValue = privateFunctions.simple(localBlock.field);
            return [calculatedValue]
          }
      }
        

      return []
  }

  function getOperationValues(block, computeOp) {
    if(block.value && block.value.block) {
      const attribute = block.value.block.field._text
      if(computeOp[attribute]) {
          return [attribute]
      }
    }
    return []
  }

  function getArrayValues(block, results, computeOp) {
    if(Array.isArray(block.field)) {
        if(privateFunctions[block._attributes.type]) {
            const func = privateFunctions[block._attributes.type](block.field)
            return func(block.statement, block => {
                return prepareCompare(block, results, {}, computeOp)
            })
        }
    }

    return { values: [] }
  }

  function prepareCompare(block, results, list = {}, computeOp) {
    
    const simple = list.simple || []
    const operations = list.operations || []
    const dexie = list.dexie || []

    const conditional = getConditional(block)

    const simpleValues = getSimpleValues(block, computeOp)
    if(simpleValues.length) {
        if(isIndex(block, results)) {
            dexie.push({ values: simpleValues, conditional })
        } else {
            simple.push({ values: simpleValues, conditional })
        }
    }

    const operationValues = getOperationValues(block, computeOp)
    if(operationValues.length) {
        operations.push({ values: operationValues })
    }

    const arrayValues = getArrayValues(block, results, computeOp)
    if(arrayValues.values.length) {
        if(isIndex(arrayValues, results)) {
            dexie.push({ values: arrayValues, conditional, ...arrayValues })
        } else {
            simple.push({ values: arrayValues, conditional, ...arrayValues })
        }
    }

    const newList = { simple, dexie, operations }

    if(block.next) {
        const nextValues = prepareCompare(block.next.block, results, newList, computeOp)
        newList.simple.concat(nextValues.simple)
        newList.operations.concat(nextValues.operations)
        newList.dexie.concat(nextValues.dexie)
    }

    return newList
  }
  
  
  function processItem(model, item) {
      if(model === 'client') {
        return { fullName: `${item.name} ${item.lastname}`, ...item }
      }
  
      return item
  }
  
  async function compareSetOperations(values, results, nameModel, computeOp) {
    
    let filteredResults = results
    if(typeof results.toArray === 'function'){
        filteredResults = await results.toArray()
    }

    filteredResults = filteredResults.map(item => processItem(nameModel, item))
  
    return await values.operations.reduce((initial, { values }) => {
      const [op] = values
      return computeOp[op](nameModel, initial)
    }, filteredResults) 
  }

  function replacePrevious(value, previous) {
      
      function singlePrevious(value, previous) {
        const isPrevious = String(value).indexOf('previous:') !== -1
        if(isPrevious) {
            const index = value.replace('previous:', '')
            return previous[index]
        }
        return value
      }

      if(Array.isArray(value)) {
        return value.map(val => singlePrevious(val, previous))
      }

      return singlePrevious(value, previous)
  }
  
  async function filterDexieResults(values, results, previous) {
    //Apply filters to dexie
    return await values.dexie.reduce((res, { conditional, values, index }) => {

        let [firstValue, secondValue] = values
        if(!firstValue || !secondValue) {
            return res;
        }

        firstValue = replacePrevious(firstValue, previous)
        secondValue = replacePrevious(secondValue, previous)
        
        if(conditional === 'between') {
            return res.where(index).between(firstValue, secondValue, true, true)
        }
  
        return res.filter(item => {
            if(item[firstValue]) {
              return eval(`${item[firstValue]} ${conditional} ${secondValue}`)
            }
    
            return eval(`${item[secondValue]} ${conditional} ${firstValue}`);
        })
  
    }, results);
  }

  function filterByCollectionMethod(values, filteredResults, nameModel) {
    //Apply filters to collection methods
    return values.simple.map(item => {
      if(collections) {
          const collection = collections[nameModel]
          //collections should have the model
          if(collection) {

              const model = new collection.prototype.model(filteredResults[0])
              const method = item.values.find(({ name }) => model[name])
              
              //If method exists in model, apply filter and exclude field
              if(method) {
                  filteredResults = filteredResults.filter(modelData => {
                      const model = new collection.prototype.model(modelData)
                      return model[method.name].apply(model, [])
                  })
                  return false;
              }
          }
      }
      return item
    })
    .filter(item => item && Array.isArray(item.values) && item.values.every(val => val.value))  
    .map(item => {
        const values = item.values.map(val => val.value )
        return values.join(` ${item.conditional} `)
    });
  }
  
  async function filterArrayResults(values, filteredResults, nameModel) {
  
    //When there aren't filters or results (return array)
    if (!values.simple.length || !filteredResults.length) {
      return filteredResults;
    }
  
    const queryString = filterByCollectionMethod(values, filteredResults, nameModel)
  
    // There aren't filters to apply to objeq
    if(!queryString.length) {
      return filteredResults;
    }

    if(queryString.some(item => item.indexOf('$createdAt') !== -1 )) {
        filteredResults = filteredResults.map(result => ({ ...result, $createdAt: new ObjectId().dateFromObjectId(result._id).toISOString() }))
    }
  
    const q$ = $objeq(queryString.join(' and '));
    const newResults = q$(filteredResults);
    
    if (!isNaN(newResults) && newResults.length) {
      return newResults[0];
    }
  
    return newResults;
  }

  return async function (block, results, previous, nameModel, computeOp) {
    const values = prepareCompare(block, results, [], computeOp);
    results = await filterDexieResults(values, results, previous)    
    results = await compareSetOperations(values, results, nameModel, computeOp)
    return await filterArrayResults(values, results, nameModel)
  }
}

export default compare