import * as React from 'react';
import { Row, Col, Icon, Panel } from 'rsuite';

import * as images from '../../assets/images/charts';

function Dashboard(){
    return (
      <Panel className="dashboard" header={<h3>Dashboard</h3>}>
        <Row gutter={30} className="header">
          <Col xs={8}>
            <Panel className="trend-box">
              <img className="chart-img" src={images.pv} />
              <div className="title">Page Views </div>
              <div className="value">281,358</div>
            </Panel>
          </Col>
          <Col xs={8}>
            <Panel className="trend-box">
              <img className="chart-img" src={images.vv} />
              <div className="title">Visits </div>
              <div className="value">251,901</div>
            </Panel>
          </Col>
          <Col xs={8}>
            <Panel className="trend-box">
              <img className="chart-img" src={images.uv} />
              <div className="title">Unique Visitors</div>
              <div className="value">25,135</div>
            </Panel>
          </Col>
        </Row>

      </Panel>
    );
  }


export default Dashboard;
