/* eslint-disable no-console */
import React, { useState, useRef, useEffect } from 'react';
import Blockly from './blockly/override';
import { xml2js, json2xml } from 'xml-js';
import usePipelineStore from '../store';

import BlocklyComponent, {
  Block,
  Value,
  Shadow,
  Category,
} from './blockly';

import './blocks/customblocks';
import './generator/generator';

const Overview = () => {
  const simpleWorkspace = useRef(null);
  const [initialXml, setInitialXml] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const { effects, ...state } = usePipelineStore();

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { blocks } = state.showReport;
    if (firstLoad && blocks && blocks.overview) {
      setInitialXml(
        json2xml(state.showReport.blocks.overview, {
          compact: true,
          ignoreComment: true,
          spaces: 4,
        }),
      );
      setFirstLoad(false);
    } else if (state.showReport) {
      setInitialXml(
        '<xml xmlns="http://www.w3.org/1999/xhtml"><block type="overview" x="250" y="50"></block></xml>',
      );
    }
  }, [state.showReport]);

  if (!initialXml) {
    return null;
  }

  const generateCode = async (event) => {
    if (simpleWorkspace && simpleWorkspace.current) {
      const { workspace } = simpleWorkspace.current;
      const dom = Blockly.Xml.workspaceToDom(workspace);
      const xml = Blockly.Xml.domToText(dom);
      try {
        const pipelineJSON = xml2js(xml, { compact: true });
        effects.setBlock('overview', pipelineJSON);

        // console.log(JSON.stringify(pipelineJSON));

        const block = workspace.getBlockById(event.blockId);
        if (block) {
          const rootBlock = block.getRootBlock();
          if (rootBlock.type === 'overview') {
            if (pipelineJSON.xml) {
              if (!state.results.pipeline) {
                await effects.setResults('pipeline');
              }
              effects.setResults('overview', 'pipeline');
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <BlocklyComponent
          ref={simpleWorkspace}
          readOnly={false}
          initialXml={initialXml}
          onMove={generateCode}
          onChange={generateCode}
          move={{
            scrollbars: false,
            drag: false,
            wheel: true,
            trashcan: false,
          }}
        >
          <Category name="General" colour={300}>
            <Block type="overviewLabel" />
            <Block type="query:count" />
            <Block type="query:sum" />
            <Block type="query:avg" />
            <Block type="overviewWhere">
              <Value name="Where">
                <Shadow type="queryNone" />
              </Value>
            </Block>
          </Category>
          <Category name="Logica" colour={165}>
            <Block type="compare" />
            <Block type="attribute" />
            <Block type="and" />
            <Block type="or" />
          </Category>
          <Category name="Valores" colour={195}>
            <Block type="string" />
            <Block type="number" />
            <Block type="null" />
            <Block type="undefined" />
            <Block type="boolean" />
          </Category>
        </BlocklyComponent>
      </header>
    </div>
  );
};

export default Overview;
