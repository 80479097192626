/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import ReactJSONTree from 'react-json-tree';
import { theme } from './base';

function JSONTree({ data, ...rest }) {
  const [json, setJson] = useState(false);

  useEffect(() => {
    try {
      if (!data) {
        setJson({});
      } else {
        setJson(JSON.parse(data));
      }
    } catch (e) {
      setJson(data);
    }
  }, [data]);

  if (!json) {
    return null;
  }

  return (
    <ReactJSONTree
      {...rest}
      data={json}
      invertTheme={false}
      theme={{
        extend: theme,
        tree: {
          padding: '10px 15px',
          fontSize: 16,
        },
      }}
    />
  );
}

export default JSONTree;
