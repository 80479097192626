/* eslint-disable react/prop-types */
import React from 'react';
import Blockly from './override';
import locale from 'blockly/msg/es';
import 'blockly/blocks';

Blockly.setLocale(locale);

class BlocklyComponent extends React.Component {
  componentDidMount() {
    const {
      initialXml,
      children,
      onMove,
      onChange,
      onCreate,
      onDelete,
      onUI,
      ...rest
    } = this.props;
    this.primaryWorkspace = Blockly.inject(this.blocklyDiv, {
      toolbox: this.toolbox,
      ...rest,
    });

    this.primaryWorkspace.addChangeListener((event) => {
      if (onMove && event.type === Blockly.Events.BLOCK_MOVE) {
        onMove(event);
      } else if (onChange && event.type === Blockly.Events.BLOCK_CHANGE) {
        onChange(event);
      } else if (onCreate && event.type === Blockly.Events.BLOCK_CREATE) {
        onCreate(event);
      } else if (onDelete && event.type === Blockly.Events.BLOCK_DELETE) {
        onDelete(event);
      } else if (onUI && event.type === Blockly.Events.UI) {
        onUI(event);
      }
    });

    if (initialXml) {
      Blockly.Xml.domToWorkspace(
        Blockly.Xml.textToDom(initialXml),
        this.primaryWorkspace,
      );
    }
  }

  get workspace() {
    return this.primaryWorkspace;
  }

  setXml(xml) {
    Blockly.Xml.domToWorkspace(
      Blockly.Xml.textToDom(xml),
      this.primaryWorkspace,
    );
  }

  render() {
    const { children } = this.props;

    return (
      <>
        <div className="relative w-100 vh-100">
            <div
                ref={(e) => (this.blocklyDiv = e)}
                className="absolute absolute--fill"
            />
        </div>
        <xml
          xmlns="https://developers.google.com/blockly/xml"
          is="blockly"
          style={{ display: 'none' }}
          ref={(toolbox) => {
            this.toolbox = toolbox;
          }}
        >
          {children}
        </xml>
      </>
    );
  }
}

export default BlocklyComponent;
