// import concat from 'lodash/fp/concat';
import create from 'zustand';
import { update } from 'common/reports/helpers';
import { computer } from 'common/reports/helpers/middleware';
// import reportItem from './data/reports';
import pipeline from 'common/reports/commonPipeline';
import commonStore from 'common/reports/commonStore';
import axios from '../../utils/axios';

const emptyReport = {
  name: '',
  icon: '',
  plugins: [],
  hiddenTimespans: [],
  defaultFilters: {},
  dateBounds: '',
  overview: [],
  columns: [],
  rows: [],
  i18n: { es: {}, en: {} },
  enabledIf: {},
  minVersion: {},
  maxVersion: {},
  dashboard: [],
  private: false,
  filterModules: {},
  isCustomCenter: false,
  omitReports: [],
  customCenters: [],
};

function getVersion(version) {
  if (!version) return false;

  const [major, minor = 0, build = 0] = version.split('.');

  if (!major) return false;

  return {
    major: parseInt(major),
    minor: parseInt(minor),
    build: parseInt(build),
  };
}

function reportsInitialState() {
  return {
    ...commonStore.initialState(),
    type: 'create',
    dexie: false,
    dbcache: false,
    stargate: axios,
    saving: false,
    rehydrate: false,
    currentPosition: 0,
    reports: [],
    results: {},
    translate: {
      langs: ['es', 'en'],
      words: ['name', 'description']
    },
  };
}

function effects(set, get) {
  return {
    ...commonStore.effects(set, get),
    pipeline: {},
    newpipeline: pipeline(set, get),
    changeLang(value, lang, key) {
      set(({ showReport }) => {
        const i18n = showReport.i18n || {};
        return {
          showReport: {
            ...showReport,
            i18n: {
              ...i18n,
              [lang]: {
                ...(i18n[lang] || {}),
                [key]: value,
              },
            },
          },
        };
      });
    },
    setOmitReports(omitReports) {
      if (Array.isArray(omitReports)) {
        set(({ showReport }) => ({
          showReport: {
            ...showReport,
            isCustomCenter: false,
            omitReports,
            customCenters: [],
          },
        }));
      }
    },

    setMinVersion(version) {
      set(({ showReport }) => ({
        showReport: {
          ...showReport,
          minVersion: getVersion(version),
        },
      }));
    },

    setMaxVersion(version) {
      set(({ showReport }) => ({
        showReport: {
          ...showReport,
          maxVersion: getVersion(version),
        },
      }));
    },

    setCustomCenters(customCenters) {
      if (Array.isArray(customCenters)) {
        set(({ showReport }) => ({
          showReport: {
            ...showReport,
            isCustomCenter: true,
            customCenters,
            omitReports: [],
          },
        }));
      }
    },
    addWord(word) {
        set(({ translate }) => ({ 
            translate: { 
                ...translate, 
                words: [...translate.words, word] 
            } 
        }))
    },
    deleteWord(word) {
      const reserved = ['name', 'description']
      if(!reserved.includes(word)) {
        set(({ translate, showReport }) => {
            const i18n = Object.keys(showReport.i18n).reduce((initial, lang) => {
                const langValues = showReport.i18n[lang]
                delete langValues[word]
                return { ...initial, [lang]: langValues }
            }, {})

            return ({ 
                showReport: {
                ...showReport,
                i18n
                },
                translate: { 
                    ...translate, 
                    words: translate.words.filter(w => w !== word) 
                } 
            })
        })
        }
    },
    setEnabledIf(value) {
      let enabledIf = {};
      switch (value) {
        case 'all':
          enabledIf = {};
          break;
        case 'beautyStore':
          enabledIf = { withFeature: ['beautyStore'] };
          break;
        case 'noBeautyStore':
          enabledIf = { withoutFeature: ['beautyStore'] };
          break;
        case 'wellness':
          enabledIf = { withFeature: ['wellness'] };
          break;
        case 'noWellness':
          enabledIf = { withoutFeature: ['wellness'] };
          break;
        default:
          enabledIf = { withFeature: ['custom'] };
          break;
      }

      set(({ showReport }) => ({ showReport: { ...showReport, enabledIf } }));
    },
    async loadReports() {
      const reports = await axios.get('/models/report/find', {
        params: {
          offset: 0,
          limit: 1000,
        },
      });

      const list = reports.filter(item => item.blocks)
      set({
        reports: list || [],
        showReport: false,
        currentPosition: 0,
      });
      // });
    },
    setReport(showReport) {
        set({ showReport })
    },
    async getReport(id) {

      get().effects.setType()

      if (id === 'new') {
        return emptyReport;
      }

      const report = await axios.get('/models/report/' + id, {
        params: {
          offset: 0,
          limit: 1000,
        },
      });

      if (!report) {
        return false
      }

      const bhaccountreportList = await axios.get(
        'models/bhaccountreport/find',
        {
          params: {
            offset: 0,
            limit: 1000,
            filter: {
              $or: [
                { idReports: { $elemMatch: { $eq: id } } },
                { omitReports: { $elemMatch: { $eq: id } } },
              ],
            },
          },
        },
      );

      const reportAdditionalData = bhaccountreportList.reduce(
        (initial, item) => {
          const { _idBHAccount } = item;
          if (item.omitReports && item.omitReports.length) {
            return {
              ...initial,
              omitReports: [...initial.omitReports, _idBHAccount],
            };
          }
          return {
            ...initial,
            isCustomCenter: true,
            customCenters: [...initial.customCenters, _idBHAccount],
          };
        },
        { omitReports: [], customCenters: [] },
      );

      const words = report.i18n && report.i18n.es ? Object.keys(report.i18n.es) : false
      if (words) set(({ translate }) => ({ translate: { ...translate, words } }))

      return {
        ...report,
        ...reportAdditionalData,
      };
    },

    setHiddenTimespans(hiddenTimespans){
        if(Array.isArray(hiddenTimespans)) {
            set(state => ({
                ...state,
                showReport: {
                    ...state.showReport,
                    hiddenTimespans
                }
            }))
        }
    },

    setDateBounds(dateBounds) {
        set(state => ({
            ...state,
            showReport: {
                ...state.showReport,
                dateBounds: dateBounds !== 'all' ? dateBounds : null
            }
        }))
    },
    async setResults(item, prev = false) {
      const { showReport, ...state } = get();
      const array = showReport.blocks[item];
      if (array && array.xml) {
        const previous = prev ? state.results[prev] : [];
        const res = await get().effects.newpipeline.compute(
          array.xml.block,
          get().effects.newpipeline.computeOp(get()),
          previous,
          previous,
        );

        set(({ results }) => ({ results: { ...results, [item]: res } }));
      }
    },
    async removeOldCustomReports() {

      const { showReport } = get();

      //Busca todos los bhaccount relacionados a este informe
      const bhaccountreportList = await axios.get(
        'models/bhaccountreport/find',
        {
          params: {
            offset: 0,
            limit: 1000,
            filter: {
              $or: [
                { idReports: { $elemMatch: { $eq: showReport._id } } },
                { omitReports: { $elemMatch: { $eq: showReport._id } } },
              ],
            },
          },
        },
      );


      return Promise.all((bhaccountreportList || []).map((item) => {
        const { _id: idBHAccountReportItem } = item;
        const omitReports = (item.omitReports || []).filter(report => report !== showReport._id)
        const idReports = (item.idReports || []).filter((report) => report !== showReport._id)

        //Si uno de los dos campos tiene data, actualiza
        if (omitReports.length && idReports.length) {
          return axios.put('models/bhaccountreport/' + idBHAccountReportItem, { ...item, omitReports, idReports });
        }

        //Si ninguno de los dos campos tiene data, elimina
        return axios.delete('models/bhaccountreport/' + idBHAccountReportItem);
      }));
    },

    async insertNewCustomReports() {
      const { showReport } = get();

      const {
        _id,
        omitReports,
        customCenters,
        isCustomCenter,
      } = showReport;

      const combinated = (omitReports || []).concat(customCenters || []);
      return Promise.all(combinated.map(async (bhaccount) => {

        //Busca cada uno de los centros especificados
        const bhaccountreports = await axios.get(
          'models/bhaccountreport/find',
          {
            params: {
              offset: 0,
              limit: 1,
              filter: { _idBHAccount: bhaccount },
            },
          },
        );

        //Si encuentra actualiza
        if (bhaccountreports.length) {

          const [bhaccountreport] = bhaccountreports;
          const { _id: idBHAccountReport } = bhaccountreport;

          const omitReportsArray = !isCustomCenter
            ? [...(bhaccountreport.omitReports || []), _id]
            : bhaccountreport.omitReports || [];

          const customCenterArray = isCustomCenter
            ? [...(bhaccountreport.idReports || []), _id]
            : bhaccountreport.idReports || [];

          const omitReports = [...new Set([...omitReportsArray])]
          const idReports = [...new Set([...customCenterArray])]

          //Si uno de los dos campos tiene data, actualiza
          if (omitReports.length && idReports.length) {
            return axios.put('models/bhaccountreport/' + idBHAccountReport, { ...bhaccountreport, omitReports, idReports });
          }

          return axios.delete('models/bhaccountreport/' + idBHAccountReport, { ...bhaccountreport });

        } else {

          //Si no encuentra inserta
          return axios.post('models/bhaccountreport', {
            _idBHAccount: bhaccount,
            idReports: isCustomCenter ? [_id] : [],
            omitReports: !isCustomCenter ? [_id] : [],
          });
        }
      }));
    },

    async setType() {
      const [urlID] = location.pathname.split('/').splice(-1, 1)
      const type = String(urlID).length === 24 ? 'update' : 'create'
      set({ type, urlID })
    },

    async deleteReport() {
      await get().effects.setType()
      const { urlID, type } = get()
      if (type === 'update') {
        await axios.delete('models/report/' + urlID);
      }
    },

    async saveReport() {
      const { showReport } = get();
      const { removeOldCustomReports, insertNewCustomReports } = get().effects;

      const {
        _id,
        minVersion,
        maxVersion,
      } = showReport;

      const nameReport = showReport.i18n.en.name || showReport.i18n.es.name || 'report-without-name'
      const reportData = {
        ...showReport,
        plugins: [...new Set([...showReport.plugins, 'table.simple'])],
        minVersion: minVersion || {},
        maxVersion: maxVersion || {},
        name: String(nameReport).toLowerCase().split(' ').join('-')
      };

      if (_id) {
        await axios.put('models/report/' + _id, reportData);
      } else {
        await axios.post('models/report', reportData);
      }

      await removeOldCustomReports()
      await insertNewCustomReports()
    },
    setIcon: (icon) =>
      set(({ showReport }) => ({ showReport: { ...showReport, icon } })),
    setCurrentPosition: (currentPosition) => set({ currentPosition }),
    saving: update((_state, saving = true) => ({ saving })),
    setPlugins: (plugins) => {
      set(({ showReport }) => ({ showReport: { ...showReport, plugins } }))
    },
    setBlock: (key, value) =>
      set(({ showReport }) => ({
        showReport: {
          ...showReport,
          blocks: { ...showReport.blocks, [key]: value },
        },
      })),
  };
}

const computed = {
  ...commonStore.computed,
  iconValue({ showReport }) {
    return showReport ? showReport.icon + '-icon' : '';
  },
};

const store = (set, get) => ({
  ...reportsInitialState(),
  effects: effects(set, get),
  computed,
});

export const stored = computer(store);
const [useStore] = create(stored);
export default useStore;
