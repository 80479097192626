const formatStr = function (str) {
    const regex = /[àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ]/g,
        translate = 'aaaaaceeeeiiiinooooouuuuyyAAAAACEEEEIIIINOOOOOUUUUY';

    return String(str || "").replace(regex, function (match) {
        return translate.substr(regex.source.indexOf(match) - 1, 1);
    }).toLowerCase();
};

function find(arrStr, key) {
    let sum = 0
    const arrKey = key.split(" ")

    for (let j = 0; j < arrKey.length; j++) {
        let found = false;
        for (let i = 0; i < arrStr.length && !found; i++) {
            if (arrStr[i] && !_.isString(arrStr[i]) && _.isFunction(arrStr[i].toString)) {
                arrStr[i] = arrStr[i].toString();
            }
            if (arrStr[i] && _.isString(arrStr[i]) && formatStr(arrStr[i]).indexOf(formatStr(arrKey[j])) != -1) {
                sum++;
                found = true;
            }
        }
    }

    return sum === arrKey.length
}

function findFullMatch(arrStr, key) {
    for (const str of _.compact(arrStr)) {
        if (str.toLowerCase() === key.toLowerCase()) {
            return true
        }
    }
    return false
}

function findStartWord(arrStr, key) {
    for (const str of _.compact(arrStr.split(' '))) {
        if (formatStr(str).indexOf(formatStr(key)) === 0) {
            return true
        }
    }
    return false
}

function findMultiWord(text, searchWords) {
    const words = Array.isArray(searchWords) ? searchWords : searchWords.split(' ')
    const resultMultiWord = new RegExp(
        `^${words.map(word => `(?=.*${word})`).join('')}.*$`,
        'gi'
    );
    return resultMultiWord.test(text) ? true : false;
}

module.exports = {
    find,
    findFullMatch,
    findStartWord,
    findMultiWord
}



