define([], function() {
    return {
        "f_day": "Diaria",
        "f_week": "Semanal",
        "f_fifteen": "Quincenal",
        "f_month": "Mensual",
        "f_bimonth": "Bimensual",
        "f_trimonth": "Trimestral",
        "f_year": "Anual",
        "sunday": "Domingo",
        "monday": "Lunes",
        "tuesday": "Martes",
        "wednesday": "Miércoles",
        "thursday": "Jueves",
        "friday": "Viernes",
        "saturday": "Sábado",
        "today": "Hoy",
        "desde": "Desde",
        "hasta": "Hasta",
        "day": "Día",
        "days": "Días",
        "day_range": "Rango de dias",
        "month": "Mes",
        "week": "Semana",
        "weeks": "Semanas",
        "year": "Año",
        "years": "Años",
        "min": "min",
        "minutes": "minutos",
        "hour": "Hora",
        "treatment": "Servicio",
        "treatments": "Servicios",
        "product": "Producto",
        "products": "Productos",
        "coupon": "Cupón",
        "coupons": "Cupones",
        "price": "Precio",
        "duration": "Duración",
        "time": "Tiempo",
        "note": "Anotación",
        "notes": "Notas",
        "client": "Cliente",
        "charge_n": "Cobro",
        "charge_np": "Cobros",
        "charge": "Cobrar",
        "charged": "Cobrado",
        "charged_f": "Cobrada",
        "charged_fp": "Cobradas",
        "charger": "Cobrador",
        "edit": "Editar",
        "delete": "Eliminar",
        "disable": "Deshabilitar",
        "enable": "Habilitar",
        "enabled": "Habilitado",
        "up": "Subir",
        "down": "Bajar",
        "close": "Cerrar",
        "cancel": "Cancelar",
        "accept": "Aceptar",
        "works": "Citas",
        "apply": "Aplicar",
        "new": "Nuevo",
        "yes": "Sí",
        "no": "No",
        "back": "Atrás",
        "optional": "Opcional",
        "dates": "citas",
        "total": "Total",
        "modify": "Modificar",
        "agenda_booking": "Reserva",
        "session": "sesión",
        "save_changes": "Guardar cambios",
        "add": "Añadir",
        "prev": "Anterior",
        "next": "Siguiente",
        "next_step": "Siguiente paso",
        "page": "Página",
        "from": "de",
        "volver": "Volver",
        "send": "Enviar",
        "sending": "Enviando",
        "sent": "¡Enviado!",
        "date": "Fecha",
        "show": "Mostrar",
        "all": "Todo",
        "history": "Historial",
        "see": "Ver",
        "quantity": "Cantidad",
        "finalprice": "Precio final",
        "empty_list": "Lista vacía",
        "quit": "Quitar",
        "of": "de",
        "question": "Pregunta",
        "auto": "Automático",
        "deny": "Denegar",
        "confirm": "Confirmar",
        "undo": "Deshacer",
        "category": "Categoría",
        "no_category": "Sin Categoría",
        "all_categories": "Todas las categorías",
        "search_a_category": "Busca una categoría",
        "search_a_client": "Busca un cliente",
        "search_a_treatment": "Busca un tratamiento",
        "no_coincidence": "Sin coincidencias",
        "create_fast_treatment_datacard": "Crea una ficha rápida de servicio",
        "more_options": "Más opciones",
        "more_hours": "Más horas",
        "create_client": "Crear cliente",
        "name": "Nombre",
        "lastname": "Apellidos",
        "email": "Email",
        "contact_email": "Email de contacto",
        "phone": "Teléfono",
        "phone2": "Teléfono 2",
        "dni": "DNI",
        "address": "Dirección",
        "postcode": "Código postal",
        "gender": "Sexo",
        "male": "Hombre",
        "female": "Mujer",
        "birthday": "Cumpleaños",
        "old": "Edad",
        "messages": "Mensajes",
        "name_ticket": "Nombre del Ticket",
        "tickets": "Cobros",
        "example": "Ejemplo",
        "nif": "NIF",
        "nifcif": "NIF/CIF",
        "create_treatment": "Crear servicio",
        "country": "País",
        "pobl": "Población",
        "prov": "Provincia",
        "city": "Ciudad",
        "street": "Calle",
        "default_dataview": "Datos principales",
        "title": "Título",
        "description": "Descripción",
        "lastname_client": "Apellidos del cliente",
        "color": "Color",
        "id_client": "ID del cliente",
        "name_client": "Nombre del cliente",
        "category_treat": "Categoría de servicio",
        "name_treat": "Nombre de servicio",
        "bono": "Bono",
        "bonus": "Bonos",
        "state": "Estado",
        "action": "Acción",
        "actions": "Acciones",
        "discount": "Descuento",
        "discounts": "Descuentos",
        "comment": "Comentario",
        "completed": "Completado",
        "all_treatments": "Cualquier servicio del centro",
        "information": "Información",
        "user": "usuario",
        "est_time_1": "Tiempo",
        "est_time_2": "estimado",
        "without": "sin",
        "with": "con",
        "search": "Buscar",
        "this_month": "Este mes",
        "the_avg": "Promedio",
        "in_total": "En total",
        "barcode": "Código de barras",
        "units": "unidades",
        "unit": "unidad",
        "disc": "Desc.",
        "web": "Web",
        "employee": "Empleado",
        "employees": "Empleados",
        "print": "Imprimir",
        "print_ticket": "Imprimir ticket",
        "down_excel": "Descargar excel",
        "char": "caracteres",
        "type": "Tipo",
        "in": "en",
        "amount": "Importe",
        "percent": "Porcentaje",
        "drawer": "Caja",
        "drawer_cash": "Caja (efectivo)",
        "cards": "Tarjetas",
        "card": "Tarjeta",
        "every_employee": "Cualquier empleado",
        "every_cash": "Cualquier caja",
        "all_list": "Toda la lista",
        "none": "Ninguno",
        "init": "Inicio",
        "init_day": "Día de inicio",
        "reason": "Motivo",
        "fix": "Fijo",
        "var": "Variable",
        "cost": "Coste",
        "continue": "Continuar",
        "will_be": "será",
        "the_price_without": "El precio sin",
        "the_price_with": "El precio con",
        "unknown": "Desconocido",
        "taxes": "Impuestos",
        "save_config": "Guardar configuración",
        "config": "configuración",
        "see_details": "Ver detalles",
        "hide_details": "Ocultar detalles",
        "other": "Otro",
        "waytopay": "Forma de pago",
        "waytopay_s": "F. pago",
        "free": "Gratis",
        "and": "y",
        "finish": "Finalizar",
        "warning": "Cuidado",
        "by_default": "por defecto",
        "timetable": "Horario",
        "holidays": "Festivos",
        "treatment_list": "Lista de servicios",
        "fullfill": "Completa los datos",
        "please_wait": "Por favor espera",
        "reload_bh": "Recargar",
        "loading": "Cargando",
        "group": "Grupo",
        "upload_img": "Subir imagen",
        "verified": "El negocio ha sido verificado y certificado por Bewe. Para cambiar estos datos, comunícate con nosotros a través del chat o por email en contacta@bewe.co",
        "timetable_center_yes": "Tiene el mismo horario que el negocio",
        "timetable_center_no": "Tiene un horario diferente al negocio",
        "choose": "Escoge",
        "vacation-all-day": "Fiesta todo el día",
        "vacations": "Fiestas",
        "add-note": "Añadir nota",
        "type-note": "Escribir nota en este día",
        "client_note": "Nota del cliente",
        "categories": "Categorías",
        "tags": "Etiquetas",
        "delete_demo_data": "Aún tienes los datos de demostración. ¡Tienes que eliminarlos antes de añadir información nueva!",
        "care": "Atención",
        "hour_abr": "h",
        "min_abr": "m",
        "resources": "Recursos",
        "see_by": "Ver por",
        "stock": "Stock",
        "margin": "Margen",
        "unitaryCost": "Coste unitario",
        "nameTicket": "Nombre del ticket",
        "avgtime": "Tiempo estimado",
        "limit_excel": "La descarga de excel",
        "tax": "Impuestos",
        "sunday3": "Dom",
        "monday3": "Lun",
        "tuesday3": "Mar",
        "wednesday3": "Mie",
        "thursday3": "Jue",
        "friday3": "Vie",
        "saturday3": "Sab",
        "january": "Enero",
        "february": "Febrero",
        "march": "Marzo",
        "april": "Abril",
        "may": "Mayo",
        "june": "Junio",
        "july": "Julio",
        "august": "Agosto",
        "september": "Septiembre",
        "october": "Octubre",
        "november": "Noviembre",
        "december": "Diciembre",
        "january3": "Ene",
        "february3": "Feb",
        "march3": "Mar",
        "april3": "Abr",
        "may3": "May",
        "jun3": "Jun",
        "july3": "Jul",
        "august3": "Ago",
        "september3": "Sep",
        "october3": "Oct",
        "november3": "Nov",
        "december3": "Dic",
        "save": "Guardar",
        "all_plural": "Todos",
        "un": "Un.",
        "dsct": "DCTO",
        "show_only": "Mostrar sólo",
        "pay_warning": "¡Atención! Ha habido un error con tu último pago. Tu cuenta será bloqueada si no se introduce una tarjeta válida. Haz clic aquí para introducirla.",
        "trial_warning": "¡Atención! Tu período de prueba acabará pronto. Haz clic aquí ahora para empezar tu suscripción.",
        "pending": "Pendiente",
        "accepted": "Aceptado",
        "rejected": "Rechazado",
        "done": "Finalizado",
        "dental_effect": "Efecto dental",
        "demo_data_bar": "Recuerda que hay datos de demostración. Antes de empezar tienes que <a class='demo-data-link'>eliminar los datos de demostración</a>",
        "exit_program": "Salir del programa",
        "agenda": "Schedule",
        "pay": "Cobrar",
        "menu": "Menú",
        "products_desc": "Gestiona los productos y stocks",
        "treatments_desc": "Gestiona los servicios del centro",
        "accounting": "Contabilidad",
        "accounting_desc": "Herramientas contables y de caja",
        "cash_of_day": "Caja del día",
        "cash_of_day_desc": "Caja del día y cierre de caja",
        "marketing": "Marketing",
        "marketing_desc": "Herramientas para captar clientes",
        "online_presence": "Tu negocio online",
        "online_presence_desc": "Configura tu web, tu presencia online y reserva de citas",
        "users_employees": "Usuarios/Empleados",
        "users_employees_desc": "Accesos a la zona privada",
        "configuration": "Configuración",
        "configuration_desc": "Configuración general del centro",
        "infohelp": "Información y ayuda",
        "infohelp_desc": "Aprende a utilizar Bewe",
        "footer_need_help": "¿Necesitas ayuda?",
        "footer_need_help_desc": "Contacta con nosotros. Es fácil, <a class='chat-session'>haz click aquí para chatear con un técnico de Bewe</a>.",
        "footer_learn": "¿Quieres aprender a usar mejor Bewe?",
        "footer_learn_desc": "<a href='#help'>Accede a la sección de información y ayuda</a> donde podrás encontrar sencillos tutoriales donde se explican diferentes funciones de Bewe.",
        "footer_feedback": "¿Sugerencias?",
        "footer_feedback_desc": "Bewe se adapta a tus necesidades, <a href='mailto:contacta@bewe.io'>envíanos un correo</a> o <a class='chat-session'>háblanos por chat</a>.",
        "footer_legal": "<a href='/tos' target='_blank'>Condiciones generales</a> y <a href='/priv' target='_blank'>Política de Privacidad</a>.",
        "password": "Contraseña",
        "wrong_password": "Contraseña incorrecta",
        "write_password": "Escribe tu contraseña",
        "enter": "Entrar",
        "recent": "Recientes",
        "al": "al",
        "no_stock_to_sell": "(No hay stock para ser cobrado)",
        "check_pricing": "Ver precios",
        "language": "Idioma",
        "locale_en": "Inglés",
        "locale_es": "Español",
        "locale_pt": "Portugués",
        "basePrice": "Precio base",
        "for": "Para",
        "notice": "Aviso",
        "recipe": "Receta",
        "create": "Crear",
        "content": "Contenido",
        "sign": "Firma",
        "consent_printer_title": "Escoge un consentimiento",
        "drdra": "Dr./Dra./Profesional",
        "consent_of": "Consentimiento de",
        "no_connection": "Se ha perdido la conexión, revisa que tienes internet antes de seguir utilizando tu cuenta.",
        "client_group": "Grupo de clientes",
        "consent": "Consentimiento",
        "consents": "Consentimientos",
        "add_new_consent": "Nuevo consentimiento",
        "consents-desc": "Descripción del consentimiento",
        "delete_consent": "Eliminar consentimiento",
        "no_items_notice2": "Ahora no tienes ninguno creado. Puedes crearlos manualmente o ",
        "no_items_notice3": "importarlos desde un excel",
        "expired": "Caducados",
        "range": "Rango",
        "download_excel": "Descargar Excel",
        "footer_need_help_desc_bewe": "Contacta con nosotros. Es fácil, <a class='chat-session'>haz click aquí para chatear con un técnico de tu cuenta</a>.",
        "footer_learn_bewe": "¿Quieres aprender a usar mejor tu cuenta?",
        "footer_learn_desc_bewe": "<a href='#help'>Accede a la sección de información y ayuda</a> donde podrás encontrar sencillos tutoriales donde se explican diferentes funciones de tu cuenta.",
        "footer_feedback_desc_bewe": "Bewe se adapta a tus necesidades, <a href='mailto:contacta@bewe.co'>envíanos un correo</a> o <a class='chat-session'>háblanos por chat</a>.",
        "footer_legal_bewe": "<a href='https://bewe.co/condiciones-legales/' target='_blank'>Condiciones generales</a> y <a href='https://bewe.co/politica-privacidad/' target='_blank'>Política de Privacidad</a>.",
        "man": "Hombre",
        "woman": "Mujer",
        "invalid_email": "Email inválido",
        "invalid_name": "Nombre inválido (Solo carácteres alfabéticos A-Z)",
        "months": "Meses",
        "price_unit": "Precio Unidad",
        "fiscal_name": "Nombre fiscal",
        "all_products": "Cualquier producto del centro",
        "code": "Código",
        "groups": "Grupos",
        "store_data_bar": "NUEVA TIENDA DE PRODUCTOS: Adquiere los productos para tu centro en nuestra nueva tienda y <b>te regalamos BEWE</b>. <a href='#store' class='store-data-link'>Infórmate AQUÍ</a>",
        "store": "Tienda de productos",
        "store_desc": "Compra productos para tu negocio",
        "score": "Puntuación",
        "filter": "Filtro",
        "giftcard": "Tarjeta regalo",
        "giftcards": "Tarjetas regalo",
        "commerce": "Centro",
        "commerces": "Centros",
        "assigned_commerce": "Centro asignado",
        "big_img_constraints": "Debe ocupar menos de",
        "big_img_constraints2": "y medir",
        "img_too_big": "Comprueba que la imagen cumple con las restricciones",
        "for_products": "Para productos",
        "for_treatments": "Para servicios",
        "to": "a",
        "promocode": "Código promocional",
        "q_have_promocode": "¿Tienes un código promocional?",
        "introduce_promocode": "Introduce el código...",
        "order": "Pedido",
        "subscription": "Suscripción",
        "subscriptions": "Suscripciones",
        "nextPayDate": "Próximo pago",
        "sell": "Venta",
        "load": "Carga",
        "internal_use": "Uso interno",
        "offline_data_bar": "No hay conexión con el servidor<em></em> - <a class='reload'> Reconectar ahora</a>",
        "reconnecting": " - Reconectando...",
        "will_reconnect_in": " - Se intentará reconectar en",
        "seconds": "segundos",
        "capacity": "Capacidad",
        "book_at_once": "cita a la vez",
        "books_at_once": "citas a la vez",
        "accessDenied_title": "No tienes acceso a esta sección",
        "accessDenied_desc": "Si necesitas acceso, contacta con el responsable de tu negocio para configurar los permisos de tu usuario.",
        "_id": "Identificador",
        "mgm": "Consigue descuentos",
        "mgm_desc": "Invita a otro negocio a BEWE y tendrás recompensa!",
        "plans_and_pricing": "Planes y precios",
        "plans_and_pricing_desc_short": "Echa un ojo a todo lo que podemos ofrecerte :D",
        "plans_and_pricing_desc": "Te presentamos nuestros precios de forma clara. Puedes ver fácilmente qué incluye tu plan actual y descubrir nuevos planes con herramientas que te pueden ir muy bien ;)",
        "quit_all": "Quitar todo",
        "by": "Por",
        "any": "Cualquiera",
        "clear": "Limpiar",
        "remove": "Quitar",
        "stats": "Estadísticas",
        "taxname": "Denom. social",
        "taxcode": "CIF/NIF",
        "taxaddress": "Dirección fiscal",
        "cancel_edit": "Cancelar edición",
        "insurance": "Empresa pagadora",
        "Documentos clinicos": "Documentos clínicos",
        "Crea documentos clinicos personalizados para recolectar datos de tus clientes y sus visitas.": "Crea documentos clínicos personalizados para recolectar datos de tus pacientes y sus visitas.",
        "Nueva ficha cliníca": "Nueva ficha clínica",
        "A continuación configura los elementos que necesitas para tu documento cliníco.": "A continuación configura los elementos que necesitas para tu documento clínico.",
        "Nueva ficha": "Nueva ficha",
        "Editar documento": "Editar documento",
        "Eliminar": "Eliminar",
        "No se han configurado fichas clínicas aún.": "No se han configurado fichas clínicas aún.",
        "Cancelar": "Cancelar",
        "Columnas": "Columnas",
        "Eliminar sección": "Eliminar sección",
        "Nueva sección": "Nueva sección",
        "Guardar documento como...": "Guardar documento como...",
        "Escribe un nuevo titulo": "Escribe un nuevo título",
        "Selecciona una opción para esta sección:": "Selecciona una opción para esta sección:",
        "Titulo": "Titulo",
        "Texto descriptivo": "Texto descriptivo",
        "Campo de texto (para rellenar)": "Campo de texto (para rellenar)",
        "Lista desplegable (opción única)": "Lista desplegable (opción única)",
        "Lista de opciones multiples": "Lista de opciones múltiples",
        "Escribe un aquí el texto que deseas mostrar...": "Escribe un aquí el texto que deseas mostrar...",
        "Nombre del campo": "Nombre del campo",
        "Escribe una opción para este campo...": "Escribe una opción para este campo...",
        "Hoy": "Hoy",
        "Vistas": "Vistas",
        "Ninguna vista guardada": "Ninguna vista guardada",
        "Eliminar vista": "Eliminar vista",
        "Guardar filtros como vista": "Guardar filtros como vista",
        "Nombre de la vista": "Nombre de la vista",
        "Guardar": "Guardar",
        "Ver por": "Ver por",
        "Día": "Día",
        "Semana": "Semana",
        "Mes": "Mes",
        "Rango de dias": "Rango de días",
        "Rango": "Rango",
        "Con y sin factura emitida": "Con y sin factura emitida",
        "Con factura emitida": "Con factura emitida",
        "Sin factura emitida": "Sin factura emitida",
        "Empleados": "Empleados",
        "Cualquier usuario": "Cualquier usuario",
        "Caja": "Caja",
        "Cualquier caja": "Cualquier caja",
        "Caja principal": "Caja principal",
        "Caja chica": "Caja chica",
        "Forma de pago": "Forma de pago",
        "Cualquier forma": "Cualquier forma",
        "Por transferencia": "Por transferencia",
        "Factura": "Factura",
        "Mostrar sólo": "Mostrar sólo",
        "Real Facturado": "Real Facturado",
        "Dinero que se ha cobrado": "Dinero que se ha cobrado",
        "subs_state_active": "Activa",
        "subs_state_unsubscribed": "Cancelada",
        "subs_state_blocked": "Bloqueada por impago",
        "subs_state_trial": "En periodo de prueba",
        "cif": "CIF",
        "fiscal_natural": "Autónomo",
        "fiscal_legal": "Empresa",
        "legal_text": "Texto legal",
        "Configuración básica": "Configuración básica",
        "treatmentsTab": "Servicios",
        "productsTab": "Productos",
        "packsTab": "Bonos",
        "prepaidTab": "Saldo",
        "giftcardTab": "T. de regalo",
        "specialTab": "Cargo especial",
        "settleDebtTab": "Saldar deuda",
        "workgroup": "Clase",
        "signed": "Firmado",
        "resource": "Recurso",
        "Configuración avanzada": "Configuración avanzada",
        "late_cancel": "Cancelada tarde",
        "Firmar via app": "Firmar via app",
        "pending_sign": "Pendiente de firma",
        "Consentimiento enviado a la app del cliente.": "Consentimiento disponible en app y web para firma del cliente",
        "Lista de espera": "Lista de espera",
        "En espera": "En espera",
        "limit": "Límite",
        "conditions": "Condiciones",
        "Contrato": "Contrato",
        "warningtext-no-subs": "Servicio impagado",
        "warningtext-limit-month": "Ha superado el límite mensual",
        "warningtext-limit-week": "Ha superado el límite semanal",
        "warningtext-limit-day": "Ha superado el límite diario",
        "warningtext-group": "El cliente no pertenece a ninguno de los grupos:",
        "visitsColumn": "Visitas",
        "totalAvgColumn": "Gasto promedio",
        "visitsAvg": "Visitas promedio",
        "totalAvg": "Gasto promedio",
        "treatmentColumn": "Tratamiento",
        "totalSumColumn": "Facturado",
        "noStock": "Sin inventario",
        "netValue": "Valor neto inventario",
        "grossValue": "Valor bruto inventario",
        "fullStock": "Inventario total",
        "productColumn": "Producto",
        "stockColumn": "Inventario",
        "unitaryCostColumn": "Coste unitario",
        "totalCostColumn": "Inventario neto",
        "basePriceColumn": "PVP",
        "totalPriceColumn": "Valor en PVP",
        "expectedProfitColumn": "Margen proyectado",
        "paidSalaryColumn": "Salario anticipado",
        "boughtProductsColumn": "Productos comprados",
        "soldProductsColumn": "Productos vendidos",
        "cashedColumn": "Servicios",
        "unitProfitColumn": "Margen / unidad",
        "reports": "Informes",
        "reports_desc": "Analiza tu negocio al detalle",
        "repeat": "Repetir",
        "repeat_since": "Durante",
        "every": "Cada",
        "no_repeat": "No repetir",
        "revert": "Revertir",
        "expires_end": "Caduca final",
        "same": "Fijo",
        "Tarjeta OXXO": "Tarjeta OXXO",
        "Cualquier tipo": "Cualquier tipo",
        "Caja secundaria": "Caja secundaria",
        "SALDO": "SALDO",
        "Cualquier empresa": "Cualquier empresa",
        "Descargar desglosado": "Descargar desglosado",
        "Subir nuevo archivo": "Subir nuevo archivo",
        "Selecciona un fichero": "Selecciona un fichero",
        "Has seleccionado: %{fileName}": "Has seleccionado: %{fileName}",
        "o arrástralo aquí": "o arrástralo aquí",
        "%{smart_count} fichero seleccionado": "%{smart_count} fichero seleccionado",
        "%{smart_count} ficheros seleccionados": "%{smart_count} ficheros seleccionados",
        "%{smart_count} fichero subido": "%{smart_count} fichero subido",
        "%{smart_count} ficheros subidos": "%{smart_count} ficheros subidos",
        "%{smart_count} fichero": "%{smart_count} fichero",
        "%{smart_count} ficheros": "%{smart_count} ficheros",
        "Subir %{smart_count} fichero": "Subir %{smart_count} fichero",
        "Subir %{smart_count} ficheros": "Subir %{smart_count} ficheros",
        "Selecciona los ficheros a subir": "Selecciona los ficheros a subir",
        "Cerrar modal": "Cerrar modal",
        "Subir": "Subir",
        "Importar ficheros desde": "Importar ficheros desde",
        "Panel de Uppy (Pulsa escape para cerrar)": "Panel de Uppy (Pulsa escape para cerrar)",
        "Panel de Uppy": "Panel de Uppy",
        "Enlace copiado al portapapeles.": "Enlace copiado al portapapeles.",
        "Copiar la siguiente URL": "Copiar la siguiente URL",
        "Hecho": "Hecho",
        "Disco local": "Disco local",
        "Arrasta ficheros aquí, pega, importa de alguno de los servicios de arriba o": "Arrasta ficheros aquí, pega, importa de alguno de los servicios de arriba o",
        "Arrastra ficheros aquí, pega o": "Arrastra ficheros aquí, pega o",
        "navegar": "navegar",
        "Progreso: velocidad de subida y tiempo estimado": "Progreso: velocidad de subida y tiempo estimado",
        "Número de ficheros seleccionados": "Número de ficheros seleccionados",
        "Subir todos los nuevos ficheros": "Subir todos los nuevos ficheros",
        "Cambiar foto": "Cambiar foto",
        "subs_state_semiblocked": "Impagada",
        "subs_state_pending": "Pendiente del primer pago",
        "download": "Descargar",
        "clientcreditcard": "Tarjeta vinculada",
        "centro": "centro",
        "personas": "personas",
        "a las": "a las",
        "process": "Proceso",
        "error": "Error",
        "manual": "Manual",
        "payment": "Pago",
        "intento": "Intento",
        "refund": "Devolver",
        "Devolución": "Devolución",
        "No hacer nada": "No hacer nada",
        "future_unsubscribed": "(Dada de baja)",
        "reactivate": "Reactivar",
        "write_a_name": "Escribe un nombre",
        "teacher": "Profesor",
        "all_treatments_of": "Cualquier servicio de",
        "all_products_of": "Cualquier producto de",
        "select_treat_or_products": "Selecciona servicios o productos",
        "fullfill_mandatory_fields": "Hay que rellenar los campos obligatorios antes de continuar",
        "Un empleado": "Un empleado",
        "El cliente": "El cliente",
        "Modo silencio": "Modo silencio",
        "Proveedores": "Proveedores",
        "Proveedor": "Proveedor",
        "Ficha de proveedor": "Ficha de proveedor",
        "subs_state_semiblockec": "En impago",
        "warningtext-classpass": "El cliente reservó a través de Classpass",
        "No hay registros en el periodo seleccionado": "No hay registros en el periodo seleccionado",
        "Nueva nota": "Nueva nota",
        "Desglose": "Desglose",
        "Fidelizaciones": "Fidelizaciones",
        "Detalles": "Detalles",
        "Fecha de inicio": "Fecha de inicio",
        "Frecuencia": "Frecuencia",
        "¿Estás seguro de querer eliminarlo?": "¿Estás seguro de querer eliminarlo?",
        "class": "Clase",
        "class_plural": "Clases",
        "init_date_subs": "Fecha de inicio de la suscripción",
        "class_type": "Tipo de clase",
        "see_agroupation": "Ver resumen",
        "hide": "Ocultar",
        "delay": "Retraso",
        "Selecciona una vista": "Selecciona una vista",
        "Valor a filtrar": "Valor a filtrar",
        "Todos en": "Todos en",
        "Imagen con puntos seleccionables": "Imagen con puntos seleccionables",
        "Arrasta una imagen aquí o": "Arrasta una imagen aquí o",
        "elige un archivo": "elige un archivo",
        "deleted": "eliminados",
        "processing": "Procesando",
        "actualizar": "Actualizar",
        "c_agendas": "Agendas",
        "close_session": "Cerrar sesión",
        "profesionals": "Profesionales",
        "tarifas": "Tarifas",
        "clients": "Clientes",
        "for_clients_from_group": "para clientes del grupo",
        "all_clients": "Todos los clientes",
        "for_clients_with_subs": "para clientes con la suscripción",
        "new_clients": "Clientes nuevos",
        "en": "en",
        "applying": "Aplicando",
        "comments": "Comentarios",
        "removing": "Quitando",
        "subtotal": "Subtotal",
        "deliver_date": "Fecha de entrega",
        "products_loaded_into_stock": "Los productos se han cargado en el stock",
        "send_order": "Enviar Orden",
        "center_search": "Buscar centro",
        "warningtext-limit-booking-subscription": "Ha superado el saldo de reservas de la suscripción",
        "warningtext-limit-booking-discount": "Ha superado el saldo de reservas del bono",
        "Grupo de clientes": "Grupo de clientes",
        "Filtrar por atributo": "Filtrar por atributo",
        "Filtrar por": "Filtrar por",
        "Todos los productos": "Todos los productos",
        "Todos los tratamientos": "Todos los tratamientos",
        "Todos los servicios": "Todos los servicios",
        "Filtrar por suscripción": "Filtrar por suscripción",
        "Todas las suscripciones": "Todas las suscripciones",
        "Todos los empleados": "Todos los empleados",
        "Buscar por cliente...": "Buscar por cliente...",
        "No hay información disponible para este reporte.": "No hay información disponible para este informe.",
        "Descargar en PDF": "Descargar en PDF",
        "Recopilando información...": "Recopilando información...",
        "Espera un momento mientras recopilamos y analizamos toda la información necesaria para este informe.": "Espera un momento mientras recopilamos y analizamos toda la información necesaria para este informe.",
        "Intenta cambiando los filtros del reporte.": "Intenta cambiando los filtros del informe.",
        "Todos las categorias": "Todas las categorias",
        "Todos los bonos": "Todos los bonos",
        "Reportes": "Reportes",
        "old-subscriptions": "Suscripciones antiguas pagadas",
        "pending-old-subscriptions": "Suscripciones pendientes de pago",
        "new-subscriptions": "Suscripciones nuevas",
        "contact": "Contacto",
        "contact_phone": "Teléfono de contacto",
        "desc": "Desc.",
        "total_without": "Total sin",
        "numero": "Número",
        "order_to": "ordenado a",
        "deliver_to": "Entregado a",
        "key": "Clave",
        "order_conditions": "Condiciones generales de compra",
        "add_products_to_order": "Añade productos para ordenar",
        "receive_order": "Recibir Orden",
        "any_state": "Cualquier estado",
        "search_by_product": "Producto",
        "search_by_product_name": "Buscar por nombre",
        "new_order": "Nueva Orden",
        "products_purchased": "Productos comprados",
        "total_registered": "Total registrado",
        "no_records_in_period": "No hay registros en este periodo",
        "repeated_client_warning": "El cliente está repetido. No puedes crear uno nuevo con la misma información.",
        "printing_tickets": "Impresión de tickets",
        "multicaja": "Multicaja",
        "campains": "Campañas",
        "Dashboard": "Dashboard",
        "Nuevo evento": "Nuevo evento",
        "event_confirm_removal": "¿Estás seguro que quieres eliminar este evento?",
        "disabled": "Deshabilitado",
        "Editar evento": "Editar evento",
        "Detalle del evento": "Detalle del evento",
        "Ficha de evento": "Ficha de evento",
        "Fecha fin": "Fecha fin",
        "Organizadores": "Organizadores",
        "Colaboradores": "Colaboradores",
        "Pases": "Pases",
        "Fin": "Fin",
        "activities": "Actividades",
        "search_label_events": "Busca un evento por nombre o descripción",
        "events": "Eventos",
        "event_state_res": "Reservado",
        "event_state_confirmed": "Confirmado",
        "event_state_res_client_rejected": "Cancelado a tiempo",
        "event_state_res_late_cancel": "Cancelado tarde",
        "event_state_res_missing": "No se ha presentado",
        "event_state_deleted": "Asistencia eliminada",
        "Cancelado por": "Cancelado por",
        "born_date": "Fecha de nacimiento",
        "customer": "Comprador",
        "deleting": "Eliminando",
        "related_to_all_classes": "Acciones sobre todas las clases",
        "propagate_to_nextworks": "Apuntar a las siguientes clases",
        "unpropagate_to_nextworks": "Desapuntar de todas las clases",
        "main_information": "Información principal",
        "yesterday": "Ayer",
        "ok": "Aceptar",
        "hours": "horas",
        "Todos los Centros": "Todos los Centros",
        "Seleccione...": "Selecciona...",
        "Cualquier proveedor": "Cualquier proveedor",
        "Seleccionado": "Seleccionado",
        "Nuevo Cliente": "Nuevo cliente",
        "Nuevo Evento": "Nuevo Evento",
        "Eventos": "Eventos",
        "events_selected": "Eventos seleccionados",
        "uploaded_img": "Imagen subida correctamente",
        "delete_img": "No fue posible eliminar el archivo",
        "assistants": "Asistentes",
        "warningtext-has-debt": "Tiene una deuda",
        "filas": "filas",
        "cols": "Cols",
        "reservas": "Reservas",
        "etiqueta": "Etiqueta",
        "noreservas": "Sin reservas",
        "date_separator_day_month": " de ",
        "product_search": "Búsqueda de productos, etc.",
        "gap-violation-text": "Aún no han pasado <%=days%> días para poder realizar este servicio.",
        "Editar curso": "Editar curso",
        "Detalle del curso": "Detalle del curso",
        "Ficha de curso": "Ficha de curso",
        "search_label_courses": "Busca un curso por nombre o descripción",
        "Nuevo Curso": "Nuevo Curso",
        "unpublished": "No publicado",
        "published": "Publicado",
        "intro_video": "Video introductorio",
        "video": "Video",
        "upload_photo": "Subir una foto",
        "courses": "Cursos",
        "progress": "Progreso",
        "Profesores": "Profesores",
        "Colaborador": "Colaborador",
        "program": "Programa",
        "Rol": "Rol",
        "location": "Ubicación",
        "limit_date": "Fecha límite",
        "Cancelar y editar": "Cancelar y editar",
        "Escribe un alias para este documento clínico": "Escribe un alias para este documento clínico",
        "event_decline_removal": "¡No es posible eliminar este evento porque tiene asistentes relacionados!",
        "fingerprint": "Firma",
        "event_link": "Clic aquí para ir al evento",
        "event_is_online": "Evento online",
        "event_is_online_short": "Es online",
        "adjust_address": "Ajustar dirección",
        "custom_video": "Video personalizado",
        "students": "Alumnos",
        "enrolled_students": "Alumnos Apuntados",
        "staff": "Staff",
        "EVENT_CAPACITY_MISSING": "Se necesita la capacidad del evento. El evento no puede ser publicado por este motivo",
        "MAX_CAPACITY_REACHED": "Revisa las fechas de las actividades del Evento, puede que se estén solapando con otras actividades. El evento no puede ser publicado por este motivo.",
        "MEETING_TOO_LONG": "No se puede hacer una actividad online que dure más de 10 horas. El evento no puede ser publicado por este motivo.",
        "EVENT_CANNOT_BE_PUBLISHED": "El evento no puede ser publicado. Contacta con soporte técnico para más información",
        "organizer": "Organizador",
        "templates": "Plantillas",
        "translations": "Traducciones",
        "appcheckin": "Face Check-in App",
        "cancelling": "Cancelando",
        "fullName": "Nombres y apellidos",
        "provider_can_order": "Se pueden generar órdenes de compra con el proveedor",
        "provider_can_not_order": "No se pueden generar órdenes de compra con el proveedor",
        "quan": "Cant",
        "emailToNotifyProductOrder": "Email",
        "all_courses": "Cualquier curso del centro",
        "warningtext-limit-balance": "Ha superado el límite de saldo mensual",
        "all_courses_of": "Cualquier pase de",
        "month_balance": "saldo mensual",
        "closed": "Cerrado",
        "required": "Obligatorio",
        "datasheet": "Ficha de datos",
        "tax_code": "Código de impuesto",
        "presencial": "Presencial",
        "Cualquier opción": "Cualquier opción",
        "online_beweclass": "Online a través de Bewe Class",
        "tv_detail": "Detalle de TV",
        "tv_new": "Nuevo TV",
        "tv_edit": "Editar TV",
        "tv_sheet": "Ficha TV",
        "add_new_video": "Agregar nuevo video",
        "show_new_video": "Nuevo video",
        "search_label_tv": "Busca un TV por nombre o descripción",
        "activated": "Activado",
        "delete_video_alert": "¿Seguro que quieres eliminar este video?",
        "employees_more": "Empleados o más",
        "Comprar": "Comprar",
        "Verificando": "Verificando",
        "new_item": "Nuevo elemento",
        "create_option": "Crear opción",
        "mkt_rules": "Tareas automáticas",
        "free_days_online": "Días gratis por compra online",
        "no_activities": "Sin actividades",
        "client_card_title": "Ficha de cliente",
        "client_card_description": "Modifica la estructura de las fichas de los clientes. Puedes ocultar secciones que no necesitas y hacer más visibles las secciones importantes",
        "restore": "Restaurar",
        "create_new_category": "Crear nueva categoria",
        "account_plan_invalid": "Comunicate con soporte para adaptar tu plan",
        "Modificar_PVP": "Modificar PVP",
        "Descargar_excel": "Descargar en Excel",
        "Mostrar_columnas": "Mostrar columnas",
        "mark": "Marca",
        "material": "Material",
        "calubre": "Calibre",
        "puente": "Puente",
        "varilla": "Varilla",
        "late_cancel_by": "Cancelación tardía por parte de",
        "el": "el",
        "la": "la",
        "change": "Cambiar",
        "provider_has_products": "Este proveedor tiene productos asociados",
        "no_template_selected": "No has seleccionado una plantilla para enviar",
        "no_marketing_email": "Ingrese un email correcto",
        "clients_p_work": "Puede ver sólo sus clientes agendados",
        "or": "o",
        "o_app": "App",
        "o_widget": "Widget",
        "o_web": "Web",
        "center_commerce": "Sede",
        "print_formula": "Imprimir fórmula",
        "provider-template": "Plantilla sobre pedido",
        "docType": "Tipo de documento",
        "personType": "Tipo de Persona",
        "juridico": "Jurídico",
        "natural": "Natural",
        "patientType": "Tipo de Paciente",
        "zoneType": "Tipo de Zona",
        "patient": "Paciente",
        "Identified": "Id",
        "Mobile": "Móvil",
        "Consultation_Date": "Fecha consulta",
        "validity": "Vigencia",
        "Professional": "Profesional",
        "Date_and_Time": "Fecha y Hora",
        "purchase_order_sended": "Orden de compra enviada con éxito",
        "write_doc_type": "Tipo de documento",
        "serviceCode": "Código de prestador de servicios de salud",
        "early_cancel_by": "Cancelación temprana por",
        "late_cancel_work_center": "Cancelación tardía por parte de",
        "late_cancel_work_client": "Cancelación tardía por parte del",
        "early_cancel_work_center": "Cancelación temprana por parte de",
        "early_cancel_work_client": "Cancelación temprana por parte del ",
        "the_day": "el día",
        "value": "Valor",
        "a": "un",
        "steps": "pasos",
        "step": "paso",
        "milimeters": "milimetros",
        "out_of_range": "fuera de rango",
        "Dinosaurio": "Dinosaurio",
        "Mariposa": "Mariposa",
        "Fecha finalizada": "Fecha finalizada",
        "Dias incapacidad": "Dias incapacidad",
        "Fecha inicio": "Fecha inicio",
        "Motivo": "Motivo",
        "Value_according_to_applied_scale": "Valor según escala aplicada",
        "Remisión": "Remisión",
        "Go_to_another_venue": "Ir a otra sede",
        "Activar": "Activar modo silencio",
        "There_are_no_notifications": "No tiene notificaciones.",
        "Search_headquarters": "Buscar sede...",
        "Request_digital_signature": "Pedir firma digital",
        "Marked_signed": "Marcado como firmado por",
        "Firmado": "Firmado",
        "deleted_img_success": "Archivo eliminado",
        "booking_paginate": "Recursos paginados",
        "booking_paginate_label": "Ver los recursos paginados",
        "booking_paginate_description": "Puedes elegir si quieres visualizar la agenda con los recursos paginados o ver todos los recursos en una misma pantalla",
        "Has_no_category": "No tiene categoría",
        "Has_no_service/product": "No tiene servicio/producto",
        "Salir": "Salir",
        "Previous_page": "Pagina anterior",
        "Next_page": "Siguiente pagina",
        "client_mid": "Número de cliente",
        "Gympass_spaces": "Espacios Gympass",
        "mid": "Número de cliente",
        "c_ClaveProd": "Clave de producto",
        "c_ClaveUnidad": "Clave de unidad",
        "basic_information": "Información básica",
        "Integraciones": "Integraciones",
        "web_basic": "Web básica",
        "Comunicaciones": "Comunicaciones",
        "Medios": "Medios",
        "SKU": "SKU",
        "List of classes or videos": "Lista de videos o Clases",
        "Biblioteca": "Biblioteca",
        "imagen": "Imagen",
        "archivo": "Archivo",
        "change cover": "Cambiar portada",
        "Matrícula": "Inscripción para compra online (una vez)",
        "size_image_logo": "Debe ocupar menos de 5MB y medir 300x100px",
        "discover_more_functionalities_in_desktop1": "Discover more in desktop version! Some settings are limited on mobile viewing",
        "no_client_records_found": "No hay registros de este cliente en el periodo",
        "warning_creation_admin_without_email": "El campo de email debe ser llenado para el usuario Administrador",
        "empty_notifications": "No hay notificaciones disponibles",
        "title_assets": "⚡ Advertencia: 8000 archivos cargados ⚡",
        "descrip_assets": "Si añades más, podrías experimentar lentitud en tus cargas. ¡Tenlo en cuenta!",
        "title_clients": "🌟¡15.000 clientes creados!🌟",
        "descrip_clients": "Cuida la velocidad: Crear más podría ocasionar lentitud en tus cargas.",
        "title_works": "✨350 citas agendadas, eres increíble✨",
        "descrip_works": "Evita lentitud en tus procesos de carga: Mantén este límite para una semana fluida",
        "title_sendings": "💫Superaste tu límite de envíos💫",
        "descrip_sendings": "¡No te preocupes! Amplía tus envíos haciendo clic aquí",
        "delete_scheduled": "¿Quieres eliminar el mensaje programado? Esta acción no puede deshacerse.",
        "locale_tr": "Turco",
        "locale_it": "Italian",
        "description_language": " En este campo podrás seleccionar el idioma para tu cuenta.",
        "description_country": "En este campo, podrás elegir tu país y en la segunda columna encontrarás la moneda en la que se efectuarán los cobros de tu cuenta.",
        "type_currency": "Tipo de moneda",
        "edit_account": "Editar Cuenta",
        "description_type_currency": "En este campo, puede seleccionar la divisa en la que se realizará el cargo en su cuenta",
        "choose_country": "Escoja un país",
        "choose_locale": "Escoja un idioma",
        "f_biannual": "Semestral",
        "cart": "carrito",
        "basic_box": "Caja básica",
        "Ahorra": "Ahorra",
        "yext_config_title": "ID de Google Business",
        "yext_config_subtitle": "Agrega este ID para activar tu perfil de negocio.",
        "yext_config_placeholder": "ID del Perfil de Negocio",
        "success_agency_notification": "Tu interés en este módulo ha sido registrado. Estamos emocionados por ayudarte. ¡Pronto nos comunicaremos contigo!",
        "error_agency_notification": "Lo sentimos, no se pudo enviar tu solicitud en este momento. Por favor, inténtalo de nuevo más tarde.",
        "already_agency_notification": "Ya hemos recibido tu solicitud. ¡Pronto nos comunicaremos contigo!",
        "img_limit_height_or_width": "Ten presente que esta imagen no cumple con los requerimientos optimos, lo cual puede afectar la forma como se visualizara en tu web"
};
});