define([], function() {
    return {
        "today": "Hoy",
        "message_loading_information_client": "¡Estamos preparando la información de tu cliente! Espera un momento por favor.",
        "current_week": "Semana actual",
        "agenda": "Agenda",
        "currenly_using": "en uso",
        "day": "Día",
        "week": "Semana",
        "agendas": "Agendas",
        "resources": "Recursos",
        "purchased": "Cobrado",
        "rejected": "Cita rechazada",
        "client_rejected": "Cancelación temprana",
        "assign_to": "Asignar a",
        "change_duration": "Cambia la duración",
        "other_options": "Otras opciones",
        "blocked_day": "Día bloqueado",
        "edit_anyway": "Editar igualmente",
        "drag_to_fix": "Arrastra para fijar la hora",
        "q_sure_delete_date": "¿Seguro que quieres eliminar la reserva?",
        "sure_delete_date": "quiero eliminar la reserva",
        "choose_day": "Escoge el día",
        "choose_agenda": "Escoge recursos",
        "if_no_datacard": "Si no tiene ficha",
        "create_fast_datacard": "crea una ficha rápida",
        "assist": "Asiste",
        "will_assist": "Asistirá",
        "not_assist": "Sin asistir",
        "marked_as_not_assist": "La cita ya está marcada como 'sin asistir'. Si finalmente el cliente ha venido, haga clic en el botón 'Asiste/Asistirá'.",
        "if_client_doesnt_come": "Si no ha venido el cliente, marca la cita como 'sin asistir' haciendo clic en el botón 'Sin asistir'.",
        "change_day": "Cambiar de día",
        "change_agenda": "Cambiar de recursos",
        "write_note": "Escribir una anotación (solo la ves tú)",
        "client_have_not_assist": "El cliente no ha venido a la cita",
        "date_periodically": "Cita que se repite periódicamente",
        "add_tags": "Añadir etiquetas a la cita",
        "edit_note": "Editar nota",
        "add_note": "Añadir nota",
        "write_something": "Escribe aquí lo que quieras",
        "choose_clone_days": "Escoge días",
        "how_many_dates_more": "¿Cuántas citas más habrá? (sin contar esta)",
        "how_long_repeat": "¿Cada cuánto se repite?",
        "once_a_week": "una vez a la semana",
        "once_every_two_weeks": "una vez cada 2 semanas",
        "once_every_three_weeks": "una vez cada 3 semanas",
        "once_every_four_weeks": "una vez cada 4 semanas",
        "warning_dates_in_holidays": "Las citas se crearán aunque caigan en día festivo o ya haya una cita a esa hora. Por lo tanto, verifica las citas una vez creadas para evitar imprevistos.",
        "choose_client": "Escoger cliente",
        "choose_treatment": "Escoger servicio",
        "add_other_treatment": "Añadir otro servicio",
        "create_date": "Crear reserva",
        "minutes_until": "minutos. Hasta las",
        "minutes": "minutos.",
        "specify_n_session": "Especifica el número de sesión",
        "treatments_options": "Opciones de servicio",
        "number_of_session": "Número de sesión",
        "edit_tags": "Modificar Etiquetas",
        "add_agenda": "Añadir agenda",
        "agenda_list": "Lista de agendas",
        "acar_header_1": "Poder tener más de una agenda es muy útil para un negocio con más de un cuarto/departamento",
        "acar_header_2": "También puede ser útil para que el personal externo tenga su propia agenda",
        "acar_add_agenda_desc": "Añade agendas de forma sencilla. Para configurar una agenda, puedes seleccionarla a través del panel izquierdo o haciendo clic en el botón 'Editar' en la lista inferior. ",
        "name_of_agenda": "Nombre de la agenda",
        "delete_agenda": "Eliminar agenda",
        "acqr_question": "¿Seguro que quieres eliminar esta agenda?",
        "acqr_desc": "Recuerda que si eliminas esta agenda, se eliminarán también todos los servicios que se hayan hecho en ella.",
        "aca_header": "Aquí podrás configurar la agenda. Su información básica, los servicios que se pueden ofrecer e incluso configurar la agenda para que puedan reservar los propios clientes (si quieres).",
        "aca_info_agenda": "Información básica de la agenda",
        "aca_write_basic_info": "Escribe los datos básicos de esta agenda.",
        "aca_desc_1": "Añade los servicios o las categorías de servicios que puede hacer la agenda. Cuando añades una categoría, se da por sentado que la agenda puede gestionar todos los servicios de esa categoría. Ten en cuenta que si dejas vacía la lista, la agenda podrá manejar todos los servicios del centro.",
        "aca_title_2": "Información visible en la cita",
        "aca_desc_2": "Elige la información que quieres que sea visible en la cita.",
        "aca_title_3": "Cobrador por defecto",
        "aca_desc_3": "Puedes seleccionar un cobrador por defecto para la agenda. Esto significa que cuando vayas a realizar un cobro a través de la agenda, se seleccionará dicho usuario como cobrador.",
        "aca_no_def_casher": "La agenda no tiene cobrador por defecto",
        "aca_title_4": "Avisos por email",
        "aca_desc_4": "Los usuarios mostrados abajo son los que tienen acceso a esta agenda. Los que estén habilitados, recibirán avisos por email cada vez que alguien, que no sea el propio usuario, añada una reserva en la agenda. Ya sea por que otro usuario ha añadido la reserva, o por que el propio cliente lo ha hecho a través de la web.",
        "aca_title_5": "Habilitar reservas por parte del cliente",
        "aca_desc_5": "Habilita esta opción si quieres que los clientes puedan reservar en esta agenda. Si lo habilitas, aparecerán más opciones abajo para configurar.",
        "res_confirmation_title": "Confirmación de citas",
        "res_confirmation_desc": "Cada vez que el cliente reserve una cita, se necesitará una confirmación por parte del empleado. Una vez aceptada o denegada la reserva, se le notificará al cliente automáticamente sobre ello.",
        "res_confirmation_check": "La reserva necesita confirmación previa",
        "aca_title_6": "Recursos",
        "aca_desc_6": "Define cuántos clientes pueden reservar a la misma hora. Por ejemplo, si tiene dos empleadas en esta agenda, puedes seleccionar '2' ya que pueden realizar dos servicios a la vez.",
        "aca_title_granularity": "Intervalos de tiempo",
        "aca_desc_granularity": "Puedes permitir que el cliente reserve una cita cada hora, cada media hora, etc. Selecciona la opción de Automático en caso de duda.",
        "aca_title_7": "Apertura de reservas",
        "aca_desc_7": "Selecciona la antelación con la que quieres que un cliente pueda reservar una cita.",
        "aca_max_ant_1": "máximo con 1 día de antelación",
        "aca_max_ant_2": "máximo con 2 días de antelación",
        "aca_max_ant_3": "máximo con 5 días de antelación",
        "aca_max_ant_4": "máximo con 1 semana de antelación",
        "aca_max_ant_5": "máximo con 2 semanas de antelación",
        "aca_max_ant_6": "máximo con 3 semanas de antelación",
        "aca_max_ant_7": "máximo con 1 mes de antelación",
        "aca_max_ant_8": "máximo con 2 meses de antelación",
        "aca_max_ant_9": "máximo con 4 meses de antelación",
        "aca_max_ant_10": "máximo con 6 meses de antelación",
        "aca_max_ant_11": "máximo con 8 meses de antelación",
        "aca_title_8": "Horarios y festivos",
        "aca_title_9": "Tipos de horario",
        "aca_desc_9": "Define tipos de horario que luego asignarás a los días. Por ejemplo, si tu horario es de mañana y tarde con un descanso para comer, especifica las horas de la mañana y haz clic en 'Más horas' para especificar las horas de la tarde.",
        "aca_label_9": "Nombre del horario",
        "aca_title_10": "Horarios de la semana",
        "aca_desc_10": "Indica qué días de la semana se trabaja y el horario de cada día.",
        "aca_desc_10_b": "Si el horario es diferente al horario del negocio, indica qué días de la semana se trabaja y el horario de cada día.",
        "aca_title_11": "Días festivos de la semana",
        "aca_desc_11": "Marca los días festivos de la semana.",
        "aca_title_12": "Vacaciones y festivos",
        "aca_desc_12": "Aquí puedes ir añadiendo los próximos días que no se trabajará por motivos especiales como vacaciones, puentes o días festivos puntuales.",
        "aca_desc_12_b": "Si la agenda tiene días festivos diferentes al negocio, puedes añadirlos aquí.",
        "holidays": "Días festivos",
        "timetable_desc": "Aquí podrás configurar los horarios de apertura del centro, para que los clientes sólo puedan reservar en horas que el centro esté abierto.",
        "auto_resources": "Recursos automáticos",
        "auto_resources_desc": "Selecciona los recursos o empleados que quieres que se ocupen cuando se crea una cita en la agenda.",
        "book_config_title": "Configuración avanzada de agendas",
        "back_to_agenda": "Volver a la agenda",
        "book_config_desc": "Aquí puedes configurar los detalles del funcionamiento de tu agenda.",
        "color_order_title": "Prioridad de colores de cita (lateral)",
        "color_order_desc": "El color de una cita depende de la ordenación de esta lista. Arrastra cada uno para moverlo hacia arriba y abajo. Para configurar este apartado, aconsejamos leer el tutorial correspondiente.",
        "color_order_type_state": "Estado",
        "color_order_type_tag": "Etiqueta",
        "color_order_type_idResource": "Recurso",
        "color_order_type_category": "Categoría de servicio",
        "color_order_state_res": "Cita normal",
        "color_order_state_charged": "Cita cobrada",
        "color_order_state_res_client": "Cita reservada online",
        "color_order_state_res_client_rejected": "Cancelada a tiempo por el cliente",
        "worktags_title": "Etiquetas personalizadas",
        "worktags_description": "Puedes añadir etiquetas personalizadas a las citas. Por ejemplo, puedes crear una etiqueta de 'En sala de espera' y asignársela a las citas en el momento que están en espera.",
        "worktags_addinput": "Escribe una etiqueta",
        "worktags_add": "Añadir etiqueta",
        "workbusy_inihour": "Inicio",
        "workbusy_fihour": "Fin",
        "purchased_online": "Pagado Online",
        "cancel_work": "Cancelar Cita",
        "will_pay_u1": "RECUERDA: Te abonaremos",
        "will_pay_u2": "por este servicio pagado online",
        "will_pay_promocode_u1": "RECUERDA: Te abonaremos lo",
        "will_pay_promocode_u2": "que falta por este servicio",
        "u_have_discount1": "RECUERDA: Esta cita tiene un descuento de",
        "u_have_discount2": "asumido por tu negocio",
        "purchased_api": "Externo",
        "purchased_portal": "De Miora",
        "default_cashers_title": "Empleados cobradores",
        "default_cashers_desc": "Aquí puedes establecer un empleado cobrador por defecto para cada Recurso, así se asigna automáticamente al cobrar una cita con ese Recurso.",
        "resource_order_title": "Ordenar recursos",
        "resource_order_desc": "Aquí puedes establecer el orden en el que aparecerán los recursos en la barra lateral de la agenda.",
        "filterview_placeholder": "Filtrar citas por servicio o cliente...",
        "rv_show_without_resources": "Ver citas sin recursos",
        "tabconfig_blockhours": "Bloquear horas",
        "tabconfig_adv_config": "Configuración avanzada",
        "color_order_state_finished": "Cita finalizada",
        "color_order_state_missing": "Cliente no presentado",
        "workbusy_note": "Comentario",
        "filter_active_warning": "Tienes un filtro activo. Hasta que no lo desactives, no podrás crear citas.",
        "resources_notice1": "¿Necesitas más agendas? ",
        "resources_notice2": "Crea un nuevo empleado",
        "resources_notice3": " o ",
        "resources_notice4": "Un nuevo recurso (sala, por ejemplo)",
        "birthdays": "Cumpleaños",
        "no_birthdays": "No hay cumpleaños",
        "no_notes": "No hay notas",
        "no_holidays": "No hay fiestas",
        "book_no_resources_warning": "No tienes ninguna agenda. Crea un empleado con especialidad para empezar a usar la agenda",
        "loading_books": "Cargando citas, espera un momento...",
        "config_pay_title": "Personalización restringida",
        "config_pay_description": "Tienes que estar suscrito para personalizar la agenda.",
        "ew_title": "Cobrar o finalizar citas",
        "ew_description": "Puedes elegir qué quieres hacer con una cita terminada. Si utilizas la parte de contabilidad será más cómodo el botón de cobrar, en cambio si no usas la parte de contabilidad preferirás el botón de finalizar.",
        "ew_rb_0": "Cobrar",
        "ew_rb_1": "Finalizar",
        "finished": "Finalizada",
        "cancel_reason": "Seleccione motivo de cancelación",
        "send_reason": "Enviar motivo",
        "cancel_reason_val1": "Socio solicita cancelación por disponibilidad",
        "cancel_reason_val2": "Socio solicita cancelación pues ya no presta el servicio",
        "cancel_reason_val3": "Socio solicita cancelación por diferencia de precio",
        "cancel_reason_val4": "Cliente solicita cancelación por disponibilidad",
        "cancel_reason_val5": "Cliente solicita cancelación por motivos personales",
        "cancel_reason_val6": "Cliente solicita cancelación porque no aplicó descuento",
        "cancel_reason_val7": "Cliente solicita cancelación porque no llegó mail de confirmación",
        "cancel_reason_val8": "Cliente solicita cancelación porque reservó servicio equivocado",
        "cancel_reason_val9": "Imposible contactar cliente",
        "cancel_reason_val10": "Reserva duplicada",
        "cancel_reason_val11": "Socio solicita cancelación porque el cliente ya no quiere tomar el servicio",
        "cancel_reason_val12": "Socio solicita cancelación por motivos personales",
        "cancel_reason_val13": "Prueba",
        "cancel_reason_val14": "Cliente solicita cancelación porque hubo un error en la reserva",
        "color_order_tag_no-employee": "Cita online sin preferencia de empleado",
        "input_view_name": "Nombre de la vista",
        "view": "Vista",
        "duplicate": "Duplicar",
        "duplicate_work": "Duplicar reserva",
        "duplicate_select_client": "Duplicar reserva: Escoja cliente",
        "more_info_booking": "Más información sobre la cita",
        "more_booking_info": "Detalles sobre la cita",
        "creation_date": "Fecha de creación",
        "created_by": "Creada por",
        "join_client": "Apuntar cliente",
        "color_order_state_res_late_cancel": "Cita cancelada tarde",
        "choose_workgroup": "Escoger clase",
        "create_workgroup": "Crear clase",
        "client_selected": "cliente seleccionado",
        "put_to_confirmed": "Confirmar reserva",
        "put_to_finished": "Finalizar reserva",
        "put_to_cancelled_by_client": "Cancelada por el cliente",
        "put_to_waitinglist": "Poner en lista de espera",
        "out_of_waitinglist": "Sacar de la lista de espera",
        "put_to_delete": "Desapuntar de la clase",
        "mod_next_classes": "Modificar próximas citas",
        "del_next_classes": "Eliminar también próximas citas",
        "put_to_cashed": "Cobrar reserva",
        "add_new_color": "Añadir nuevo color",
        "booked_ios": "A través de la app de iPhone",
        "color_order_title_background": "Prioridad de colores de cita (Color de fondo)",
        "color_order_desc_background": "El color de una cita depende de la ordenación de esta lista. Arrastra cada uno para moverlo hacia arriba y abajo. Para configurar este apartado, aconsejamos leer el tutorial correspondiente.",
        "color_order_type_idTreatment": "Servicio",
        "put_to_late_cancelled_by_client": "Cancelación tardía por el cliente",
        "Cancelar cita (cancelación tardía)": "Cancelar cita (cancelación tardía)",
        "online": "Online",
        "booked_android": "A través de la app de Android",
        "booked_web": "A través de la web",
        "booked_widget": "A través del widget web",
        "booked_fb": "A través de Facebook",
        "prop_client_works": "Copiar clientes",
        "modification_date": "Fecha de modificación",
        "search_in_other_center": "Busca el cliente en otro centro",
        "cant_create_book_same_time": "Ya hay una cita creada a esa hora, no se puede crear otra.",
        "Se creó el día": "Se creó el día",
        "Cambiar estado": "Cambiar estado",
        "Cambiar a res": "Volver al estado inicial",
        "Cambiar a confirmed": "Confirmar cita",
        "Cambiar a waiting": "Poner en espera",
        "Cambiar a progress": "Cita en proceso",
        "Cambiar a finished": "Finalizar cita",
        "Cambiar a res_client_rejected": "Cancelada a tiempo por el cliente",
        "Cambiar a res_late_cancel": "Cancelada tarde por el cliente",
        "Cambiar a res_missing": "El cliente no ha venido (No show)",
        "message_res": "Estado inicial",
        "message_res_client": "Estado inicial",
        "message_confirmed": "Cita confirmada",
        "message_waiting": "En espera",
        "message_progress": "En proceso",
        "message_finished": "Cita finalizada",
        "message_res_client_rejected": "Cancelada a tiempo",
        "message_res_missing": "No ha venido a la cita",
        "message_res_late_cancel": "Cancelada tarde",
        "Bono aplicado": "Bono aplicado",
        "Suscripción aplicada": "Suscripción aplicada",
        "El servicio ya ha sido pagado online": "El servicio ya ha sido pagado online",
        "aca_want_clients_booking": "Sí, quiero que los clientes puedan reservar en esta agenda.",
        "clients_selected": "clientes seleccionados",
        "apply_date": "Fecha de aplicación",
        "empty_subs": "Ninguna suscripción",
        "no_subs_to_apply": "No tiene ninguna suscripción o bono aplicable al servicio",
        "has_subs_to_apply": "Suscripción o bono",
        "apply_subs": "Aplicar suscripción",
        "change_subs": "Cambiar suscripción",
        "choose_pack_subs_to_apply": "Puede aplicar varias suscripciones o bonos",
        "Suscripción aplicada inactiva": "Suscripción aplicada inactiva",
        "Cambiar a charged": "Cobrar cita",
        "ag_zoom_title": "Tamaño de las citas",
        "ag_zoom_desc": "Hay 3 modos para visualizar la agenda. Si tus citas normalmente tienen una duración de 1-2 horas, el modo normal es el ideal. En cambio, si tus citas normalmente son de pocos minutos es mejor optar por una visualización más amplia para ver las citas más grandes.",
        "ag_zoom_0": "Vista normal",
        "ag_zoom_1": "Vista ampliada",
        "ag_zoom_2": "Vista muy ampliada",
        "spot_on_class": "Espacio en la clase",
        "q_apply_changes_to_future_works": "Quieres aplicar el cambio a las citas futuras?",
        "join_client_waitinglist": "Apuntar a lista de espera",
        "color_order_state_confirmed": "Cita Confirmada",
        "Clase en directo": "Emisión en directo",
        "Escribe la dirección web": "Escribe la dirección del enlace",
        "Has introducido una dirección incorrecta de directo": "Has introducido una dirección incorrecta de directo",
        "Sin emisión": "Sin emisión",
        "A través de Youtube": "A través de Youtube",
        "A través de Bewe Live": "A través de Clases Online",
        "Empezar clase con Bewe Live": "Empezar clase Online",
        "message_res_rejected": "Cancelada por el centro",
        "Cancelar clase": "Cancelar clase",
        "q_sure_cancel_workgroup": "Quieres cancelar la clase?",
        "q_sure_cancel_workgroup_exp": "Al cancelar la clase todos los alumnos recibirán una notificación (excepto en modo silencio) y recuperarán la sesión de bono o suscripción que tuvieran aplicado.",
        "No cancelar": "No cancelar",
        "assign_video": "Asignar clase en video",
        "class_without_video_assigned": "Clase sin video asignado",
        "Video asignado": "Video asignado",
        "A través de Zoom": "A través de Link",
        "resource_scroll_title": "Deslazamiento de recursos",
        "resource_scroll_description": "Habilitar o deshabilitar el desplazamiento cuando hay muchos recursos o profesores",
        "resource_scroll_enable": "Activar Scroll",
        "resource_scroll_disable": "Desactivar Scroll",
        "pick_subs_to_apply": "Selecciona un bono/suscripción para el servicio",
        "subs_to_apply": "Bono/suscripción a aplicar",
        "finished_pick_sub": "Escoge la suscripción/bono que deseas aplicar a la clase",
        "work_to_waitinglist": "Cita agregada a la lista de espera",
        "whatsapp_tittle": "Acceso directo a Whatsapp web",
        "whatsapp_description": "Cuando se despliega una cita en la agenda, al lado del nombre del cliente se añadirá un icono para acceder directamente a la conversación con el cliente en Whatsapp Web. Siempre y cuando el cliente tenga número de teléfono en su ficha.",
        "whatsapp_rb_0": "Habilitar acceso directo a Whatsapp Web en la agenda.",
        "add_other_instructor": "Agregar otro instructor",
        "add_instructor": "Añadir otro instructor",
        "change_room": "Cambiar sala",
        "update_room": "Actualizar sala",
        "assign_new_room": "Asignar nueva sala",
        "go_to_request": "Ir a la solicitud",
        "signup_next_class": "Apuntar a la siguiente clase",
        "signup_next_classes": "Apuntar a las siguientes clases",
        "color_order_state_res_preconfirmation": "Cita en espera de confirmación",
        "Cambiar a res_rejected": "Cancelada por el centro",
        "all_quotes": "Todas las citas",
        "delinking_classes": "Se está desapuntando de todas las clases",
        "enrolling_classes": "Se está apuntando a todas las clases",
        "show_quotes": "Mostrar citas",
        "added_outside_your_hours": "No se puede agregar una cita fuera de su horario",
        "choose_color_prioritized": "Escoge el color que quieres que se priorice",
        "description_choose_color_prioritized": "Selecciona el color que deseas que se priorice en los cambios de estado, esta configuración solo aplica a filtro de citas y colores de estado, las citas seguirán respetando los colores configurados.",
        "possible_block_agenda_outside_center_hours": "No se puede bloquear la agenda fuera del horario del centro",
        "reserved_spaces_gympass": "Espacios Reservados Gympass",
        "Integration": "Integración",
        "bcheck_title": "Creación de citas",
        "bcheck_desc": "Aquí puedes bloquear la creación de cita si el recurso está fuera de su horario laboral o si ya tiene una cita creada a esa misma hora. ",
        "bcheck_bcheck_0": "Sí, quiero bloquear la creación de citas si el recurso está fuera de su horario laboral o si ya tiene una cita registrada a esa misma hora.",
        "Sincronizar desde Google Calendar": "Sincronizar desde Google Calendar",
        "title_ticket_main_demo": "Controla la gestión financiera de tu negocio y mantén la administración bajo control",
        "title_one_main_demo": "Almacena toda la información de tus servicios y productos para que no pierdas ningún detalle",
        "Deja tus comentarios y previsualiza tu página": "Deja tus comentarios y previsualiza tu página",
        "subtitle_ticket_demo": "Automatiza procesos y olvídate de hacer tareas manuales",
        "subtitle_one_demo": "Organiza los datos en un solo lugar y haz un seguimiento automático evitando errores humanos",
        "title_agenda_main_demo": "Organiza la gestión diaria de tu negocio con una agenda online",
        "subtitle_agenda_demo": "Activa esta función y visualiza las citas, empleados, recursos y más para tus servicios",
        "title_treatment_main_demo": "Almacena toda la información de tus servicios y productos para que no pierdas ningún detalle",
        "subtitle_treatment_demo": "Organiza los datos en un solo lugar y haz un seguimiento automático evitando errores humanos",
        "broadcast_with_link": "Emitir con Link",
        "without_connection": "No hay suficiente conexión a internet",
        "check_connection": "Revisa tu conexión e intenta nuevamente",
        "slow_connection": "Red lenta",
        "new_client_first_work": "Nuevo cliente",
        "new_client_first_work_tittle": "Primera cita de nuevos clientes",
        "new_client_first_work_description": "Cuando un cliente se apunte a su primera visita, al lado de su nombre, se añadirá un icono para que sepas que es un cliente nuevo",
        "new_client_first_work_label": "Si lo habilitas, en la primera cita del cliente le aparecerá el icono de 'Nuevo cliente'. ",
        "pointing_classes": "Se está apuntando a tu(s) cliente(s) a {numClass} clases",
        "unscheduling_classes": "Estamos buscando al cliente(s) en {numClass} clases recurrentes y realizando la modificación seleccionada.",
        "my": "mi",
        "saving": "Ahorro"
};
});