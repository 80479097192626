/* eslint-disable no-console */
/* eslint-disable no-shadow */
const es = require('date-fns/locale/es');
const en = require('date-fns/locale/en');
const { format } = require('date-fns');
const _ = require('bewelib/libs/underscore.extend');
const i18nCore = require('../../i18n/locales/common/core');
const i18nCash = require('../../i18n/locales/cash/cash');
const i18nReports = require('../../i18n/locales/reports/reports');
const i18nTreatments = require('../../i18n/locales/treatments/treatments');
const i18nEmployees = require('../../i18n/locales/employees/employees');
const i18nClients = require('../../i18n/locales/clients/clients');
const i18nProducts = require('../../i18n/locales/products/products');
const i18nBook = require('../../i18n/locales/book/book');
const i18nMarketing = require('../../i18n/locales/marketing/marketing');
const i18nWeb = require('../../i18n/locales/web/web');
const i18nConfig = require('../../i18n/locales/config/config');
const i18nTimkot = require('../../i18n/locales/timkot/timkot');

const i18n = _.extend(
  {},
  i18nCore,
  i18nCash,
  i18nReports,
  i18nTreatments,
  i18nClients,
  i18nBook,
  i18nMarketing,
  i18nWeb,
  i18nConfig,
  i18nProducts,
  i18nEmployees,
  i18nTimkot,
);

/*
export const i18n = new Jed({
  locale_data: {
    messages: {
      '': {
        domain: 'messages',
        lang: 'es',
        plural_forms: 'nplurals=2; plural=(n != 1);',
      },
      creditcard: ['Tarjeta de crédito'],
      cash: ['En efectivo'],
      prepaid: ['Saldo'],
      debt: ['Deuda'],
      giftcard: ['Tarjeta de regalo'],
      workFilter: ['Servicios'],
      productFilter: ['Productos'],
      packFilter: ['Bonos'],
      specialFilter: ['Cobros especiales'],
      prepaidFilter: ['Saldos y deudas'],
    },
  },
}) */

/**
 * EmptyLabel
 * @param {*} list array
 * @param {*} ifEmpty label
 * @param {*} otherwise label
 * @returns {string} label
 */
function emptyLabel(list, ifEmpty, otherwise) {
  if (!list.length) {
    return ifEmpty;
  }

  return otherwise;
}

function tt(str, ...values) {
  const key = String.raw(str, ...values);
  const value = i18n[key] || key;

  if (!(key in i18n)) {
    window.missingKeys = window.missingKeys || {};
    window.missingKeys[key] = key;
  }

  return value;
}

function ttReport(str, view) {
  const defaultLocale = 'es';
  const locale = window.sessionUser.locale || defaultLocale;
  const { i18n = { es: {} } } = view;

  if (i18n[locale]) {
    return i18n[locale][str] || i18n[defaultLocale][str] || tt`${str}`;
  }

  return i18n[defaultLocale][str] || tt`${str}`;
}

function dateToString(date, body = 'dddd, D MMMM YYYY') {
  if (!window.sessionUser)
    console.error(
      'Se está ejecutando dateToString antes de iniciar la aplicación',
    );
  const locale =
    window.sessionUser && window.sessionUser.locale === 'en' ? en : es;
  return format(date, body, { locale });
}

function rangeDateString({ fromDate, toDate, timespan, ...props }) {
  const { format = false } = props;
  switch (timespan) {
    case 'day':
      return dateToString(fromDate);
    case 'week':
      return `${dateToString(fromDate, format || 'D MMMM')} - ${dateToString(
        toDate,
        format || 'D MMMM YYYY',
      )}`;
    case 'month':
      return dateToString(fromDate, 'MMMM YYYY');
    case 'year':
      return dateToString(fromDate, 'YYYY');
    default:
      return false;
  }
}

function currency(amount) {
  return _.bh.toPrice(amount, { format: 'text' });
}

function currencySymbol() {
  return window.sessionUser.taxcurrency || window.sessionUser.csymbolext || '$';
}

const dateFromObjectId = (id, format = "D [de] MMMM YYYY") => {
  const creationDate = new Date(parseInt(id.slice(0, 8), 16) * 1000)

  return dateToString(creationDate, format)
}

module.exports = {
  i18n,
  tt,
  ttReport,
  emptyLabel,
  currency,
  dateToString,
  rangeDateString,
  currencySymbol,
  dateFromObjectId
};
