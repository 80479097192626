/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Container, Sidebar, Sidenav, Icon, Content, Nav } from 'rsuite';
import { Layout, HeaderSiderBar } from './styles';
import { NavToggle } from './NavToggle';
import { MainMenu } from './MainMenu';

const Page = (props) => {
  const { children } = props;
  const [expand, setExpand] = useState(true);

  const handleToggle = () => {
    setExpand((v) => !v);
  };

  return (
    <Layout>
      <Container className="frame">
        <Sidebar
          style={{ display: 'flex', flexDirection: 'column' }}
          width={expand ? 200 : 56}
          collapsible
        >
          <Sidenav.Header>
            <HeaderSiderBar>
              <Icon
                icon="logo-analytics"
                size="lg"
                style={{ verticalAlign: 0 }}
              />
              <span style={{ marginLeft: 12 }}> BACKOFFICE </span>
            </HeaderSiderBar>
          </Sidenav.Header>
          <Sidenav
            expanded={expand}
            defaultOpenKeys={['3']}
            appearance="subtle"
          >
            <Sidenav.Body>
              <Nav>
                <MainMenu />
              </Nav>
            </Sidenav.Body>
          </Sidenav>
          <NavToggle expand={expand} onChange={handleToggle} />
        </Sidebar>

        <Container className="side-container">
          <Content> {children}</Content>
        </Container>
      </Container>
    </Layout>
  );
};

export default Page;
