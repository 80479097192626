import * as Blockly from 'blockly/core';

const color = 120;

Blockly.Blocks.set = {
  init() {
    this.appendDummyInput()
      .appendField('set')
      .appendField(new Blockly.FieldTextInput(''), 'set_value');
    this.setPreviousStatement(true, ['Array', 'value']);
    // this.setNextStatement(true, null);
    this.setColour(color);
  },
};

Blockly.Blocks.globalVariable = {
    init() {
      this.appendDummyInput()
        .appendField('Global variable')
        .appendField(
            new Blockly.FieldDropdown([['set', 'set'], ['get', 'get']]),
            'type'
        )
        .appendField(new Blockly.FieldTextInput(''), 'name')
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(color);
    },
  };