define([], function() {
    return {
        "configuration": "Configuración",
        "bhpayment": "Tus pagos",
        "enablesms": "Habilitar SMS",
        "myaccount": "TU NEGOCIO",
        "tpv": "Terminal de venta (TPV)",
        "multicaja": "Multi-caja",
        "printing_tickets": "Impresión de tickets",
        "ways_to_pay": "Formas de pago",
        "importname": "Nombre de la importación...",
        "sms_current_saldo": "Saldo de SMS actual",
        "sms_manyways": "Compra SMS y utilízalos de muchas formas",
        "reminders_desc": "Podrás recordarle las citas a los Clientes automáticamente. Es una característica muy importante ya que se consiguen dos cosas: Evitarás que haya Clientes que se olviden de la cita y, en el caso de que no puedan venir, verás como avisarán con más antelación para que puedas reorganizarte.",
        "offers_promotions_desc": "Envía SMS a muchos Clientes a la vez de forma fácil y simple. Te servirá para poder avisarles de cualquier noticia importante y también para enviarles promociones y descuentos.",
        "fullfill_fact_data": "Completa los datos de facturación",
        "fiscality": "Tributación",
        "freelance_company": "Autónomo/Empresa",
        "sms_conditions_desc": "Condiciones de contrato referente a la utilización de SMS comprados en WWW.Bewe.COM</br>-----------</br>1) Los SMS comprados en WWW.Bewe.COM únicamente podrán ser utilizados dentro del servicio WWW.Bewe.COM.</br>2) Cada SMS consta de 160 caracteres. Si el texto supera los 160 caracteres, cada envío contará como 2 SMS o más según el número de caracteres.3) WWW.Bewe.COM no se responsabiliza del contenido de los SMS. La responsabilidad es cedida al comprador o en su defecto al autor que haya escrito el texto contenido en el SMS.</br>4) Una vez comprados los SMS, no podrá ser devuelto el importe.</br>-----------</br>Condiciones actualizadas el 4 de Julio de 2013 a las 00:00",
        "sms_accept": "Acepto las condiciones SMS",
        "concept": "Concepto",
        "config_notif_war_prem": "Recuerda que estas configuraciones no tendrán efecto, ya que no es posible enviar emails con la versión gratuita.",
        "welcome_title": "Habilitar email de bienvenida",
        "welcome_text_desc": "Escribe un texto de bienvenida que formará parte del e-mail que recibirán los Clientes cuando des de alta tu ficha.",
        "enable_reminder_title": "Habilita recordatorio de citas por email/sms",
        "enable_reminder_desc": "Habilita esta opción si quieres que los Clientes reciban un email/sms de recordatorio cada vez que tengan una cita asignada.",
        "efooter_text_desc": "Añade un texto al final de los emails que se envían. Es un texto con un tamaño de letra pequeño, ideal para textos legales.",
        "import_data_desc": "<p>Aquí puedes importar clientes, productos y servicios de forma automática. Una vez que hagas la importación, se dará como válida y se subirá al sistema. </p><p style='margin-top:10px'>Ten en cuenta que, para importar correctamente los datos, tienes que subir un archivo que cumpla un formato exacto.</p><p style='margin-top:10px'>Descarga un ejemplo de cómo tendrían que ser los archivos según lo que quieras importar: <a class='a-link' id='a-link_clients' href='https://bewe-media.s3.amazonaws.com/formato_clientes.csv'>ejemplo de Clientes</a>, <a class='a-link' id='a-link_services' href='https://s3.amazonaws.com/bellahora_my_files/import_templates/es/servicios.csv'>ejemplo de servicios</a>, <a class='a-link' id='a-link_products' href='https://s3.amazonaws.com/bellahora_my_files/import_templates/es/productos.csv'>ejemplo de productos</a> .</p>",
        "import_excel_desc": "Recuerda que tiene que estar en formato CSV y codificación UTF-8",
        "import_undo": "Deshacer importación",
        "has_imported": "Se han importado",
        "multiple_cajas": "Múltiples cajas",
        "multiple_cajas_desc": "Aquí puedes configurar múltiples cajas para tener los cobros mejor organizados.",
        "caja_list": "Lista de cajas",
        "add_edit_caja": "Añade, edita o deshabilita las cajas.",
        "caja_name": "Nombre de la caja",
        "choose_every_charge": "Seleccionar la caja en cada cobro",
        "config_location_title": "Ubicación del negocio",
        "config_location_desc": "Busca tu negocio en el campo de texto. También puedes arrastrar el punto rojo por el mapa para detallar más la posición.",
        "loading_prov": "Cargando provincias",
        "multiple_users_desc": "Es muy común que en la computadora del centro (terminal de venta), quieras entrar con diferentes usuarios. Con el sistema multi-usuario podrás cambiar entre usuarios de forma muy rápida. Así te aseguras que cada empleado entre con su usuario y podrás llevar un control más preciso de lo que hace cada uno.",
        "block_btn_title": "Botón de bloqueo en el menú",
        "block_btn_yes": "Sí, quiero habilitar el botón multi-usuario en este ordenador.",
        "inactivity_title": "Bloquear por inactividad",
        "inactivity_desc": "Habilita esta opción si quieres que el programa se bloquee automáticamente cuando no se está usando. Para desbloquearlo, se necesitará entrar con algún usuario. Deberás activar esta opción en cada computadora donde quieras usar el bloqueo automático.",
        "custom_title": "Personalización",
        "show_dni_field": "Mostrar campo de DNI",
        "show_addr_field": "Mostrar campo de dirección",
        "show_cp_field": "Mostrar campo de código postal",
        "show_phone_field": "Mostrar campo de segundo teléfono",
        "show_mid_field": "Añadir un identificador automático al crear un cliente",
        "ways_to_pay_desc": "Personaliza las formas de pago que necesitas para tu negocio.",
        "ways_to_pay_input": "Escribe una forma de pago...",
        "ways_to_pay_cash": "En efectivo",
        "ways_to_pay_card": "Con tarjeta",
        "dataview_list_desc": "Añade diferentes fichas y personaliza los datos que quieras",
        "dataview_list_input": "Nombre de la nueva ficha...",
        "dataview_list_nested_input": "Nombre del nuevo campo...",
        "go_premium": "Planes PREMIUM",
        "go_creditcard": "Cambia tu tarjeta de crédito",
        "card_changed": "¡Tarjeta Cambiada!",
        "save": "Guardar",
        "fullfill_bill": "Completa los datos de facturación (opcional)",
        "idfiscal": "Identificador Fiscal (CIF/NIF)",
        "physical_address": "Dirección física",
        "choose_quota": "Elige la cuota que prefieras",
        "quota": "Cuota",
        "quota_month": "Mensual ( cada mes )",
        "quota_year": "Anual ( cada 12 meses )",
        "debit_or_credit_card": "Tarjeta de crédito o débito",
        "link_your_card": "Forma de pago",
        "link_your_card_desc": "Vincula la tarjeta de crédito/débito con la que quieres pagar la cuota premium",
        "accept_and_go": "Aceptar y pasar a PREMIUM",
        "q_have_promocode": "¿Tienes un código promocional?",
        "introduce_promocode": "Introduce el código...",
        "validate": "Validar",
        "anual_tip": "Consigue un descuento",
        "anual_tip_desc": "¡Suscríbete a una cuota anual para conseguir un precio aún mejor!",
        "paypal_title": "Activación de la suscripción",
        "paypal_desc": "Para activar la suscripción de la cuenta PREMIUM de Bewe, se utiliza la plataforma segura de PayPal. Haga 'click' en el botón 'Suscribirse' para ir a Paypal y completar la suscripción. Una vez realizado, se activará la cuenta premium en menos de 24 horas.",
        "paypal_not_needed": "No es necesario tener una cuenta PayPal para hacer la suscripción. En la página que aparece al hacer clic en 'Suscribirse', debes seleccionar el enlace marcado en la siguiente imagen.",
        "congrats_premium": "¡Felicidades! Ya eres Premium",
        "congrats_premium_desc": "Ya puedes empezar a disfrutar de todas las ventajas que te ofrece la cuenta premium.<br/> El programa se recargará ahora para activar tu cuenta.",
        "congrats_gold": "¡Felicidades! Ya eres Gold",
        "congrats_gold_desc": "Ya puedes empezar a disfrutar de todas las ventajas que te ofrece la cuenta gold. El programa se recargará ahora para activar tu cuenta.",
        "discover_premium": "Descubre las ventajas de ser PREMIUM",
        "prem_reason_title_1": "Página web",
        "prem_reason_title_4": "Facturas electrónicas",
        "prem_reason_title_6": "Envía e-mails a todos tus Clientes",
        "prem_reason_title_7": "Visualiza más de dos agendas a la vez",
        "prem_reason_title_9": "Gestiona el salario de los empleados",
        "prem_reason_title_10": "Gestiona y optimiza los gastos del negocio",
        "prem_reason_title_11": "Y mucho más:",
        "prem_reason_1": "Donde podrás poner la información y servicios del centro e incluso configurarla para que tus Clientes reserven citas online. Te contactaremos para personalizar el diseño de la página según los colores y logo de tu negocio.",
        "prem_reason_3": "Podrás configurar lo que quieres que vean tus usuarios/empleados. Es una buena manera de controlar lo que pueden hacer tus empleados, trabajadores externo u otro tipo de usuarios.",
        "prem_reason_5": "Cada vez que añades una cita en alguna agenda, se le recordará al cliente automáticamente a través de un e-mail.",
        "prem_reason_7": "En la sección de Agendas podrás ver al mismo tiempo todas las agendas que quieras.",
        "prem_reason_8": "¿No sabes si un servicio está calando bien en la clientela? Esta herramienta enviará una pequeña encuesta que el cliente podrá rellenar anónimamente. De esta manera, podrás analizar los problemas y virtudes del servicio. ",
        "prem_reason_9": "Podrás gestionar el salario de cada uno de tus empleados, pudiendo gestionar de forma muy flexible la parte fija de su salario y la parte variable a través de comisiones.",
        "prem_reason_10": "Podrás gestionar gastos periódicos, gastos puntuales e incluso gastos periódicos variables ( por ejemplo: luz y agua ). Introduce todos los gastos aquí y obtendrás estadísticas y estimaciones que te ayudarán a controlar y optimizar mejor tus gastos.",
        "prem_reason_11": "Hay muchas pequeñas funcionalidades que sólo funcionan con la cuenta PREMIUM, y podrás disfrutar del programa de Bewe completo por un precio inmejorable.                ",
        "prem_contract": "Condiciones de contrato referente a la subscripción denominada PREMIUM de WWW.Bewe.COM</br>-----------</br>La subscripción PREMIUM contiene una serie de herramientas y funcionalidades extras para el cliente respecto a la versión GRATUITA ofrecida por WWW.Bewe.COM. Dichas herramientas y funcionalidades se listan a continuación:</br>1) En la cuenta PREMIUM se eliminan todo tipo de anuncios.</br>2) Se permite la configuración de permisos de usuario/empleados.</br>3) Se habilita la opción de envío de facturas simplificadas electrónicas a los Clientes.</br>4) Se habilita la opción de enviar e-mails de forma ilimitada para diversos motivos: encuestas y campañas de marketing.</br>5) Se permite la activación de recordatorios a las citas de la agena por e-mail y SMS.</br>6) Posibilidad de configurar encuestas de satisfacción que serán enviadas a los Clientes.</br>7) Herramienta para la gestión de los salarios de los empleados.</br>8) Herramienta para la gestión de gastos y beneficios del negocio.</br>9) Pequeñas mejoras que hacen de la versión PREMIUM un producto más completo y avanzado.</br>10) Posibilidad de realizar consultas de soporte técnico de forma ilimitada a través del chat incluído en la página de WWW.Bewe.COM o a través de e-mail a la dirección contacta@Bewe.com</br></br>El cobro mensual de la cuenta PREMIUM empieza el mismo día que se activa la subscripción.</br> No es posible devolver el importe del mes en curso.</br>Para cancelar la subscripción debe enviar un e-mail a contacta@Bewe.com y la subscripción será cancelada cuando se acabe el mes en curso.</br>-----------</br>Condiciones actualizadas al 14 de Septiembre de 2013 a las 00:00",
        "prem_accept": "Acepto las <a class='a-link' href='https://bewe.io/es/condiciones-legales/' target='_blank'>condiciones de uso</a> y <a class='a-link' href='https://bewe.io/es/politica-privacidad/' target='_blank'>privacidad</a>",
        "your_bills": "Tus pagos a Bewe",
        "download_autobill": "Descargar autofactura",
        "show_bills": "Mostrar facturas de Bewe",
        "bill": "Factura",
        "autobill": "Autofactura",
        "config_sms": "Configuración de SMS",
        "cs_desc": "<p>Puedes comunicarte con tus Clientes también por SMS. El SMS tiene una ventaja muy clara sobre el email: <b>la mayoría de los Clientes son más receptivos a leer un SMS que un email</b>.</p> <p>Aprovéchate de esta ventaja y <b>envía los recordatorios de cita y las ofertas más importantes por SMS</b>. Aunque los SMS tengan un coste, <b>notarás la diferencia</b> a la hora de comunicarte con tus Clientes.</p>",
        "buy_saldo_desc": "Compra uno de nuestros packs de SMS en un clic. Cuanto más SMS compres, más barato te saldrá.",
        "subs_sms": "SMS fuera de saldo",
        "subs_sms_desc": "¿Estás siempre pendiente del saldo? Puedes activar esta opción para que se envíen SMS aunque tengas el saldo en cero. Estos SMS se cobrarán junto a la mensualidad premium a un precio de 12 céntimos por SMS.",
        "subs_sms_check": "Sí, quiero poder enviar SMS aunque no tenga saldo.",
        "remind_sms": "SMS en los recordatorios y confirmaciones de citas",
        "sms_as_possible": "Enviar SMS siempre que sea posible",
        "only_if_not_email": "Enviar SMS sólo si el cliente no tiene un email en su ficha",
        "only_if_not_email_confirmations": "Solo envíe confirmaciones por SMS si su cliente no tiene un correo electrónico registrado.",
        "config_ticket": "Configuración de tickets",
        "config_ticket_desc": "Aquí puedes configurar los tickets que imprimes con la impresora de tickets.",
        "font_size": "Tamaño de letra",
        "font_size_9": "Letra muy grande",
        "font_size_5": "Letra mediana",
        "font_size_3": "Letra pequeña",
        "ticket_width": "Ancho del ticket",
        "ticket_margin_desc": "Añade un margen para la parte izquierda del ticket. El margen derecho puedes configurarlo indirectamente configurando el ancho.",
        "ct_info_show_desc": "Puedes mostrar o ocultar cierta información en el ticket.",
        "ct_show_nif": "Mostrar el NIF",
        "ct_show_address": "Mostrar la dirección",
        "ct_show_center": "Mostrar el nombre del negocio",
        "ct_show_attend": "Mostrar atendido por",
        "ct_phrase_default": "Gracias por su visita",
        "install_qz_desc": "Instalar la impresora tiene muchas ventajas. Podrás imprimir tickets más rápido, con más calidad y te permitirá configurar la apertura del cajón de monedas.",
        "printer_no_selected": "Impresora no seleccionada",
        "java_not_found": "Para terminar de instalar la impresora necesitas instalar java. Puedes instalarlo <a href='http://java.com/dt-redirect' target='_blank'>aquí</a>",
        "cth_title": "Horarios y días festivos del centro",
        "timetable_types": "Tipos de horario",
        "pg_title": "Personaliza los colores de tu cuenta",
        "pg_b": "Color de fondo de la cabecera",
        "pg_f": "Color de letra de la cabecera",
        "pg_l_title": "Personaliza el logo de tu cuenta",
        "pg_l_desc": "Puedes subir tu logo y que aparezca en la cabecera del programa.",
        "intern_err": "Error Interno del programa. Contacta con Asistencia Técnica",
        "free_account": "Gratuita",
        "go_gold": "Pasar a Gold",
        "already_premium": "Ya eres premium",
        "go_gold2": "Pásate a Gold",
        "card_num": "Número de la tarjeta de crédito",
        "card_name": "Nombre que figura en la tarjeta",
        "card_expiry": "Fecha de caducidad",
        "go_special": "Conviértete en PREMIUM o GOLD por menos de 1€ al día",
        "go_special_no": "¡Ya eres premium!",
        "use_native": "Impresora Nativa",
        "use_native_desc": "Escoge si quieres usar la impresora nativa de tu ordenador o usar la impresora de tickets",
        "gopremium": "Planes PREMIUM",
        "info": "Información",
        "mybhaccount": "Mi cuenta",
        "bills": "Cobros y facturas",
        "holidays": "Horarios y festivos",
        "notif_cli": "Notificaciones",
        "customiz": "Personalización",
        "multiuser": "Multi-usuario",
        "import": "Importar",
        "importing": "Importando",
        "reminders": "Recordatorios",
        "offers_promotions": "Ofertas y promociones",
        "every_sms_to": "Cada SMS a",
        "buysms": "Comprar SMS",
        "particular": "Particular",
        "retention_msg": "Se aplica una retención al ser Autónomo/Empresa por ser un servicio de caracter profesional",
        "not_ur_country": "¿No está tu país?",
        "contact_to_us": "Comunícate con nosotros",
        "sms_conditions_title": "Condiciones de SMS",
        "choose_quantity_and_way": "Escoja la cantidad y forma de pago",
        "quantity_sms": "Cantidad de SMS",
        "bank_transfer": "Transferencia bancaria",
        "only_bank_transfer": "Sólo disponible a través de transferencia bancaria",
        "retention": "Retención",
        "completed_request": "¡Petición completada!",
        "completed_request_desc": "Realiza la transferencia con los datos que hay a continuación. Al recibir la transferencia, automáticamente se hará efectivo el recargo de los SMS. Nos pondremos en contacto contigo para facilitarte la factura electrónica.",
        "num_account": "Número de cuenta",
        "config_notif_header": "Configura las notificaciones por e-mail o SMS que se envían a tus Clientes.",
        "welcome_desc": "Habilita esta opción si quieres que los Clientes reciban un email de bienvenida cuando des de alta su ficha.",
        "welcome_yes": "Sí, quiero que los Clientes reciban el email de bienvenida.",
        "welcome_text_title": "Texto de email de bienvenida",
        "welcome_text_def": "A través de estos correos nos comunicaremos contigo para recordarte cuando tengas una cita reservada, avisarte de nuestras ofertas y muchas cosas más.",
        "enable_reminder_yes": "Sí, quiero que los Clientes reciban recordatorios de citas.",
        "efooter_text_title": "Texto en el pie de los emails",
        "import_data": "Importar datos",
        "import_excel": "Sube el archivo a importar",
        "import_updated_title": "Comprueba que los datos que has subido son correctos. Una vez que sepas con certeza que todos los datos han sido subidos correctamente, haz clic en el botón de \"importación correcta\". Si por el contrario la importación no ha sido como deseas, haz click en el botón \"deshacer e inténtalo con un archivo más apropiado\".",
        "import_done": "Importación correcta",
        "add_caja": "Añadir caja",
        "choose_caja_title": "Elegir caja al cobrar",
        "choose_caja_desc": "A la hora de realizar un cobro, puedes seleccionar la caja o que automáticamente vayan todos los cobros a la caja principal. Los cobros se pueden mover de caja en la sección de contabilidad/facturación.",
        "choose_main": "Cobrar automáticamente en caja principal",
        "premium_caja": "Tener más cajas de las que hay",
        "cic_title": "Información del negocio",
        "cic_desc": "Introduce la información pública de tu negocio. Es muy importante que rellenes esta información ya que aparecerá en la web que te proporcionamos, en los emails que envías, en tu perfil, en los tickets que imprimes, etc.",
        "basic_info": "Información básica",
        "web_and_social": "Web y redes sociales",
        "web_and_social_desc": "Introduce la página web y la página de Facebook de tu negocio. Esta información aparecerá en los emails que le lleguen a tus Clientes y así entrarán más frecuentemente a tus páginas.",
        "web": "Web",
        "domain_com": "www.tudominio.com",
        "facebook_your_page": "www.facebook.com/tupagina",
        "write_your_address": "Introduce tu dirección",
        "multiple_users": "Múltiples usuarios",
        "block_btn_desc": "Habilita esta opción si quieres que aparezca en el menú superior un botón para bloquear Bewe y obligar a entrar con algún usuario. Deberás activar esta opción en cada computadora donde quieras utilizar el botón de bloqueo.",
        "inactivity_check": "Sí, quiero bloquear Bewe en esta computadora cuando esté inactivo.",
        "custom_desc": "Esta sección te permite personalizar aspectos generales del programa.",
        "premium_reminder": "Recuerda que las personalizaciones sólo están disponibles en la versión premium.",
        "dataview_list_title": "Personaliza la ficha del cliente",
        "go_premium_btn": "Pasar a PREMIUM",
        "change_card": "Cambiar tarjeta",
        "quota_sem": "Semestral ( cada 6 meses )",
        "link_your_card_btn": "Vincular tarjeta",
        "total_1_cuota": "1º cuota",
        "prem_reason_title_2": "Sin anuncios",
        "prem_reason_title_3": "Usuarios/Empleados con permisos",
        "prem_reason_title_5": "Recordar la cita por e-mail",
        "prem_reason_title_8": "Encuestas de satisfacción",
        "prem_reason_2": "Al ser premium se eliminan todo tipo de anuncios. De esta manera tendrás el programa de Bewe mucho más claro y sin distracciones.",
        "prem_reason_4": "Olvídate de la impresora de tickets. Podrás enviar el ticket/factura electrónica por e-mail a tus Clientes con un solo 'click' al realizar el cobro.",
        "prem_reason_6": "Podrás enviar e-mails a tus Clientes con ofertas, promociones, noticias, etc.",
        "prem_conditions": "Condiciones PREMIUM",
        "prembills_title": "Aquí puedes ver y descargar las facturas de tu cuenta. Recuerda que puedes comunicarte con nosotros a través del email o por chat para cualquier duda sobre la facturación. Si por algún motivo necesitas cambiar tus datos de facturación también debes comunicarte con nosotros.",
        "download_bill": "Descargar factura",
        "buy_saldo": "Comprar saldo",
        "saldo_sms_txt_positive": "Tienes <%=sms%> SMS de saldo.",
        "saldo_sms_txt_negative": "Hay <%=sms%> SMS fuera de saldo.",
        "remit_sms": "Remitente del SMS",
        "remit_sms_desc": "Puedes cambiar el remitente que el cliente verá cuando reciba el SMS. En lugar de que vea un número, puede ver una palabra que se identifique más con tu centro (máximo 11 caracteres).",
        "remit": "Remitente",
        "remind_sms_desc": "A la hora de enviar recordatorios en las citas de los Clientes, puedes elegir si enviarlas por SMS o por e-mail. Selecciona aquí cuándo se enviarían SMS.",
        "remind_dont_send": "No enviar nunca SMS para recordatorios",
        "remind_dont_send_confirmations": "No enviar nunca SMS para confirmaciones",
        "font_size_desc": "Elige el tamaño de letra del ticket. Dependiendo de la impresora es posible que prefieras un tamaño u otro.",
        "font_size_7": "Letra grande",
        "ticket_width_desc": "Hay impresoras de tickets con diferentes tamaños de ancho. Escoge el que más se adapte a tu impresora.",
        "ticket_margin": "Margen izquierdo del ticket",
        "ct_info_show": "Información mostrada",
        "ct_phrase": "Frase final de ticket",
        "ct_phrase_desc": "Al final del ticket se muestra una frase que puedes personalizar. Lo habitual es poner 'Gracias por su visita' pero también lo puedes aprovechar para informar al cliente de tus ofertas o novedades.",
        "install_qz": "Instalar impresora",
        "uninstall_qz": "Desinstalar impresora",
        "cth_desc": "Aquí podrá configurar los horarios de apertura y los días festivos del centro. Es muy útil agregar estos datos para que en su perfil web los Clientes puedan ver cuándo está abierto el centro.",
        "pg_desc": "Elige los colores que quieras para personalizar el programa al máximo y que se adapte a tu imagen corporativa.",
        "pg_c": "Color de los botones",
        "card_err": "Hay un problema con el pago. Comprueba que los datos de tu tarjeta sean válidos y que tienes saldo suficiente.",
        "go_premium2": "Pásate a Premium",
        "fullfill_card": "Datos de la tarjeta",
        "pay": "Pagar",
        "attributes": "Características",
        "cvv_help": "Los números de CVV son un requerimiento de seguridad que encontrarás en la parte trasera de la tarjeta y está formado por los 3 últimos dígitos",
        "go_msg": "Pasarse a",
        "name_premium": "Premium",
        "name_basic": "Basic",
        "name_business": "Business",
        "name_business-mobile": "Mobile-Business",
        "name_free": "Gratis",
        "name_free-agenda": "Agenda gratis",
        "go_upgrade": "Mejora tu plan",
        "upgrading_tip": "Al mejorar el plan de tu cuenta se descontará el coste de los días no usados de tu plan actual",
        "serial_number": "Nº de serie",
        "q_sure_remove_cash": "¿Seguro que quieres eliminar esta caja?",
        "q_sure_remove_cash_desc": "Recuerda que si eliminas esta caja perderás la contabilidad de cobros que hayas hecho en ella.",
        "remove_cash": "Eliminar caja",
        "intro_to_enter": "Pulsa INTRO para entrar",
        "select_your_user": "Selecciona tu usuario",
        "account_type": "Plan",
        "unlimited": "Ilimitado",
        "tracking_list_desc": "Añade diferentes seguimientos y personaliza los campos que quieres",
        "sms_cost": "Los SMS tienen un coste adicional",
        "ct_have_big": "Sí, tengo una impresora de DIN A4",
        "consents": "Consentimientos",
        "add_new_consent": "Nuevo consentimiento",
        "consents-desc": "Crea las plantillas de tus consentimientos, añadelos en la ficha de tus clientes y obtén su firma digital rápidamente. Cada firma queda registrada con fecha y dirección IP.",
        "delete_consent": "Eliminar consentimiento",
        "invalid_request_error": "Faltan datos de la tarjeta de crédito por completar.",
        "incorrect_number": "El número de tarjeta de crédito no es válido.",
        "invalid_number": "El número de tarjeta de crédito no es correcto.",
        "invalid_expiry_month": "El mes de la fecha de caducidad de la tarjeta no es válido.",
        "invalid_cvc": "El código de seguridad de la tarjeta no es válido.",
        "incorrect_cvc": "El código de seguridad de la tarjeta no es correcto.",
        "card_declined": "La tarjeta de crédito ha sido rechazada. Si el error persiste, ponte en contacto con asistencia técnica.",
        "processing": "Procesando...",
        "subscription_message": "Quiero suscribirme al programa",
        "upgrade_message": "Quiero mejorar mi suscripción, tengo el plan ",
        "name_trial-silver": "Trial Silver",
        "name_silver": "Silver",
        "name_gold": "Gold",
        "name_diamond": "Diamond",
        "Código promocional no válido.": "Código promocional no válido.",
        "Código promocional no válido para el plan seleccionado.": "Código promocional no válido para el plan seleccionado.",
        "Código promocional no válido para la cuota seleccionada.": "Código promocional no válido para la cuota seleccionada.",
        "contact_to_change_country": "Contacta por chat para cambiar de país",
        "reload_info": "El programa se recargará en unos segundos para hacer efectivos los cambios...",
        "quota_trimestral": "Trimestral ( cada 3 meses )",
        "Verifica que todos los datos de tu tarjeta de crédito son correctos": "Verifica que todos los datos de tu tarjeta de crédito son correctos.",
        "Debes seleccionar un método de pago.": "Debes seleccionar un método de pago.",
        "La tarjeta de crédito no pertenece a la franquicia seleccionada.": "La tarjeta de crédito no pertenece a la franquicia seleccionada.",
        "natural_type": "Freelance",
        "invalid_expiry": "La fecha de caducidad de la tarjeta no es válida.",
        "change_quota": "Cambiar cuota",
        "show_bills_of": "Mostrar facturas de",
        "dataview_typenumber_input": "Tipo: Numérico",
        "dataview_typedate_input": "Tipo: Fecha",
        "sms_reminder_title": "Texto de recordatorio de SMS",
        "sms_confirmation_title": "Texto de confirmación de cita por SMS",
        "sms_confirmation_desc": "Aquí puedes personalizar el SMS de confirmación de cita que llega al cliente.",
        "name_freelance_negocio": "Autónomo Básico",
        "name_freelance_negocio_mkt": "Autónomo Avanzado",
        "name_team_negocio": "Empresarial Básico",
        "name_team_negocio_mkt": "Empresarial Avanzado",
        "i_want_it": "¡Lo quiero!",
        "tracking_list_title": "Crea seguimientos para los Clientes",
        "invalid_expiry_year": "El año de la fecha de caducidad de la tarjeta no es válido.",
        "abort": "Ha ocurrido un error de conexión y no se ha procesado el pago. Inténtalo más tarde por favor.",
        "quota_semestral": "Semestral ( cada 6 meses )",
        "Verifica que la fecha de expiración es válida.": "Verifica que la fecha de expiración es válida.",
        "legal_type": "Empresa",
        "dataview_typetext_input": "Tipo: Texto",
        "numeric": "Numérico",
        "sms_reminder_desc": "Aquí puedes personalizar el SMS de recordatorio que le llega al cliente.",
        "name_freelance_negocio_mkt_app": "Autónomo Apps",
        "name_team_negocio_mkt_app": "Empresarial Apps",
        "special_feature_price": "<em>Precio</em>Aquí tienes el precio al mes de cada plan. Puedes conseguir importantes descuentos si pagas la cuota anualmente, también si recomiendas Bewe a otro negocio o si compras productos en nuestra tienda.",
        "feature_employees": "<em>Empleados</em>¿Cuántos trabajadores tienes? Nuestros planes se adaptan al tamaño de tu negocio en base a los empleados que necesitas gestionar. Simple y sencillo.",
        "feature_agendas": "<em>Agendas</em>La sección de agendas te permite controlar todas las citas que pasan por tu negocio. Puedes enviar recordatorios a tus Clientes (por email o SMS) e incluso tus Clientes podrán reservar cita online sin complicaciones.",
        "feature_management": "<em>Cobros</em>Gestiona la caja diaria, la facturación, las comisiones de los empleados de una forma sencilla.",
        "feature_stock": "<em>Control de stock</em>Puedes tener el control de stock de los productos que consumes a nivel interno y también los que tengas de venta al público.",
        "feature_marketing": "<em>Marketing</em>Es fundamental el poder realizar envíos de email o SMS a un grupo de Clientes con un descuento. Con esta herramienta podrás hacerlo de forma sencilla, sin perder tiempo y generando un gran impacto y atención en tus Clientes.",
        "feature_offline_access": "<em>Sin conexión</em>¿Temes el día que quedes sin internet y no puedas gestionar tu negocio? Sabemos lo que se siente, por eso hemos desarrollado una tecnología que te permitirá estar en la web de bewe y poder trabajar aunque te quedes días sin internet. Qué alivio, ¿no?",
        "feature_statistics": "<em>Estadísticas e informes</em>En esta sección podrás analizar hasta el más mínimo detalle de tu negocio. Te damos una herramienta para que analices tu negocio al mismo nivel que las grandes empresas y cadenas, pero de una forma mucho más visual y sencilla. ",
        "feature_center_app": "<em>Tu propia app</em>Imagina que en el móvil de tus Clientes, al lado de su app de Instagram y de Whatsapp, hubiera una app con tu propio logo. Tener algo así hace que tus Clientes te recuerden, estén siempre al día de tus ofertas y puedan reservar hora en segundos.",
        "feature_support_chat": "<em>Chatea con nosotros</em>No te preocupes. Si tienes cualquier duda o problema podrás comunicarte con nosotros de forma inmediata. Abre el chat, háblanos y te ayudaremos enseguida.",
        "i_have_it": "Lo tengo :)",
        "fiscal_address": "Dirección fiscal",
        "business_idfiscal": "Identificador Fiscal (CIF)",
        "business_name": "Nombre empresa",
        "business_res_name": "Nombre del responsable",
        "business_res_idfiscal": "Identificador del responsable",
        "ct_footer": "Texto legal",
        "Contratar plan": "Contratar plan",
        "o escribe los datos de la tarjeta": "o escribe los datos de la tarjeta",
        "Escribe los datos de la tarjeta": "Escribe los datos de la tarjeta",
        "gateway_alternative": "Si estás seguro que la tarjeta es correcta, intenta pagar con <em>nuestra pasarela de pago alternativa haciendo click aquí</em>",
        "empty_bill_info": "Introduce todos los datos de facturación para poder continuar",
        "loading_gateway": "Cargando pasarela de pago...",
        "Nueva tarjeta": "Nueva tarjeta",
        "ct_sheet_type": "Tipo de impresora",
        "ct_sheet_type_desc": "Selecciona esta opción si tienes una impresora convencional DIN A4 en vez de una impresora de tickets.",
        "ct_footer_desc": "Añade un párrafo donde puedes detallar condiciones legales de compra, de devolución, etc.",
        "Añadir nuevo proveedor": "Añadir nuevo proveedor",
        "emailToNotifyProductOrder": "Email",
        "provider_can_order": "Se pueden generar órdenes de compra con el proveedor",
        "provider_can_not_order": "No se pueden generar órdenes de compra con el proveedor",
        "delete_provider": "Eliminar proveedor",
        "fields_client_title": "Campos de la ficha del cliente",
        "fields_client_desc": "Personaliza los campos de la ficha del cliente. Puedes habilitar y deshabilitar ciertos datos para que las fichas del cliente contengan justamente los datos que necesitas.",
        "ct_show_client": "Mostrar cliente",
        "importclients": "Importación de datos",
        "feature_clients": "<em>Clientes</em>En este apartado podrás gestionar toda la información de tus clientes, anotaciones, bonos que tienen activos, saber cuánto hace que no vienen y mucho más.",
        "pg_sf": "Color de letra del menú lateral",
        "pg_sb": "Color de fondo del menú lateral",
        "Selecciona el tipo de documento que deseas crear:": "Selecciona el tipo de documento que deseas crear:",
        "new_subscription": "Tu nueva suscripción",
        "subsc_discount": "Descuento comercial",
        "update_now": "Actualizar ahora",
        "update_desc": "Para poder tener una nueva agenda necesitas actualizar tu suscripción",
        "update_price_title": "Precio de tu suscripción a partir del ",
        "update_price_subtotal": "subtotal",
        "update_price_tax": "impuestos",
        "update_price_total": "Total",
        "update_desc2_01": "Se realizará un cobro ahora en tu tarjeta vinculada de",
        "update_desc2_02": "para actualizar inmediatamente tu suscripción y a partir del",
        "update_desc2_03": "ya pagarás el precio actualizado",
        "pricing_error": "no puedes hacer la suscripción en el momento",
        "error_upgrading": "Error en la configuración de la cuenta",
        "update_now_cta": "Actualizar suscripción",
        "update_diference": "la diferencia",
        "add_credit_card": "Agregar tarjeta",
        "delete_credit_card": "Eliminar tarjeta",
        "credit_card_of": "Tarjeta vinculada de",
        "credit_card_time_left": "Con tarjeta vinculada desde hace",
        "new_communication": "Nueva comunicación",
        "who_to_send": "¿A quién se envía?",
        "where_to_send": "¿Por dónde?",
        "immediately": "De inmediato",
        "when_to_send": "¿Cuándo?",
        "msg_content": "Contenido del mensaje",
        "send_communication": "Enviar comunicación",
        "msg_content_desc": "Aquí se escribe el contenido del SMS, notificación PUSH o WhatsApp",
        "msg_email_content": "Contenido del email",
        "email_simple_msg": "Mensaje sencillo",
        "email_bd_template": "Plantilla de cumple",
        "email_congrat_template": "Plantilla de felicitación",
        "email_covid19_comeback": "Retorno COVID-19",
        "email_msg_title": "Título del email",
        "email_msg_title_ph": "Escribe un titulo...",
        "email_msg_template_ph": "Elige una plantilla",
        "providers_desc": "Aquí puedes configurar tus proveedores. Para poder crear órdenes de compra en la sección de productos debes tener configurados previamente los proveedores necesarios.",
        "month": "mes",
        "CONGRATS_TEMPLATE": "Plantilla de felicitación",
        "COVID19_TEMPLATE": "Retorno COVID-19",
        "BIRTHDAY_TEMPLATE": "Plantilla de cumple",
        "SIMPLE_MESSAGE": "Mensaje sencillo",
        "No se ha subido ningún archivo": "No se ha subido ningún archivo",
        "payments_balance": "Balance de pagos",
        "All_the_payments_up_to_date": "Tiene todos los pagos al día",
        "Has_a_credit_balance": "Tiene saldo a favor",
        "Unpaid_Subscription": "Suscripción con impago",
        "Unpaid_Subscriptions": "Suscripciones con impago",
        "required_or_novalid": "requerido o no válido",
        "sendingNotifications_title": "Envío de notificaciones",
        "sendingNotifications_description": "Aquí podrás elegir el medio por el que se envían las notificaciones a tus clientes.",
        "sendingNotifications1": "Enviar sólo por email.",
        "sendingNotifications2": "Enviar por notificación PUSH en la APP y por email.",
        "sendingNotifications3": "Enviar por Whatsapp y por email.",
        "password_required": "Contraseña requirida",
        "social_media": "Redes Sociales",
        "NO_SOCIAL_MEDIA_URL": "No tiene configurado un sitio para Socialmedia",
        "packages": "bonos",
        "puller_day": "Numero del dia",
        "puller_weekdayName": "Nombre del dia",
        "puller_dayName": "Nombre y numero del dia",
        "puller_month": "Numero del mes",
        "puller_monthName": "Nombre del mes",
        "transfer_the_cash_amount_to_the_following_day": "Traspasar el importe de caja al día siguiente",
        "desc_cash_clouse": "A la hora de realizar el cierre de caja, el sistema indicará el importe que se dejó en caja el último día que se realizó un cierre.",
        "desc_checkbox_cash_clouse": "Sí, quiero que se muestre el importe que se dejó en caja en el último cierre.",
        "select_a_headquarters": "Seleccione una sede",
        "cs_desc_whatsapp": "<p>Puedes comunicarte con tus Clientes también por Whatsapp. El Whatsapp tiene una ventaja muy clara sobre el email: <b>la mayoría de los Clientes son más receptivos a leer un Whatsapp que un email</b>.</p> <p>Aprovéchate de esta ventaja y <b>envía los recordatorios de cita y las ofertas más importantes por Whatsapp</b>. Aunque los Whatsapp tengan un coste, <b>notarás la diferencia</b> a la hora de comunicarte con tus Clientes.</p>",
        "saldo_whatsapp_txt_positive": "Tienes <%=whatsapp%> Whatsapp de saldo.",
        "saldo_whatsapp_txt_negative": "Hay <%=whatsapp%> Whatsapp fuera de saldo.",
        "buy_saldo_desc_whatsapp": "Compra uno de nuestros packs de Whatsapp en un clic. Cuanto más Whatsapps compres, más barato te saldrá.",
        "subs_whatsapp": "Whatsapp fuera de saldo",
        "subs_whatsapp_desc": "¿Estás siempre pendiente del saldo? Podés activar esta opción para que se envíen Whatsapp aunque tengas el saldo en cero. Estos Whatsapp se cobrarán junto a la mensualidad premium a un precio de 12 céntimos por Whatsapp.",
        "subs_whatsapp_check": "Sí, quiero poder enviar Whatsapp aunque no tenga saldo.",
        "remit_whatsapp": "Remitente del Whatsapp",
        "remit_whatsapp_desc": "Puedes cambiar el remitente que el cliente verá cuando reciba el Whatsapp. En lugar de que vea un número, puede ver una palabra que se identifique más con tu centro (máximo 11 caracteres).",
        "remind_whatsapp_desc_confirmations": "A la hora de enviar confirmaciones en las citas de los Clientes, puedes elegir si quieres enviarlos por Whatsapp o por email. Selecciona aquí cuándo se enviarían los Whatsapp.",
        "remind_whatsapp_desc": "A la hora de enviar recordatorios en las citas de los Clientes, podés elegir si quieres enviarlos por Whatsapp o por email. Selecciona aquí cuándo se enviarían los Whatsapp.",
        "remind_dont_send_Whatsapp": "No enviar nunca Whatsapp para recordatorios",
        "remind_dont_send_confirmations_Whatsapp": "No enviar nunca Whatsapp para confirmaciones",
        "remind_whatsapp": "Whatsapp en los recordatorios de citas",
        "remind_whatsapp_confirmations": "Whatsapp en las confirmaciones de citas",
        "whatsapp_as_possible": "Enviar Whatsapp siempre que sea posible",
        "only_if_not_email_confirmations_whatsapp": "Solo envíe confirmaciones por Whatsapp si su cliente no tiene un correo electrónico registrado.",
        "only_if_not_email_whatsapp": "Enviar Whatsapp sólo si el cliente no tiene un email en su ficha",
        "sendingNotifications_description_whatsapp": "Aquí podrás elegir las notificaciones que le enviarás a tus clientes.",
        "whatsapp_notifications::buy_subscription": "Enviar mensaje cuando compra una suscripción.",
        "whatsapp_notifications::checkout_summary": "Enviar mensaje del total de su compra.",
        "whatsapp_notifications::credit_card_will_expire_soon": "Enviar mensaje cuando la tarjeta está a punto de caducar.",
        "whatsapp_notifications::event_class_join": "Enviar mensaje cuando se adquiere un evento.",
        "whatsapp_notifications::notif_user_work_cancelled": "Enviar mensaje cuando la cita es cancelada.",
        "whatsapp_notifications::rem_hoursago": "Enviar mensaje para recordarle la cita.",
        "whatsapp_notifications::res_confirmation": "Enviar mensaje cuando la reserva a sido confirmada.",
        "whatsapp_notifications::subscription_will_expire_soon": "Enviar mensaje cuando la suscripción caducará pronto.",
        "whatsapp_notifications::welcome": "Enviar mensaje de bienvenida.",
        "google_calendar_error": "No se ha podido conectar con Google Calendar. Por favor, inténtalo de nuevo.",
        "google_calendar_error_scopes": "Es necesario que des acceso a todos los permisos solicitados, Inicia sesión nuevamente y aprueba todos los permisos",
        "google_calendar_general_description": "Conecta tu calendario de Google para que tus citas se sincronicen automáticamente con tu agenda.",
        "google_calendar_sync_from_google_description": "<b>Al tener activada esta opción, las citas que se creen, modifiquen y eliminen en Google Calendar se sincronizarán automáticamente con tu agenda, contemplando los siguientes casos:</b><li>La sincronización solo está activa en los calendarios creados en el sistema para cada empleado, si el calendario se elimina de Google Calendar se creará otro para sincronizar los nuevos cambios.</li><li> Si eliminas en Google Calendar un evento que ya sucedió o ya se encuentra cobrado, no se eliminará en el software.</li><li> Los eventos creados en Google Calendar deben tener hora de inicio y hora fin en el trascurso del mismo día para que se genere en tu software.</li><li> Si se elimina un calendario de Google Calendar, esta eliminación no tendrá repercusión en el software.</li><li> Las citas creadas en el sistema deben tener un cliente y servicio asociado, de lo contrario no se creará el evento en Google Calendar.</li>",
        "new_domain_com": "https://tudominio.com",
        "new_facebook_your_page": "https://facebook.com/tupagina",
        "title_wrapper_whatsapp": "¡Aprovecha los beneficios de comunicarte vía WhatsApp con tus clientes!",
        "description_wrapper_whatsapp": "Podrás enviar los recordatorios de citas, ofertas y hacer uso de las plantillas de mensajes más funcionales. Haz efectiva la comunicación con tus clientes activando esta funcionalidad.",
        "title_whatsapp_buy_package": "COMPRAR SALDO",
        "description_whatsapp_buy_package": "Compra uno de nuestros paquetes de WhatsApp a un solo clic.",
        "title_whatsapp_section_collapsible": "ENVÍO DE NOTIFICACIONES",
        "title_whatsapp_avalible": "WhatsApp de saldo",
        "title_info_pay_whatsapp": "Información de compra",
        "title_slider_whatsapp": "Mensajes de WhatsApp",
        "footer_description_buy_whatsapp": "El pago se cargará en la tarjeta que tengas vinculada en la cuenta de Bewe.",
        "title_whatsapp_collapsible": "Aquí podrás <span>habilitar las notificaciones</span> que quieras enviarles a tus clientes. También, podrás hacer clic en vista previa y previsualizar las plantillas de mensajes disponibles para cada una.</p>",
        "preview_collapsible_whatsapp": "Vista Previa",
        "title_wrapper_sms": "Aprovecha los beneficios de la <span>mensajería SMS</span> para comunicarte con tus clientes de manera directa. Podrás enviar los <span>recordatorios de citas y las ofertas más atractivas mediante SMS.</span>",
        "description_sms_buy_package": "¡Adquiere uno de nuestros paquetes de SMS a un solo clic!",
        "title_collapsible_sms": "SMS EN LOS RECORDATORIOS Y CONFIRMACIONES DE CITAS",
        "description_collapsible_sms": "A la hora de enviar recordatorios en las citas de los Clientes, puedes elegir si enviarlas por SMS o por e-mail. Selecciona aquí cuándo se enviarían SMS.",
        "title_sms_avalible": "Mensajes de saldo",
        "title_remitent_sms": "REMITENTE DEL SMS",
        "description_remitent_sms": "Puedes cambiar el remitente que el cliente verá cuando reciba el SMS. En lugar de que vea un número, puede ver una palabra que se identifique más con tu centro. <span>(Máximo 11 caracteres)</span>",
        "title_reminder_sms": "TEXTO DE RECORDATORIO DE SMS",
        "description_reminder_sms": "Aquí puedes personalizar el SMS de recordatorio que le llega al cliente. (Máximo 120 caracteres)",
        "button_sms_personalize": "Personalizar",
        "title_confirmation_sms": "TEXTO DE CONFIRMACIÓN DE CITA POR SMS",
        "description_confirmation_sms": "Aquí puedes personalizar el SMS de confirmación de cita que llega al cliente. <span>(Máximo 120 caracteres)</span>",
        "t::published::off": "Como tienes la web desactivada, la URL a la web redireccionará al Login, para mayor comodidad para ti y tus empleados.",
        "t::published::yes": "Si, quiero activar la web.",
        "confirmations": "Confirmaciones",
        "new_legal_conditions_com": "https://tudominio.com/terminos_condiciones",
        "legal_conditions": "Términos y condiciones pasarela de pago",
        "guru_leads": "Tus contactos",
        "google_calendar_alert_sync_employee": "Atención, la sincronización se ha habilitado para todas las agendas, si deseas deshabilitar esta sincronización para alguna de ellas podrás hacerlo a través de la ficha de cada empleado.",
        "text": "Texto",
        "title_config_leads": "Personaliza tu formulario de Registro",
        "whatsapp_notifications::res_added_to_waitlist": "Enviar mensaje cuando está en la lista de espera.",
        "whatsapp_notifications::res_move_waitlist_to_class": "Enviar mensaje cuando ya no está en la lista de espera.",
        "whatsapp_notifications::res_added_to_class": "Enviar mensaje cuando adquiere una clase.",
        "current_balance_of": "Saldo actual de",
        "add_custom_variables": "Añadir variables personalizadas",
        "start_unsubscribe_process": "Iniciar proceso de baja",
        "country_vary": "Para cambiar de pais debes escribir al chat",
        "country": "País",
        "language": "Idioma",
        "phone": "Teléfono",
        "email": "Correo electrónico",
        "minimum_date_warn": "No es posible acceder a la fecha seleccionada, indique una fecha más reciente o contacte con soporte para información anterior",
        "side_widgets_title": "Widget flotante (BETA)",
        "side_widgets_information": "Aquí puedes personalizar el tamaño, la tipografía y los colores de tus widgets flotantes para crear dinamismo en las reservas, clases, contactos y WhatsApp en tu web.",
        "side_widgets_text": "Texto",
        "side_widgets_size": "Tamaño",
        "side_widgets_icon": "Icono",
        "side_widgets_font": "Fuente",
        "side_widgets_background": "Fondo",
        "side_widgets_filled": "Relleno",
        "payment_sms": "El pago se debitará de la tarjeta vinculada en tu cuenta.",
        "unpaid_sms": "No fue posible realizar el pago. En este caso escríbenos directamente al chat de soporte para comprar tu paquete de SMS.",
        "stripe_card_content": "Recibe pagos electrónicos instantáneos que verás reflejados en tu cuenta y configura pagos recurrentes, esto puede ser útil para las suscripciones y los pagos periódicos.",
        "payment_method": "Medios de pago",
        "mercadopago_card_content": "Cobra con el medio que mejor se adapte a tu negocio y obtén tu dinero al instante de forma sencilla y 100% online.",
        "payments_methods_description": "La solución definitiva para cobros eficientes. Conecta tu pasarela de pago y ofrece a tus clientes la comodidad y seguridad que merecen",
        "mercadopago_payment_description1": "¡Importante! Recuerda configurar los precios de tus ítems con un mínimo de 1 dólar (de acuerdo a tu moneda) para asegurar el correcto procesamiento del pago. Si lo que deseas es desvincular tu pasarela de pago, ",
        "mercadopago_payment_description2": "Si deseas saber cómo cobrar cuando se tiene una tarjeta vinculada o cómo puedes vincularlas en la ficha de tus clientes:",
        "payment_methods_connect": "Conectar",
        "payment_methods_linked": "Vinculada",
        "notification_sucess": "Colores cambiados con éxito",
        "remove_gateway_question": "Estás a punto de desconectar tu pasarela",
        "sure_to_do_it": "¿Estás seguro de hacerlo?",
        "disconnect_stripe": "Desconectar stripe",
        "Rows exceeded, maximum number per import is 2500": "Filas superadas, el número máximo por importación es de 2500",
        "Invalid type file": "Tipo de archivo invalido",
        "import_success": "Se importaron satisfactoriamente",
        "import_failed": "Fallaron en la importación",
        "download_errors_file_text": "Durante la importación se detectaron algunos errores, puedes descargar el archivo CSV con el listado, corrígelos e inténtalo de nuevo.",
        "relogin_for_complete_import": "Para completar la importación, por favor cierra sesión e inicia nuevamente.",
        "inactive_addon": "Tipo de envío inactivo",
        "available_for_campaigns": "disponibles para campañas",
        "availability_exceeded": "Se excede la cantidad disponible para enviar",
        "addon_not_available": "Tipo de envío no disponible",
        "premiums_billguard_bill": "Factura",
        "contact_service_provider": "Contacta a tu proveedor de servicio",
        "starts_now": "¡Empieza ahora!",
        "slide_2": "Ahora podrás realizar tus cobros de forma más simple y rápida",
        "slide_1": "¡Nuevas actualizaciones han llegado al software!",
        "slide_1_2": "Te presentamos la versión 2.21 ¿Preparado para el cambio?",
        "explore_more": "Explora más",
        "slide_3": "Disfruta un nuevo panel de productos más completo y haz tus configuraciones en minutos",
        "find_out": "Descúbrelo",
        "slide_4": "Configura, vende y aplica tarjetas de regalo a tus clientes en pocos clics",
        "know_it": "Conócelo",
        "google_analytics_general_description": "Conecta Google Analytics para visualizar en el Dashboard de Captación información de valor para convertirla en estrategias efectivas para hacer crecer tu negocio.",
        "save_change_google_analytics": "¿Estás seguro que deseas cambiar o modificar tu llave pública?",
        "save_analytics_credentials": "¿Estás seguro que deseas guardar tu llave pública?",
        "public_key": "Llave pública",
        "email_client": "Email del cliente",
        "get_user_key_analytics": "¿Cómo obtener mi User Client y Key para Analytics?",
        "know_it_here": "Conócelo aquí",
        "private_key_is_not_valid": "Formato de la llave no es válido.",
        "required_key": "Se requiere ingresar una llave",
        "id_google_analytics": "Analytics google id",
        "report_tooltip": "Tu informe se enviará al correo electrónico del usuario con el que estés logueado. Alternativamente, puedes ingresar el correo electrónico al cual deseas que llegue la información.",
        "report_description": "Aquí podrás extraer los informes de tu agencia, filtrando la fecha del periodo correspondiente que desees obtener.",
        "agency_reports": "Informes de agencias",
        "select_reports": "Seleccione una opción de reporte",
        "report_notification": "¡Tu reporte llegará pronto!",
        "sessions": "Sesiones",
        "uniqueSessions": "Sesiones únicas",
        "centersWithActivity": "Centros con actividad",
        "statusAccounts": "Estado de cuentas",
        "servicesAccounts": "Servicios de cuentas",
        "bookingsAccounts": "Reservas de cuentas",
        "clientsWithBookings": "Clientes con reserva",
        "createFullReport": "Informe completo",
        "generalActivity": "Actividad general",
        "button_selection_widget_flotante": "Selecciona los botones que quieres mostrar a tus clientes dentro del Widget Flotante",
        "invalid_coll_name_format": "Formato de columnas inválido, verifica el formato requerido en el archivo: <a class='a-link' id='a-link_clients' href='https://s3.amazonaws.com/bellahora_my_files/format_import_clients.csv'>ejemplo de Clientes</a>",
        "analyticsTrials": "Trials social media activos",
        "analyticsTrialsLost": "Trials social media perdidos",
        "error_utf8_encoding": "El archivo debe tener codificación UTF-8",
        "enter_your": "Ingresa tu ",
        "detailed_address": "Dirección detallada",
        "no_clients_selected": "No se seleccionaron clientes",
        "analyticsActive": "Social media activos"
};
});