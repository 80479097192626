import * as Blockly from 'blockly/core';
import { dexie, collections } from './utils';

const color = 20;

// Deprecated
Blockly.Blocks.fetch = {
  init() {
    this.appendDummyInput()
      .appendField('fetch')
      .appendField(
        new Blockly.FieldDropdown(dexie.sort().map((item) => [item, item])),
        'option',
      );
    this.appendStatementInput('where').setCheck('logic').appendField('where');
    this.setPreviousStatement(true, 'Fetch');
    this.setNextStatement(true, 'Array');
    this.setColour(color);
  },
};

Blockly.Blocks.find = {
    init() {
      this.appendDummyInput()
        .appendField('find')
        .appendField(
          new Blockly.FieldDropdown(collections.sort().map((item) => [item, item])),
          'collection',
        );
      this.appendStatementInput('where')
      .setCheck('find-logic')
      .appendField('where');
      this.setPreviousStatement(true, 'Find');
      this.setNextStatement(true, 'Array');
      this.setColour(color);
    },
  };

Blockly.Blocks.get = {
  init() {
    this.appendDummyInput()
      .appendField('get')
      .appendField(new Blockly.FieldTextInput(''), 'get_value');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(color);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks.queryNone = {
  init() {
    this.appendDummyInput().appendField('none');
    this.setInputsInline(false);
    this.setPreviousStatement(true, null);
    this.setColour(color);
  },
};

Blockly.Blocks.debug = {
    init() {
        this.appendDummyInput()
        .appendField("debug")
        this.setPreviousStatement(true, ['Array', 'Fetch']);
        this.setNextStatement(true, ['Array']);
    }
}