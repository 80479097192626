define([], function() {
    return {
        "public_info": "Información pública",
        "web_sections": "Secciones web",
        "customization": "Personalización",
        "publish": "Publicar web",
        "results": "Resultados",
        "config": "Configuración",
        "online_web": "Presencia online",
        "business_stars": "Valoración pública del negocio",
        "online_systems": "Tecnología para tu negocio",
        "bh_profile": "Perfil en Bewe",
        "webpage": "Página web",
        "mobileapp": "App Android y iOS",
        "booking_system": "Reserva de citas",
        "configuration": "Configuración",
        "extra_resources": "Recursos extra",
        "widgets": "Widgets",
        "pictures": "Imágenes",
        "wt_desc": "Configura los servicios/tratamientos que <b>quieres mostrar online</b>. Aquí también puedes gestionar cuáles de éstos van a ser los que los clientes van a poder reservar online.",
        "wt_enable_desc": "Habilita los servicios que quieres que aparezcan en la sección 'Servicios' de la web.",
        "enable_all": "Habilitar todos",
        "disable_all": "Deshabilitar todos",
        "the_business": "El centro",
        "config_info_desc": "Configura la sección 'El centro' de tu web.",
        "title_and_desc": "Título y descripción",
        "title_and_desc_desc": "Añade la información de encabezado que saldrá en la sección.",
        "wc_title": "Personaliza tu página web",
        "wc_desc": "<p>Personaliza tu web para que sea <b>única</b>. Esta sección hace posible que de forma muy fácil puedas <b>escoger el diseño base, cambiar colores, imágenes y otros detalles</b> para que tu web luzca genial.</p><p>Todo lo que puedes configurar está especialmente pensado para que puedas personalizar la web <b>sin miedo a perder la facilidad de uso y la accesibilidad de la web</b>.</p><p>Puedes acceder a tu web a través de la siguiente dirección:</p>",
        "wp_title": "Imágenes del negocio",
        "wp_desc": "<b>Sube el logo e imágenes del negocio</b>. El logo puede aparecer en los emails que envias, en la web que te vamos a proporcionar y en el perfil de tu negocio en tu cuenta.",
        "portal_title": "Tu negocio en el portal de reservas de Miora",
        "portal_desc": "Que aparezca tu perfil en el portal de reservas de Miora te permite tener una <b>gran visibilidad en internet</b> y <b>la oportunidad de conseguir nuevos clientes</b> a través de las reservas online.",
        "active_portal_title": "Activa tu perfil en Miora",
        "active_portal_desc": "Antes de activar tu perfil, comprueba que tienes imágenes de tu negocio en la sección de Imágenes y toda la información de tu negocio añadida en la sección de Información.",
        "active_portal_check": "Sí, quiero que mi negocio aparezca en el portal de reservas de Miora",
        "empty_chart": "No hay datos suficientes",
        "valorations": "Valoraciones",
        "comments": "Comentarios",
        "config_stars_title": "Valoración pública de tu negocio",
        "config_stars_desc": "<p>Es importante tener una buena valoración de tu negocio para que se posicionen tus servicios con más visibilidad en el portal. Para conseguir valoraciones, se envía un email al cliente que haya reservado en tu negocio a través del portal para que valore y opinen sobre él.</p><p>Una buena valoración puede servir para aportar confianza a los usuarios que quieran reservar en tu centro desde el portal.",
        "config_stars_check_title": "Habilitar valoraciones de mis clientes",
        "config_stars_check_desc": "Habilitando esta opción, tus propios clientes también podrán valorar tu negocio. La oportunidad de que también tus clientes valoren tu negocio es muy buena ya que nadie va a valorar tan bien tu negocio como tus clientes.",
        "config_stars_check_check": "Sí, quiero habilitar las valoraciones de mis clientes",
        "stars_val_title": "Valoraciones de los clientes",
        "valorations_made": "Valoraciones realizadas",
        "valorations_made_desc": "Muestra la cantidad de valoraciones que se han hecho en los últimos meses",
        "scores": "Puntuaciones",
        "scores_desc": "Muestra el número de personas que han respondido cada una de las posibles puntuaciones.",
        "avg_score": "Puntuación media",
        "avg_score_desc": "Muestra la puntuación media de los últimos meses para poder apreciar una posible evolución.",
        "booking_config_title": "Configura las reservas de citas",
        "booking_config_desc": "Con tu cuenta, tus clientes podrán reservar una cita desde tu página web y desde tu página de Facebook. Simplemente tenés que activarlo acá según te explicamos.",
        "config_booking_fb_title": "Reserva de citas en tu página de Facebook",
        "config_booking_fb_desc": "Verás una nueva pestaña en tu página de Facebook de 'Reserva cita' donde tus clientes podrán reservar una cita en tu negocio.",
        "config_booking_fb_btn": "Instalar la reserva de citas en Facebook",
        "booking_cancel_title": "Cancelación de citas por parte del cliente",
        "booking_cancel_desc": "Habilita/Deshabilita que los clientes puedan cancelar sus citas en el caso de que no puedan asistir. De esta manera, podrán notificar de forma más cómoda si no pueden asistir y el hueco podrá ser usado por otra reserva.",
        "booking_cancel_check": "Sí, quiero que los clientes puedan cancelar sus citas",
        "config_preconfirm_title": "Confirmación previa",
        "config_preconfirm_desc": "Esta opción significa que cuando entre una reserva en la agenda, podrás aceptarla o rechazarla según tu disponibilidad. Si utilizaras la agenda de Bewe como tu agenda principal, no necesitarías esta confirmación previa ya que la cita sólo iría a huecos vacíos. Ten en cuenta que tener confirmación previa hace que tengas muy poca visibilidad en el portal ya que se da prioridad a los negocios que no necesitan confirmación.",
        "config_preconfirm_check": "Sí, quiero confirmación previa",
        "btreat_title": "Reserva de citas: servicios",
        "btreat_desc": "Aquí podrás configurar los <b>servicios</b> que deseas que sean <b>reservados online por el cliente</b>. Tus clientes podrían reservar por las siguientes formas que prefieras:",
        "btreat_desc_via_1": "A través de <b>tu página web</b> (la que te proporciona tu cuenta u otra)",
        "btreat_desc_via_2": "A través de tu página de <b>Facebook</b>",
        "btreat_desc_via_3": "A través de <b>tu app móvil</b> (la que te proporcionamos)",
        "btreat_desc_via_4": "A través del portal de reservas de <b>tu cuenta</b>",
        "btreat_main_button": "Añadir servicio reservable",
        "btreat_title_list": "Lista de servicios reservables",
        "btreat_empty_message": "Aún no tienes servicios en la reserva de citas",
        "btreat_empty_message_a": "Ve al tutorial de cómo configurarlo y descubre sus ventajas",
        "btreat_wiz_1_title": "Paso 1: El servicio",
        "btreat_wiz_1_desc": "Selecciona el servicio que deseas que reserven los clientes.",
        "btreat_wiz_1_btn_sel": "Escoger servicio",
        "btreat_wiz_2_title": "Paso 2: Los empleados",
        "btreat_wiz_2_desc": "¿Qué especialidad de empleado se necesita para este servicio? Al seleccionar una especialidad, Bewe podrá asignar el servicio a cualquier empleado de esa especialidad que esté disponible en ese momento.",
        "add_role": "Añadir una nueva especialidad",
        "btreat_wiz_2_desc_new_role": "Which employee can perform this service?.",
        "btreat_wiz_2_desc_new_role_name": "Los empleados seleccionados comparten una habilidad para poder hacer este servicio. A esa habilidad la llamamos 'especialidad'. Una especialidad puede ser por ejemplo 'Peluquero','Estilista','Masajista' o algo aún más especializado como 'Masajista descontracturante'.",
        "write_role_name": "Nombre de la especialidad...",
        "btreat_wiz_2_choose_emp": "¿Quieres que el cliente pueda elegir el empleado al reservar? Eligirá entre los que estén disponibles de esa especialidad en ese momento. Si no quieres que puedan elegirlo, Bewe lo eligirá automáticamente.",
        "btreat_wiz_2_choose_emp_bool": "Sí, quiero que el cliente seleccione el empleado",
        "btreat_wiz_resources_title": "Paso 3: Los recursos",
        "btreat_wiz_resources_desc": "¿Qué recursos necesita el servicio? Por ejemplo, si hubiese un grupo 'Cabinas de masaje' que tiene dos recursos ( 2 cabinas ), Bewe escogería una de las cabinas y ya no podría ser utilizada por nadie más mientras dure el servicio. Siguiendo el ejemplo, selecciona los grupos de recursos necesarios:",
        "btreat_wiz_agenda_title": "Último paso: La agenda",
        "btreat_wiz_agenda_desc": "Elige la agenda a la que quieres que vaya este servicio.",
        "btreat_wiz_agenda_advance_btn": "Opción avanzada: Quiero que vaya a una agenda u otra según el empleado/recurso",
        "add_treatment_this_config": "Añadir servicio con esta configuración",
        "rem_treat": "Quitar servicio",
        "disc_portal": "de descuento en portal",
        "in_portal": "En portal Bewe",
        "in_web_app_fb": "En web y Facebook",
        "bres-desc": "<p>En algunos casos para que el cliente pueda reservar una cita de un servicio, se necesita <b>algo más que tener disponible el empleado</b> que lo va a realizar. Aquí podrás <b>crear recursos que pueden representar muchas cosas</b>: una cabina, una máquina, un lavacabezas o incluso un secador.</p><p>Luego, al configurar el servicio podrás decir que además de necesitar un determinado empleado, <b>se necesita libre una cabina o lavacabezas</b>.</p>",
        "resource": "Recurso",
        "add_new_resource": "Crear nuevo recurso",
        "new_class_resource": "Nuevo grupo",
        "add_class_resource": "Añadir grupo",
        "class_resource_input": "Nombre del grupo...",
        "class_resource_empty": "No hay grupos",
        "new_class_example_1": "Cabina",
        "new_class_example_2": "Lavacabezas",
        "new_class_example_3": "Camilla de masaje",
        "delete_resource": "Eliminar recurso",
        "add_img": "Añadir nueva imagen",
        "uploading_file": "Subiendo archivo.",
        "uploading_file_2": "Completado. Espere por favor...",
        "uploading_error": "Error. No se ha podido producir la subida del archivo correctamente.",
        "t::color-bg::title": "Selecciona el color de fondo",
        "t::color-bg::desc": "Escoge el color de fondo de la web. Normalmente si quieres que la web sea oscura, será un color que tenderá a negro. Mientras que si quieres que la web sea clara, tenderá a blanco.",
        "t::color-base::title": "Selecciona el color principal",
        "t::color-base::desc": "El color principal de tu diseño suele ser el color más destacado de tu logo o de la estética de tu negocio.",
        "t::color-header::title": "Selecciona el color de la cabecera",
        "t::color-header::desc": "La cabecera es la parte superior de la página que contiene el logo y el menú. Escoge un color que destaque o que encaje perfectamente con tu diseño de logo.",
        "t::logo::title": "Logo de tu negocio",
        "t::logo::desc": "Sube la imagen de tu logo. Es importante que sea una imagen con buena calidad y tenga un tamaño de 300 x 100 px.",
        "t::main-gallery::title": "Imágenes del negocio",
        "t::main-gallery::desc": "Captura la atención de tus clientes con un atractivo carrusell de imágenes en la App. Además si posees varias sucursales, la primera imagen que subas aquí, se destacará en la app que te ofrecemos. Selecciona cuidadosamente no más de 6 fotos de calidad con el tamaño ideal de 790 x 380 px, para proyectar lo mejor de tu negocio.",
        "t::main-gallery::titlePatient": "Imágenes del negocio",
        "t::main-gallery::descPatient": "Captura la atención de tus clientes con un atractivo carrusell de imágenes en la App. Además si posees varias sucursales, la primera imagen que subas aquí, se destacará en la app que te ofrecemos. Selecciona cuidadosamente no más de 6 fotos de calidad con el tamaño ideal de 790 x 380 px, para proyectar lo mejor de tu negocio.",
        "t::intro-img::title": "Imagen principal",
        "t::intro-img::desc": "Imagen que aparecerá en primera página. Muy útil para focalizar la atención del cliente en una oferta, promoción o en algo característico de tu negocio.",
        "booking_annots_title": "Anotaciones en citas por parte del cliente",
        "booking_annots_desc": "Habilita/Deshabilita que los clientes puedan hacer comentarios al reservar las citas.",
        "booking_annots_check": "Sí, quiero que los clientes puedan hacer comentarios al agendar una reserva.",
        "booking_time_incr_title": "Intervalo en el que se puede reservar",
        "booking_time_incr_desc": "Especifica cada cuantos minutos pueden reservar los clientes.",
        "booking_time_incr_label1": "Intervalos de ",
        "booking_time_incr_label2": " minutos",
        "web_pictures": "Imágenes Web",
        "t::color-font::title": "Color de la fuente",
        "t::color-font::desc": "Selecciona el color de la fuente que aparecerá en la página web.",
        "t::font-base::title": "Fuente base",
        "t::font-base::desc": "Fuente que se usará en toda la página web del centro",
        "t::treat-img::title": "Imagen página de servicios",
        "t::treat-img::desc": "Imágen que aparecerá encima de la sección de servicios.",
        "t::description::title": "Descripción",
        "t::description::desc": "Texto que aparecerá en la página principal de la página web",
        "t::email::title": "Email",
        "t::phone1::title": "Teléfono",
        "t::phone2::title": "Teléfono sec.",
        "t::whatsapp::title": "Whatsapp",
        "t::twitter::title": "Twitter",
        "t::facebook::title": "Facebook",
        "t::contact::title": "Datos Contacto",
        "t::contact::desc": "Datos de contacto del centro que aparecerán en la web",
        "t::main-image::title": "Imagen del Centro",
        "t::main-image::desc": "Esta será la imagen del centro, se mostrará junto con los datos donde sea necesario para identificarlo.",
        "t::published::title": "Activar Web",
        "t::published::desc": "Te proporcionamos una web que muestra información de tu centro y permite a tus clientes reservar cita.",
        "t::published::off": "Como tienes la web desactivada, la URL a la web redireccionará al Login, para mayor comodidad para ti y tus empleados.",
        "t::published::yes": "Si, quiero activar la web.",
        "big_img_constraints": "Debe ocupar menos de",
        "big_img_constraints2": "y medir",
        "img_too_big": "Comprueba que la imagen cumple con las restricciones",
        "Ficha del recurso": "Ficha del recurso",
        "Horarios y festivos": "Horarios y festivos",
        "write_resource_name": "Nombre del recurso",
        "cancel_time_title": "Tiempo de cancelación",
        "cancel_time_desc": "Configura si quieres que el cliente no pueda cancelar la cita con pocos minutos de antelación.",
        "cancel_time_label1": "El cliente no podrá cancelar cuando falten ",
        "cancel_time_label2": "minuto(s) para la cita",
        "t::instagram::title": "Instagram",
        "t::tiktok::title": "Tiktok",
        "subscription_delection_error_title": "No se puede eliminar",
        "subscription_delection_error": "La suscripción no puede ser eliminada porque hay clientes que tienen la suscripción contratada. Para eliminar esta suscripción, asegúrate que ningún cliente la tenga activa",
        "booking_email_verification_tittle": "Solicitar verificación de mail al cliente",
        "booking_email_verification_desc": "Activa/Desactiva la verificación de correo electronico para tus clientes durante el registro online",
        "booking_email_verification_check": "Sí, quiero que mis clientes tengan que verificar el correo para realizar su registro online",
        "booking_email_verification_tooltip": "Esta opción te garantizará la autenticidad de los correos electrónicos de tus clientes, lo que es esencial para tus campañas de email"
};
});