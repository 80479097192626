const filters = {
  timebar: 'Barra de fecha',
  timespan: 'Lapso de tiempo',
  dateGroup: 'Ver por (dia, mes, año)',
  subscription: 'Suscripciónes',
  ticketItems: 'Tickets',
  clients: 'Clientes',
  treatments: 'Servicios',
  products: 'Productos',
  employees: 'Empleado',
  packList: 'Bonos',
  subscriptionState: 'Estados de suscripcion',
  workStateFilter: 'Estados de Clases o Citas',
  employeeMulti: 'Empleados'
};

const keys = Object.keys(filters);
export default keys.map((value) => ({
  label: filters[value],
  value,
}));
