define([], function() {
    return {
        "add_treat": "Añadir servicio",
        "add_pack": "Añadir pack de servicios",
        "pack_treatment": "Pack de servicios",
        "to_treats": "a servicios",
        "new_category": "Nueva categoría",
        "back_to_categories": "Volver a categorías",
        "write_its_category": "Escribe tu categoría",
        "example_foto": "Ejemplo: Fotodepilación",
        "example_all_legs": "Ejemplo: Piernas enteras",
        "write_the_treat": "Nombre interno del servicio...",
        "ticket_text": "Texto corto para el ticket de compra...",
        "max_10_char": "Máximo 10 letras",
        "description_placeholder": "Añade una descripción para detallar mejor el servicio. Muy útil para los clientes que reserven online.",
        "included_services": "Servicios incluidos",
        "add_internal_ref": "Añadir categoría y nombre interno",
        "internal_category": "Categoría interna",
        "internal_name": "Nombre interno",
        "name_public": "Nombre",
        "write_name_public": "Nombre del servicio...",
        "treat_public_name": "Pertenece a",
        "treat_public_name_btn": "Escoge dónde encaja el servicio",
        "price_without_1": "El precio sin IVA",
        "price_without_2": "será",
        "price_with_1": "El precio con IVA",
        "price_with_2": "será",
        "share_treatment": "Compartir tratamiento",
        "share_treatment_info": "Si activas esta opción, se creará el tratamiento en todos los centros vinculados.",
        "shared_treatment": "Este tratamiento se comparte con los centros vinculados.",
        "not_shared_treatment": "Este tratamiento no se comparte con otros centros.",
        "delete_treat": "Eliminar servicio",
        "stats_treats_done": "Servicios realizados",
        "billed_by_treat": "Facturado por el servicio",
        "booking_employee": "Especialidad del empleado",
        "create_new_role": "Nueva especialidad",
        "back_to_roles": "Volver a especialidades",
        "ph_role": "Escribe una especialidad...",
        "booking_resources": "Recursos necesarios",
        "agenda_dest": "Agenda correspondiente",
        "choosable_employee": "Empleados",
        "choosable_employee_text": "El cliente puede seleccionar al empleado al reservar",
        "no_choosable_employee_text": "El cliente <span style='color:red'>no</span> puede seleccionar al empleado al reservar",
        "of_discount": "de descuento",
        "bookable_web": "Web y Facebook",
        "bookable_bh": "Miora Marketplace",
        "booking_where": "Se puede reservar en",
        "portal_discount": "Descuento en Portal",
        "cant_book": "Para configurar la reserva online, primero tienes que escoger la especialidad o recurso que necesita el servicio",
        "no_discount": "Sin descuento",
        "noneBooking": "En ningún sitio",
        "no_resources": "Ninguno",
        "none_role": "Ninguna",
        "apply_to_all_category": "Aplicar configuración en servicios de la misma categoría",
        "own_discount": "Aplicar descuento en reservas propias",
        "time_before_booking": "Tiempo mínimo antes de la cita",
        "dv_treatment": "Ficha del servicio",
        "dv_treatmentpack": "Ficha del pack de servicios",
        "download_txt_treat": "Descargar servicios en Excel",
        "search_label_treat": "Busca un servicio por categoría o nombre...",
        "pages_txt_treat": "servicios en total",
        "pack_percent_err": "La suma de los porcentajes de los servicios incluidos debe sumar 100",
        "q_ticketsByCategory": "Categorías que más venden",
        "q_treatmentbooking": "Servicios con reserva online",
        "tb_withbooking": "Con reserva online",
        "tb_withoutbooking": "Sin reserva online",
        "no_treatments_notice1": "Los servicios son los trabajos o tratamientos que realizas al cliente.",
        "cancel_policy": "Política de cancelación",
        "restrictions": "Restricciones",
        "bookable_miora": "Habilitar en Marketplace",
        "miora_raw_discount": "Precio con descuento",
        "no_category": "Sin categoría",
        "choosable_commerces": "Sedes donde se puede reservar",
        "search_label_treatment": "Busca un servicio por nombre o categoría",
        "new_treatment": "Nuevo tratamiento",
        "Ficha del servicio": "Ficha del servicio",
        "Estadísticas": "Estadísticas",
        "Reserva online": "Reserva online",
        "Recursos necesarios": "Recursos necesarios",
        "autoconfig_which_category": "La configuración de 'Recursos necesarios' y 'Reserva online' se aplicará a todos los servicios de la categoría:",
        "not_needed_special_group": "El cliente no necesita pertenecer a un grupo para poder reservar",
        "book_portal_title": "Configura para que el cliente reserve online",
        "Sedes con reserva online": "Sedes con reserva online",
        "Bloquea recursos para no poder reservarlos online": "Bloquea recursos para no poder reservarlos online",
        "at_home_text": "Es un servicio a domicilio y se le pedirá al cliente su dirección.",
        "apply_config_to_others": "Aplicar configuración a otros servicios",
        "not_needed_special_subs": "El cliente no necesita una suscripción para poder reservar",
        "res_choose_groups": "Añade grupos. El cliente deberá tener alguno de los que añadas para poder reservar.",
        "res_choose_subs": "Añade suscripciones. El cliente deberá tener alguna de las que añadas para poder reservar.",
        "time_late_cancel": "Cancelación tardía",
        "waitlist_option": "Si la clase se llena, podrán seguir apuntándose en una lista de espera.",
        "pack": "pack",
        ". Selecciona los posibles candidatos": ". Selecciona los posibles candidatos:",
        "Necesita un recurso especial": "Necesita un recurso especial",
        "¿Qué tipo de servicio es?": "¿Qué tipo de servicio es?",
        "Añadir sala": "Añadir sala",
        "error-no-monitor": "La clase necesita un monitor. Selecciona al menos un monitor de la lista.",
        "El servicio necesitará un recurso de la lista": "El servicio necesitará un recurso de la lista",
        "Necesita un empleado": "Necesita un empleado",
        "Un tratamiento, servicio o clase individual": "Un tratamiento, servicio o clase individual",
        "Una clase colectiva (con un grupo de personas)": "Una clase colectiva (con un grupo de personas)",
        "¿Necesita algún recurso más?": "¿Necesita algún recurso más?",
        "La clase necesitará un monitor de la lista": "La clase necesitará un monitor de la lista",
        "¿Qué capacidad tiene la clase? (nº máximo de asistentes)": "¿Qué capacidad tiene la clase? (nº máximo de asistentes)",
        "Una capacidad concreta": "Una capacidad concreta",
        "La capacidad de la sala": "La capacidad de la sala",
        "res_late_cancel": "Cancelación tardía",
        "res_missing": "Cliente no asistió",
        "res_client_rejected": "Cancelación temprana",
        "confirmed": "Confirmada",
        "finished": "Finalizada",
        "book_agenda_config": "Empleados/Recursos que necesita el servicio",
        "advanced_agendas": "Configuración avanzada de agendas",
        "La clase se hará en una sala de la lista": "La clase se hará en una sala de la lista",
        "Necesitará también un recurso de la lista": "Necesitará también un recurso de la lista",
        "¿Qué recurso necesita el servicio?": "¿Qué recurso necesita el servicio?",
        "last_clients_done": "Últimos clientes que usaron el servicio",
        "gapconfig_1": "Si el cliente realizó un servicio de",
        "need_subs_or_pack": "Necesita una suscripción o un bono para poder reservar",
        "need_group_to_book": "El cliente tiene que pertenecer a un grupo de la lista para poder reservar",
        "gapconfig_2": "deben pasar",
        "gapconfig_3": "días para poder reservar este servicio.",
        "¿El cliente tiene un espacio concreto en la clase?": "¿El cliente tiene un espacio concreto en la clase?",
        "No, el cliente sólo tiene que unirse a la clase.": "No, el cliente sólo tiene que unirse a la clase.",
        "Sí, cada cliente tiene un espacio concreto en el siguiente mapa:": "Sí, cada cliente tiene un espacio concreto en el siguiente mapa:",
        "new_treat": "Nuevo servicio",
        "new_pack": "Nuevo pack",
        "booking_balance": "Saldo de reservas",
        "balance_booking_active": "Si hay un limite de reservas que puede hacer un usuario simultaneamente.",
        "¿El servicio es presencial o es online a través de Bewe Class?": "¿El servicio es presencial o es online?",
        "streaming_days": "Días para ver video en diferido",
        "Enable_online_booking": "Habilitado checkout para reserva online",
        "sub_or_pack_suggestion": "Sugerir suscripción o bono para comprar si el cliente no dispone de ninguno.",
        "packs": "bonos",
        "wrong_data_or_not_found": "Datos no válidos o no encontrados, actualice el campo.",
        "no_found": "No encontrado",
        "all_treatments_products_and_packages": "Todos los servicios, productos o bonos",
        "no_excepto": "Sin excepciones",
        "service_categories": "Categorías de servicios",
        "service_products": "Categorías de productos",
        "all_packages": "Cualquier bono del centro",
        "products_gympass": "Producto de gympass",
        "discount_creation_error": "El descuento no pudo ser creado, el campo de servicios debe ser llenado",
        "offer_package_creation_error": "El bono no pudo ser creado, es necesario seleccionar un servicio",
        "subscriptions_creation_error": "La suscripción no pudo ser creada, es necesario seleccionar un servicio",
        "treatment_creation_error": "No pudo ser creado, el campo de nombre debe ser llenado",
        "treatment_without_employee_creation_error": "No pudo ser creado, es necesario seleccionar un empleado antes de guardar",
        "invalid_expire_date_package": "El valor del campo caducidad debe ser mayor de o igual a 0",
        "what_type_of_service_is": "¿Qué tipo de servicio es?",
        "is_a_individual_treatment_or_class": "Un tratamiento, servicio o clase individual",
        "a_colective_class_with_a_people_group": "Una clase colectiva (con un grupo de personas)",
        "capacity_of_the_class": "¿Qué capacidad tiene la clase? (nº máximo de asistentes)",
        "room_capacity": "La capacidad de la sala",
        "specific_capacity": "Una capacidad concreta",
        "people": "personas",
        "required_to_choose_a_specific_spot": "¿El cliente tiene un espacio concreto en la clase?",
        "the_client_only_has_to_book_the_class": "No, el cliente sólo tiene que unirse a la clase.",
        "will_need_an_instructor_from_this_list": "La clase necesitará un monitor de la lista",
        "select_room_for_the_class": "La clase se hará en una sala de la lista",
        "is_virtual_or_presential_service": "¿El servicio es presencial o es online?",
        "this_service_need_a_resource_from_the_list": "El servicio necesitará un recurso de la lista",
        "need_also_a_resource_of_the_list": "Necesitará también un recurso de la lista",
        "which_resource_is_necessary": "¿Qué recurso necesita el servicio?",
        "need_a_more_resources": "¿Necesita algún recurso más?",
        "one_employee_is_required": "Necesita un empleado",
        "special_resource_need": "Necesita un recurso especial",
        "select_the_employees_that_can_provide_the_service": ". Selecciona los posibles candidatos:"
};
});