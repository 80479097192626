import React from 'react';
import { Alert } from 'buwi';
import useTemplatesStore from '../store';

function DuplicateLanguage() {

    const { effects, isOpenModalDuplicate, selectedTemplate } = useTemplatesStore()
    const onClose = () => effects.closeTemplate()
    const buttons = [
        {
            text: 'Cancelar',
            type: 'cancel',
            onClick: () => effects.closeTemplate(),
        },
        {
            text: 'Duplicar',
            type: 'submit',
            onClick: () => effects.saveDuplicateTemplate(),
        },
    ];
    
    
    return (
        <Alert buttons={buttons} isOpen={isOpenModalDuplicate} onClose={onClose} title="Atención!">
            ¿Estás seguro que deseas duplicar el lenguaje {String(selectedTemplate.language).toUpperCase()} ?
        </Alert>
    )
}

export default DuplicateLanguage
