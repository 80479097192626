import * as Blockly from 'blockly/core';

const color = 225;

Blockly.Blocks.pipeline = {
    init() {
        this.appendStatementInput('pipeline')
            .setCheck(['Aggregate', 'Find'])
            .appendField('pipeline');
        this.setColour(color);
        this.jsonInit({ style: { hat: 'cap' } });
    },
};

Blockly.Blocks.overview = {
    init() {
        this.appendStatementInput('overview')
            .setCheck('Label')
            .appendField('overview');
        this.setColour(color);
        this.jsonInit({ style: { hat: 'cap' } });
    },
};

Blockly.Blocks.columns = {
    init() {
        this.appendStatementInput('columns')
            .setCheck('Label')
            .appendField('columns');
        this.setColour(color);
        this.jsonInit({ style: { hat: 'cap' } });
    },
};