import React, { useEffect } from 'react';
import { Steps } from 'buwi';
import { useParams } from 'react-router-dom';
import useReportStore from './store';
import StepsContent from './components/stepsContent';

const StepItems = [
  'Filtros',
  'Información',
  'Columnas',
  'Visión General',
  'Textos',
  'Previsualizar',
];

function ReportSteps() {
  const { id } = useParams();
  const { effects, currentPosition } = useReportStore();

  async function fetchData() {
    const report = await effects.getReport(id);
    effects.setReport(report);
  }

  useEffect(() => {
    fetchData();
  }, [effects.newpipeline]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div style={{ padding: '0 1rem' }}>
      <Steps
        vertical
        current={currentPosition}
        onClick={(position) => effects.setCurrentPosition(position)}
        items={StepItems}
        content={({ current }) => <StepsContent current={current} />}
      />
    </div>
  );
}

export default ReportSteps;
