import React from 'react';
import { Table, Topbar } from 'buwi';
import { useParams } from 'react-router-dom';
import useTemplatesStore from '../store';
import { i18n } from 'common/reports/libs/i18n';
import DeleteTemplateModal from './deleteTemplateModal'

const columnList = [
  {
    field: 'language',
    label: 'Idioma',
    format: (lang) => String(lang).toUpperCase(),
  },
  {
    field: 'emailTitle',
    label: 'Asunto',
    format: (subject) => subject || 'Sin asunto',
  },
  {
    field: 'hidden',
    label: 'Plantilla interna',
    format: (hidden) => (hidden ? 'Si' : 'No'),
  },
];

function DetailTable() {
  const { effects, templatesByNamespace = [] } = useTemplatesStore();
  const { namespace } = useParams();
  const buttonsRight = [
    {
      text: `Nuevo idioma`,
      func: () => effects.openEditModal({ namespace, language: '' }),
      icon: 'icon-plus',
    },
  ];

  const actions = [
    {
      icon: 'icon-editar',
      onClick(row) {
        effects.openEditModal(row);
      },
    },
    {
      icon: 'icon-shuffle',
      onClick(row) {
        effects.openDuplicate(row);
      },
    },
    {
      icon: 'icon-x',
      onClick(row) {
        effects.toggleDeleteModal(row);
      },
    },
  ];

  const handleClick = (row) => {
    effects.openTemplate(row);
  };

 

  return (
    <React.Fragment>
      <Topbar
        type="detail"
        placeholder="Buscar por nombre o descripción"
        buttonsRight={buttonsRight}
        keys={['i18n.es.name', 'i18n.es.description']}
      />
      <Table
        i18n={i18n}
        title={`Idiomas para ${String(namespace).toUpperCase()}`}
        onClick={handleClick}
        columns={columnList}
        rows={templatesByNamespace}
        actions={actions}
        withCheck={false}
        paginatorContent={({ totalPages, currentPage, length }) => (
          <span>
            {'Pagina'} {currentPage} {'de'} {totalPages} ( {length}{' '}
            {'registros'} )
          </span>
        )}
      />
      <DeleteTemplateModal />
    </React.Fragment>
  );
}

export default DetailTable;
