import * as Blockly from 'blockly/core';

const color = 240;

Blockly.Blocks.none = {
  init() {
    this.appendDummyInput().appendField('none');
    this.setInputsInline(false);
    this.setOutput(true, null);
    this.setColour(color);
  },
};

Blockly.Blocks.none_dummy = {
  init() {
    this.appendDummyInput().appendField('none');
    this.setPreviousStatement(true, null);
    this.setColour(color);
  },
};
