import React, { useRef, useState } from 'react';
import { Button, Alert } from 'buwi'
import usePipelineStore from '../store';

const Translate = () => {
  const { effects, showReport, translate } = usePipelineStore();
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const onClose = () => setModalIsOpen(false);

  const inputRef = useRef(null)
  const { i18n = { es: {}, en: {} } } = showReport;

  const buttons = [
    {
        text: 'Cancelar',
        type: 'cancel',
        onClick: () => setModalIsOpen(false),
    },
    {
        text: 'Agregar',
        type: 'submit',
        onClick: () => {
            effects.addWord(inputRef.current.value)
            inputRef.current.value = ''
            setModalIsOpen(false)
        },
    },
];


  return (
    <div>
        <span className="relative db h1 mr4">
            <Button small className="absolute right-0" onClick={() => setModalIsOpen(true)}>Nueva traducción</Button>
        </span>
        
        {translate.langs.map(lang => (
            <div className="mv4" key={String(lang)}>
                <h2>{String(lang).toUpperCase()}</h2>  
                {translate.words.map(key => (
                    <div className="flex items-center mb2" key={String(lang + key)}>
                        <div style={{ minWidth: '200px' }}>
                            <b>{key}</b>
                        </div>
                        <input
                            className="flex-auto h2 pl2"
                            onKeyUp={(e) => effects.changeLang(e.target.value, lang, key)}
                            defaultValue={i18n[lang] && i18n[lang][key] || ''}
                        />
                        <span onClick={() => effects.deleteWord(key)} class={`icon-x f4 ${['name', 'description'].includes(key) ? 'white' : 'red pointer'}`}></span>
                    </div>
                ))}  
            </div>
        ))}

        <Alert buttons={buttons} isOpen={modalIsOpen} onClose={onClose} title="Ingrese el texto que se va a traducir">
            <input ref={inputRef} class="h2 w-100 pa3 f4"/>
        </Alert>
    </div>
  );
};

export default Translate;
