define([], function() {
    return {
        "marketing": "Marketing",
        "discounts": "Descuentos",
        "fidelity": "Fidelización",
        "bonos": "Bonos",
        "fix_discounts": "Descuentos fijos",
        "massive_send_title": "Envíos de Email/SMS/Push",
        "simple_send": "Nuevo envío",
        "sending_log": "Registro de envíos",
        "poll_client_title": "Encuestas privadas al cliente",
        "results": "Resultados",
        "config": "Configuración",
        "empty_chart": "No hay datos suficientes",
        "discounts_desc": "¡Dale un giro a tus ventas! Configura descuentos a tus servicios, productos y bonos por días, horarios o grupo de clientes y dedica más tiempo a lo que realmente importa.",
        "discounts_doubt": "Si no sabes cómo hacer tus descuentos, envíanos un email a&nbsp;<a href='mailto:contacta@bewe.io'>contacta@bewe.io</a>",
        "discounts_list": "Lista de descuentos del centro",
        "add_disc": "Añadir un nuevo descuento:",
        "name_disc": "Nombre del descuento",
        "of_disc": "de descuento",
        "err_put_name_disc": "Tienes que ponerle un nombre al descuento",
        "disc_add_btn": "Añadir descuento",
        "fid_offers": "Ofertas de fidelización",
        "fid_offers_desc": "Aquí podrás configurar las ofertas para premiar a los clientes por haberse hecho servicios en el centro (Ejemplos: A la décima manicura te regalamos una. Por un tratamiento de fotodepilación, el siguiente tiene un 50% de descuento, etc ).",
        "fid_offers_doubt": "Transforma tus días tranquilos en una fiesta de ventas. ¿Deseas adquirir más funcionalidades para potenciar tu negocio? Escríbenos al correo",
        "fid_list": "Lista de tus ofertas de fidelización",
        "fid_add": "Añadir una nueva oferta",
        "fid_name_offer": "Nombre de la oferta",
        "fid_if_client_do": "Si el cliente hace",
        "fid_treat_of": "servicio de",
        "fid_win": "se le premiará con",
        "err_put_name_fid": "Tienes que ponerle un nombre a la oferta",
        "fid_add_btn": "Añadir oferta",
        "bono_list": "Lista de bonos",
        "packs_title": "Bonos de servicios",
        "packs_desc": "Aquí podrás crear bonos de servicios a un precio cerrado. Son muy útiles para que los clientes compren un conjunto de servicios a un precio menor que por separado y los vayan gastando poco a poco. Los bonos podrás cobrarlos directamente en caja una vez creados.",
        "packs_doubt": "Si no ves claro cómo hacer un pack, envíanos un email a&nbsp;<a href='mailto:contacta@bewe.io'>contacta@bewe.io</a>",
        "pack_list": "Lista de bonos de servicios",
        "add_new_pack": "Añadir un nuevo bono",
        "pack_name": "Nombre del bono",
        "add_more_treats": "añadir más servicios",
        "add_pack_btn": "Añadir bono",
        "err_put_name_pack": "Tienes que ponerle un nombre al bono y un precio",
        "client_rest_packitems": "Servicios que le quedan",
        "apoll_title": "Análisis de las encuestas",
        "apoll_q1": "¿Qué le ha parecido el centro? ( 0: Malo , 5: Excelente )",
        "apoll_q2": "¿Qué le ha parecido la atención recibida en el servicio? ( 0: Muy mala , 5: Excelente )",
        "apoll_q3": "¿Qué le han parecido los productos utilizados en el servicio? ( 0: Malos , 5: Excelentes )",
        "apoll_q4": "¿Qué le ha parecido la calidad/precio del servicio? ( 0: Mala , 5: Excelente )",
        "apoll_q5": "¿Recomendaría el servicio a sus amigos/as? ( 0: No. , 5: ¡Me ha encantado! Lo recomendaré seguro. )",
        "score_total": "Puntuación total obtenida a partir de todas las preguntas",
        "warning_5_polls": "Si hay 5 encuestas o menos, no se mostrarán los resultados para asegurar el anonimato.",
        "scores": "Puntuaciones",
        "scores_desc": "Muestra el número de personas que han respondido cada una de las posibles puntuaciones.",
        "avg_score": "Puntuación media",
        "avg_score_desc": "Muestra la puntuación media de los últimos meses para poder apreciar una posible evolución.",
        "poll_done": "Encuestas realizadas",
        "poll_done_desc": "Muestra las encuestas que se han hecho durante los últimos meses.",
        "cpoll_title": "Configuración de encuestas",
        "cpoll_desc": "Bewe enviará un e-mail al cliente con una encuesta de satisfacción pocas horas después de ser cobrado. Las encuestas de satisfacción pueden ser una herramienta muy útil para ver, de forma anónima, la opinión de tus clientes.",
        "cpoll_desc_2": "Aquí puedes configurar con qué servicios cobrados quieres que se envíe la encuesta, e incluso puedes poner un pequeño descuento como premio para quien complete la encuesta.",
        "cpoll_add_cat": "Añade los servicios o las categorías de servicios con los que se enviaría la encuesta de satisfacción.",
        "cpoll_disc_title": "Descuento por encuesta hecha",
        "cpoll_disc_desc": "Para incentivar que tus clientes hagan las encuestas, ofrece un pequeño descuento como premio. ",
        "enable_disc": "Habilitar descuento.",
        "next_treat": "El próximo servicio de",
        "simple_sending": "Envío simple",
        "simple_sending_desc": "Envía mensajes de texto a tus clientes. Al no contener imágenes ni un diseño complejo, puedes enviar estos mensajes a través de e-mail pero también a través de SMS. Es muy simple y eficaz para clientes que no tienen e-mail o no lo miran muy habitualmente.",
        "create_ss": "Crear un nuevo envío simple",
        "ss_pending": "Envíos pendientes",
        "ss_no_pending": "No hay envíos pendientes",
        "ss_last": "Últimos envíos realizados",
        "ss_empty": "Aún no hay envíos realizados",
        "cancel_send": "Cancelar envío",
        "send_to": "Enviado a",
        "men_and_women": "Mujeres y hombres.",
        "women": "Mujeres.",
        "men": "Hombres.",
        "all_olds": "Todas las edades.",
        "from_0_10": "De 0 a 10 años.",
        "from_11_20": "De 11 a 20 años.",
        "from_21_40": "De 21 a 40 años.",
        "more_than_40": "41 años o más.",
        "datesend": "Fecha de envío",
        "hour_pre": "a las",
        "total_to_send": "Total para enviar",
        "total_sent": "Total enviado",
        "sendingNew": "Crear un nuevo envío",
        "how_will_sent": "¿Cómo será enviado?",
        "sn_email": "Por e-mail. Sólo a quien tenga en la ficha.",
        "sn_sms": "Por SMS. Sólo a quien tenga móvil en la ficha.",
        "sn_both": "Por e-mail a quien tenga en la ficha. Si no tiene, se enviará por SMS.",
        "sn_resumee": "Envíos programados",
        "by_email": "Por e-mail",
        "by_sms": "Por SMS",
        "not_enought_sms": "No tienes saldo para enviar SMS. Comunícate con nosotros para comprar saldo o haz el envío sólo por e-mail.",
        "write_a_title": "Escribe un título ( sólo para los e-mails )",
        "write_a_message": "Escribe el mensaje.",
        "header_or_not": "Enviar email con cabecera o en blanco",
        "header_yes": "Sí, quiero que incluya la cabecera.",
        "header_no": "No, quiero que sea un email en blanco donde sólo aparezca mi mensaje.",
        "test_email_title": "Envía una prueba a una dirección de e-mail",
        "when_send": "Ahora que ya tienes el mensaje preparado, ¿cuándo quieres que se haga el envío masivo?",
        "immediately": "Inmediatamente",
        "concrete_date": "A una fecha y hora concreta",
        "create_send": "Hacer el envío masivo",
        "create_send_error": "Error. Los campos en rojo están mal.",
        "create_send_success": "Felicidades, has programado el envío con éxito",
        "create_send_success_desc": "Se realizarán los envíos en el momento que hayas configurado.",
        "close_panel": "Cerrar panel",
        "content": "Añade aquí el contenido del email",
        "totally_free": "totalmente gratis",
        "free": "Gratis",
        "al": "al",
        "of_discount": "de descuento",
        "q_disable_discount": "¿Quieres deshabilitar el descuento?",
        "q_disable_discount_desc": "Cuando lo vuelvas a habilitar, la caja volverá a tenerlo en cuenta automáticamente.",
        "q_remove_discount": "¿Seguro que quieres eliminar el descuento?",
        "q_remove_discount_desc": "Si eliminas el descuento, se deshabilitará y lo perderás de esta lista",
        "treatment_not_defined": "servicio no definido",
        "q_disable_fidelity": "¿Quieres deshabilitar esta oferta?",
        "q_disable_fidelity_desc": "Si la deshabilitas, no se aplicarán los descuentos para los clientes que tomen esos servicios desde ahora.",
        "q_remove_fidelity": "¿Seguro que quieres eliminar esta oferta?",
        "q_remove_fidelity_desc": "Recuerda que si eliminas esta oferta, se eliminarán los progresos de los clientes que estaban consiguiendo la oferta",
        "q_disable_pack": "¿Quieres deshabilitar este bono?",
        "q_disable_pack_desc": "Si lo deshabilitas, no se podrá comprar en caja, pero quien ya lo haya comprado aún lo conservará y podrá utilizarlo.",
        "q_remove_pack": "¿Seguro que quieres eliminar este bono?",
        "q_remove_pack_desc": "Si lo eliminas, todos los clientes que ya lo hayan comprado lo perderán.",
        "pack_card": "Ficha de bono de servicios",
        "all_groups": "Todos los grupos",
        "expiry": "Caducidad",
        "sn_push": "Por notificación PUSH. Sólo quien tenga la app del negocio instalada.",
        "sn_all": "Por notificación PUSH a quien tenga la app. Si no, por email y si no tiene se enviará por SMS.",
        "by_push": "Por PUSH",
        "ini_hour": "Hora de inicio",
        "fi_hour": "Hora de finalización",
        "active_week_days": "Días de la semana en los que el descuento está activo",
        "date_to_date": "Fecha inicio y final",
        "start_date": "De cualquier día",
        "finish_date": "A cualquier día",
        "any": "Cualquier",
        "clear_date": "Limpiar Fechas",
        "since_buy_to_expire": "días de validez. (Desde que el cliente lo compra hasta que caduca)",
        "apply_expiration": "¿Quieres aplicar caducidad al bono?",
        "subscriptions": "Suscripciones",
        "subs_offers_desc": "Aquí podrás configurar suscripciones para tus clientes. Una suscripción es un pago periódico que hace el cliente a cambio de servicios, descuentos u otras ventajas. <br /> A través de la ficha de cliente podrás asignarla una suscripción y desde contabilidad podrás llevar la gestión de quién tiene que pagar.",
        "subs_offers_doubt": "Si no ves claro cómo funcionan las suscripciones, contáctanos por chat para resolver tus dudas.",
        "subs_list": "Lista de suscripciones",
        "subs_add_title": "Añadir suscripción",
        "subs_ph_name": "Nombre de la suscripción",
        "subs_phrase": "Se le cobrará al cliente cada",
        "fid_if_client_buy": "Si el cliente compra",
        "fid_prod_of": "productos de",
        "product_not_defined": "servicio no definido",
        "valid_days": "Válido para",
        "valid_from_hour": "Válido desde las",
        "valid_to_hour": "Válido hasta las",
        "percent_discount": "Descuento porcentual",
        "fix_discount": "Descuento fijo",
        "fid_expires_config": "y",
        "fid_expires": "Con una caducidad de",
        "fid_no_expires": "Sin ninguna caducidad",
        "fid_expires_days": "días después de adquirir la oferta.",
        "fid_expiration": "días después de adquirir la oferta.",
        "sent": "Enviado",
        "new_message": "Nuevo mensaje",
        "send_by_sms": "Enviar por SMS",
        "send_by_push": "Enviar por notificación PUSH",
        "send_by_email": "Enviar por email",
        "send_by_auto": "Enviar por el canal preferido por cada cliente",
        "send_by_short_sms": "Por SMS",
        "send_by_short_push": "Por PUSH",
        "send_by_short_email": "Por email",
        "send_by_short_auto": "Automático",
        "tooltip_delay": "Programa el envío para más tarde",
        "tooltip_via": "Haz el envío por email, sms o push",
        "all_insurances": "Todas las empresas pagadoras",
        "Ficha del bono": "Ficha del bono",
        "Se necesita un título para poder enviar el mensaje.": "Se necesita un título para poder enviar el mensaje.",
        "Hay que escribir un texto en el mensaje": "Hay que escribir un texto en el mensaje",
        "subs_not_online": "No se puede contratar online",
        "subs_online": "Sí se puede contratar online",
        "begin_month": "Cobrada al inicio de mes",
        "online": "Se puede contratar online",
        "subs_card": "Ficha de suscripción",
        "Ficha de la suscripción": "Ficha de la suscripción",
        "add_limit_treat": "Añadir límite de servicios al día, semana o mes",
        "subs_begin": "La suscripción se ajusta automáticamente para empezar a inicio de mes. (Cobrando meses parciales cuando sea necesario)",
        "all_pack": "Todos los bonos",
        "pack_not_defined": "Bono no definido",
        "all_subscriptions": "Todas las suscripciones",
        "buyOnline": "El cliente lo puede comprar online",
        "no_buyOnline": "El cliente no lo puede comprar online",
        "subs_online_begin": "Sí se puede contratar online y se cobra el mes en curso para luego cobrar al inicio de cada mes.",
        "Editor de descuentos": "Editor de descuentos",
        "Verifica el error encontrado antes de guardar los cambios.": "Verifica el error encontrado antes de guardar los cambios.",
        "Verifica los ": "Verifica los ",
        " errores encontrados antes de guardar los cambios.": " errores encontrados antes de guardar los cambios.",
        "apply_config_to_old_subs": "Actualizar suscripciones antiguas",
        "autoconfig_fathering_subs_title": "Hay suscripciones que tienen una configuración antigua. Puedes actualizarle la suscripción a los que selecciones en la lista.",
        "which_clients": "¿A qué clientes?",
        "bono_applied_list": "Dónde se ha aplicado el bono?",
        "bono_applied_date": "Fecha de aplicación",
        "bono_applied_date_work": "Fecha de la reserva",
        "link_youtube_account_title": "Vincula tu cuenta",
        "link_youtube_account_subtitle": "Vincula tu cuenta de Youtube para poder gestionar desde BEWE todos tus canales, directos y vídeos.",
        "email_template_title": "Plantillas de email",
        "email_template_desc": "Las plantillas son diseños de emails que te proporcionamos o que puedes crear/editar tú mismo, para enviar a tus clientes.",
        "email_editor": "Editor de emails",
        "new_email_template_placeholder": "Escribe el nombre de la nueva plantilla...",
        "webapp_translations_title": "Modifica los textos de tu web y app",
        "webapp_translations_desc": "Modifica y personaliza todos los textos que aparecen en la web y app de tu negocio. De esta manera, podrás traducirlo a un nuevo idioma o simplemente adaptar algunas palabras que entiendan mejor tus clientes.",
        "q_cant_remove": "No se puede eliminar",
        "q_cant_remove_pack_desc": "El bono no puede ser eliminado por que hay clientes que lo han comprado y lo tienen activo. Puedes deshabilitarlo pero no puedes eliminarlo hasta que no quede ningún cliente con este bono activo",
        "latest_bonus_changes_by_employees": "Últimos cambios de bonos por los empleados",
        "search_label_package": "Buscar un bono por nombre o categoría",
        "pages_txt_packages": "Bonos en total",
        "base_title": "Plan: Essential",
        "base_description": "Base de software de BEWE. Todo lo necesario para gestionar su negocio.",
        "app_title": "App personalizada",
        "app_description": "Tus clientes podran reservar via web y a través de una app totalmente personalizada para iOS y Android con tu imagen de marca.",
        "pass_title": "Bewe Pass",
        "pass_description": "App de reconocimiento facial para registrar la entrada al negocio y a las clases. Pueden hacerlo tanto clientes como empleados.",
        "online_title": "Class, Cursos y TV",
        "online_description": "Incluye todo lo necesario para llevar tus servicios online: citas virtuales, clases virtuales, cursos online y TV.",
        "checkout_title": "Bewe Checkout",
        "checkout_description": "Con el checkout podrás incluir en tu web nuestro sistema de pagos. Todo integrado para que tus clientes compren y esté todo sincronizado en Bewe.",
        "electronic-f_title": "Facturación electrónica",
        "electronic-f_description": "Al realizar facturas nos comunicamos con Hacienda por ti para generar automáticamente las facturas electrónicas.",
        "interval_title": "Periodo de pago",
        "interval_description": "Elige la periodicidad de pago que prefieras. A más meses, mayor será el descuento.",
        "pricing_title": "Compra BEWE ahora",
        "pricing_description": "Configura tu suscripción de acuerdo con las necesidades de tu negocio.",
        "discount-vendor_title": "Descuento comercial",
        "discount-vendor_description": "Aprovecha el descuento comercial. Es por tiempo limitado! Quedan",
        "discount-vendor_afterDescription": "para que expire el descuento",
        "deactivated": "Desactivado",
        "subs_bewetv": "Tiene acceso a TV",
        "autopay_desc": "Automáticamente se cobrará cada mes al cliente un nuevo bono",
        "autopay_config_title": "Pago automático",
        "autopay_state_monthly": "El pago automático está activo.",
        "autopay_state_inactive": "El pago automático está desactivado.",
        "autopay_state_semiblocked": "El pago automático está fallando por pagos rechazados, seguiremos intentándolo cada día, pero es preferible que contacte con el cliente para solucionar los problemas con su tarjeta.",
        "autopay_state_blocked": "El pago automático está bloqueado por pagos rechazados. Contacte con el cliente para solucionar los problemas con su tarjeta y después reactive los pagos de nuevo.",
        "autopay_state_completed": "El pago automático se completó.",
        "autopay_nextpaydate_willbe": "El próximo cobro debería ser: ",
        "buy_online_trial_local": "El cliente lo puede comprar online, pero sólo una vez.",
        "buy_online_trial_global": "El cliente lo puede comprar online, pero sólo si nunca ha adquirido ningún bono o suscripción en el negocio.",
        "note_tag_none": "Sin aviso",
        "note_tag_yellow": "Aviso leve",
        "note_tag_red": "Aviso grave",
        "marketing_rules_description": "Una tarea automática se puede resumir en acción - reacción. Cuando ocurre algo en tu software (acción), automáticamente se ejecuta la reacción configurada. Hay varias reacciones que puedes configurar: enviar un mensaje al cliente, añadir el cliente a un grupo, etc.",
        "Modificar regla": "Modificar tarea",
        "Crear regla": "Crear tarea",
        "Regla modificada con éxito": "Tarea modificada con éxito",
        "Regla creada con éxito": "Tarea creada con éxito",
        "trigger_birthday_title": "Cumpleaños de un cliente",
        "trigger_birthday_phrase": "Cuando es el cumpleaños de un cliente",
        "trigger_birthday_phraseWithVars": "Cuando es el cumpleaños de un cliente",
        "trigger_clientAbsentsXDays_title": "Un cliente lleva X días sin aparecer por el centro",
        "trigger_clientAbsentsXDays_phrase": "Cuando un cliente lleva **unos días** sin ir al negocio",
        "trigger_clientAbsentsXDays_phraseWithVars": "Cuando un cliente lleva **{{numberOfDays}} días** sin ir al negocio",
        "trigger_clientAbsentsXDays_filter_numberOfDays_buttonText": "X",
        "trigger_leftXDaysOfXPackExpiration_title": "Quedan X días para que caduque un bono",
        "trigger_leftXDaysOfXPackExpiration_phrase": "Cuando faltan **unos días** para que caduque un bono",
        "trigger_leftXDaysOfXPackExpiration_phraseWithVars": "Cuando faltan **{{numberOfDays}} días** para que caduque el bono **{{selectPack}}**",
        "trigger_leftXDaysOfXPackExpiration_filter_numberOfDays_buttonText": "X",
        "trigger_leftXDaysOfXPackExpiration_filter_selectPack_buttonText": "X",
        "trigger_leftXDaysOfXSubscriptionExpiration_title": "Quedan X días para que caduque una sucripción",
        "trigger_leftXDaysOfXSubscriptionExpiration_phrase": "Cuando faltan **unos días** para que caduque una suscripción",
        "trigger_leftXDaysOfXSubscriptionExpiration_phraseWithVars": "Cuando faltan **{{numberOfDays}} días** para que caduque la suscripción **{{selectSubscription}}**",
        "trigger_leftXDaysOfXSubscriptionExpiration_filter_numberOfDays_buttonText": "X",
        "trigger_leftXDaysOfXSubscriptionExpiration_filter_selectSubscription_buttonText": "X",
        "trigger_leftXSessionsOfXPack_title": "Quedan X sesiones de un bono",
        "trigger_leftXSessionsOfXPack_phrase": "Cuando falten solo **unas sesiones** de un bono",
        "trigger_leftXSessionsOfXPack_phraseWithVars": "Cuando falten solo **{{quantity}} sesiones** del bono **{{selectPack}}**",
        "trigger_leftXSessionsOfXPack_filter_quantity_buttonText": "X",
        "trigger_leftXSessionsOfXPack_filter_selectPack_buttonText": "X",
        "trigger_worksFinishedAfterXDays_title": "Hace X días que un cliente no accede a un servicio",
        "trigger_worksFinishedAfterXDays_phrase": "Cuando un cliente no realiza **un servicio** desde hace **unos días**",
        "trigger_worksFinishedAfterXDays_phraseWithVars": "Cuando un cliente no realiza el servicio **{{selectTreatment}}** desde hace **{{numberOfDays}} días**",
        "trigger_worksFinishedAfterXDays_filter_numberOfDays_buttonText": "X",
        "trigger_worksFinishedAfterXDays_filter_selectTreatment_buttonText": "X",
        "patient_trigger_birthday_title": "Cumpleaños de un paciente",
        "patient_trigger_birthday_phrase": "Cuando es el cumpleaños de un paciente",
        "patient_trigger_birthday_phraseWithVars": "Cuando es el cumpleaños de un paciente",
        "patient_trigger_clientAbsentsXDays_title": "Un paciente lleva X días sin aparecer por el centro",
        "patient_trigger_clientAbsentsXDays_phrase": "Cuando un paciente lleva **unos días** sin ir al negocio",
        "patient_trigger_clientAbsentsXDays_phraseWithVars": "Cuando un paciente lleva **{{numberOfDays}} días** sin ir al negocio",
        "patient_trigger_clientAbsentsXDays_filter_numberOfDays_buttonText": "X",
        "patient_trigger_leftXDaysOfXPackExpiration_title": "Quedan X días para que caduque un bono",
        "patient_trigger_leftXDaysOfXPackExpiration_phrase": "Cuando faltan **unos días** para que caduque un bono",
        "patient_trigger_leftXDaysOfXPackExpiration_phraseWithVars": "Cuando faltan **{{numberOfDays}} días** para que caduque el bono **{{selectPack}}**",
        "patient_trigger_leftXDaysOfXPackExpiration_filter_numberOfDays_buttonText": "X",
        "patient_trigger_leftXDaysOfXPackExpiration_filter_selectPack_buttonText": "X",
        "patient_trigger_leftXDaysOfXSubscriptionExpiration_title": "Quedan X días para que caduque una sucripción",
        "patient_trigger_leftXDaysOfXSubscriptionExpiration_phrase": "Cuando faltan **unos días** para que caduque una suscripción",
        "patient_trigger_leftXDaysOfXSubscriptionExpiration_phraseWithVars": "Cuando faltan **{{numberOfDays}} días** para que caduque la suscripción **{{selectSubscription}}**",
        "patient_trigger_leftXDaysOfXSubscriptionExpiration_filter_numberOfDays_buttonText": "X",
        "patient_trigger_leftXDaysOfXSubscriptionExpiration_filter_selectSubscription_buttonText": "X",
        "patient_trigger_leftXSessionsOfXPack_title": "Quedan X sesiones de un bono",
        "patient_trigger_leftXSessionsOfXPack_phrase": "Cuando falten solo **unas sesiones** de un bono",
        "patient_trigger_leftXSessionsOfXPack_phraseWithVars": "Cuando falten solo **{{quantity}} sesiones** del bono **{{selectPack}}**",
        "patient_trigger_leftXSessionsOfXPack_filter_quantity_buttonText": "X",
        "patient_trigger_leftXSessionsOfXPack_filter_selectPack_buttonText": "X",
        "patient_trigger_worksFinishedAfterXDays_title": "Hace X días que un paciente no accede a un servicio",
        "patient_trigger_worksFinishedAfterXDays_phrase": "Cuando un paciente no realiza **un servicio** desde hace **unos días**",
        "patient_trigger_worksFinishedAfterXDays_phraseWithVars": "Cuando un paciente no realiza el servicio **{{selectTreatment}}** desde hace **{{numberOfDays}} días**",
        "patient_trigger_worksFinishedAfterXDays_filter_numberOfDays_buttonText": "X",
        "patient_trigger_worksFinishedAfterXDays_filter_selectTreatment_buttonText": "X",
        "action_sendMail_phrase": "enviar un email",
        "action_sendMail_phraseWithVars": "enviar un {{email}}",
        "action_sendMail_param_email_buttonText": "email",
        "action_notifClient_phrase": "enviarle un mensaje",
        "action_notifClient_phraseWithVars": "enviar un mensaje",
        "action_notifClient_param_message_buttonText": "notificación",
        "action_addClientToGroup_phrase": "añadir cliente a un grupo",
        "action_addClientToGroup_phraseWithVars": "añadir cliente al grupo **{{selectGroup}}**",
        "action_addClientToGroup_param_selectGroup_buttonText": "grupo",
        "action_removeClientFromGroup_phrase": "quitar cliente de un grupo",
        "action_removeClientFromGroup_phraseWithVars": "quitar cliente del grupo **{{selectGroup}}**",
        "action_removeClientFromGroup_param_selectGroup_buttonText": "grupo",
        "action_addNoteToClient_phrase": "añadir una nota al cliente",
        "action_addNoteToClient_phraseWithVars": "añadir una nota al cliente",
        "action_addNoteToClient_param_addNote_buttonText": "nota",
        "patient_action_sendMail_phrase": "enviar un email",
        "patient_action_sendMail_phraseWithVars": "enviar un {{email}}",
        "patient_action_sendMail_param_email_buttonText": "email",
        "patient_action_notifClient_phrase": "enviarle un mensaje",
        "patient_action_notifClient_phraseWithVars": "enviar un mensaje",
        "patient_action_notifClient_param_message_buttonText": "notificación",
        "patient_action_addClientToGroup_phrase": "añadir paciente a un grupo",
        "patient_action_addClientToGroup_phraseWithVars": "añadir paciente al grupo **{{selectGroup}}**",
        "patient_action_addClientToGroup_param_selectGroup_buttonText": "grupo",
        "patient_action_removeClientFromGroup_phrase": "quitar paciente de un grupo",
        "patient_action_removeClientFromGroup_phraseWithVars": "quitar paciente del grupo **{{selectGroup}}**",
        "patient_action_removeClientFromGroup_param_selectGroup_buttonText": "grupo",
        "patient_action_addNoteToClient_phrase": "añadir una nota al paciente",
        "patient_action_addNoteToClient_phraseWithVars": "añadir una nota al paciente",
        "patient_action_addNoteToClient_param_addNote_buttonText": "nota",
        "rules_label_days": "Número de días",
        "rules_label_discounts": "Bono",
        "rules_label_treatments": "Servicio",
        "rules_label_subscriptions": "Suscripción",
        "rules_label_group": "Grupo",
        "rules_label_email": "Email",
        "rules_label_subject": "Asunto",
        "rules_label_title": "Título",
        "rules_label_message": "Mensaje",
        "rules_label_content": "Mensaje",
        "rules_label_quantity": "Número de sesiones restantes",
        "rules_label_tag": "Tipo de alerta",
        "rules_selectDefaultText_discounts": "Selecciona un bono",
        "rules_selectDefaultText_treatments": "Selecciona un servicio",
        "rules_selectDefaultText_subscriptions": "Selecciona una suscripción",
        "rules_selectDefaultText_courses": "Selecciona un curso",
        "rules_selectDefaultText_group": "Selecciona un grupo",
        "rules_selectDefaultText_tag": "Selecciona un tipo de alerta",
        "Complete todos los campos": "Complete todos los campos",
        "Cualquier bono": "Cualquier bono",
        "Cualquier suscripción": "Cualquier suscripción",
        "Tipo": "Tipo",
        "No hay parámetros que configurar": "No hay parámetros que configurar",
        "Acción": "Acción",
        "Reacción": "Reacción",
        "Modificar tarea": "Modificar tarea",
        "Activar tarea": "Activar tarea",
        "Tareas": "Tareas",
        "Nueva tarea": "Nueva tarea",
        "Tareas automáticas": "Tareas automáticas",
        "Descubre las tareas automáticas": "Descubre las tareas automáticas",
        "Lista de tareas activas": "Lista de tareas activas",
        "Qué es una tarea automática": "Qué es una tarea automática",
        "Activa una nueva tarea": "Activa una nueva tarea",
        "Empieza activando alguna tarea": "Empieza activando alguna tarea",
        "Deshabilitar tarea": "Deshabilitar tarea",
        "Habilitar tarea": "Habilitar tarea",
        "Eliminar tarea": "Eliminar tarea",
        "Tarea modificada con éxito": "Tarea modificada con éxito",
        "Tarea creada con éxito": "Tarea creada con éxito",
        "Ha ocurrido un error al intentar guardar la regla": "Ha ocurrido un error al intentar guardar la tarea",
        "Regla elimada con éxito": "Tarea elimada con éxito",
        "Ha ocurrido un error al eliminar la regla": "Ha ocurrido un error al eliminar la tarea",
        "Deshabilitar": "Deshabilitar",
        "Cancelar": "Cancelar",
        "Eliminar": "Eliminar",
        "Cualquier servicio": "Cualquier servicio",
        "Categoría": "Categoría",
        "rules_label_trigger_clientAbsentsXDays_days": "Días sin ir al negocio",
        "rules_label_trigger_leftXDaysOfXPackExpiration_discounts": "Bono",
        "rules_label_trigger_leftXDaysOfXPackExpiration_days": "Días que faltan para que caduque el bono",
        "rules_label_trigger_leftXDaysOfXSubscriptionExpiration_subscriptions": "Suscripción",
        "rules_label_trigger_leftXDaysOfXSubscriptionExpiration_days": "Días que faltan para que expire la suscripción",
        "rules_label_trigger_leftXSessionsOfXPack_discounts": "Bono",
        "rules_label_trigger_leftXSessionsOfXPack_quantity": "Número de sesiones restantes",
        "rules_label_trigger_worksFinishedAfterXDays_treatments": "Servicio",
        "rules_label_trigger_worksFinishedAfterXDays_days": "Días sin reservar el servicio",
        "rules_label_action_notifClient_title": "Título del mensaje",
        "rules_label_action_notifClient_message": "Contenido del mensaje",
        "rules_label_action_addClientToGroup_group": "Grupo",
        "rules_label_action_removeClientFromGroup_group": "Grupo",
        "rules_label_action_addNoteToClient_message": "Texto de la nota",
        "rules_label_action_addNoteToClient_tag": "Tipo de alerta",
        "Textos": "Textos web y app",
        "automatic_task": "Tareas automáticas",
        "templates_email": "Plantillas de email",
        "Comunication": "Comunicación",
        "Sendings": "Comunicaciones enviadas",
        "Campaings": "Campañas",
        "Cotifications": "Notificaciones",
        "Envios_realizados": "Envíos realizados",
        "trigger_clientAcquiresNewSubscription_title": "Un cliente adquiere una nueva suscripción",
        "trigger_clientAcquiresNewSubscription_phrase": "Cuando un cliente adquiere una **suscripción**",
        "trigger_clientAcquiresNewSubscription_phraseWithVars": "Cuando un cliente adquiere la suscripción **{{selectSubscription}}**",
        "trigger_clientAcquiresNewSubscription_filter_selectSubscription_buttonText": "X",
        "rules_label_trigger_clientAcquiresNewSubscription_subscriptions": "Suscripción",
        "autoconfig_which_autopay": "Hay bonos que tienen la configuración antigua de renovación automática. ¿Quieres aplicar esta nueva configuración a los bonos adquiridos?",
        "si_aplicar": "Si, aplicar",
        "no_aplicar": "No, no aplicar",
        "suscription_not_defined": "Suscripción no definida",
        "web/app": "web y app",
        "menu_online": "Online",
        "menu_comunications": "Comunicaciones",
        "menu_comunications_sent": "Enviadas y programadas",
        "menu_configuracion": "Configuración",
        "configuration_pictures": "Configuración imágenes",
        "menu_finance": "Finanzas",
        "menu_compounds": "Compuestos",
        "menu_base": "Base",
        "schedule_and_booking": "Agendas y reservas",
        "Restaurar email original": "Restaurar email original",
        "WELCOME": "Email de bienvenida cuando se crea un cliente nuevo",
        "RES_CONFIRMATION": "Email de confirmación cuando un cliente hace una reserva de servicios",
        "STARS": "Email para que el cliente califique la satisfacción de la atención recibida",
        "BILL": "Email que se envía al cliente con la factura adjunta",
        "NOTIF_USER_WORK_CANCELLED": " Email para notificar al cliente que la reserva ha sido cancelada",
        "EMPLOYEE_PASSWORD_CREATE": "Email que le llega al empleado para asignar su primera contraseña",
        "NOTIF_WORK_CREATED": "Email para notificar al empleado que se ha creado una reserva en su agenda",
        "COURSE_JOIN": "Email para notificar al cliente que se ha apuntado a un curso correctamente",
        "RES_ADDED_TO_WAITLIST": " Email para notificar al cliente que ha sido añadido a la lista de espera",
        "RES_MOVE_WAITLIST_TO_CLASS": "Email para informar al cliente que ha pasado de lista de espera a lista oficial",
        "BUY_SUBSCRIPTION": "Email para confirmar al cliente la compra de una suscripción",
        "SUBSCRIPTION_WILL_EXPIRE_SOON": "Email para avisarle al cliente que su suscripción vencerá pronto",
        "EVENT_CLASS_JOIN": "Email para notificar al cliente que se ha apuntado a un evento correctamente",
        "EVENT_CLASS_ACTIVITY_REMINDER": "Email para recordar al cliente que el evento se aproxima",
        "PAYORDER": "Email enviado a tu proveedor con la orden de compra que solicitaste",
        "BUY_PACK": "Email para notificar al cliente la compra de un bono",
        "RES_ADDED_TO_CLASS": "Email de confirmación cuando un cliente hace una reserva de clases",
        "CREDIT_CARD_WILL_EXPIRE_SOON": "Email para recordarle al cliente que su tarjeta de crédito expirará pronto",
        "EMPLOYEE_PASSWORD_RECOVERY": "Email para que el empleado pueda reestablecer su contraseña",
        "CLIENT_NEW_LEAD": "Email que llega al administrador informando la inscripción de un nuevo lead",
        "REM_HOURSAGO": "Email que llega al cliente recordando su reserva",
        "BIRTHDAY_TEMPLATE": "Email que le llega al cliente cuando está cumpliendo años",
        "deliveries_made": "Envíos realizados",
        "Sales_processes": "Procesos de venta",
        "Messages_and_campaigns": "Mensajes y campañas",
        "marketing_campaign_help": "Crea plantillas para enviar mensajes o campañas desde la seccion de clientes.",
        "marketing_notification_help": "Puedes personalizar los emails automáticos que se envían en ciertas situaciones.",
        "readed": "Leído",
        "clicked": "Clickado",
        "action_birthday_title": "Cumpleaños de un cliente",
        "action_birthday_phrase": "Cuando es el cumpleaños de un cliente",
        "action_birthday_phraseWithVars": "Cuando es el cumpleaños de un cliente",
        "action_clientAbsentsXDays_title": "Un cliente lleva X días sin aparecer por el centro",
        "action_clientAbsentsXDays_phrase": "Cuando un cliente lleva **unos días** sin ir al negocio",
        "action_clientAbsentsXDays_phraseWithVars": "Cuando un cliente lleva **{{numberOfDays}} días** sin ir al negocio",
        "action_clientAbsentsXDays_filter_numberOfDays_buttonText": "X",
        "action_leftXDaysOfXPackExpiration_title": "Quedan X días para que caduque un bono",
        "action_leftXDaysOfXPackExpiration_phrase": "Cuando faltan **unos días** para que caduque un bono",
        "action_leftXDaysOfXPackExpiration_phraseWithVars": "Cuando faltan **{{numberOfDays}} días** para que caduque el bono **{{selectPack}}**",
        "action_leftXDaysOfXPackExpiration_filter_numberOfDays_buttonText": "X",
        "action_leftXDaysOfXPackExpiration_filter_selectPack_buttonText": "X",
        "action_leftXDaysOfXSubscriptionExpiration_title": "Quedan X días para que caduque una sucripción",
        "action_leftXDaysOfXSubscriptionExpiration_phrase": "Cuando faltan **unos días** para que caduque una suscripción",
        "action_leftXDaysOfXSubscriptionExpiration_phraseWithVars": "Cuando faltan **{{numberOfDays}} días** para que caduque la suscripción **{{selectSubscription}}**",
        "action_leftXDaysOfXSubscriptionExpiration_filter_numberOfDays_buttonText": "X",
        "action_leftXDaysOfXSubscriptionExpiration_filter_selectSubscription_buttonText": "X",
        "action_leftXSessionsOfXPack_title": "Quedan X sesiones de un bono",
        "action_leftXSessionsOfXPack_phrase": "Cuando falten solo **unas sesiones** de un bono",
        "action_leftXSessionsOfXPack_phraseWithVars": "Cuando falten solo **{{quantity}} sesiones** del bono **{{selectPack}}**",
        "action_leftXSessionsOfXPack_filter_quantity_buttonText": "X",
        "action_leftXSessionsOfXPack_filter_selectPack_buttonText": "X",
        "action_worksFinishedAfterXDays_title": "Hace X días que un cliente no accede a un servicio",
        "action_worksFinishedAfterXDays_phrase": "Cuando un cliente no realiza **un servicio** desde hace **unos días**",
        "action_worksFinishedAfterXDays_phraseWithVars": "Cuando un cliente no realiza el servicio **{{selectTreatment}}** desde hace **{{numberOfDays}} días**",
        "action_worksFinishedAfterXDays_filter_numberOfDays_buttonText": "X",
        "action_worksFinishedAfterXDays_filter_selectTreatment_buttonText": "X",
        "rules_label_action_clientAbsentsXDays_days": "Días sin ir al negocio",
        "rules_label_action_leftXDaysOfXPackExpiration_discounts": "Bono",
        "rules_label_action_leftXDaysOfXPackExpiration_days": "Días que faltan para que caduque el bono",
        "rules_label_action_leftXDaysOfXSubscriptionExpiration_subscriptions": "Suscripción",
        "rules_label_action_leftXDaysOfXSubscriptionExpiration_days": "Días que faltan para que expire la suscripción",
        "rules_label_action_leftXSessionsOfXPack_discounts": "Bono",
        "rules_label_action_leftXSessionsOfXPack_quantity": "Número de sesiones restantes",
        "rules_label_action_worksFinishedAfterXDays_treatments": "Servicio",
        "rules_label_action_worksFinishedAfterXDays_days": "Días sin reservar el servicio",
        "action_clientAcquiresNewSubscription_title": "Un cliente adquiere una nueva suscripción",
        "action_clientAcquiresNewSubscription_phrase": "Cuando un cliente adquiere una **suscripción**",
        "action_clientAcquiresNewSubscription_phraseWithVars": "Cuando un cliente adquiere la suscripción **{{selectSubscription}}**",
        "action_clientAcquiresNewSubscription_filter_selectSubscription_buttonText": "X",
        "rules_label_action_clientAcquiresNewSubscription_subscriptions": "Suscripción",
        "action_clientAcquiresNewCourse_title": "Un cliente adquiere un nuevo curso",
        "action_clientAcquiresNewCourse_phrase": "Cuando un cliente adquiere un **curso**",
        "action_clientAcquiresNewCourse_phraseWithVars": "Cuando Un cliente adquiere el curso **{{selectCourse}}**",
        "action_clientAcquiresNewCourse_filter_selectCourse_buttonText": "X",
        "rules_label_action_clientAcquiresNewCourse_courses": "Curso",
        "reaction_sendMail_phrase": "enviar un email",
        "reaction_sendMail_phraseWithVars": "enviar un {{email}}",
        "reaction_sendMail_param_email_buttonText": "email",
        "reaction_notifClient_phrase": "enviarle un mensaje",
        "reaction_notifClient_phraseWithVars": "enviar un mensaje",
        "reaction_notifClient_param_message_buttonText": "notificación",
        "reaction_addClientToGroup_phrase": "añadir cliente a un grupo",
        "reaction_addClientToGroup_phraseWithVars": "añadir cliente al grupo **{{selectGroup}}**",
        "reaction_addClientToGroup_param_selectGroup_buttonText": "grupo",
        "reaction_removeClientFromGroup_phrase": "quitar cliente de un grupo",
        "reaction_removeClientFromGroup_phraseWithVars": "quitar cliente del grupo **{{selectGroup}}**",
        "reaction_removeClientFromGroup_param_selectGroup_buttonText": "grupo",
        "reaction_addNoteToClient_phrase": "añadir una nota al cliente",
        "reaction_addNoteToClient_phraseWithVars": "añadir una nota al cliente",
        "reaction_addNoteToClient_param_addNote_buttonText": "nota",
        "rules_label_reaction_notifClient_title": "Título del mensaje",
        "rules_label_reaction_notifClient_message": "Contenido del mensaje",
        "rules_label_reaction_addClientToGroup_group": "Grupo",
        "rules_label_reaction_removeClientFromGroup_group": "Grupo",
        "rules_label_reaction_addNoteToClient_message": "Texto de la nota",
        "rules_label_reaction_addNoteToClient_tag": "Tipo de alerta",
        "subscription/bonus": "Suscripción/bono",
        "alert_delete_task": "¿Estás seguro de eliminar la tarea? No podrás recuperarla.",
        "alert_disable_task": "Si deshabilitas la tarea, ésta no se ejecutará.",
        "alert_active_task": "Activa la tarea para poder ser usada nuevamente.",
        "action_clientAcquiresEventPass_title": "Un cliente adquiere un nuevo pase de evento",
        "action_clientAcquiresEventPass_phrase": "Cuando un cliente adquiere un **pase de evento**",
        "action_clientAcquiresEventPass_phraseWithVars": "Cuando Un cliente adquiere el pase del evento **{{selectEventClass}}**",
        "action_clientAcquiresEventPass_filter_selectEventClass_buttonText": "X",
        "rules_label_action_clientAcquiresEventPass_eventClasses": "Evento",
        "action_clientCompleteServiceOrClassFirst_title": "Un cliente finaliza su primer **servicio o clase**",
        "action_clientCompleteServiceOrClassFirst_phrase": "Cuando un cliente finaliza su primer **servicio o clase**",
        "action_clientCompleteServiceOrClassFirst_phraseWithVars": "Cuando un cliente finaliza su primer servicio o clase {{selectTreatment}}",
        "action_clientCompleteServiceOrClassFirst_filter_selectTreatment_buttonText": "X",
        "rules_label_action_clientCompleteServiceOrClassFirst_treatments": "Servicio o clase",
        "Cualquier curso": "Cualquier curso",
        "Cualquier evento": "Cualquier evento",
        "rules_selectDefaultText_eventClasses": "Selecciona un evento",
        "rules_selectDefaultText_products": "Selecciona un producto",
        "rules_selectDefaultText_employees": "Selecciona un empleado",
        "Cualquier producto": "Cualquier producto",
        "Cualquier empleado": "Cualquier empleado",
        "action_clientAcquiresNewPack_title": "Un cliente adquiere un nuevo bono",
        "action_clientAcquiresNewPack_phrase": "Cuando un cliente adquiere un **bono**",
        "action_clientAcquiresNewPack_phraseWithVars": "Cuando un cliente adquiere el bono **{{selectPack}}**",
        "action_clientAcquiresNewPack_filter_selectPack_buttonText": "X",
        "rules_label_action_clientAcquiresNewPack_packs": "Bono",
        "action_clientAcquiresNewProduct_title": "Un cliente adquiere un nuevo producto",
        "action_clientAcquiresNewProduct_phrase": "Cuando un cliente adquiere un **producto**",
        "action_clientAcquiresNewProduct_phraseWithVars": "Cuando un cliente adquiere el producto **{{selectProduct}}**",
        "action_clientAcquiresNewProduct_filter_selectProduct_buttonText": "X",
        "rules_label_action_clientAcquiresNewProduct_products": "Producto",
        "action_clientBooksServiceOrClass_title": "Un cliente reserva un servicio o clase",
        "action_clientBooksServiceOrClass_phrase": "Cuando un cliente reserva un **servicio o clase**",
        "action_clientBooksServiceOrClass_phraseWithVars": "Cuando un cliente reserva el servicio o clase **{{selectTreatment}}**",
        "action_clientBooksServiceOrClass_filter_selectTreatment_buttonText": "X",
        "rules_label_action_clientBooksServiceOrClass_treatments": "Servicio o clase",
        "action_clientBooksServiceOrClassFirstTime_title": "Un cliente reserva un servicio o clase por primera vez",
        "action_clientBooksServiceOrClassFirstTime_phrase": "Cuando un cliente reserva un **servicio o clase** por primera vez",
        "action_clientBooksServiceOrClassFirstTime_phraseWithVars": "Cuando un cliente reserva por primera vez el servicio o clase **{{selectTreatment}}**",
        "action_clientBooksServiceOrClassFirstTime_filter_selectTreatment_buttonText": "X",
        "rules_label_action_clientBooksServiceOrClassFirstTime_treatments": "Producto",
        "action_minimumProductStock_title": "Queda un número mínimo de unidades de stock de un producto",
        "action_minimumProductStock_phrase": "Cuando queda **un número** mínimo de unidades de stock de un **producto**",
        "action_minimumProductStock_phraseWithVars": "Cuando quedan como mínimo **{{quantity}}** unidades de stock del producto **{{selectProduct}}**",
        "action_minimumProductStock_filter_quantity_buttonText": "X",
        "rules_label_action_minimumProductStock_quantity": "Cantidad mínima",
        "action_minimumProductStock_filter_selectProduct_buttonText": "X",
        "rules_label_action_minimumProductStock_products": "Producto",
        "reaction_notifEmployee_phrase": "enviar mensaje a un empleado",
        "reaction_notifEmployee_phraseWithVars": "enviar mensaje al empleado **{{selectEmployee}}**",
        "reaction_notifEmployee_param_selectEmployee_buttonText": "X",
        "rules_label_reaction_notifEmployee_employees": "Empleado",
        "rules_label_reaction_notifEmployee_title": "Título del mensaje",
        "rules_label_reaction_notifEmployee_message": "Contenido del mensaje",
        "rules_label_action_clientAcquiresNewPack_discounts": "Bono",
        "Currently this action only works with the operations carried out in web, widget and app": "Actualmente esta acción solo funciona con las operaciones realizadas en web, widget y app",
        "action_clientHasNotWatchedBeweTvForXDays_phrase": "Cuando un cliente no ha visualizado ningún contenido en TV en **unos días**",
        "action_clientHasNotWatchedBeweTvForXDays_phraseWithVars": "Cuando un cliente no ha visualizado ningún contenido en TV en **{{numberOfDays}} días**",
        "action_clientHasNotWatchedBeweTvForXDays_filter_numberOfDays_buttonText": "X",
        "rules_label_action_clientHasNotWatchedBeweTvForXDays_days": "Días sin ver TV",
        "action_clientAttendsServiceOrClassXTimesInXDays_phrase": "Cuando un cliente asiste **varias veces** a **clases/servicios** en un **periodo de tiempo**",
        "action_clientAttendsServiceOrClassXTimesInXDays_phraseWithVars": "Cuando un cliente asiste **{{numberOfTimes}} veces** a **{{selectTreatment}}** en **{{numberOfDays}} día(s)**",
        "action_clientAttendsServiceOrClassXTimesInXDays_filter_selectTreatment_buttonText": "X",
        "action_clientAttendsServiceOrClassXTimesInXDays_filter_numberOfTimes_buttonText": "X",
        "action_clientAttendsServiceOrClassXTimesInXDays_filter_numberOfDays_buttonText": "X",
        "rules_label_action_clientAttendsServiceOrClassXTimesInXDays_treatments": "Servicio/Clase",
        "rules_label_action_clientAttendsServiceOrClassXTimesInXDays_times": "Número de veces que asiste",
        "rules_label_action_clientAttendsServiceOrClassXTimesInXDays_days": "Número de días",
        "action_clientAttendsServiceOrClassXSessionsWithoutFail_phrase": "Cuando el cliente consigue una **\"racha\" de varios servicios/clases** seguidos sin fallar o cancelar",
        "action_clientAttendsServiceOrClassXSessionsWithoutFail_phraseWithVars": "Cuando el cliente consigue una **\"racha\" de {{numberOfTimes}}** **{{selectTreatment}}** seguidos sin fallar o cancelar",
        "action_clientAttendsServiceOrClassXSessionsWithoutFail_filter_selectTreatment_buttonText": "X",
        "action_clientAttendsServiceOrClassXSessionsWithoutFail_filter_numberOfTimes_buttonText": "X",
        "rules_label_action_clientAttendsServiceOrClassXSessionsWithoutFail_treatments": "Servicio/Clase",
        "rules_label_action_clientAttendsServiceOrClassXSessionsWithoutFail_times": "Número de veces que asiste",
        "action_clientSpendsXInXDays_phrase": "Cuando el cliente **gasta una cantidad de dinero** en un **periodo de tiempo**",
        "action_clientSpendsXInXDays_phraseWithVars": "Cuando el cliente gasta **{{quantity}} :csymbol** en un periodo de **{{numberOfDays}} días**",
        "action_clientSpendsXInXDays_filter_quantity_buttonText": "X",
        "action_clientSpendsXInXDays_filter_numberOfDays_buttonText": "X",
        "rules_label_action_clientSpendsXInXDays_quantity": "Cantidad gastada (:csymbol)",
        "rules_label_action_clientSpendsXInXDays_days": "Número de días",
        "pull_template_copy": "copia",
        "no_templates_email": "No usar plantillas de email",
        "rules_selectDefaultText_pullTemplates": "Seleccionar plantilla de email",
        "rules_label_reaction_notifClient_pullTemplates": "Plantilla de email",
        "action_clientActiveXPeriod_title": "Cuando el cliente se mantiene activo en el negocio durante un periodo tiempo",
        "action_clientActiveXPeriod_phrase": "Cuando el **cliente** se mantiene activo en el negocio durante un **periodo de tiempo**",
        "action_clientActiveXPeriod_phraseWithVars": "Cuando el cliente se mantiene activo en el negocio por **{{number}}** **{{selectDatePeriod}}**",
        "rules_label_action_clientActiveXPeriod_number": "Numero de **{{selectDatePeriod}}**",
        "rules__action_clientActiveXPeriod_number": "¿Cuánto tiempo?",
        "rules_selectDefaultText_datePeriod": "Selecciona un tipo",
        "rules_label_action_clientActiveXPeriod_datePeriod": "Tipo de periodo",
        "action_clientActiveXPeriod_filter_selectDatePeriod_buttonText": "X",
        "action_clientAboutToWinFidelity_phrase": "Cuando obtiene un descuento especial para su siguiente compra",
        "action_clientAboutToWinFidelity_phraseWithVars": "Cuando obtiene un descuento especial para su siguiente compra",
        "reaction_sendTemplateEmail_phraseWithVars": "Enviar plantilla de e-mail",
        "reaction_sendTemplateEmail_phrase": "Enviar plantilla de e-mail al cliente",
        "rules_label_reaction_sendTemplateEmail_title": "Título del e-mail",
        "rules_label_reaction_sendTemplateEmail_pullTemplates": "Plantilla de email",
        "action_clientWaitingListXClassXTime_phrase": "Cuando el cliente lleva X clases en X tiempo apuntado a la lista de espera",
        "action_clientWaitingListXClassXTime_phraseWithVars": "Cuando el cliente lleva **{{selectTreatment}}** en **{{numberOfDays}} días** apuntado a la lista de espera",
        "action_clientWaitingListXClassXTime_filter_selectTreatment_buttonText": "X",
        "rules_label_action_clientWaitingListXClassXTime_treatments": "Clase",
        "rules_label_action_clientWaitingListXClassXTime_days": "Número de días",
        "edit_warning_template": "Los cambios almacenados aquí alterarán la plantilla original",
        "action_leftXDaysForCreditCardExpiration_phrase": "Cuando faltan **unos días** para que la tarjeta vinculada caduque",
        "action_leftXDaysForCreditCardExpiration_phraseWithVars": "Cuando faltan **{{numberOfDays}} días** para que la tarjeta vinculada caduque",
        "action_leftXDaysForCreditCardExpiration_filter_numberOfDays_buttonText": "X",
        "rules_label_action_leftXDaysForCreditCardExpiration_days": "Número de días",
        "action_leftXDaysOfXEventStart_phrase": "Cuando faltan **unos días** para el inicio de un evento",
        "action_leftXDaysOfXEventStart_phraseWithVars": "Cuando faltan **{{numberOfDays}} días** para que inicie el evento **{{selectEventClass}}**",
        "action_leftXDaysOfXEventStart_filter_selectEventClass_buttonText": "X",
        "action_leftXDaysOfXEventStart_filter_numberOfDays_buttonText": "X",
        "rules_label_action_leftXDaysOfXEventStart_eventClasses": "Evento",
        "rules_label_action_leftXDaysOfXEventStart_days": "Días para el inicio del evento",
        "photos_local_store": "Fotos de tienda local",
        "locate_business": "Busca tu negocio en el campo de texto. También, puedes arrastrar el punto rojo por el mapa para detallar más las posición.",
        "business_placeholder": "Ingresa el nombre del negocio",
        "business_name": "Nombre del negocio",
        "reviews": "Reseñas",
        "general_information": "Información general",
        "week_schedule": "Horarios de la semana",
        "invalid_phone_number": "El número de teléfono es inválido",
        "invalid_url": "La Url es invalida",
        "maintain_your_business_information": "Mantén la información de tu negocio actualizada y logra que tu audiencia te encuentre en la búsqueda de Google y Google Maps. Personaliza tu perfil con las fotos más recientes de tu negocio y empieza a obtener resultados.",
        "brand_description": "Descripción de la marca",
        "error": "Algo ha salido mal",
        "register_successful": "Completaste tu registro",
        "import_services_products": "Importar servicios y/o productos",
        "product_creation_guide": "Guía de creación del producto",
        "service_creation_guide": "Guía de creación del servicio",
        "required_field": "Campo requerido",
        "save_continue": "Guardar y continuar",
        "enclose_instructions_book": "Adjuntar manual de marca ",
        "google_business_question": "¿Tienes Google Business Profile?",
        "drag_drop": "Arrastra y suelta aquí tus archivos",
        "draft_selection": "Selecciona una platilla que se adapte a tu negocio",
        "keep_website": "¿Deseas mantener la web?",
        "welcome": "¡Bienvenido!",
        "digital_transformation": "¡Aquí inicia tu transformación digital!",
        "we_are_in_charge": "Nos encargaremos de configurar el software y haremos que se adapte a la perfección con las necesidades de tu negocio. ¿Estás listo?",
        "begin": "Comenzar",
        "lets_setup": "¡Vamos a configurar tu cuenta!",
        "company_information": "Información de la empresa",
        "service_setup": "Configuración de servicio",
        "import_service": "Importar servicios",
        "import_product": "Importar productos",
        "brand_information": "Información de la marca",
        "book_first_appointment": "Agenda tu primera cita",
        "book_training": "Agenda tu capacitación",
        "onboarding_analyst": "Analista de Onboarding",
        "marketing_specialist": "Especialista de marketing",
        "enclose_brand_driver": "Adjuntar manual de marca (opcional)",
        "images_limit_exceeded": "No se pueden subir más imágenes",
        "remove": "Quitar",
        "add_turn": "Añadir turno",
        "loading": "Cargando...",
        "error_places": "No hay lugares",
        "enter_location": "Ingresa tu ubicación",
        "benefits_from_using": "Beneficios de usar su servicio o producto",
        "images_size_advise": "Aquí podrás cargar las imágenes que te gustaría incluir en tu sitio web. Recuerda escoger fotografías de buena calidad y composición.",
        "maximum_size": "(El tamaño recomendado es de 722x800 px y la imagen debe pesar máximo a 5MB)",
        "new_opportunities_coming": "¡Tendrás la oportunidad de convertirte en un estratega! En este apartado podrás subir banners promocionales o destacar tus productos y/o servicios estrella.",
        "images_quantity_restriction": "Recuerda que tendrás un máximo de (3) fotografías tipo banner para cargar. (El tamaño recomendado es de 2000x400 px y el banner no debe pasar máximo de 5MB)",
        "section_dynamic": "En esta sección podrás conocer el estado y los avances de tu sitio web. De igual modo, conseguirás dejar tu opinión a través de comentarios en cada apartado de la página.",
        "click_and_begin": "Haz clic en el siguiente enlace y empieza de inmediato:",
        "business_location": "Ubicación del negocio",
        "phone_number": "Teléfono",
        "web_url": "URL Web",
        "enter_domain": "Ingresa tu dominio",
        "instagram_url": "URL de Instagram",
        "enter_instagram_url": "Ingresa tu URL de Instagram",
        "facebook_url": "URL de Facebook",
        "enter_facebook_url": "Ingresa tu URL de Facebook",
        "upload_logo": "Subir logo",
        "select_file": "Seleccionar archivo (2MB)",
        "values": "Valores",
        "colors": "Colores",
        "do_you_have_website": "¿Tienes una página web?",
        "primary_color": "Color primario",
        "entity_name": "Nombre de la empresa",
        "choose_your_business_model": "Escoge tu modelo de negocio",
        "optional_instagram_url": "Url de Instagram (opcional)",
        "optional_facebook_url": "Url de Facebook (opcional)",
        "brand_identity": "Identidad de la marca",
        "my_images": "Mis imágenes",
        "banner": "Banner",
        "aditional_information": "Información adicional",
        "check": "Cheque",
        "financing": "Financiación",
        "invoice_payment": "Depósito bancario",
        "delivery_options": "Opciones de entrega",
        "phonetic_name": "Nombre fonético",
        "neighborhood": "Barrio",
        "keywords": "Palabras claves",
        "languages": "Idiomas",
        "foundation_business": "¿En qué año se fundó tu empresa?",
        "delivery": "Domicilio",
        "in_store_pickup": "Recogida en tienda",
        "pickup_not_offered": "No se ofrece recogida en tienda",
        "no_contact_delivery": "Entrega sin contacto",
        "biosecurity": "¿Cuentas con medidas de bioseguridad?",
        "social_razon": "Razón social",
        "preview": "Previsualizar",
        "book": "Agendar",
        "action_clientAcquiresNewTreatment_title": "Un cliente adquiere un nuevo servicio",
        "action_clientAcquiresNewTreatment_phrase": "Cuando un cliente adquiere un **servicio**",
        "action_clientAcquiresNewTreatment_phraseWithVars": "Cuando un cliente adquiere el servicio **{{selectTreatment}}**",
        "action_clientAcquiresNewTreatment_filter_selectTreatment_buttonText": "X",
        "rules_label_action_clientAcquiresNewTreatment_treatments": "Servicios",
        "days_left_trial_ending": "Te quedan",
        "of_free_trial": "de free trial",
        "free_trial": "Free Trial",
        "timkot_main_title": "Plan actual",
        "available_schedule": "Agendas disponibles",
        "available_schedule_description": "Elige la cantidad de agendas necesarias para que lleves el seguimiento de cada uno de tus empleados",
        "button_add_cart": "Seleccionar",
        "title_app_clients": "Bewe para clientes",
        "qr_name": "código QR",
        "clients_app_desc": "Al hacer clic en el botón de descargar,  podrás obtener el",
        "clients_app_desc2": "para que puedas compartirlo con tus clientes que aún no se han registrado en tu centro.",
        "clients_app_desc3": "Una vez lo hagan, los clientes podrán agendarte desde la",
        "app_for_clients": "App para clientes",
        "download_qr": "Descargar QR",
        "available": "Disponible en",
        "your_digital_transformation_begins_here": "¡Aquí inicia tu transformación digital!",
        "digital_transformation_description": "Nos encargaremos de configurar el software y haremos que se adapte a la perfección con las necesidades de tu negocio. ¿Estás listo?",
        "business_model": "Modelo de negocio",
        "we_are_ready_to_start": "¡Estamos listos para comenzar!",
        "start_configuring": "Empecemos a configurar",
        "initial_business_information": "Información inicial de negocio",
        "configuring_services": "Configuración de servicios, productos e importación de clientes",
        "start_setting_account": "Empecemos a configurar tu cuenta",
        "import_clients": "Importar clientes",
        "initial_information": "Información inicial",
        "information_upload_file": "Ten presente que para importar correctamente los datos, debes subir un archivo que cumpla un formato de columnas exacto.",
        "download_example_corresponding_format": "Descarga un ejemplo de cada formato correspondiente",
        "example_of": "ejemplo de",
        "thank_you_setting_account": "¡Muchas gracias por configurar tu cuenta!",
        "title_app_business": "Bewe para negocios",
        "business_desc": "¡Lleva el control de tu negocio desde cualquier lugar!",
        "business_desc2": "Escanea desde tu móvil el código QR y descarga la aplicación",
        "preview_website": "Previsualiza la plantilla antes de elegir la que se adapte a tu negocio. Recuerda que una vez la selecciones no podrás cambiarla. No te preocupes, tu especialista podrá personalizar la plantilla que elegiste",
        "information_booking_time": "Debes disponer de 60 minutos para reunirte con tu analista de onboarding y especialista de marketing. Recuerda, solo tendrás una oportunidad para elegir el agendamiento. Para re-agendar tu cita comunícate con soporte",
        "button_add_cart_c": "Plan Actual",
        "tmk_btn_start": "Empezar",
        "NOTIF_WORK_CANCELLED": "Email para notificar al empleado que un cliente ha cancelado su reserva",
        "Panel de redes sociales": "Social Media",
        "legal_conditions_url": "URL de los términos y condiciones",
        "enter_legal_url": "Ingresa tu URL de términos y condiciones",
        "action_doActionOnEmployeeEveryXDays_title": "Realizar una acción en un empleado un día del mes",
        "action_doActionOnEmployeeEveryXDays_phrase": "Realizar una acción en un empleado un **día** del mes",
        "action_doActionOnEmployeeEveryXDays_phraseWithVars": "Realizar una acción en un empleado el día **{{dayOfMonth}}** del mes",
        "action_doActionOnEmployeeEveryXDays_filter_dayOfMonth_buttonText": "X",
        "rules_selectDefaultText_days": "Seleccionar un día del mes",
        "rules_label_action_doActionOnEmployeeEveryXDays_days": "Día del mes",
        "rules_label_reaction_sendReportEmployee_employees": "Seleccionar empleado",
        "rules_selectDefaultText_reports": "Seleccionar un informe",
        "rules_label_reaction_sendReportEmployee_reports": "Seleccionar informe",
        "reaction_sendReportEmployee_phraseWithVars": "Enviar mensaje a **{{selectEmployee}}** con el informe **{{selectReport}}**",
        "reaction_sendReportEmployee_phrase": "Enviar mensaje a un empleado",
        "reaction_sendReportEmployee_param_selectEmployee_buttonText": "X",
        "reaction_sendReportEmployee_param_selectReport_buttonText": "X",
        "sended": "Enviado",
        "processed": "Procesado",
        "deferred": "Diferido",
        "delivered": "Entregado",
        "open": "Abierto",
        "click": "Clic",
        "bounce": "Rebotado",
        "dropped": "Descartado",
        "spamreport": "Spam",
        "unsubscribe": "Cancelación de suscripción",
        "group_unsubscribe": "Cancelación de suscripción grupal",
        "group_resubscribe": "Resuscripción grupal",
        "one-panel_title": "Social Media",
        "one-web_title": "Web Editor",
        "one-google_title": "Rankings",
        "one-panel_description": "Refuerza tu reputación online con un panel multifuncional que te permite analizar y monitorear las métricas de tus redes sociales en un solo lugar.",
        "one-web_description": "Obtendrás una web profesional con tu propio dominio que te ayudará en el posicionamiento de tu negocio. ",
        "one-google_description": "Te ayudamos a crear y configurar tu ficha de Google para que te posiciones en los primeros lugares del buscador y tus clientes te encuentren fácilmente.",
        "rules_label_reaction_sendReportEmployee_message": "Contenido del mensaje",
        "action_sendingInvoicesInXPeriodOfTime_title": "Enviar facturas por correo electrónico semanal, quincenal o mensualmente",
        "action_sendingInvoicesInXPeriodOfTime_phrase": "Enviar facturas por correo electrónico **semanal, quincenal o mensualmente**",
        "action_sendingInvoicesInXPeriodOfTime_phraseWithVars": "Enviar facturas por correo electrónico con periodicidad **{{selectPeriodOfTime}}**",
        "action_sendingInvoicesInXPeriodOfTime_filter_selectPeriodOfTime_buttonText": "X",
        "rules_selectDefaultText_periodOfTime": "Seleccionar un periodo de tiempo",
        "rules_label_action_sendingInvoicesInXPeriodOfTime_periodOfTime": "Periodo de tiempo (desde que se activa la tarea)",
        "reaction_sendInvoicesEmail_phraseWithVars": "A los correos electrónicos inscritos en la configuración y a continuación",
        "rules_label_reaction_sendInvoicesEmail_emailList": "Se enviará a los correos configurados en Configuración > Procesos de venta y a los que se agregan en el listado (separados por coma)",
        "reaction_sendInvoicesEmail_phrase": "Enviar a través de correo electrónico",
        "weekly": "Semanal",
        "biweekly": "Quincenal",
        "monthly": "Mensual",
        "app_for_business": "App para negocios",
        "reaction_sendTemplateEmailEmployee_phraseWithVars": "Enviar plantilla de e-mail a empleado",
        "reaction_sendTemplateEmailEmployee_phrase": "Enviar plantilla de e-mail a empleado",
        "rules_label_reaction_sendTemplateEmailEmployee_title": "Título del e-mail",
        "rules_label_reaction_sendTemplateEmailEmployee_pullTemplates": "Plantilla de email",
        "rules_label_reaction_sendTemplateEmailEmployee_employees": "Empleado",
        "CHECKOUT_SUMMARY_ADMIN": "Email que llega al administrador informando una nueva compra en línea",
        "CHECKOUT_SUMMARY": "Email para notificar al cliente una compra en línea",
        "title_app_personalized": "App personalizada",
        "qr_name_personalized": "código QR",
        "personalized_app_desc": "Al hacer clic en el botón de descargar,  podrás obtener el",
        "personalized_app_desc2": "para que puedas compartirlo con tus clientes que aún no se han registrado en tu centro.",
        "personalized_app_desc3": " Una vez lo hagan, los usuarios podrán agendarte desde tú",
        "app_personalized": "App nombre del centro.",
        "invalid_name_template_email": "El nombre no debe contener tildes ni caracteres especiales como la letra 'ñ' o signos de puntuación y no puede ser repetido. Por favor ingrese un nombre válido.",
        "google_business_profile": "Rankings",
        "text_button_yext": "Haz clic en el siguiente botón y previsualizar métricas relevantes",
        "short_type_tasks_description": "Se enviarán 4 veces al día en intervalos de 4 horas",
        "daily_tasks_description": "Se ejecutan una vez al día.",
        "inmediatly_task_description": "En el momento que se ejecuta la acción que provoca que la tarea salte.",
        "contact_adn": "Contáctanos",
        "rrss_adn": "Síguenos en redes sociales",
        "hiring_adn": "a tu contratación",
        "add_adn": "Agrega productos o funcionalidades ",
        "Adn": "ADN",
        "description_adn1": "Para cambiar tu paquete ADN y ",
        "description_adn2": "ampliar sus funcionalidades comunícate al ",
        "phone_adn": "800 202 7275",
        "description_adn3": "Ten a la mano tu número de cliente ADN",
        "page": "Página",
        "from": "de",
        "Stripe": "Stripe",
        "text_stripe": "Si deseas saber cómo cobrar cuando se tiene una tarjeta vinculada o cómo puedes vincularla en la ficha de tus clientes",
        "button1_stripe": "Consúltalo aquí",
        "button2_stripe": "Pasarela Configurada",
        "go_editor": "Ir a mi editor",
        "account_not_linked": "Tu cuenta aún no está vinculada",
        "Bewe one": "Marketing online",
        "Web Premium One": "Web Editor",
        "Google Business Profile": "Rankings",
        "text_stripe1": "¡Importante! Recuerda configurar los precios de tus ítems con un mínimo de 1 dólar (de acuerdo a tu moneda) para asegurar el correcto procesamiento del pago. Si lo que deseas es desvincular Stripe.",
        "text_stripe2": "¿Quieres hacer cambio de tu clave? Ponte en contacto con el chat del servicio al cliente. Recuerda que si haces el cambió, puedes perder tus tarjetas ya guardadas.",
        "contact_stripe": "Consúltalo aquí",
        "text_description_yext": "Panel de posicionamiento",
        "AÑO_NUEVO": "Año Nuevo",
        "BLACK_FRIDAY": "Black Friday",
        "CYBER_LUNES": "Cyber Lunes",
        "DIA_DEL_PADRE": "Dia Del Padre",
        "DIA_DEL_PELUQUERO": "Dia Del Peluquero",
        "DIA_DE_PILATES": "Dia De Pilates",
        "DIA_INTERNACIONAL_DEL_FITNESS": "Dia Internacional Del Fitness",
        "DIA_INTERNACIONAL_DEL_YOGA": "Dia Internacional Del Yoga",
        "DIA_INTERNACIONAL_DE_LA_DANZA": "Dia Internacional De La Danza",
        "DÍA_DEL_HOMBRE": "Dia Del Hombre",
        "DÍA_DEL_NIÑO": "Dia Del Niño",
        "DÍA_DEL_TRABAJO": "Dia Del Trabajo",
        "DÍA_DE_LA_MADRE": "Dia De La Madre",
        "DÍA_DE_LA_MUJER": "Dia De La Mujer",
        "DÍA_DE_SAN_PATRICIO": "Dia De San Patricio",
        "HALLOWEEN": "Halloween",
        "NAVIDAD": "Navidad",
        "SAN_VALENTIN": "San Valentin",
        "text_description_load_yext": "Haz clic en el botón y asegurate de que la información básica esté completa",
        "text_button_load_yext": "Sincronización en progreso...",
        "synchronization_progress": "Sincronización en progreso...",
        "text_copy_url_notification": "Copiado",
        "err_put_name_disc_hour": "La hora de inicio debe ser menor a la hora de finalización",
        "err_put_name_disc_date": "La fecha de inicio debe ser menor a la fecha de finalización",
        "USER_CENTER_PASSWORD_RECOVER": "Email que se envía al cliente para restablecer su contraseña",
        "RES_PRECONFIRMATION_DECISION": "Email que se envía al cliente para confirmar la recepción de su solicitud.",
        "RECOVERY_PASSWORD_BEWE_PARA_CLIENTES": "Email que se envía al cliente para restablecer su contraseña",
        "NOTIF_BASIC": "Notificación básica",
        "RES_PRECONFIRMATION_DECISION_ACEPTED": "Email que se envía para notificar al cliente que su reserva está confirmada",
        "RES_PRECONFIRMATION_DECISION_CANCELLED": "Email que se envía al cliente para notificar que su reserva ha sido denegada",
        "CLIENT_NOTIF_WORK_INSCRIPTION": "Email que se envía al cliente para confirmar la recepción de su solicitud.",
        "NOTIF_USER_WORK_CANCELLED_LATE": "Email para notificar al cliente que la reserva ha sido cancelada tarde",
        "text_validation_yext": "Debes validar la categoría y la dirección en la configuración",
        "bewe_academy": "Academy",
        "schedules": "Programados",
        "automatics": "Automáticos",
        "type_sending": "Tipo de envío",
        "_push": "Notificaciones push",
        "_email": "Emails",
        "_sms": "SMS",
        "last_30_days": "Últimos 30 días",
        "_sendings": "Envíos (BETA)",
        "emails_available": "Email disponibles para campañas",
        "create_sending": "Crear envío",
        "deferred_send": "Rechazado",
        "view_campaign": "Ver Campaña",
        "view_message": "Ver Mensaje",
        "new_sending": "Nuevo Envío",
        "sending_done": "Envío realizado",
        "onepage-special_title": "Bewe editor",
        "domain_title": "Dominio personalizado",
        "more_information": "Más información",
        "basic_plan_added": "Se ha añadido el plan básico",
        "personalize_your_plan": "Personaliza tu plan ideal",
        "basic-customizable_title": "Empleados adicionales",
        "basic-customizable_description": "Selecciona la cantidad de Empleados adicionales que necesitas para gestionar más reservas de clientes de manera eficiente. ¡Optimiza tu capacidad y asegura una gestión sin complicaciones!",
        "basic_plan_includes_employees": "Tu plan básico incluye 2 Empleados",
        "basic_website": "Página web básica",
        "basic_reports": "Informes básicos",
        "sending_notifications_by_mail": "Envíos de notificaciones por mail",
        "customers_management_and_follow": "Clientes (gestión y seguimiento)",
        "custom_web_editor": "Editor web personalizado",
        "recruitment_dashboard": "Dashboard de captación",
        "generate_credibility_and_trust_in_your_customers": "Genera credibilidad y confianza en tus clientes",
        "better_visibility_in_search_engines": "Mejor visibilidad en motores de búsqueda",
        "positioning_in_different_search_engines": "Posicionamiento en diferentes buscadores",
        "connections_with_platforms": "Conexiones con plataformas como Waze, Bing, entre otras",
        "detailed_analysis_indicators": "Análisis detallado de indicadores",
        "strategic_content_planning": "Planificación estratégica de contenido",
        "app_android": "App para Android (Play Store)",
        "app_ios": "App para iOs (App Store)",
        "tooltip_message_basic_customizable": "Cada empleado puede tener una agenda para reservas de clientes incluida en este costo",
        "tooltip_message_domain": "Añade un dominio personalizado si tienes Bewe editor. *El pago del dominio se realizar una vez al año",
        "schedule_your_appointment": "Agenda tu cita",
        "alert_delete_schedules": "¿Desea eliminar el mensaje programado? Esta acción no se puede deshacer",
        "discount_limited_time": "Descuento por tiempo limitado",
        "get_your_plan_now": "Adquiere tu plan ahora",
        "schedule_a_demo": "Agenda una demo",
        "message_popup_trial_pricing": "Selecciona las funcionalidades que mejor se adaptan a tus necesidades y la periodicidad que te resulte más conveniente.",
        "message_popup_discount_trial": "¡Tienes un increíble descuento esperándote!",
        "message_popup_discount_trial_pricing": "¡Es momento de disfrutar al máximo! Ahora puedes beneficiarte de un fabuloso descuento.",
        "offer_limited_time": "Aprovecha esta oferta exclusiva por tiempo limitado.",
        "trial_days": "Te quedan {days} días de prueba",
        "message_popup_trial": "Te quedan {days} días de prueba en tu cuenta gratuita, no dudes en dar el salto",
        "onepage_title": "Bewe Editor",
        "onepage_description": "Editor web personalizado",
        "error_sso": "No podemos acceder a su panel de control. Comuníquese con nosotros a través del chat u otros canales de comunicación.",
        "err_put_date_disc": "Tienes que ponerle una fecha de inicio y una fecha de finalización.",
        "yext_integration_incomplete": "Integracion Rankings incompleta, verifica tu información",
        "enjoy_20%_off_now": "¡Disfruta hasta 20% OFF ahora!",
        "take_advantage_15%_off": "¡Aprovecha hasta un 15% OFF!",
        "up_10%_discount": "¡Hasta 10% de descuento!",
        "payment_without_savings": "Pago sin ahorros",
        "cancel_your_free_trial": "Anular tu prueba gratuita",
        "reprogrammed": "Reprogramado",
        "_unsubscribe": "Darse de baja",
        "declined": "Rechazado",
        "sends": "Enviados",
        "tool_click": "Clic a un enlace",
        "tool_delivered": "Correo enviado con éxito (aún sin confirmar recepción en la bandeja del cliente).",
        "tool_deferred": "Intentando entregar mensaje.",
        "tool_processed": "Preparando para ser enviado.",
        "tool_bounce": "No se aceptó el mensaje permanentemente.",
        "tool_spamreport": "Se marcó el mensaje como spam.",
        "tool_dropped": "El mail no fue enviado.",
        "tooltip_click": "El usuario abrió el correo y dió clic dentro del mail o en un enlace.",
        "tool_open": "Tu cliente ha recibido el correo y lo ha abierto.",
        "tool_unsubscribe": "Tu cliente canceló la suscripción al correo.",
        "WELCOME_CENTER": "Email de bienvenida nuevo centro",
        "UPSELLING_CENTER": "Email de solicitud upselling",
        "exceeded_campaign_sending": "Superaste tu límite de envíos de campañas",
        "text_addons": "¡Tranquilo! Amplía la cantidad emails en un clic",
        "activate_addons": "Activar Addons",
        "addons_confirmation_buy": "¿Estás seguro de agregar estos emails?",
        "empty_addons": "El centro no tiene emails configurados",
        "recurrent_base_tool": "Emails configurados y actualizados mensualmente",
        "title_activate_addons": "Emails de la Cuenta",
        "recurrent_base": "La base recurrente es de:",
        "extra_emails_tool": "Compra adicional de emails",
        "available_addons_tool": "Cantidad de mails disponibles, incluidos base recurrente y extra.",
        "_available": "Disponibles",
        "_extras": "Extras",
        "next_payment_will": "Tu próximo pago será el",
        "periodicity": "periodicidad",
        "active_your_subscription": "¡Activo en tu suscripción!",
        "update_frequency_your_plan": "Actualiza la periodicidad de tu plan",
        "get_best_price": "Obtén el mejor precio: Actualiza tu Periodicidad!",
        "your_current_frequency": "Tu periodicidad actual es:",
        "description_modal_interval": "Ahorra más y cambia tu periodicidad! Descubre opciones exclusivas para elevar tu plan.",
        "change_trimestral": "Cambia a Trimestral",
        "description_change_trimestral": "Cambia tu facturación a trimestral y ahorra más por mes.",
        "change_semestral": "Cambia a Semestral, obtén mejores precios",
        "description_change_semestral": "Cambia tu facturación a semestral y obtén descuentos.",
        "change_year": "¡Ahorra más al cambiar a Anual!",
        "description_change_year": "Cambia tu facturación a anual y obtén descuentos.",
        "best_price": "Mejor precio",
        "current_discount:": "Descuento actual",
        "if_you_default": "*En caso de entrar en mora, perderás tu porcentaje de descuento.",
        "message_discount_upgrade": "Tienes un descuento por tu periodicidad actual, además de un {value_discount_vendor}% por descuento comercial",
        "periodicity_discount": "Descuento por periodicidad",
        "additional_discount": "Descuento adicional",
        "update_description": "Se realizará un cobro de manera inmediata de la diferencia hasta tu siguiente pago de <b>{proration}</b> y a partir del",
        "additional-agendas_title": "Agendas adicionales",
        "everything_need_manage_business": "Todo lo necesario para gestionar tu negocio",
        "create_and_edit_website": "Crea y edita tu sitio web",
        "next_payment": "próximo pago",
        "custom_domain_payment_annual": "El pago de Dominio personalizado es anual. Próximo pago: {nextPayDate}",
        "you_plan": "Tu plan",
        "you_payment": "Tu pago",
        "interval_change_title": "Cambio de periodicidad",
        "discount_previous_payment": "Descuento por pago anterior -{discount}",
        "description_change_interval": "Tu suscripción se actualizará de inmediato a una periodicidad {interval}. Te cobraremos <b>{proration}</b> por los {months} meses siguientes de las funcionalidades añadidas, menos un descuento por tu pago anterior.",
        "update_desc_interval": "A partir del {nextPayDate} pagarás el precio actualizado.",
        "discount_vendor_previous_payment": "Descuento comercial por pago anterior -{discount}",
        "message_discount_value_upgrade": "Tienes un descuento por tu periodicidad actual, además de un  descuento comercial por {value_discount_vendor}",
        "checkout_extra_employees": "{{num_employees}} empleado(s)",
        "unlimited_counter": "Ilimitadas",
        "multipage_title": "Bewe editor"
};
});