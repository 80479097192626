import React, { useRef } from 'react';
import { ReactModal, Button } from 'buwi';
import { useParams } from 'react-router-dom';
import useTemplatesStore from '../store';
import { FormControl } from '../../Reports/components/styles';

const buttons = () => {
  return (
    <a className="close">
      <span className="icon-user2" />
    </a>
  );
};

function ModalEdit() {
  const formRef = useRef(null);
  const { namespace } = useParams();
  const { effects, isOpenModalInfo, selectedTemplate } = useTemplatesStore();

  const handleSave = (e) => {
    e.preventDefault();
    const data = new FormData(formRef.current);
    const values = [...data.keys()].reduce((initial, key) => {
      return { ...initial, [key]: data.get(key) };
    }, {});

    effects.saveTemplate(values);
  };

  return (
    <ReactModal
      buttons={buttons()}
      isOpen={isOpenModalInfo}
      position="right"
      onClose={() => effects.closeTemplate()}
      title={namespace ? String(
        `${namespace} (${selectedTemplate.language})`,
      ).toUpperCase(): 'Nueva plantilla'}
    >
      <form style={{ padding: 15 }} ref={formRef} onSubmit={handleSave}>
        {!namespace && (
          <FormControl>
            <label className="f5">Namespace</label>
            <input
              name="namespace"
              type="text"
              className="pa2 f5"
              defaultValue={selectedTemplate.namespace}
            />
          </FormControl>
        )}
        <FormControl>
          <label className="f5">Asunto</label>
          <input
            name="emailTitle"
            type="text"
            className="pa2 f5"
            defaultValue={selectedTemplate.emailTitle}
          />
        </FormControl>
        <FormControl>
          <label className="f5">Idioma</label>
          <input
            name="language"
            className="pa2 f5"
            type="text"
            defaultValue={String(selectedTemplate.language).toUpperCase()}
          />
        </FormControl>
        {console.log('selectedTemplate', selectedTemplate)}
        {console.log('type', selectedTemplate.type)}
        <FormControl>
          <label className="f5">Tipo</label>
          <select name="type" className="pa2 f5">

            <option selected={selectedTemplate.type === 'notification' || !selectedTemplate.type } value="notification">Notificación</option>
            <option selected={selectedTemplate.type === 'campaign'} value="campaign">Campaña</option>
          </select>
        </FormControl>
        <FormControl>
          <label className="f5">Interna</label>
          <select name="hidden" className="pa2 f5" style={{minWidth: 121}}>
            <option selected={selectedTemplate.hidden === false || !selectedTemplate.hidden } value="false">No</option>
            <option selected={selectedTemplate.hidden === true} value="true">Sí</option>
          </select>
        </FormControl>
        <FormControl>
          <label className="f5">EmailDesign</label>
          <input
            name="emailDesign"
            className="pa2 f5"
            type="text"
            defaultValue={JSON.stringify(selectedTemplate.emailDesign || {})}
          />
        </FormControl>
        <Button title="Guardar">Guardar</Button>
      </form>
    </ReactModal>
  );
}

export default ModalEdit;
