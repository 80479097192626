const hiddenTimespan = {
  day: 'Día',
  week: 'Semana',
  month: 'Mes',
  year: 'Año',
  range: 'Rango de fechas',
};

const keys = Object.keys(hiddenTimespan);
export default keys.map((value) => ({
  label: hiddenTimespan[value],
  value,
}));
