const accountTypes = {
  all: 'Todos los centros',
  beautyStore: 'Todos los Beauty',
  noBeautyStore: 'Todos los NO Beauty',
  wellness: 'Todos los Wellness',
  noWellness: 'Todos los NO Wellness',
  custom: 'Centros en específico',
};

const keys = Object.keys(accountTypes);
export default keys.map((value) => ({
  label: accountTypes[value],
  value,
}));
