import Blockly from '../blockly/override';
import { collections } from './utils';
Blockly.Msg['LISTS_CREATE_EMPTY_TITLE'] = ''
Blockly.Msg['LISTS_CREATE_WITH_INPUT_WITH'] = ''


Blockly.Blocks.aggregate = {
    init() {
        this.appendDummyInput()
            .appendField(new Blockly.FieldLabelSerializable("aggregate"), "aggregate")
            .appendField(
                new Blockly.FieldDropdown(collections.sort().map((item) => [item, item])),
                'collection',
            )
        this.appendStatementInput("aggregatePipeline")
            .setCheck(['aggregate-logic'])

        this.setPreviousStatement(true, 'Aggregate');
        this.setNextStatement(true, 'Array');
        this.setColour(20);
    },
};


Blockly.Blocks['jsonStart'] = {
    init: function () {
        this.setColour(250);
        this.appendDummyInput()
            .appendField("JSON")
        this.appendDummyInput()
            .appendField("debug")
            .appendField(new Blockly.FieldCheckbox("FALSE"), "debug_checkbox");    

        this.appendValueInput('json')
            .setCheck("json")
        this.setPreviousStatement(true, 'aggregate-logic');
    }
};


Blockly.Blocks['jsonTrue'] = {
    init: function () {
        this.setColour(20);
        this.setOutput(true, ["element"]);

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField('true');
    }
};


Blockly.Blocks['jsonFalse'] = {
    init: function () {
        this.setColour(20);
        this.setOutput(true, ["element"]);

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField('false');
    }
};

Blockly.Blocks['jsonSpace'] = {
    init: function () {
        this.setColour(20);
        this.setOutput(true, ["element"]);

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField('space');
    }
};



Blockly.Blocks['jsonString'] = {
    init: function () {
        this.setColour(190);
        this.setOutput(true, ["element"]);

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField('"')
            .appendField(new Blockly.FieldTextInput(''), 'string_value')
            .appendField('"');
    }
};

Blockly.Blocks['jsonObjectId'] = {
    init: function () {
        this.setColour(190);
        this.setOutput(true, ["element"]);

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField('ObjectId')
            .appendField(new Blockly.FieldTextInput(''), 'objectid_value');
    }
};

Blockly.Blocks['jsonNumber'] = {
    init: function () {
        this.setColour(210);
        this.setOutput(true, ["element"]);

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField('№')
            .appendField(new Blockly.FieldTextInput('0', Blockly.FieldTextInput.numberValidator), "number_value");
    }
};

Blockly.Blocks['jsonDictionary'] = {
    length: 0,
    init: function () {
        
        this.setColour(120);
        this.setOutput(true, ["json"]);

        this.appendDummyInput('EMPTY')
        
        this.appendDummyInput('JSON')
        .appendField('JSON', 'JSON')

        this.setInputsInline(false);
        this.jsonInit({ "mutator": "array_json_create_with_mutator" })
    },
};


Blockly.Blocks['jsonArray'] = {
    length: 0,
    init: function () {

        
        this.setColour(350);
        this.setOutput(true, ["json"]);
        this.appendDummyInput('EMPTY')

        this.appendDummyInput('Array')
        .appendField('Array')

        this.setInputsInline(false);
        this.jsonInit({"mutator": "array_json_create_with_mutator"})
    }
};

Blockly.Blocks.json = {
    init() {
        this.appendDummyInput()
            .appendField("JSON")
            .appendField(new Blockly.FieldTextInput(''), 'json_key')
            .appendField(' : ')
        this.appendStatementInput("aggregatePipeline")
            .setCheck(['aggregate-logic'])

        this.setPreviousStatement(true, 'aggregate-logic');
        this.setNextStatement(true, 'aggregate-logic');
        this.setColour(40);
    }
}

Blockly.Blocks.jsonValue = {

    init: function () {
        this.appendValueInput("jsonValue")
            .setCheck('aggregateKey')
            .appendField(new Blockly.FieldTextInput(""), "json_key")
            .appendField(" :");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(60);
    }
}

Blockly.Blocks.array = {
    init() {
        this.appendDummyInput()
            .appendField("Array")
            .appendField(' : ')
        this.appendStatementInput("aggregatePipeline")
            .setCheck(['aggregate-logic'])

        this.setPreviousStatement(true, 'aggregate-logic');
        this.setNextStatement(true, 'aggregate-logic');
        this.setColour(40);
    }
}

Blockly.Blocks.arrayValueNumber = {
    init() {
        this.appendDummyInput()
            .appendField('Number')
            .appendField(new Blockly.FieldTextInput(''), 'field')
        this.setOutput(true, 'aggregateKey');
        this.setInputsInline(false);
        this.setColour(60);
    },
};

Blockly.Blocks.arrayValueString = {
    init() {
        this.appendDummyInput()
            .appendField('String')
            .appendField(new Blockly.FieldTextInput(''), 'field')
        this.setOutput(true, 'aggregateKey');
        this.setInputsInline(false);
        this.setColour(60);
    },
};

Blockly.Blocks.arrayValueAttribute = {
    init() {
        this.appendValueInput('value').setCheck('aggregateKey');
        this.setInputsInline(true);
        this.setPreviousStatement(true, ['aggregate-logic']);
        this.setNextStatement(true, 'aggregate-logic');
        this.setColour(60);
        this.setTooltip('');
        this.setHelpUrl('');
    },
};
