import React, { useEffect } from 'react';
import { Table, Topbar } from 'buwi';
import { i18n } from 'common/reports/libs/i18n';
import { useHistory } from 'react-router-dom';
import useTemplatesStore from './store';
import ModalEdit from './components/modalEdit';

const columnList = [
  {
    field: 'namespace',
    label: 'Nombre',
  },
  {
    field: 'languages',
    label: 'Idiomas',
    format: langs => langs.join(', ') 
  }
];

function EmailTemplates() {
  const { effects, templates = [] } = useTemplatesStore();
  const history = useHistory();

  const buttonsRight = [
    {
      text: `Nueva plantilla`,
      func: () => effects.openEditModal({ namespace: '', language: '' }),
      icon: 'icon-plus',
    },
  ];

  const handleClick = row => {
      history.push('email-template/' + String(row.namespace).toLowerCase())
  }

  useEffect(() => {
    effects.loadTemplates();
  }, []);

  return (
    <div style={{ padding: '0 1rem' }}>
      <Topbar
        type="detail"
        placeholder="Buscar por nombre o descripción"
        buttonsRight={buttonsRight}
        keys={['i18n.es.name', 'i18n.es.description']}
      />
      <Table
        i18n={i18n}
        title="Plantillas email"
        onClick={handleClick}
        columns={columnList}
        rows={templates}
        withCheck={false}
        paginatorContent={({ totalPages, currentPage, length }) => (
          <span>
            {'Pagina'} {currentPage} {'de'} {totalPages} ( {length}{' '}
            {'registros'} )
          </span>
        )}
      />
      <ModalEdit />
    </div>
  );
}

export default EmailTemplates;
