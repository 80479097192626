define([], function() {
    return {
        "current_week": "Semana actual",
        "current_month": "Mes actual",
        "caja": "Caja",
        "main_caja": "Caja principal",
        "current_caja": "Caja actual",
        "caja_closed": "Caja cerrada",
        "every_caja": "Cualquier caja",
        "close_caja": "Cerrar caja",
        "every_way": "Cualquier forma",
        "efectivo": "Efectivo",
        "in_cash": "En efectivo",
        "in_card": "Con tarjeta",
        "in_mix": "Mixto",
        "in_prepaid": "Con saldo",
        "bill": "Factura",
        "receipt": "Recibo",
        "billed": "Facturado",
        "continue": "Continuar",
        "range_days": "Rango de días",
        "init_day": "Escoge día de inicio ",
        "fi_day": "Escoge día de finalización",
        "councash_title": "Arqueo de caja",
        "coins": "Monedas",
        "papermoney": "Billetes",
        "money_in_coins": "Dinero en monedas",
        "money_in_paper": "Dinero en billetes",
        "bc_title": "Configuración de facturas",
        "bc_desc": "Aquí puedes configurar los datos para emitir las facturas simplificadas  del centro y otras opciones de personalización para adaptarse mejor a tus necesidades.",
        "bc_title_2": "Información básica de facturación",
        "bc_desc_2": "Configura el nombre (si eres autónomo, tu nombre y apellidos, o la denominación social ) y el NIF.",
        "bc_title_3": "Recibo y/o Factura simplificada",
        "bc_desc_3": "A la hora de cobrar a un cliente, el último paso es entregarle un comprobante de compra. Actualmente, para la mayoría de los servicios, se debe expedir una factura simplificada. Aún así, seguimos dando la opción de imprimir un recibo clásico. Elige a continuación las opciones que se mostrarán al finalizar cada cobro:",
        "bc_3_item_1": "Mostrar la opción de imprimir facturas simplificada y también la opción de recibo clásico",
        "bc_3_item_2": "Mostrar sólo la opción de imprimir factura simplificada",
        "bc_3_item_3": "Mostrar sólo la opción de imprimir recibo clásico",
        "bc_3_item_4": "No mostrar ninguna opción",
        "bc_title_4": "Emitir facturas simplificadas automáticamente",
        "bc_desc_4": "Por defecto no se emite una factura simplificada, a menos que la imprimas al finalizar un cobro. Habilita esta opción si quieres que automáticamente se emitan las facturas al finalizar cada cobro.",
        "bc_4_item_1": "Sí, quiero que la factura simplificada se emita automáticamente al finalizar el cobro.",
        "bc_title_5": "Tipos de personal",
        "bc_desc_5": "Cuando estás realizando un cobro, puedes asignar personal a cada servicio. Esto luego puede ser muy útil para calcular comisiones y tener un mejor control estadístico.",
        "bc5_placeholder": "Nuevo tipo de personal...",
        "cbr_title": "Cierre de caja",
        "cbr_desc_2": "En esta sección puedes cerrar la caja e imprimir un ticket con el resumen.",
        "cbr_desc_2_1": "En la tabla que aparece a continuación saldrán los números de caja desde el último cierre de caja.",
        "cbr_desc_2_2": "Como de momento nunca has cerrado la caja, aparecen únicamente los números de caja del día.",
        "cbr_desc_2_3": "Puedes cerrar la caja cada dia, cada semana, o en el acumulado de días que quieras.",
        "cbr_question_count": "¿Cuánto dinero hay en el cajón?",
        "cbr_question_extract": "¿Cuánto dinero vas a sacar del cajón?",
        "cbr_movement_since": "Movimientos y facturación del día",
        "cbr_no_mov": "No hay nuevos movimientos",
        "init_drawer": "Inicio en cajón",
        "mov_drawer": "Movimientos en cajón",
        "cash_name": "Nombre de la caja",
        "closing_number": "Número de cierre",
        "closing_date": "Fecha de cierre",
        "closing_hour": "Hora de cierre",
        "charged_in_cash": "Cobros en efectivo",
        "charged_in_card": "Cobros con tarjeta",
        "total_taxes": "Impuestos",
        "total_charges": "Cargos totales",
        "total_expenses": "Gastos totales",
        "init_saldo": "Saldo del cajón al cierre",
        "extract_drawer": "Extraído del cajón",
        "final_drawer": "Saldo del cajón cerrado",
        "caja_closed_when": "Caja cerrada el",
        "cbs_title": "Estadísticas sobre productos y servicios",
        "cbs_new_stat": "Crear nueva estadística",
        "cccae_title": "No hay clientes con esas coincidencias",
        "search_client_to_charge": "Buscar el cliente a cobrar...",
        "charge_waiting": "Ver cobros en espera",
        "continue_charging": "Continuar con el cobro",
        "expenses": "Gastos",
        "select_expenses": "Seleccionar gastos",
        "add_new_expense": "Añadir nuevo gasto",
        "puntual": "Puntual",
        "fix_periodic": "Periódico fijo",
        "delete_expense": "Eliminar gasto",
        "name_puntual": "Nombre específico del gasto ",
        "new_expense": "Nuevo gasto",
        "name_fix_periodic": "Nombre del gasto periódico fijo ",
        "ce_remove_q": "¿Quieres eliminar este gasto periódico?",
        "maintain_already_exp": "Manteniendo los gastos realizados",
        "erasing_already_exp": "Borrando los gastos realizados",
        "name_var_periodic": "Nombre del gasto periódico variable ",
        "back_to_prev_step": "Volver al paso anterior",
        "client_pay": "El cliente paga",
        "client_debt": "El cliente deja a deber",
        "client_prepaid": "Paga con saldo",
        "cf_how_way": "¿Cómo se efectúa el cobro?",
        "cf_how_way_mix": "¿Cómo se efectúa parte del cobro?",
        "pending_mix": "Queda pendiente",
        "cf_which_cash": "¿En qué caja quieres registrar el cobro?",
        "cf_finish": "Finalizar cobro",
        "cf_to_charge": "A cobrar",
        "client_pay_with": "El cliente paga con",
        "cf_return": "Hay que devolverle",
        "cf_done": "Cobro finalizado",
        "cf_email": "Enviar factura por e-mail",
        "cf_print_bill": "Imprimir factura",
        "cf_print_rec": "Imprimir recibo",
        "cf_print_luck": "Nº de Recibo",
        "not_print": "No imprimir",
        "cf_email_addr": "Enviar factura a la dirección de correo",
        "write_email": "Escribe el e-mail...",
        "cf_finished": "Cobro finalizado",
        "cf_loading_print": "Cargando ventana para imprimir...",
        "cf_reprint": "Volver a imprimir",
        "write_luck_number": "Escribe el nº de recibo",
        "ctp_n_simp": "Nº de factura simplificada",
        "cnt": "C",
        "punid": "PVP",
        "desc": "Desc.",
        "total_without": "Total sin",
        "payed": "Pagado",
        "todebt": "A deber",
        "attend_by": "Atendido por",
        "thanks_visit": "GRACIAS POR SU VISITA",
        "ticket_publi_bh": "WWW.MIORA.IO",
        "ticket_publi_bh_es": "WWW.MIORA.ES<br/>PROMOCIONES DE BELLEZA EN TU CIUDAD",
        "cancel_charge": "Cancelar cobro",
        "put_on_wait": "Poner en espera",
        "charge_debt": "Cobro a deber",
        "charge_mix": "Cobro mixto",
        "prepaid": "Saldo",
        "prepaid_payment": "Saldo",
        "has_debt": "Deuda de",
        "debt": "Deuda",
        "cs_title": "Salarios y comisiones",
        "cs_emp_shown": "Usuarios/Empleados mostrados",
        "cs_com_type_1": "Valor fijo",
        "base_salary": "Sueldo base",
        "csi_treat_com": "Comisiones de servicios",
        "comision": "Comisión",
        "csi_prod_com": "Comisiones de productos",
        "csi_bon_com": "Comisiones de bonos",
        "csi_esp_com": "Comisiones de cobros especiales",
        "csi_sal_base": "Sueldo fijo mensual",
        "csi_charged_by": "Cobrado por",
        "csi_assigned_to": "Asignado a",
        "csi_charged_in": "Cobrado en",
        "add_comision": "Añadir comisión",
        "csi_treat_com_s": "Comisión de servicio",
        "csi_prod_com_s": "Comisión de producto",
        "csi_bon_com_s": "Comisión de bono",
        "csi_esp_com_s": "Comisión de cobro especial",
        "charged_by_every": "Cobrados por cualquier usuario/empleado",
        "charged_in_every": "Cobrados en cualquier caja",
        "charged_in_main": "Cobrados en Caja principal",
        "cl_conta": "Contabilidad",
        "cl_fact": "Facturación",
        "cl_sal": "Sueldos y comisiones",
        "cl_exp": "Gastos",
        "cl_debts": "Deudas",
        "cl_daily": "Caja del dia",
        "c_close": "Cierre de caja",
        "c_movements": "Movimientos de caja",
        "c_count": "Contar caja",
        "c_stats": "Estadísticas",
        "c_stats_prod_serv": "Productos y servicios",
        "rest": "Resto",
        "add_money": "Ingresar",
        "add_money_n": "Ingresado",
        "ext_money": "Sacar",
        "ext_money_n": "Sacado",
        "propina": "Propina",
        "write_reason": "Escribe el motivo",
        "mm_mov_last": "Movimientos de caja desde el último cierre",
        "mm_no_mov": "No hay nuevos movimientos",
        "to_charges": "a cobros",
        "bill_number": "Nº de Factura",
        "emit_bill": "Emitir factura",
        "rem_bill": "Quitar factura al cobro",
        "charge_user": "Usuario de cobro",
        "price_without": "Precio sin",
        "total_price": "Precio total",
        "taxes_of": "Impuestos de",
        "delete_charge": "Eliminar cobro",
        "tc_detailed": "Información desglosada del cobro",
        "delete_bill": "Eliminar factura",
        "citm_disc_per": "Descuento con porcentaje",
        "citm_disc_fix": "Descuento con precio fijado",
        "citm_disc_apply": "Aplica un descuento especial",
        "of_discount": "de descuento",
        "apply_other_price": "Aplica otro precio",
        "charge_other_emp": "Cobrar para otro usuario",
        "assign_casher": "Asignar personal",
        "assigned_casher": "Personal asignado",
        "apply_discount": "Aplicar descuento",
        "choose_casher_type": "Asigna a los tipos",
        "unit_quantity": "Cantidad de unidades",
        "change_quantity": "Cambiar cantidad",
        "every_employee": "Cualquier usuario",
        "tll_bill_all": "Con y sin factura emitida",
        "tll_bill_with": "Con factura emitida",
        "tll_bill_out": "Sin factura emitida",
        "show_only_of_charge": "Mostrar sólo",
        "special_charges": "Cobros especiales",
        "tll_cant_emit": "No es posible emitir la factura en los cobros",
        "tll_cant_emit_desc": "Hay cobros en los que no se puede emitir la factura simplificada. El problema es que hay cobros con fecha más reciente que ya tienen la factura emitida. Para continuar, soluciona el problema para poder emitir las facturas de los cobros.",
        "select_charges": "Seleccionar cobros",
        "move_to_other_caja": "Mover a otra caja",
        "emit_bills": "Emitir facturas",
        "print_bills": "Imprimir facturas",
        "down_excel_bill": "Descargar en excel",
        "down_excel_normal": "Por cobros",
        "down_excel_all": "Por cobros desglosados",
        "limit_premium_emit": "Puedes emitir facturas una por una, o bien emitir todas las facturas a la vez",
        "limit_premium_print": "Puedes imprimir facturas una por una, o bien imprimir varias a la vez",
        "search_by_name": "Buscar por nombre",
        "search_by_number": "Buscar por número",
        "search_by_prod": "Buscar por nombre o código",
        "search_by_cat": "Buscar por categoría",
        "intro_reason": "Introduce el motivo",
        "add_cargo": "Añadir cargo",
        "add_treat": "Añadir servicio",
        "add_prod": "Añadir producto",
        "add_bon": "Añadir bono",
        "add_prepaid": "Añadir saldo",
        "special_charge": "Cargo especial",
        "rem_debt": "Saldar deuda",
        "disc_poll_done": "Descuento por encuesta hecha",
        "disc_fid_done": "Descuento por completar fidelización",
        "disc_special": "Descuento especial",
        "emp_apply_charge": "Empleado a aplicar el cobro",
        "win_fid": "Ganador de fidelidad",
        "client_debts": "Deudas de clientes",
        "select_debts": "Seleccionar deudas",
        "ErrorEditNoUser": "No puedes editar un cobro que no ha sido realizado con tu usuario",
        "CantEmitBill": "No se puede emitir la factura. Hay facturas emitidas con fecha posterior a este cobro.",
        "ErrorNoRemoveBill": "No se puede quitar esta factura. Para quitarla, debes primero quitar las facturas con numeración más alta.",
        "ErrorRemoveChargeWithBill": "No se puede eliminar un cobro con factura. Intenta quitar la factura antes de eliminar el cobro por completo.",
        "dv_ticket": "Datos del cobro",
        "c_stats_reports": "Informes",
        "cashdebts_desc": "<p>En esta sección aparece el total de clientes que tienen deuda actualmente.</p><p>Recuerda que las deudas se crean a través de un <b>'cobro a deber'</b> a los clientes y para quitar la deuda a un cliente se debe hacer un nuevo cobro, seleccionar el cliente, añadir <b>'saldar deuda'</b> y finalizar el cobro.</p>",
        "remove_stat": "Eliminar estadística",
        "name_stat": "Nombre de la estadística",
        "save_config": "Guardar configuración",
        "config_stat": "Configurar estadística",
        "add_stat": "Añadir estadística",
        "money_income": "Ingresos",
        "cl_prepaids": "Saldos",
        "bc_title_6": "Impuestos del cobro adicional, saldo y tarjetas regalo",
        "bc_desc_6": "Aplicar impuestos al realizar un cobro adicional, cobrar un saldo o una tarjeta regalo",
        "bc_6_item_1": "Sí, quiero que se apliquen impuestos al realizar un cobro adicional",
        "assign_employee_for_service": "Asignar por servicio",
        "bc_title_7": "Habilitar factura con datos de cliente",
        "bc_desc_7": "Mostrar CIF/NIF y denominación social del cliente en las facturas.",
        "bc_7_item_1": "Sí, quiero que se muestren los datos del cliente en las facturas.",
        "apply_global_disc": "Aplicar descuento global",
        "global_disc": "Descuento global",
        "prepaid_debts": "Saldos y deudas",
        "rem_debt_ticket": "Saldar deuda",
        "advance_salary": "Anticipar salario",
        "choose_employee": "Escoger empleado",
        "glob_disc": "Descuento Global",
        "without": "Sin",
        "total_to_pay": "Total a pagar",
        "total_without_desc": "Total sin desc.",
        "download_salary": "Descargar comisiones",
        "commission_type": "Tipo de Comisión",
        "assigned_to": "Asignado A",
        "cashed": "Cobrado",
        "commission": "Comisión",
        "tax_perc": "% de impuesto",
        "client_gender": "Género del cliente",
        "mov_type": "Tipo de movimiento",
        "ticket_short_name": "Nombre corto del ticket",
        "applied_taxes": "Impuestos aplicados",
        "total_with_taxes": "Total con impuestos",
        "leaves_unpaid": "Deja a deber",
        "base_discounted": "Base con descuento",
        "amount_base": "Importe base",
        "concept": "Concepto",
        "base_disc": "Base con DTO.",
        "bc_title_8": "Cobro de productos a empleados",
        "bc_desc_8": "Permitir el cobro de productos a empleados. Estos serán descontados de su sueldo y aparecerán en la sección de sueldos y comisiones",
        "bc_8_item_1": "Si, quiero que se puedan cobrar productos a empleados.",
        "dont_ignore": "No ignorar",
        "ignore": "Ignorar",
        "to_comision": "Comisionar",
        "real_cost": "Precio real",
        "with_discount": "Con descuento",
        "accounted_cashes": "Real Facturado",
        "cashed_money": "Dinero que se ha cobrado",
        "cash_type_notice": "La diferencia entre estas opciones se nota en cómo se muestran las deudas de tus clientes.<br/><br/>En \"Real facturado\" cada cobro aparece en su totalidad, aunque el cliente lo tenga a deber y no te lo haya pagado aún.<br/><br/>En \"Dinero que se ha cobrado\" te muestra el dinero que ha entrado en caja. Si un cliente no te ha pagado por que lo tiene a deber no te aparecerá.<br/>Hay que tener en cuenta que esta opción no es válida para la declaración de impuestos.",
        "make_refund": "Hacer devolución",
        "successful_refund": "Devolución hecha con éxito!",
        "refund_error": "Ha habido un error al hacer la devolución",
        "choose_to_debt": "Escoge un cliente",
        "choose_to_debt_content": "Escoge un cliente para cobrar a deber",
        "prepaid_used": "Saldo usado",
        "advanced_salary": "Salario anticipado",
        "advanced_salaries": "Salarios anticipados",
        "bought_employee_products": "Productos comprados al negocio",
        "total_cashed": "Total cobrado",
        "num_luck": "Número de recibo",
        "download_salary_details": "Desc. comisiones detalladas",
        "ticket_luck_number": "Número de cobro o recibo",
        "payed_with": "Pagado con",
        "taxes_without_desc": "Impuestos sin DTO.",
        "c_special": "Pagos especiales",
        "add_giftcard_short": "Añadir T. Regalo",
        "add_giftcard": "Añadir Tarjeta Regalo",
        "code_giftcard": "Código de tarjeta",
        "message_giftcard_used": "Este código de tarjeta ya está en circulación. Al cobrarlo se le añadirá a su saldo actual:",
        "valid_giftcard_still_remain": "Tarjeta válida. Aún le quedarán",
        "not_enough_funds_giftcard": "No hay suficiente saldo en la tarjeta",
        "not_valid_giftcard": "La tarjeta regalo introducida no existe",
        "delete_giftcard": "Eliminar Tarjeta regalo",
        "giftcard-desc": "Aquí puedes ver las tarjetas regalo que tienes en circulación. Puedes editarlas, cambiar su saldo o eliminarlas cuando ya no estén en circulación.",
        "total_debt": "Deuda total",
        "download_all_salaries": "Descargar todas",
        "payed_with_cash_money": "Pagado con dinero de caja?",
        "mark_as_paid": "Marcar como pagado",
        "subscription_explanation": "<p>Cuando marcas una suscripción como pagada, se genera un ticket que podrás ver en facturación y automáticamente se moverá esa suscripción al siguiente día que tenga que pagar.</p>",
        "cash_target_subscription": "¿En qué caja se tienen que cobrar?",
        "wtp_target_subscription": "¿Con qué forma de pago se cobran?",
        "pack_discount": "Descontar bonos",
        "won_fidelity_pos": "para tu próxima visita. No olvides preguntar de qué se trata.",
        "won_fid_expires_pre": "Cuentas con ",
        "won_fid_expires_pos": " días para poder utilizar esta oferta.",
        "cf_finishing": "Finalizando cobro...",
        "creditcard": "Tarjeta de crédito",
        "cash": "En efectivo",
        "giftcard": "Tarjeta de regalo",
        "treatFilter": "Servicios",
        "productFilter": "Productos",
        "packFilter": "Bonos",
        "specialFilter": "Cobros especiales",
        "prepaidFilter": "Saldos y deudas",
        "insurances_title": "Empresas pagadoras (seguros médicos)",
        "insurances_desc": "Las empresas pagadoras son aquellas que se encargan de pagar los tratamientos a un cliente. Lo más común es que sean empresas de seguro médico, pero también pueden encajar otro tipo de empresas y convenios.",
        "incomeTax": "Retención en la fuente",
        "_mix": "Mixto",
        "debtFilter": "Deudas",
        "dateColumn": "Fecha del cobro",
        "clientColumn": "Cliente",
        "employeeColumn": "Cobrador",
        "cashColumn": "Caja",
        "invoiceColumn": "Factura",
        "wtpColumn": "Forma de pago",
        "amountColumn": "Importe",
        "taxesColumn": "Impuestos",
        "totalColumn": "Total",
        "Total": "Total",
        "Columnas visibles": "Columnas visibles",
        "Mover a caja": "Mover a caja",
        "Emitir facturas": "Emitir facturas",
        "Imprimir facturas": "Imprimir facturas",
        "Descargar en excel": "Descargar en excel",
        "Principal": "Principal",
        "No": "No",
        "Resumen de Real facturado": "Resumen de Real facturado",
        "cobros": "cobros",
        "importe": "importe",
        "impuestos": "impuestos",
        "deuda": "deuda",
        "Seleccionado": "Seleccionar impuesto",
        "Transfiriendo movimientos...": "Transfiriendo movimientos...",
        "Estás por transferir los cobros de una caja a otra. ¿Donde transferiremos los cobros?": "Estás por transferir los cobros de una caja a otra. ¿Dónde transferiremos los cobros?",
        "Elige una caja destino": "Elige una caja destino",
        "Transferir caja": "Transferir caja",
        "Hubo un error al intentar emitir las facturas seleccionadas.": "Hubo un error al intentar emitir las facturas seleccionadas.",
        "Los facturas han sido emitidas correctamente.": "Los facturas han sido emitidas correctamente.",
        "Se ha enviado la factura a ": "Se ha enviado la factura a ",
        "No se ha podido generar la factura, intenta emitir la factura primero.": "No se ha podido generar la factura, intenta emitir la factura primero.",
        "El cliente no tiene email en su ficha donde enviar la factura.": "El cliente no tiene email en su ficha donde enviar la factura.",
        "ticket_title_legal": "Factura",
        "ticket_title_natural": "Factura",
        "workFilter": "Citas",
        "giftcardFilter": "Tarjetas regalo",
        "subscriptionFilter": "Suscripciones",
        "product": "Producto",
        "treatment": "Servicio",
        "pack": "Bono",
        "wtp_clientcreditcard": "Tarjeta vinculada",
        "point": "Puntual",
        "Descargar factura": "Descargar factura",
        "SALDO": "SALDO",
        "debtListFirstLine": "En esta sección aparece el total de clientes que tienen deuda actualmente.",
        "debtListSecondLine": "Recuerda que las deudas se crean a través de un **cobro a deber** a los clientes y para quitar la deuda a un cliente se debe hacer un nuevo cobro, seleccionar el cliente, añadir **saldar deuda** y finalizar el cobro.",
        "dFreq": "Diaria",
        "wFreq": "Semanal",
        "fFreq": "Quincenal",
        "mFreq": "Mensual",
        "bmFreq": "Bimestral",
        "tmFreq": "Trimestral",
        "yFreq": "Anual",
        "subscriptionsAvg": "Suscripciones promedio",
        "subscriptionsColumn": "Suscripciones",
        "feeAvg": "Cargo promedio",
        "cfinish_payment_ask_title": "Al finalizar el cobro cargaremos el importe en la tarjeta vinculada del cliente. ¿Estás seguro?",
        "cfinish_payment_error_title": "El pago ha fallado. Elige otra forma de pago.",
        "subs_move_date": "Cambiar fecha de pago",
        "movedate_explanation": "Elige el nuevo día en el que se cobrará la suscripción. Es recomendable avisar al cliente de que va a cambiar el día que se le va a cobrar.",
        "se cobrará el": "Se cobrará el",
        "move_date_btn": "Cambiar fecha",
        "Año": "Año",
        "Tarjeta OXXO": "Tarjeta OXXO",
        "Cualquier tipo": "Cualquier tipo",
        "Caja secundaria": "Caja secundaria",
        "Cualquier empresa": "Cualquier empresa",
        "Descargar desglosado": "Descargar desglosado",
        "No hay cobros registrados en este periodo.": "No hay cobros registrados en este periodo.",
        "Intenta cambiando los parametros de busqueda.": "Intenta cambiando los parámetros de búsqueda.",
        "Importe a devolver": "Importe a devolver",
        "Cómo se devolverá": "Cómo se devolverá",
        "Saldo en su ficha de cliente": "Saldo en su ficha de cliente",
        "Reembolsar en su tarjeta": "Reembolsar en su tarjeta",
        "No se devuelve el dinero": "No se devuelve el dinero",
        "Qué hacer con la factura": "Qué hacer con la factura",
        "Qué hacer con el cobro": "Qué hacer con el cobro",
        "Hacer factura rectificativa": "Hacer factura rectificativa",
        "Hacer un cobro negativo": "Hacer un cobro negativo",
        "Aplicar un descuento global": "Aplicar un descuento al cobro",
        "No recomendable al ser una factura emitida": "No recomendable al ser una factura emitida",
        "Aplicar devolución": "Aplicar devolución",
        "Aplicando devolución": "Aplicando devolución",
        "refund_balance": "Devuelto en saldo",
        "refund_clientcreditcard": "Dinero devuelto",
        "Factura rectificativa": "Factura rectificativa",
        "Cobro negativo": "Cobro negativo",
        "Con impuestos": "Con impuestos",
        "Sin impuestos": "Sin impuestos",
        "Recibo de venta": "Recibo de venta",
        "Cobro": "Cobro",
        "Cobro finalizado": "Cobro finalizado",
        "Cobros en espera": "Cobros en espera",
        "Presupuestos": "Presupuestos",
        "Ningún presupuesto registrado.": "Ningún presupuesto registrado.",
        "Presupuestos base": "Presupuestos base",
        "Cobrar": "Cobrar",
        "Cerrar ventana": "Cerrar ventana",
        "Finalizar cobro": "Finalizar cobro",
        "Opciones adicionales": "Opciones adicionales",
        "Poner en espera": "Poner en espera",
        "Marcar como presupuesto": "Marcar como presupuesto",
        "Cliente": "Cliente",
        "Cobrador": "Cobrador",
        "Añade elementos al cobro para comenzar.": "Añade elementos al cobro para comenzar.",
        "Añadir servicio, producto, etc.": "Añadir servicio, producto, etc.",
        "Importe": "Importe",
        "Impuestos": "Impuestos",
        "de descuento": "de descuento",
        "Busqueda en tus servicios, productos, etc.": "Busqueda en tus servicios, productos, etc.",
        "Sin categoría": "Sin categoría",
        "en stock": "en stock",
        "No es posible añadir sin stock disponible": "No es posible añadir sin stock disponible",
        "Reanudar": "Reanudar",
        "Presupuesto": "Presupuesto",
        "Utilizar presupuesto base": "Utilizar presupuesto base",
        "Guardar y cerrar": "Guardar y cerrar",
        "Nombre del presupuesto": "Nombre del presupuesto",
        "Estado del presupuesto": "Estado del presupuesto",
        "Tarjeta de regalo": "Tarjeta de regalo",
        "Código": "Código",
        "Precio": "Precio",
        "Añadir tarjeta de regalo": "Añadir tarjeta de regalo",
        "gastos": "gastos",
        "Total registrado": "Total registrado",
        "Resumen de gastos": "Resumen de gastos",
        "Gastos periódicos": "Gastos periódicos",
        "Gasto puntual": "Gasto puntual",
        "Gasto periódico fijo": "Gasto periódico fijo",
        "Fecha": "Fecha",
        "Gasto registrado": "Gasto registrado",
        "Tipo": "Tipo",
        "Monto": "Monto",
        "No hay gastos registrados en este periodo.": "No hay gastos registrados en este periodo.",
        "Descargar excel": "Descargar excel",
        "Configurar gastos periódicos": "Configurar gastos periódicos",
        "Gastos periódicos activos": "Gastos periódicos activos",
        "Nuevo gasto": "Nuevo gasto",
        "Ningún gasto periódico registrado.": "Ningún gasto periódico registrado.",
        "Registrar nuevo gasto": "Registrar nuevo gasto",
        "¿Pagado con dinero de caja?": "¿Pagado con dinero de caja?",
        "Registrar en caja": "Registrar en caja",
        "Si": "Si",
        "Desde:": "Desde:",
        "Eliminar gasto": "Eliminar gasto",
        "Descargar todas": "Descargar todas",
        "Cierres": "Cierres",
        "Resumen de deudas pendientes": "Resumen de deudas pendientes",
        "Deudas": "Deudas",
        "Deuda": "Deuda",
        "Saldar deuda sin cobro": "Saldar deuda sin cobro",
        "Registro de deuda": "Registro de deuda",
        "Ningún cobro registrado.": "Ningún cobro registrado.",
        "¿Quieres borrar los gastos seleccionados? Ten en cuenta que esta acción no se puede revertir...": "¿Quieres borrar los gastos seleccionados? Ten en cuenta que esta acción no se puede revertir...",
        "Crear gasto": "Crear gasto",
        "Eliminar gasto periódico": "Eliminar gasto periódico",
        "Eliminar y borrar los gastos realizados": "Eliminar y borrar los gastos realizados",
        "Eliminar y mantener los gastos realizados": "Eliminar y mantener los gastos realizados",
        "Importe del gasto": "Importe del gasto",
        "No cierres esta ventana, estamos trabajando para borrar los gastos relacionados.": "No cierres esta ventana, estamos trabajando para borrar los gastos relacionados.",
        "Nombre del gasto": "Nombre del gasto",
        "Nombre específico del gasto": "Nombre específico del gasto",
        "Nuevo gasto periódico": "Nuevo gasto periódico",
        "csi_subs_com": "Comisiones de suscripción",
        "csi_subs_com_s": "Comisión de cobro de suscripción",
        "csi_class_com_s": "Comisión de clases",
        "impartida_by_every": "Cualquier profesor",
        "impartida_by": "Impartida por",
        "csi_class_com": "Comisiones de clases",
        "attend_interval": "Rango de asistentes",
        "class_match_requirements": "por clase que cumpla los requisitos",
        "Restablecer filtros": "Restablecer filtros",
        "¿Estás seguro de querer eliminar": "¿Estás seguro de querer eliminar",
        "? Ten en cuenta que esta acción no se puede revertir.": "? Ten en cuenta que esta acción no se puede revertir.",
        "¿Quieres borrar la deuda guardada para los clientes seleccionados": "¿Quieres borrar la deuda guardada para los clientes seleccionados",
        "Cobro con deuda": "Cobro con deuda",
        "Pago de deuda": "Pago de deuda",
        "Espera un momento, estamos transfiriendo tus cobros": "Espera un momento, estamos transfiriendo tus cobros",
        "Espera un momento, estamos eliminando los cobros y caja": "Espera un momento, estamos eliminando los cobros y caja",
        "Escribe en el recuadro": "Escribe en el recuadro",
        "Sí, quiero eliminar los cobros": "Sí, quiero eliminar los cobros",
        "para confirmar la operación": "para confirmar la operación",
        "Escribe el texto de confirmación...": "Escribe el texto de confirmación...",
        "Borrar caja": "Borrar caja",
        "Estás a punto de transferir los cobros de una caja a otra. ¿Donde transferiremos los cobros?": "Estás a punto de transferir los cobros de una caja a otra. ¿Donde transferiremos los cobros?",
        "Estás a punto de eliminar una caja de cobros, ¿Qué quieres que ocurra con los cobros dentro?": "Estás a punto de eliminar una caja de cobros, ¿Qué quieres que ocurra con los cobros dentro?",
        "Borrar cobros": "Borrar cobros",
        "Transferir a otra caja": "Transferir a otra caja",
        "No aplicar retención": "No aplicar retención",
        "clients_waiting": "Clientes en espera",
        "Hubo un error al intentar transferir los cobros seleccionados.": "Hubo un error al intentar transferir los cobros seleccionados.",
        "Hubo un error al intentar guardar los cambios. Intente de nuevo más tarde o reporte este incidente.": "Hubo un error al intentar guardar los cambios. Intente de nuevo más tarde o reporte este incidente.",
        "Ocurrió un error desconocido. Intenta la operación más tarde.": "Ocurrió un error desconocido. Intenta la operación más tarde.",
        "Los cobros han sido transferidos correctamente.": "Los cobros han sido transferidos correctamente.",
        "Cobrados": "Cobrados",
        "order_cancelled": "Cancelada",
        "Busqueda_de_productos": "Búsqueda en tus productos",
        "smFreq": "Semestral",
        "tdni_title": "Mostrar datos de cada profesional",
        "tdni_desc": "Al habilitar esta opción, en cada fila del ticket impreso aparecerá el nombre y RFC del profesional que ha realizado el servicio.",
        "tdni_item": "Sí, mostrar datos de cada profesional en el ticket",
        "cl_centers": "Facturación global",
        "pages_txt_tickets": "Cobros en total",
        "pages_txt_budgets": "Presupuestos en total",
        "centers": "Centros",
        "every_center": "Cualquier centro",
        "won_fidelity_curr": "Con esta visita has acumulado un servicio para obtener un descuento especial",
        "order_sent": "Enviada",
        "order_received": "Orden recibida",
        "provider": "Proveedor",
        "number": "Número",
        "purchase_order": "Orden de Compra",
        "purchase_orders": "Ordenes de Compra",
        "Fecha de pago": "Fecha de pago",
        "Fecha de emisión": "Fecha de emisión",
        "Añadir datos de factura": "Añadir datos de factura",
        "won_fidelity_pre": "Con esta visita obtuviste una oferta para tu próxima visita, que incluye: ",
        "courseFilter": "Cursos",
        "eventclassFilter": "Eventos",
        "Tu resumen de cobros": "Tu resumen de cobros",
        "time_comission": "Comisión por hora trabajada",
        "per hour": "por hora",
        "Buscar Presupuesto": "Buscar Presupuesto",
        "Buscar por cliente": "Buscar por cliente",
        "Acciones": "Acciones",
        "Desc. Fijo": "Desc. Fijo",
        "Desc. Porcentual": "Desc. Porcentual",
        "Aprobar": "Aprobar",
        "Buscar": "Buscar",
        "delete budget": "Cancelar presupuesto",
        "Cant.": "Cant.",
        "add_course": "Añadir curso",
        "add_event": "Añadir evento",
        "add_new_subs": "Añadir nueva suscripción",
        "in_creditcard": "Con tarjeta",
        "won_fidelity": "Con esta visita ganaste fidelidad.",
        "won_fidelity_finally": "Has completado tu descuento de fidelidad ",
        "won_fidelity_next": ", en tu próxima visita tendrás: ",
        "won_fidelity_apply": "Descuento de fidelidad aplicado",
        "to obtain": "para conseguir",
        "descuento conseguido, el siguiente": "descuento conseguido, el siguiente",
        "of": "de",
        "Mostrar seguimiento de saldo pendiente": "Mostrar seguimiento de saldo pendiente",
        "Pague el saldo pendiente sin transacción": "Pague el saldo pendiente sin transacción",
        "Quitar filtro": "Quitar Filtro",
        "No hay clientes con deuda registrados.": "No hay clientes con deuda registrados.",
        "order_preparing": "En preparación",
        "order_delivering": "En proceso de envío",
        "order_cancelled_provider": "Cancelada por el Proveedor",
        "order_cancelled_provider_action": "Cancelar orden",
        "account_client": "Cliente",
        "modified_provider": "Modificada por el Proveedor",
        "any_account_client": "Cualquier cliente",
        "provider_orders": "Ordenes recibidas",
        "Seleccionados": "Seleccionados",
        "Facturar": "Facturar",
        "generate_invoice": "Generar factura",
        "recurring_charges": "Cobros recurrentes",
        "no_client_payment": "cobro sin",
        "onhold_delete": "¡Cuidado! Estas eliminando un cobro en espera, ¿deseas continuar?",
        "generate_order": "Generar pedido",
        "generate_charge": "Generar cobro",
        "Pedir": "Pedir",
        "Later_cashier_new": "Mas tarde",
        "Generated": "generada",
        "Print_order_on_request": "Imprimir orden sobre pedido",
        "Do_you_want_to_print_the_order": "¿Quieres imprimir la orden de productos sobre pedido?",
        "Do_you_want_to_generate_a_charge": "¿Quieres generar un cobro sobre la",
        "At_the_end": "Al finalizar",
        "Add_another_wtp": "Agregar otra forma de pago",
        "Product_on_request": "Producto sobre pedido",
        "Search_product_or_service": "Busca un servicio, producto...",
        "Find_a": "Buscar un",
        "Duplicate_giftCard": "Código de tarjeta regalo ya esta registrado en el sistema.",
        "Units": "Unidades",
        "Search_a_pending_charge": "Buscar un cobro en espera...",
        "Search_a_professional": "Busca un profesional...",
        "validate_fields": "Por favor llena la información del cliente en su ficha para proceder con un cobro con facturación electrónica",
        "Descuento porcentual": "Descuento porcentual",
        "Descuento fijo": "Descuento fijo",
        "Unknown": "Desconocido",
        "TPV_Charge": "Cobro",
        "Generar_pedido": "Generar pedido",
        "search_by": "Buscar por",
        "optic_emit_bill": "Generar pedido",
        "Generated2": "generado",
        "Do_you_want_to_generate_a_charge2": "¿Quieres generar un cobro sobre el",
        "Pedido_creado": "Pedido creado",
        "giftcard_paymix_invalid": "No se puede pagar con tarjeta de regalo",
        "press_pay": "Presiona cobrar para pagar",
        "add_special_charge": "Añadir cargo especial",
        "confirm_close_alert": "Estas a punto de cerrar el proceso de cobro, ¿Qué deseas hacer?",
        "cancel_without_saving": "Cancelar sin guardar",
        "Look_out": "¡Atención!",
        "delete_pack": "Se aplica bono {nombre del bono}, ¿deseas eliminar esta aplicación?",
        "return_stock": "Qué hacer con los productos",
        "main_stock_av": "disponible(s)",
        "return_products": "Devolver todos los productos al stock",
        "do_not_return_products": "No devolver ningún producto al stock",
        "Tarjeta_regalo": "Tarjeta regalo",
        "Nueva": "Nueva",
        "Order_delivered_to_supplier": "Pedido entregado al proveedor",
        "Notify_client": "Notificar al cliente",
        "Order_received": "Pedido recibido",
        "Verified_by_scientific_director": "Verificado por el director científico",
        "Client_notified": "Cliente notificado",
        "Order_delivered_to_customer": "Pedido entregado al cliente",
        "Invoice_issued": "Factura emitida",
        "eventclass": "Evento",
        "previus_debt": "Deuda anterior",
        "actual_debt": "Deuda posterior",
        "cita": "Cita",
        "apply_fixed_disc": "Aplicar descuento fijo",
        "apply_percentage_disc": "Aplicar descuento porcentual",
        "by_payments": "Por",
        "Add state": "Añadir estado",
        "Created": "Creado",
        "Concept_Block": "Bloque de conceptos",
        "Name_Block": "Nombre del bloque",
        "Concepts": "Conceptos",
        "Add_Concept": "Agregar Concepto",
        "Delete_block": "Eliminar bloque",
        "Only_services_products_with_price_range": "Sólo servicios/productos con un rango de precio",
        "Only_services_products_from_the_selected_list": "Sólo servicios/productos de la lista seleccionada",
        "All_the_Prices": "Todos los precios",
        "All_products_and_services": "Todos los productos y servicios",
        "It_is_a_mandatory_concept": "Es un concepto obligatorio",
        "Template_name": "Nombre de la plantilla",
        "Budget_templates": "Plantillas de presupuestos",
        "New_Template": "Nueva plantilla",
        "Create_Template": "Crear plantilla",
        "Days_of_validity": "Dias de vigencia",
        "Options": "Opciones",
        "New_Concept": "Nuevo concepto",
        "Create_new_template": "Crear nueva plantilla",
        "Configure_Templates_Text": "Configura plantillas que permiten restringir los conceptos del presupuestos, tener descuentos especiales y otras muchas configuraciones.",
        "No_Records_Found": "No se encontraron registros.",
        "Activa": "Activa",
        "first": "Primer",
        "second": "Segundo",
        "third": "Tercer",
        "fourth": "Cuarto",
        "fifth": "Quinto",
        "sixth": "Sexto",
        "cl_charges": "Cobros",
        "cl_order": "Pedidos",
        "seventh": "Séptimo",
        "Notify_by_email_to": "Notificar por email a",
        "progressColumn": "Progreso",
        "delete_fidelity": "Quitar fidelidad",
        "orders_empty": "No hay pedidos registrados en este periodo.",
        "order_error_emit": "Para emitir factura de este cobro, debes hacerlo desde el pedido.",
        "issued_by": "Emitida por",
        "charge_has_order": "Con pedido asociado",
        "charge_hanst_both": "Sin pedido ni factura asociado",
        "ophthalmic_glasses": "Lentes oftálmicos",
        "add_ophthalmic_glasses": "Agregar lentes oftálmicos",
        "You_must_enter_the_information_with_a_period": "Debes ingresar la información con punto",
        "reject_budget": "Rechazar presupuesto",
        "Realizar un cobro": "Realizar un cobro",
        "delete_discount": "Quitar descuento",
        "error_pay_mix_no_options": "debes completar el pago ya que no tienes más opciones de pago",
        "delete_creditcard_title": "Eliminar tarjetas de crédito",
        "delete_creditcard_desc": "Permite eliminar tarjetas de credito asociadas a clientes desde web y aplicacion movil",
        "delete_creditcard_check": "Si, quiero que los clientes puedan eliminar la tarjeta de credito asociada",
        "delete_patient": "Eliminar paciente",
        "create_patient": "Crear paciente",
        "Notify_patient": "Notificar al paciente",
        "search_label_patient": "Busca un paciente por nombre, email o teléfono",
        "pages_txt_patient": "pacientes en total",
        "patients": "pacientes",
        "join_patients": "Unir paciente",
        "report-active-patients": "Paciente activos",
        "report-active-patients-desc": "Analiza cuántos pacientes activos tienes en el negocio. Un paciente activo es aquel que ha asistido al menos una vez en los últimos 30 días. Hay que tener en cuenta que los últimos 30 días son los 30 días pasados desde la fecha que marques en el informe, así puedes ver los pacientes activos que tenías ayer, hace una semana o incluso hace 3 meses.",
        "report-inactive-patients": "Pacientes inactivos",
        "report-inactive-patients-desc": "Conoce a tus pacientes inactivos en un periodo determinado.",
        "report-profitable-patients": "Pacientes que más gastan",
        "report-profitable-patients-desc": "Aquí puedes visualizar los pacientes que han generado un ingreso al negocio en un periodo de tiempo. Está ordenado de manera que se vean en primer lugar los pacientes que más han gastado.",
        "report-new-patients": "Pacientes nuevos",
        "report-new-patients-desc": "Analiza cuántos pacientes nuevos tiene el negocio en un periodo de tiempo. Puedes compara periodos de tiempo para ver si estás captando pacientes de forma más rápida que antes.",
        "new_patient": "Nuevo paciente",
        "pacient_selected": "Pacientes seleccionados",
        "pacient_card_title": "Ficha del paciente",
        "pacient_card_description": "Modifica la estructura de las fichas de los pacientes. Puedes ocultar secciones que no necesitas y hacer más visibles las secciones importantes",
        "dataview_list_title_patient": "Personaliza la ficha del paciente",
        "tracking_list_title_patient": "Crea seguimientos para los Pacientes",
        "config_notif_header_patient": "Configura las notificaciones por e-mail o SMS que se envían a tus Pacientes.",
        "welcome_desc_patient": "Habilita esta opción si quieres que los Pacientes reciban un email de bienvenida cuando des de alta su ficha.",
        "welcome_yes_patient": "Sí, quiero que los Pacientes reciban el email de bienvenida.",
        "welcome_text_desc_patient": "Escribe un texto de bienvenida que formará parte del e-mail que recibirán los Pacientes cuando des de alta tu ficha.",
        "enable_reminder_desc_patient": "Habilita esta opción si quieres que los Pacientes reciban un email/sms de recordatorio cada vez que tengan una cita asignada.",
        "enable_reminder_yes_patient": "Sí, quiero que los Pacientes reciban recordatorios de citas.",
        "sms_reminder_desc_patient": "Aquí puedes personalizar el SMS de recordatorio que le llega al paciente.",
        "sms_confirmation_desc_patient": "Aquí puedes personalizar el SMS de confirmación de cita que llega al paciente.",
        "web_and_social_desc_patient": "Introduce la página web y la página de facebook de tu negocio. Esta información aparecerá en los emails que le lleguen a tus Pacientes y así entrarán más frecuentemente a tus páginas.",
        "cth_desc_patient": "Aquí podrá configurar los horarios de apertura y los días festivos del centro. Es muy útil agregar estos datos para que en su perfil web los Pacientes puedan ver cuándo está abierto el centro.",
        "whatsapp_description_patient": "Cuando se despliega una cita en la agenda, al lado del nombre del paciente se añadirá un icono para acceder directamente a la conversación con el paciente en Whatsapp Web. Siempre y cuando el paciente tenga número de teléfono en su ficha.",
        "last_patient_bought": "Últimos pacientes que lo han comprado",
        "marketing_campaign_help_clients": "Crea plantillas para enviar mensajes o campañas desde la seccion de pacientes.",
        "WELCOME_PATIENT": "Email de bienvenida cuando se crea un paciente nuevo",
        "RES_CONFIRMATION_PATIENT": "Email de confirmación cuando un paciente hace una reserva de servicios",
        "STARS_PATIENT": "Email para que el paciente califique la satisfacción de la atención recibida",
        "BILL_PATIENT": "Email que se envía al paciente con la factura adjunta",
        "NOTIF_USER_WORK_CANCELLED_PATIENT": " Email para notificar al paciente que la reserva ha sido cancelada",
        "COURSE_JOIN_PATIENT": "Email para notificar al paciente que se ha apuntado a un curso correctamente",
        "RES_ADDED_TO_WAITLIST_PATIENT": " Email para notificar al paciente que ha sido añadido a la lista de espera",
        "RES_MOVE_WAITLIST_TO_CLASS_PATIENT": "Email para informar al paciente que ha pasado de lista de espera a lista oficial",
        "BUY_SUBSCRIPTION_PATIENT": "Email para confirmar al paciente la compra de una suscripción",
        "SUBSCRIPTION_WILL_EXPIRE_SOON_PATIENT": "Email para avisarle al paciente que su suscripción vencerá pronto",
        "EVENT_CLASS_JOIN_PATIENT": "Email para notificar al paciente que se ha apuntado a un evento correctamente",
        "EVENT_CLASS_ACTIVITY_REMINDER_PATIENT": "Email para recordar al paciente que el evento se aproxima",
        "BUY_PACK_PATIENT": "Email para notificar al paciente la compra de un bono",
        "RES_ADDED_TO_CLASS_PATIENT": "Email de confirmación cuando un paciente hace una reserva de clases",
        "CREDIT_CARD_WILL_EXPIRE_SOON_PATIENT": "Email para recordarle al paciente que su tarjeta de crédito expirará pronto",
        "REM_HOURSAGO_PATIENT": "Email que llega al paciente recordando su reserva",
        "BIRTHDAY_TEMPLATE_PATIENT": "Email que le llega al paciente cuando está cumpliendo años",
        "marketing_rules_description_patient": "Una tarea automática se puede resumir en acción - reacción. Cuando ocurre algo en tu cuenta (acción), automáticamente se ejecuta la reacción configurada. Hay varias reacciones que puedes configurar: enviar un mensaje al paciente, añadir el paciente a un grupo, etc.",
        "webapp_translations_desc_patients": "Modifica y personaliza todos los textos que aparecen en la web y app que te propociona tu cuenta. De esta manera, podrás traducirlo a un nuevo idioma o simplemente adaptar algunas palabras que entiendan mejor tus pacientes.",
        "patient_config_stars_desc": "<p>Es importante tener una buena valoración de tu negocio para que se posicionen tus servicios con más visibilidad en el portal. Para conseguir valoraciones, se envía un email al paciente que haya reservado en tu negocio a través del portal para que valore y opinen sobre él.</p><p>Una buena valoración puede servir para aportar confianza a los usuarios que quieran reservar en tu centro desde el portal.",
        "patient_config_stars_check_title": "Habilitar valoraciones de mis pacientes",
        "patient_config_stars_check_desc": "Habilitando esta opción, tus propios pacientes también podrán valorar tu negocio. La oportunidad de que también tus pacientes valoren tu negocio es muy buena ya que nadie va a valorar tan bien tu negocio como tus pacientes.",
        "patient_config_stars_check_check": "Sí, quiero habilitar las valoraciones de mis pacientes",
        "patient_postive_balance_onClient_card": "Saldo en su ficha de paciente",
        "find_by_patient": "Buscar por paciente",
        "patient_cashdebts_desc": "<p>En esta sección aparece el total de pacientes que tienen deuda actualmente.</p><p>Recordá que las deudas se crean a través de un <b>'cobro a deber'</b> a los pacientes y para quitar la deuda a un pacientes se debe hacer un nuevo cobro, seleccionar el paciente, agregar <b>'saldar deuda'</b> y finalizar el cobro.</p>",
        "patient_debtListFirstLine": "En esta sección aparece el total de pacientes que tienen deuda actualmente.",
        "patient_debtListSecondLine": "Recuerda que las deudas se crean a través de un **cobro a deber** a los pacientes y para quitar la deuda a un paciente se debe hacer un nuevo cobro, seleccionar el paciente, añadir **saldar deuda** y finalizar el cobro.",
        "no_patients_with_detbs": "No hay pacientes con deuda registrados.",
        "cashprepaids_desc": "<p>En esta sección aparece el total de clientes que tienen saldo a su favor actualmente.</p><p>Recuerda que los saldos se crean a través de un cobro de añadir saldo a los clientes y para quitar el saldo a un cliente se debe hacer uso de ese dinero el cual podrá ser usado en cualquier item de un cobro.</p>",
        "patiente_cashprepaids_desc": "<p>En esta sección aparece el total de pacientes que tienen saldo a su favor actualmente.</p><p>Recuerda que los saldos se crean a través de un cobro de añadir saldo a los pacientes y para quitar el saldo a un paciente se debe hacer uso de ese dinero el cual podrá ser usado en cualquier item de un cobro.</p>",
        "resumen_de_saldos": "Resumen de saldos",
        "cant_collect_creditcard_patient": "No puede cobrar con tarjeta vinculada del pacient",
        "patients_p": "Acceso del trabajador a los pacientes",
        "download_patients": "Descargar Pacientes",
        "visible_patients": "Pacientes visibles",
        "visible_clients": "Clientes visibles",
        "patients_p_all": "Puede ver todos los pacientes del centro",
        "patients_p_group": "Puede ver sólo los pacientes de su grupo",
        "patients_p_own": "Puede ver sólo sus propios pacientes",
        "patients_p_work": "Puede ver sólo sus pacientes agendados",
        "modpatient_p": "Pacientes que puede modificar",
        "modpatient_p_all": "Puede modificar todos los pacientes del centro",
        "modpatient_p_group": "Puede modificar sólo los pacientes de su grupo",
        "modpatient_p_own": "Puede modificar sólo sus propios pacientes",
        "modpatient_p_none": "No puede modificar ningún patiente",
        "spatient_p": "Búsqueda de pacientes",
        "spatient_p_all": "Puede buscar todos los pacientes del centro",
        "spatient_p_group": "Puede buscar sólo los pacientes de su grupo",
        "spatient_p_none": "Puede buscar sólo sus propios pacientes",
        "patient_cnotes_p": "Anotaciones del paciente",
        "patient_cnotes_p_all": "Puede ver todas las anotaciones del paciente",
        "patient_cnotes_p_group": "Puede ver sólo las anotaciones que su grupo agregue",
        "patient_cnotes_p_none": "Puede ver sólo las anotaciones que el propio trabajador agregue",
        "patient_can_collect_creditcard": "Puede cobrar con tarjeta vinculada del paciente",
        "patient_no_buyOnline": "El paciente no lo puede comprar online",
        "patient_buyOnline": "El paciente lo puede comprar online",
        "patient_buy_online_trial_local": "El paciente lo puede comprar online, pero sólo una vez.",
        "patient_buy_online_trial_global": "El paciente lo puede comprar online, pero sólo si nunca ha adquirido ningún bono o suscripción en el negocio.",
        "patient_filterview_placeholder": "Filtrar citas por servicio o paciente...",
        "choose_patient": "Elegir paciente",
        "search_a_patient": "Busca un paciente",
        "no_file_saved_for_this_patient": "Ningún archivo almacenado para este paciente",
        "patient_phone_email_permission": "Ver email o telefono del paciente",
        "patient_has_phone_email_permission": "Puede ver email o telefono del paciente",
        "patient_has_not_phone_email_permission": "No puede ver email o telefono del paciente",
        "patient_fid_offers_desc": "Aquí podrás configurar las ofertas para premiar a los pacientes por haberse hecho servicios en el centro (Ejemplos: A la décima manicura te regalamos una. Por un tratamiento de fotodepilación, el siguiente tiene un 50% de descuento, etc ).",
        "fid_if_patient_do": "Si el paciente hace",
        "fid_if_patient_buy": "Si el paciente compra",
        "patient_choosable_employee_text": "El paciente puede seleccionar al empleado al reservar",
        "patient_no_choosable_employee_text": "El paciente <span style='color:red'>no</span> puede seleccionar al empleado al reservar",
        "patient_description_placeholder": "Añade una descripción para detallar mejor el servicio. Muy útil para los pacientes que reserven online.",
        "patient_space_in_the_class": "¿El paciente tiene un espacio concreto en la clase?",
        "patient_space_in_the_class_no": "No, el paciente sólo tiene que unirse a la clase.",
        "patient_space_in_the_class_yes": "Sí, cada paciente tiene un espacio concreto en el siguiente mapa:",
        "patient_at_home_text": "Es un servicio a domicilio y se le pedirá al paciente su dirección.",
        "patient_gapconfig_1": "Si el paciente realizó un servicio de",
        "patient_need_group_to_book": "El paciente tiene que pertenecer a un grupo de la lista para poder reservar",
        "patient_autopay_desc": "Automáticamente se cobrará cada mes al paciente un nuevo bono",
        "patient_subs_offers_desc": "Aquí podrás configurar suscripciones para tus pacientes. Una suscripción es un pago periódico que hace el paciente a cambio de servicios, descuentos u otras ventajas. <br /> A través de la ficha de paciente podrás asignarle una suscripción y desde contabilidad podrás llevar la gestión de quién tiene que pagar.",
        "patient_subs_phrase": "Se le cobrará al paciente cada",
        "can_delete_budgetTemplate": "Estas a punto de eliminar esta plantilla de presupuesto ¿Deseas continuar?",
        "cannnot_delete_budgetTemplate": "No puedes eliminar esta plantilla de presupuesto debido a que esta siendo usanda por alguno de tus clientes.",
        "cannnot_delete_budgetTemplate_patient": "No puedes eliminar esta plantilla de presupuesto debido a que esta siendo usanda por alguno de tus pacientes.",
        "restore_budget": "Restaurar presupuesto",
        "budget_status": "Estado",
        "if_apply": "si aplica",
        "order_number": "Nº de pedido",
        "goto_order_emit": "ir al pedido para emitir factura",
        "no_BudgetTemplate": "Sin plantilla",
        "status_expired": "Caducado",
        "Expires_in": "Caduca el: ",
        "patientColumn": "Paciente",
        "orderColumn": "Orden",
        "optionsColumn": "Opciones",
        "charge_order": "Pedido",
        "products_or_services_not_found": "No se ha encontrado ningún producto o servicio.",
        "templateEmail_client": "Nombre del cliente",
        "templateEmail_employee": "Nombre del empleado",
        "templateEmail_centro": "Nombre del centro",
        "templateEmail_book_init_hour": "Hora de inicio de la reserva",
        "templateEmail_class_name": "Nombre de la clase",
        "templateEmail_book_service": "Nombre del servicio agendado",
        "templateEmail_book_date": "Fecha de la reserva completa",
        "templateEmail_book_date_short": "Fecha de la reserva corta",
        "templateEmail_book_duration": "Tiempo de duración de la cita",
        "templateEmail_stars_url": "Encuesta de calificación de satisfacción",
        "idIntegration": "Código",
        "recieve_order": "Recibir la orden",
        "edit_order": "Editar la orden",
        "cancel_order": "Cancelar la orden",
        "order_in_preparation": "Orden en preparación",
        "order_in_shipment": "Orden en envio",
        "in_progress": "En progreso",
        "past_orders": "Pasadas",
        "accept_order_change": "Aceptar modificación",
        "base_tax": "Base gravable",
        "search_a_product": "Busca un producto",
        "new_product_order": "Nueva orden de compra",
        "print_order": "Imprimir orden de compra",
        "product_orders": "Órdenes de compra",
        "cannot_receive_order_not_in_delivering": "No se puede recibir la orden, no esta en envio",
        "cannot_edit_order_status_product_order_need_accepting_changes": "No es posible editar el estatus, la orden de productos tiene cambios por aceptar",
        "cannot_edit_order_because": "No es posible editar la orden.",
        "updated_file": "Archivo subido",
        "file_not_valid": "Archivo no valido",
        "detail": "Detalle",
        "order_number_productOrder": "# Orden",
        "order_modified_provider": "Modificada por el proveedor",
        "apply_to": "Aplicado a",
        "except_to": "Excepto a",
        "no_group_filter": "Sin filtro de grupo",
        "no_suscription_filter": "Sin filtro por suscripción",
        "apply_to_some_clients": "Aplica a ciertos clientes",
        "days_of_the_week": "Días de la semana",
        "days_times": "Horas del día",
        "temporality": "Temporalidad",
        "when": "Cuándo",
        "alert_new_discount_text": "Estas a punto de duplicar una plantilla de email, por favor ingresa un nuevo nombre.",
        "delete_scheduled_delivery": "¿Esta seguro de eliminar este envío programado?",
        "validity_label_discount": "Validéz",
        "cancel_url": "URL de cancelación",
        "Active": "Activar",
        "search_label_discount": "Busca un descuento por nombre...",
        "no_expiration_date": "Sin fecha de vencimiento",
        "one_title": "Marketing Online",
        "one_description": "Acompañamiento digital en marketing",
        "one-plus_title": "Marketing Online",
        "one-plus_description": "Acompañamiento digital en marketing y acceso ilimitado de agendas",
        "alert_delete_discount": "Estás a punto de eliminar este descuento. ¿Deseas continuar?",
        "alert_disable_discount": "Estás a punto de deshabilitar este descuento ¿Deseas continuar?",
        "alert_enable_discount": "Estas a punto de activar esta descuento ¿Deseas continuar?",
        "hour_range_error": "Si escoges un rango horario, debes seleccionar tanto la hora de inicio (desde) como la hora final (hasta).",
        "all_days": "Todos los días",
        "_all_": "Cualquier servicio del centro",
        "shop": "Tienda",
        "shop_warehouse": "Almacén tienda",
        "central_warehouse": "Almacén central",
        "test_warehouse": "Almacén de prueba",
        "origin": "Origen",
        "destination": "Destino",
        "movements_history": "Histórico de movimientos",
        "move_between_warehouses": "Mover entre almacenes",
        "registered_warehouses": "Almacenes registrados",
        "registered_warehouses_description": "Añade, edita o deshabilita los almacenes",
        "main_warehouse": "Almacén principal",
        "warehouse_store": "Almacén tienda",
        "multi_werehouse": "Multi-almacén",
        "message_templates": "Plantillas de mensajes",
        "receipt_send": "Se ha enviado el cobro a ",
        "idProvider": "Proveedor",
        "level": "Nivel",
        "discount_days_error": "Debe ingresar al menos un día de la semana para guardar el descuento.",
        "activate_automatic_cash_movement": "Activar movimientos de caja automático",
        "Choose_the_day_to_transfer_the_cash_closing": "Selecciona el día al que quieres traspasar el importe que dejas en cajón. Si no lo haces ahora, luego no podrás recuperar este registro.",
        "cancel_request": "Cancelar pedido",
        "canceled": "Cancelados",
        "can_not_cancel_order": "No se puede cancelar pedidos con cobros, con factura emitida o una orden de producto asociada",
        "see_detail": "Ver detalle",
        "amount_without_discount": "Importe sin dcto.",
        "consecutive_number": "Número consecutivo",
        "not_enough_stock": "Cantidad de productos no disponibles",
        "what_to_do_with_the_debt": "¿Que hacer con la deuda?",
        "let_detb_as_settled": "Dejar la deuda saldada",
        "add_detb_to_client_card": "Añadir la deuda al balance del cliente",
        "date_invoice_column": "Fecha de la factura",
        "invoices": "Facturas",
        "your_invoice_summary": "Tu resumen de facturas",
        "information_received_product_order_linked_to_ticket": "¡La orden de producto ha sido recibida!, recuerda que se encuentra vinculada al pedido #",
        "invoice_issued_customer": "Con factura emitida al cliente",
        "generic_invoice": "Con factura genérica",
        "invoice_canceled": "Con factura cancelada",
        "send_generic_invoice": "Enviar factura genérica",
        "sendings_bills_title": "Envío de facturas por correo electrónico",
        "sendings_bills_desc": "Envía un e-mail con la factura de renovación de la suscripción.",
        "sendings_bills_check": "Si, quiero enviar un e-mail con la factura.",
        "send_generic_invoice_client": "Utilizar cliente genérico para factura",
        "issuing_invoice": "Emitiendo factura...",
        "cancel_bill": "Refacturar",
        "cf_receipt": "Enviar recibo por e-mail",
        "rebilling": "Refacturando...",
        "eb_title": "Envío de facturas para tarea automática",
        "eb_desc": "Se enviará en un periodo de tiempo las facturas a la siguiente lista de correos / facturación electrónica",
        "eb_placeholder": "Correo electrónico contable",
        "folio": "Folio",
        "serie": "Serie",
        "ncontrol": "Nº Control",
        "commissions_to_apply": "Selecciona empleado",
        "charge_another_user": "Cobrar para otro usuario",
        "amount_without_taxes": "Importe sin impuestos",
        "fiscal_folio": "Folio fiscal",
        "description_cost_shipping": "Se aplicará este costo extra a tus clientes, el cual cubre los gastos de envío al comprar productos online (no incluye impuestos).",
        "discount": "Descuento",
        "delete_agree": "¿Deseas eliminar esta aplicación?",
        "return_payment_methods": "Volver a los medios de pago.",
        "sin generar cobros? Ésta acción no puede deshacerse...": "sin generar cobros? Ésta acción no puede deshacerse...",
        "no_login_required_text": "No solicitar inicio de sesión al empezar la reserva ",
        "error_actual_day": "La fecha no puede ser mayor a la fecha actual",
        "continue_pay_amount": "Para continuar, indica el importe a pagar con el medio de pago seleccionado",
        "Factura": "Factura",
        "Factura simplificada": "Factura simplificada",
        "Dirección": "Dirección",
        "F. de emisión": "F. de emisión",
        "de": "de",
        "Concepto": "Concepto",
        "Cantidad": "Cantidad",
        "Impuesto": "Impuesto",
        "Descuento comercial": "Descuento comercial",
        "Web Editor": "Web Editor",
        "Rankings": "Rankings",
        "Social Media": "Social Media",
        "Bewe Software": "Bewe Software",
        "APP": "APP",
        "Onboarding Personalizado": "Onboarding Personalizado",
        "Especialista en diseño web": "Especialista en diseño web",
        "Web editor Onepage": "Web editor Onepage",
        "Web editor Multipage": "Web editor Multipage",
        "Web editor Onepagespecial": "Web editor Onepagespecial",
        "Dominio Personalizado": "Dominio Personalizado",
        "Total (base imponible)": "Total (base imponible)",
        "Total a pagar": "Total a pagar",
        "Inscrita en el Registro Mercantil de Madrid, Tomo 33594, Folio 46, Sección 8, Hoja M-604664, NIF B87309464": "Inscrita en el Registro Mercantil de Madrid, Tomo 33594, Folio 46, Sección 8, Hoja M-604664, NIF B87309464",
        "Inscrita en el Registro Mercantil de Barcelona, Tomo 44108, Folio 219, Hoja 447187, NIF B66204751": "Inscrita en el Registro Mercantil de Barcelona, Tomo 44108, Folio 219, Hoja 447187, NIF B66204751"
};
});