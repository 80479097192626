export const dexie = [
  'cashmoney',
  'client',
  'clientdiscount',
  'clientsubscription',
  'discount',
  'employee',
  'eventclass',
  'product',
  'ticket',
  'treatment',
  'work',
  'subscription',
  'provider',
];

export const collections = [
  'budgets',
  'clientcourses',
  'clienteventclasses',
  'clientdiscounts',
  'clientsubscriptions',
  'clients',
  'courses',
  'discounts',
  'employees',
  'eventclasses',
  'products',
  'providers',
  'subscriptions',
  'tickets',
  'treatments',
  'works',
  'logworkstates'
]

export const aggregates = [
    'match',
    'project',
    'lookup',
    'group',
    'unwind',
    'replaceRoot'
].sort((a, b) => (a.localeCompare(b)))
.map(item => [item, item]);


export const dexieWhereClauses = [
  ['==', 'equals'],
  ['>', 'adove'],
  ['!=', 'notEqual'],
  ['<', 'bellow'],
  ['>=', 'aboveOrEqual'],
  ['<=', 'bellowOrEqual'],
  ['between', 'between'],
  ['between with upper', 'between|upper'],
  ['between with lower', 'between|lower'],
  ['between with upper and lower', 'between|upper|lower'],
];

export const dateFilters = [
  ['$fromDate', '$fromDate'],
  ['$toDate', '$toDate'],
  ['$fromDateUTC', '$fromDateUTC'],
  ['$toDateUTC', '$toDateUTC'],
  ['$fromDateISO', '$fromDateISO'],
  ['$toDateISO', '$toDateISO'],
  ['$toDateUTC:previousDays:30', '$toDateUTC:previousDays:30'],
];

export const filterList = [
  ['Cliente', 'clientID'],
  ['Grupo de clientes', 'clientGroup'],
  ['Atributos del cliente', 'extraAttrsFilter'],
  ['Productos', 'products'],
  ['Categoría de productos', 'productCategory'],
  ['Servicio', 'treatments'],
  ['Categoría de servicios', 'treatmentCategory'],
  ['Grupos de servicios', 'treatmentsList'],
  ['Suscripción', 'subscription'],
  ['Estado de suscripción', 'subscriptionState'],
  ['Tipo de descuento', 'discount'],
  ['Empleado', 'employee'],
  ['Bono', 'pack'],
  ['Estados de citas o clases', 'workState'],
  ['Empleados', 'employeeMulti']
]

export const filterDate = [
  ['desde', 'fromDate'],
  ['hasta', 'toDate'],
];

export const compareOperators = [
  ['=', '=='],
  ['≠', '!='],
  ['<', '<'],
  ['≤', '<='],
  ['>', '>'],
  ['≥', '>='],
  ['in', 'in'],
  ['includes', 'includes'],
];

export const timeRanges = [
  ['segundos', 'seconds'],
  ['minutos', 'minutes'],
  ['horas', 'hours'],
  ['semanas', 'weeks'],
  ['meses', 'months'],
  ['años', 'years'],
];

export const dateFormatList = [
  ['date', 'date'],
  ['timestamp', 'timestamp'],
  ['iso', 'iso'],
  ['utc', 'utc'],
];

export const simpleFormats = [
    ['Número', 'number'],
    ['Decimal', 'decimal'],
    ['Porcentaje', 'percentage'],
    ['Moneda', 'currency']
  ]

export const formats = [
   ...simpleFormats,
  ['Texto', 'string'],
  ['Fecha y hora', 'datetime'],
  ['Fecha corta', 'shortDate'],
  ['Fecha larga', 'date'],
  ['Booleano', 'boolean']
]