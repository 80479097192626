class ObjectId {
    constructor(objectId) {
        this.objectId = objectId
        if(!this.isValid()) {
            this.increment = (Math.floor(Math.random() * (16777216))) + 1;
            this.pid = Math.floor(Math.random() * (65536));
            this.machine = Math.floor(Math.random() * (16777216));
            this.timestamp = Math.floor(new Date().valueOf() / 1000);
            this.objectId = !objectId ?  this.toString() : null
        }
    }

    isValid() {
        return !!String(this.objectId).match(/^[0-9a-fA-F]{24}$/)
    }

    getTime() {
        return parseInt(String(this.objectId).substring(0, 8), 16) * 1000
    }

    toDate() {
        return new Date(this.getTime())
    }

    toString() {

        if(this.objectId) {
            this.objectId = String(this.objectId)
            return this.objectId
        }
    
        const timestamp = this.timestamp.toString(16);
        const machine = this.machine.toString(16);
        const pid = this.pid.toString(16);
        const increment = this.increment.toString(16);
        const objectId = '00000000'.substr(0, 8 - timestamp.length) + timestamp +
                '000000'.substr(0, 6 - machine.length) + machine +
                '0000'.substr(0, 4 - pid.length) + pid +
                '000000'.substr(0, 6 - increment.length) + increment;
    
        this.objectId = objectId
        return objectId
    }

    //Deprecated: use toDate
    dateFromObjectId(objectId) {
        return new ObjectId(objectId).toDate()
    }
}

module.exports = ObjectId