// flow

import * as React from 'react';
import * as errors from '../../assets/images/errors';
import styled from 'styled-components';

const FormattedMessage = styled.h1`
  color: red;
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
  margin-top: -40px;
  justify-content: center;
  align-items: center;
  .item {
    img {
      height: 260px;
    }
    .text {
      text-align: center;
      color: red;
    }
  }
`;

function ErrorPage({ code = 404 }) {
  return(
  <Container>
    <div className="item">
      <img src={errors[`error${code}`]} />
      <div className="text">
        <h1 className="code">{code}</h1>
        <p>
          <FormattedMessage id={`error${code}`} />
        </p>
      </div>
    </div>
  </Container>
)};

export default ErrorPage;
