import * as Blockly from 'blockly/core';

const color = 220

Blockly.Blocks['query:count'] = {
    init() {
      this.appendDummyInput().appendField('count');
      this.setPreviousStatement(true, ['Array']);
      this.setNextStatement(true, 'value');
      this.setColour(color);
      this.setTooltip('');
      this.setHelpUrl('');
    },
  };
  
  Blockly.Blocks['query:sum'] = {
    init() {
      this.appendDummyInput()
          .appendField('sum')
          .appendField(new Blockly.FieldTextInput(''), 'sum_field')
  
      this.setPreviousStatement(true, 'Array');
      this.setNextStatement(true, 'value');
      this.setColour(color);
      this.setTooltip('');
      this.setHelpUrl('');
    },
  };
  
  Blockly.Blocks['query:avg'] = {
      init() {
        this.appendDummyInput()
            .appendField('avg')
            .appendField(new Blockly.FieldTextInput(''), 'avg_field')
    
        this.setPreviousStatement(true, 'Array');
        this.setNextStatement(true, 'value');
        this.setColour(color);
        this.setTooltip('');
        this.setHelpUrl('');
      },
    };