var _ = require("./underscore");

function beRound(num, rlength = 2) {
  if (isNaN(num)) return 0;
  return Math.round(+num*Math.pow(10,rlength))/Math.pow(10,rlength);
}

function beFixed(n, fractionDigits= 2) {
  let num =+n;
	if (isNaN(num)) return fractionDigits ? '0.'+'0'.repeat(fractionDigits): 0;
	num = beRound(num);
	if (Number.isInteger(num)) {
	  return fractionDigits ? String(num) + '.'+'0'.repeat(fractionDigits) : String(num);
  }
  const numString = String(num) 
  const fractionDigitsPending = fractionDigits - numString.split('.').pop().length;
  return numString+'0'.repeat(fractionDigitsPending);
}

const cformatMap = {
  usd: "$%s.%s",
  eur: "%s.%s€"
};

//Sum: sum attribute of all collection items
_.bh = {};


_.bh.trim = function(str) {
  return str && str.trim ? str.trim() : str
}

_.bh.zeroLeft = function(str, numZeros) {
  if (_.isNumber(str)) {
    str = str.toString();
  }
  var zeros = Math.max(0, numZeros - str.length);
  var zeroString = Math.pow(10, zeros)
    .toString()
    .substr(1);
  return zeroString + str;
};

_.bh.sum = function(arr, attr, startSum) {
  return _.reduce(
    arr,
    function(memo, item) {
      return memo + (item[attr] || 0);
    },
    startSum || 0
  );
};

_.bh.capitalize = function(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

_.bh.toPrice = function(num, options) {
  options ||
    (options = { });
/*  cformat: "%s,%s",
      options: "text",
      bhprice: true,
      country: { idKey: "es" }*/

  let _window = {};
  try {
    _window = window;
  } catch (error) {}

  var number = parseFloat(num);
  var country = options.country
    ? options.country.idKey
    : _window.sessionUser && _window.sessionUser.country;
  var countryObj = options.country || _window.sessionUser;

  var format = function(units, decimals) {
    if (country === "co" && decimals === "00") decimals = "";
    var str =options.cformat
      ? options.cformat
      : options.bhprice
      ? countryObj.bhcformat
      : countryObj.cformat;

    for (let arg in arguments)
      str = str.replace(RegExp("\\%s", "i"), arguments[arg]);
    if (_.last(str) === "," || _.last(str) === ".")
      str = str.substr(0, str.length - 1);
    return str;
  };

  if (_.isNumber(number)) {
    var arr = beFixed(number, 2).split(".");
    country = (country || "").replace(/w*(\d)/, ""); // removing number extension (es2, cr2, ...)
    if (country === "ar") country = "es-AR"; // arabic lang..
    if (parseInt(arr[0]).toLocaleString)
      arr[0] = parseInt(arr[0]).toLocaleString(country || 'es');
    if (options.format === "html") {
      arr[0] = '<span class="units number">' + arr[0] + "</span>";
      arr[1] = '<span class="decimals number">' + arr[1] + "</span>";
    }
    return format(arr[0] || "0", arr[1] || "00");
  } else {
    return "---";
  }
};

_.bh.payPrice = function(value, country, currency) {
  var cformat = currency
    ? cformatMap[currency.toLowerCase()] || country.bhcformat
    : country.cformat;

  return _.bh.toPrice(value, { country, cformat });
};

/**
 * Input Value
 * value should be an string with 24h format. 
 * If value has one or two digits -> its just the hour. 
 * If value has four digits -> hour + minutes
 * If value has six digits -> hour + minutes + seconds
 * value=Date
 * value=3        -> 3:00:00
 * value=330      -> 3:30:00
 * value=2300     -> 23:00:00
 * value=230303   -> 23:03:03
 * value=030      -> 00:30:00
 *  
 * Input Format: String with variables: %h (hour) %m (minutes) %s (seconds) %d (detail of am/pm in AM/PM mode)
 * default -> %h:%m%sd -> 11:30 pm
 * format='%h%d' -> '23' or '11pm'
 * format='%h:%m %d' -> '23:03' or '11:03 pm'
 */
_.bh.printHour = function (value, format, formatForAMPM) {
  if(value == 'Invalid Date') return value
  if(!value) return ''

  if (_.isNumber(value) && ('' + value).length > 10) value = new Date(value)
  if (_.isString(value) && value.indexOf('T') === 10) value = new Date(value)
    
  if (_.isDate(value)) value = _.bh.zeroLeft(value.getHours(), 2) + _.bh.zeroLeft(value.getMinutes(), 2) + _.bh.zeroLeft(value.getSeconds(), 2)
  if (!_.isString(value)) value = value.toString()

  // Also supports value as format 13:30:30, just need to remove ':' character
  value = value.replace(/\:/g, '')

  var isAMPM = window.sessionUser.hoursAMPM
  var length = value.length

  // if its an odd length -> first zero is missing.
  if (length % 2 == 1) {
    value = '0' + value
  }

  if (length <= 2) {
    value += '0000'
  } else if (length <= 4) {
    value += '00'
  } else if (length > 6) {
    throw new Error(`${value} has wrong format.`)
  } 


  // Check hours, minutes and seconds
  var hours = value.substr(0,2)
  if (parseInt(hours) > 23) throw new Error(`${hours} is not a valid hour value.`)

  var minutes = value.substr(2,2)
  if (parseInt(minutes) > 59) throw new Error(`${minutes} is not a valid minute value.`)

  var seconds = value.substr(4,2)
  if (parseInt(seconds) > 59) throw new Error(`${seconds} is not a valid second value.`)

  // Transform AMPM if necessary
  var ampm = isAMPM ? (hours >= 12 ? 'pm' : 'am') : ''
  if (isAMPM) {
    var hoursInt = parseInt(hours)
    if (hoursInt == 0) {
      hours = '12'       // Exception: when 00:00 -> 12 am
    } else if (hoursInt > 12) {
      var newHour = hoursInt - 12
      hours = (newHour < 10 ? '0' : '') + newHour
    }
  }

  // Apply format
  var appliedFormat = formatForAMPM && isAMPM ? formatForAMPM : format
  return (appliedFormat || '%h:%m%sd')
    .replace('%h', hours)                 // Hours
    .replace('%o', parseInt(hours))       // Hours (removing left zero)
    .replace('%m', minutes)               // Minutes
    .replace('%s', seconds)               // Seconds
    .replace('%d', ampm)                  // am|pm
}

module.exports = _;
