export const theme = {
  scheme: 'monokai',
  base00: '#fff',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#4CAF50',
  base0C: '#a1efe4',
  base0D: '#42a1b3',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};
