import React from 'react';
import BlocklyComponent from './BlocklyComponent';

export default BlocklyComponent

export const Block = (p) => {
  const { children, ...props } = p;
  props.is = 'blockly';
  return React.createElement('block', props, children);
};

export const Category = (p) => {
  const { children, ...props } = p;
  props.is = 'blockly';
  return React.createElement('category', props, children);
};

export const Value = (p) => {
  const { children, ...props } = p;
  props.is = 'blockly';
  return React.createElement('value', props, children);
};

export const Field = (p) => {
  const { children, ...props } = p;
  props.is = 'blockly';
  return React.createElement('field', props, children);
};

export const Shadow = (p) => {
  const { children, ...props } = p;
  props.is = 'blockly';
  return React.createElement('shadow', props, children);
};

export const Mutator = (p) => {
    const { children, ...props } = p;
    props.is = 'blockly';
    return React.createElement('mutation', props, children);
}
