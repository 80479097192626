import styled from 'styled-components'

export const FormControl = styled.div.attrs({
  className: 'flex align-center mb3'
})`
 label {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   margin-right: 20px;
   width: 15%;
 }
`

export const Button = styled.button.attrs({
  className: 'pa2 w-100 db',
})`
  background: #22a9b0;
  color: #fff;
`;
