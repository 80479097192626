// import concat from 'lodash/fp/concat';
import { computer } from 'common/reports/helpers/middleware';
import create from 'zustand';
import axios from '../../utils/axios';

const globalBaseFilters = { $or: [ { allBHAccount: true }, { _idBHAccount: { $exists: false } } ] }

function formatNamespace(namespace) {
  return String(namespace)
    .replace(/ +(?= )/g, '')
    .trim()
    .toUpperCase()
    .replace(/ /g, '_');
}

function formatJSON(json) {
  try {
    return JSON.parse(json)
  } catch (e) {
    return json
  }
}

function initialState() {
  return {
    isOpenModal: false,
    isOpenModalInfo: false,
    isOpenModalDuplicate: false,
    selectedTemplate: {},
    templatesByNamespace: [],
    deleteModal: false,
    deleteRow: false,
    templates: [],
    namespace: '',
  };
}

function getTemplateByNamespace(namespace, baseFilters = {}) {
  return axios.get('/models/pulltemplates/find', {
    params: {
      offset: 0,
      limit: 1000,
      sort: 'language',
      filter: { namespace: { $regex: `^${namespace}$`, $options: 'i' }, ...globalBaseFilters, ...baseFilters }
    },
  });
}

function effects(set, get) {
  return {
    async loadTemplates() {
      const results = await axios.get('/models/pulltemplates/find', {
        params: {
          offset: 0,
          limit: 1000,
          sort: 'namespace',
          filter: globalBaseFilters
        },
      });

      const templates = (results || []).reduce((initial, current) => {
        const namespace = current.namespace
        const language = current.language
        const index = initial.findIndex(prev => prev.namespace === namespace)
        if (index === -1) {
          return [{ namespace, languages: [language] }, ...initial]
        }

        const languages = initial[index].languages || []
        languages.push(language)

        return initial
      }, [])

      templates.reverse()

      set({ templates });
    },

    async closeTemplate() {
      set({ isOpenModalDuplicate: false, isOpenModalInfo: false, isOpenModal: false, selectedTemplate: {} })
    },

    async openTemplate(selectedTemplate) {
      set({ selectedTemplate, isOpenModal: true })
    },

    async openEditModal(selectedTemplate) {
      set({ selectedTemplate, isOpenModalInfo: true })
    },

    async openDuplicate(selectedTemplate) {
      set({ selectedTemplate, isOpenModalDuplicate: true })
    },

    async saveDuplicateTemplate() {
      const dataToSave = get().selectedTemplate
      delete dataToSave._id
      get().effects.saveTemplate(dataToSave)
    },

    async toggleDeleteModal(row) {
      set({ deleteModal: !!row, deleteRow: row })
    },

    async deleteTemplateLanguage() {
      const { deleteRow, effects } = get()
      await axios.delete('models/pulltemplates/' + deleteRow._id);
      effects.loadTemplateByNamespace(deleteRow.namespace)
      set({ deleteModal: false, deleteRow: false })
    },

    async saveTemplate(formData) {
      const { selectedTemplate, namespace } = get()
      const id = selectedTemplate._id
      const language = formData.language ? String(formData.language).toLowerCase() : selectedTemplate.language
      const type = (formData.type ? String(formData.type).toLowerCase() : selectedTemplate.type) || 'notification'
      const formatedNamespace = formData.namespace ? formatNamespace(formData.namespace) : formatNamespace(selectedTemplate.namespace)
      const emailDesign = formData.emailDesign ? formatJSON(formData.emailDesign) : selectedTemplate.emailDesign
      const dataToSave = {
        ...selectedTemplate,
        ...formData,
        allBHAccount: true,
        namespace: formatedNamespace,
        emailDesign,
        language,
        type
      }

      const hasNamespace = !!String(dataToSave.namespace).trim()
      const hasLang = !!String(dataToSave.language).trim()
      if (hasNamespace && hasLang) {
        if (id) {
          await axios.put('models/pulltemplates/' + id, dataToSave);
        } else {
          await axios.post('models/pulltemplates', dataToSave);
        }


        const templatesByNamespace = await getTemplateByNamespace(namespace) || []
        for(let template of templatesByNamespace) {
          await axios.put('models/pulltemplates/' + template._id, { type });
        }
        
        const { loadTemplateByNamespace, loadTemplates, closeTemplate } = get().effects
        namespace ? loadTemplateByNamespace(namespace) : loadTemplates()
        closeTemplate()
      }
    },

    async loadTemplateByNamespace(namespace) {
      const templatesByNamespace = await getTemplateByNamespace(namespace)
      set({ templatesByNamespace, namespace })
    }
  }
}

const computed = {

};

const store = (set, get) => ({
  ...initialState(),
  effects: effects(set, get),
  computed,
});

export const stored = computer(store);
const [useStore] = create(stored);
export default useStore;
