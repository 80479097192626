import React from 'react' 
import { EmailEditor as DefaultEmailEditor } from 'buwi';
import { useParams } from 'react-router-dom';
import useTemplatesStore from '../store';

function EmailEditor() {
    const { namespace } = useParams();
    const { effects, isOpenModal, selectedTemplate } = useTemplatesStore();
    return (
        <DefaultEmailEditor 
            isOpen={isOpenModal}
            onClose={() => effects.closeTemplate()}
            title={String(`${namespace} (${selectedTemplate.language})`).toUpperCase()}
            template={selectedTemplate.emailDesign}
            commitBtn="Guardar"
            onCommit={({ design, html }) => {
                effects.saveTemplate({
                    emailDesign: design,
                    emailHtml: html
                })
            }}
        />
    )
}

export default EmailEditor