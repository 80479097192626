/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-closing-bracket-location */
import React, { useEffect, useState } from 'react';
import { IconPicker, TagPicker, SelectPicker } from 'buwi';
import { FormControl } from './styles';
import icons from '../data/icons';
import filters from '../data/filters';
// import tableList from '../data/tableTypes';
// import charts from '../data/charts';
import accountTypes from '../data/accountTypes';
import dateBoundsOptions from '../data/dateBoundsOptions'
import hiddenTimespanOptions from '../data/hiddenTimespan'
import useReportStore from '../store';

function versionToString(version) {
  if (!version) return '';
  const { major, minor = 0, build = 0 } = version;
  if(!major) {
      return ''
  }

  return [major, minor, build].join('.')
}

function getEnableIf(enableIf) {
  const { withFeature, withoutFeature } = enableIf || {};
  if (withoutFeature) {
    return withoutFeature.includes('beautyStore')
      ? 'noBeautyStore'
      : 'noWellness';
  }
  if (withFeature) {
    return withFeature[0];
  }

  return false;
}

const General = () => {
  const { effects, showReport } = useReportStore();
  const [type, setType] = useState(false);
  const {
    icon,
    plugins,
    omitReports,
    customCenters,
    minVersion,
    maxVersion,
    enabledIf,
    dateBounds,
    hiddenTimespans
  } = showReport;

  useEffect(() => {
    if (plugins) {
      setType(getEnableIf(enabledIf));
    }
  }, [plugins]);

  if (!plugins) {
    return null;
  }

  const handleSetType = (value) => {
    effects.setEnabledIf(value);
    if (value === 'all') {
      setType(false);
      return;
    }
    setType(value);
  };

  // const handleSelectChangePlugins = (plugin, list) => {
  //   const newPlugins = (plugins || []).filter(
  //     (plugin) => !list.find(({ value }) => value === plugin),
  //   );
  //   if (plugin) {
  //     newPlugins.push(plugin);
  //   }

  //   effects.setPlugins(newPlugins);
  // };

  const handleTagChangePlugins = (item) => {
    if (item && Array.isArray(item)) {
      const newPlugins = showReport.plugins.filter(
        (plugin) => !filters.some(({ value }) => value === plugin),
      );
      effects.setPlugins([...new Set([...newPlugins, ...item])]);
    }
  };

  return (
    <div>
      <FormControl>
        <label>Icono</label>
        <IconPicker
          i18n={{}}
          prefix="icon-"
          icons={icons}
          value={icon}
          onSelect={(icon) => effects.setIcon(icon)}
        />
      </FormControl>
      {/* <FormControl>
        <label>Tipo de tabla</label>
        <SelectPicker
          style={{ width: '80%' }}
          data={tableList}
          value={(plugins || []).find((plugin) =>
            tableList.find(({ value }) => value === plugin),
          )}
          onSelect={(plugin) => handleSelectChangePlugins(plugin, tableList)}
        />
      </FormControl> */}
      {/* <FormControl>
        <label>Tipo de gráfico</label>
        <SelectPicker
          style={{ width: '80%' }}
          data={charts}
          value={
            (plugins || []).find((plugin) =>
              charts.find(({ value }) => value === plugin),
            ) || ''
          }
          onSelect={(chart) => handleSelectChangePlugins(chart, charts)}
        />
      </FormControl> */}
      <FormControl>
        <label>Lista de filtros</label>
        <TagPicker
          style={{ width: '80%' }}
          data={filters}
          defaultValue={plugins}
          onChange={handleTagChangePlugins}
        />
      </FormControl>
      {plugins.includes('timespan') &&
        <FormControl>
            <label>Lapso de tiempo (ocultos)</label>
            <TagPicker
                style={{ width: '80%' }}
                data={hiddenTimespanOptions}
                defaultValue={hiddenTimespans}
                onChange={effects.setHiddenTimespans}
            />
        </FormControl>
      }

      <FormControl>
        <label>Límites de filtro de fecha</label>
        <SelectPicker
          style={{ width: '64%' }}
          data={dateBoundsOptions}
          value={dateBounds || 'all'}
          onChange={effects.setDateBounds}
        />
      </FormControl>
      <hr />
      <FormControl>
        <label>Tipos de centro</label>
        <SelectPicker
          style={{ width: '64%' }}
          data={accountTypes}
          value={type || 'all'}
          onChange={handleSetType}
        />
      </FormControl>

      {type === 'custom' ? (
        <FormControl>
          <label>Centros</label>
          <input
            style={{ width: '64%' }}
            className="h2 pl2"
            defaultValue={
              omitReports && customCenters.length
                ? customCenters.join(', ')
                : ''
            }
            onKeyUp={(e) =>
              effects.setCustomCenters(
                e.target.value.split(',').filter((item) => item),
              )
            }
          />
        </FormControl>
      ) : (
        <FormControl>
          <label>Excepciones</label>
          <input
            style={{ width: '80%' }}
            className="h2 pl2"
            defaultValue={
              omitReports && omitReports.length ? omitReports.join(', ') : ''
            }
            onKeyUp={(e) =>
              effects.setOmitReports(
                e.target.value.split(',').filter((item) => item),
              )
            }
          />
        </FormControl>
      )}

      <hr />
      <FormControl>
        <label>Versión minima</label>
        <input
          style={{ width: '64%' }}
          className="h2 pl2"
          defaultValue={versionToString(minVersion)}
          onKeyUp={(e) => effects.setMinVersion(e.target.value.trim())}
        />
      </FormControl>
      <FormControl>
        <label>Versión máxima</label>
        <input
          style={{ width: '64%' }}
          className="h2 pl2"
          defaultValue={versionToString(maxVersion)}
          onKeyUp={(e) => effects.setMaxVersion(e.target.value.trim())}
        />
      </FormControl>
    </div>
  );
};

export default General;
