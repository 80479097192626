/* eslint-disable radix */
import { css } from 'styled-components';

const breakpoints = {
  lg: 1280,
  md: 768,
  sm: 480,
  xs: 320,
};

const media = Object.keys(breakpoints).reduce((acc, label) => {
  const mediaQuery = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;

  return { ...acc, [label]: mediaQuery };
}, {});

function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
}

function mix(color1, color2, weight) {
  function d2h(d) {
    return d.toString(16);
  } // convert a decimal value to hex
  function h2d(h) {
    return parseInt(h, 16);
  } // convert a hex value to decimal

  const weight2 = typeof weight !== 'undefined' ? weight : 50; // set the weight to 50%, if that argument is omitted

  let color = '#';

  for (let i = 0; i <= 5; i += 2) {
    // loop through each of the 3 hex pairs—red, green, and blue
    const v1 = h2d(color1.replace('#', '').substr(i, 2)); // extract the current pairs
    const v2 = h2d(color2.replace('#', '').substr(i, 2));
    // combine the current pairs from each source color, according to the specified weight
    let val = d2h(Math.floor(v2 + (v1 - v2) * (weight2 / 100.0)));

    while (val.length < 2) {
      val = '0' + val;
    } // prepend a '0' if val results in a single digit

    color += val; // concatenate val to our new color string
  }

  return color; // PROFIT!
}

function theme() {
  const highColor = '#22a9b0';
  const leftmenusb = '#082c42';
  const leftmenusf = '#ffffff';
  const circleColor = mix(leftmenusb, '#ffffff', 50);

  const menuToggleTopbarBackground = leftmenusb;
  const highColorSelect = mix(leftmenusb, '#f9f9f9', 75);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const icon = {
    back: 'icon-left-open-mini',
    save: 'icon-check_alt',
    edit: 'icon-editar',
    cancel: 'icon-x',
    add: 'icon-plus',
    arrowLeft: 'icon-left',
    arrowRight: ' icon-right',
    iconDownMicro: 'icon-down-micro',
  };

  return {
    highColor,
    leftmenusb,
    leftmenusf,
    circleColor,
    highColorSelect,
    menuToggleTopbarBackground,
    icon,
    media,

    colorBase: '#b2a5c8',
    colorBaseStrong: '#a693c8',
    colorBaseLight: '#c5bbd4',
    colorBaseWhite: shadeColor('#e8e4ee', 20),
    colorBaseBlack: shadeColor('#e8e4ee', -200),
    complement1: '#ca84d3',

    overlight: mix(highColor, '#ffffff', 10),
    gray: '#E5E5E5',
    grayBg: '#FBFBFA',
    grayFont: '#666868',
    grayBorder: '#E1E1E3',
    grayStrong: '#E1E5E8',
    black: '#353637',
    colorDelete: '#b80000',
    colorSuccess: '#2A9A1D',
    colorError: '#A11',
    bewePrimary: '#0099A7',
    beweSecondary: '#00273B',
    topbarBg: '#fff',
    topbarBorder: '#e8e7e7',

    beweBackground: '#f9f9f9',
    iconColorMenu: '#092c42',
    isSafari,
    fontFamily: 'Helvetica Neue',
  };
}

export { theme, shadeColor, mix };
