/* eslint-disable no-nested-ternary */
import {
    endOfDay,
    startOfDay,
    eachDay,
    isMonday,
    isEqual,
    startOfMonth,
    isAfter,
  } from 'date-fns';
  import { timespanDate, forwardDate, backwardDate } from './libs/helpers';
  
  const DEFAULT_FILTERS = {
    fromDate: startOfDay(new Date()),
    toDate: endOfDay(new Date()),
    billType: 'all',
    cashs: [],
    opticas: "category",
    employees: [],
    kind: [
      'product',
      'treat',
      'work',
      'pack',
      'special',
      'prepaid',
      'debt',
      'giftcard',
      'subscription',
    ],
    status: 'accounted',
    timespan: 'day',
    waysToPay: [],
    insurances: [],
    subscription: false,
    products: false,
    productCategory: false,
    treatments: false,
    bhaccount: false,
    treatment: '',
    category: '',
    clientGroup: false,
    subscriptionState: 'old-subscriptions',
    extraAttr: false,
    extraAttrValue: '',
    groupDate: 'week',
    productivityTreatment: false,
    employee: false,
    discount: false,
    clientID: false,
  };
  
  function initialState() {
    return {
      dbcache: {},
      bhaccount: {},
      dexie: {},
      views: [],
      webService: false,
      showReport: false,
      suggestions: [],
      results: [],
      namedPipes: {},
      overview: [],
      aggregated: [],
      loading: true,
      firstTimeLoad: false,
      loadingOverview: false,
      loadingAggregation: false,
      loadingResults: false,
      childAccounts: [],
      globalVar: {},
      selected: {},
      filters: {
        ...DEFAULT_FILTERS,
      },
    };
  }
  
  function effects(set, get) {
    return {
      async show(report) {
        const {
          onRawChange,
          changeDates,
          _showReport,
          updateReport,
          onChangeDateError,
        } = get().effects;
  
        // Reset filters
        set({ filters: { ...get().filters, ...DEFAULT_FILTERS }})
        const reportDefaultFilters = report.defaultFilters || {};
  
        // Apply list of default filters using onRawChange.
        const rawState = Object.keys(reportDefaultFilters).reduce((initial, filter) =>
          ({ ...initial, ...onRawChange(filter, reportDefaultFilters[filter], false)})
        , {});
  
        if ('dateBounds' in report && report.dateBounds === 'previousPeriod') {
          const changeDateFilters = changeDates(backwardDate, onChangeDateError, false);
          rawState['filters'] = { ...rawState.filters, ...changeDateFilters.filters }
        }
  
        report.columns = get().effects.getColumns(report)
        _showReport(report, rawState);
        updateReport();
      },
      async calculateOverview(results) {
          const { showReport } = get()
          const { blocks } = showReport;
          const { pipeline } = get().effects;
          const batch = Promise.resolve(results);
    
          if (blocks) {
            if (
              blocks.overview &&
              blocks.overview.xml &&
              blocks.overview.xml.block &&
              blocks.overview.xml.block.statement
            ) {
    
              const extractOverview = async (block, overview = []) => {
                const { newpipeline } = get().effects;
                const { field, statement, next } = block
                const label = field[0]._text
                const format = field[1]._text
                const value = await newpipeline.compute(
                  statement.block,
                  newpipeline.computeOp({
                    ...effects,
                    ...get(),
                    namedPipes: [],
                  }),
                  batch,
                  batch
                );
    
                overview.push({ label, value, format })
                if(next && next.block) {
                    return extractOverview(next.block, overview)
                }
    
                return overview
              }
    
              const overviewData = await extractOverview(blocks.overview.xml.block.statement.block)
              const overview = [overviewData.map(item => item.value)]
              return { overview, loadingOverview: false, showReport: { ...showReport, overview: overviewData }}
            }
          }
    
          const overviewJSON = get().showReport.overview
            ? Array.isArray(get().showReport.overview[0])
              ? get().showReport.overview
              : [get().showReport.overview]
            : [[]];
    
          const overview = await Promise.all(
            overviewJSON.map((list) =>
              Promise.all(
                list.map((item) =>
                  pipeline.computePipe
                    ? pipeline.computePipe(item.pipeline, batch, batch, get())
                    : [],
                ),
              ),
            ),
          );
  
          return { overview }
        },
      async updateOverview(results, aditionState = {}) {
        const { calculateOverview } = get().effects;
        const state = await calculateOverview(results)
        set({ ...state, ...aditionState });
      },
      async calculateAggregated(results) {
        const { pipeline } = get().effects;
  
        return await Promise.resolve(get()).then((state) => {
          const { filters, showReport } = state;
          if (showReport === false) {
            return [];
          }
          if (showReport.plugins.includes('table.aggregated') === false) {
            return [];
          }
          if ('rows' in showReport === false) {
            return [];
          }
          const { rows } = showReport;
          const { fromDate, toDate, groupDate } = filters;
          const { to } = timespanDate(groupDate);
  
          // Compute a list of tuples containing the date ranges for the groups.
          const dates = eachDay(fromDate, toDate).filter((date) => {
            if (groupDate === 'month') {
              return isEqual(date, startOfMonth(date));
            }
            if (groupDate === 'day') {
              return true;
            }
            return isMonday(date) || isEqual(date, fromDate);
          });
          const groups = dates.map((date) => [date, to(date)]);
  
          // Then compute the subset of results->rows for the groups.
          const grouped = groups.map((dates) => {
            const [from, to] = dates;
            const _from = from.getTime();
            const _to = to.getTime();
            const filtered = results.map((result) => {
              const { list } = result;
              const sublist = list.filter(
                (item) =>
                  Number(new Date(item.date)) >= _from &&
                  Number(new Date(item.date)) <= _to,
              );
              sublist.result = result;
              const batch = Promise.resolve(sublist);
              const rowsFormated = pipeline.formatRows(list, rows);
              const computedRows = rowsFormated.map((row) => {
                return pipeline.computePipe(row.pipeline, batch, batch, state);
              });
  
              return Promise.all(computedRows).then((rows) => ({
                ...result,
                list: sublist,
                rows,
              }));
            });
  
            return Promise.all(filtered).then((results) => ({ results, dates }));
          });
  
          return Promise.all(grouped);
        });
      },
      async updateReport(aditionalState = {}) {
        function updateNamedPipes(state) {
          const report = state.showReport;
          if (!('namedPipelines' in report)) {
            return [];
          }
          return Object.keys(report.namedPipelines).map((key) =>
            state.pipeline()
              .compute(report.namedPipelines[key], { ...effects, ...state })
              .then((results) => ({ results, name: key })),
          );
        }
  
        const [results, namedPipes] = await Promise.resolve(get())
          .then((state) =>
            Promise.all([
              Promise.resolve(state), // Keep state in the chain.
              ...updateNamedPipes(state),
            ]),
          )
          .then(([state, ...named]) => {
            const namedPipes = named.reduce((named, result) => {
              named[result.name] = result.results;
              return named;
            }, {});
  
            const { blocks } = state.showReport;
            if (blocks) {
              if (
                blocks.pipeline &&
                blocks.pipeline.xml &&
                blocks.pipeline.xml.block
              ) {
                const { newpipeline } = get().effects;
                const res = newpipeline.compute(
                  blocks.pipeline.xml.block,
                  newpipeline.computeOp({
                    ...effects,
                    ...state,
                    namedPipes,
                  }),
                );
                return Promise.all([res, Promise.resolve(named)]);
              }
            }
  
            const { pipeline } = get().effects;
            if (pipeline.compute) {
              // We solved the namedPipes first so we can pull their results in the main pipeline.
              return Promise.all([
                pipeline.compute(state.showReport.pipeline, {
                  ...effects,
                  ...state,
                  namedPipes
                }),
                Promise.resolve(named),
              ]);
            }
  
            return Promise.all([[], Promise.resolve(named)]);
          })
  
        const { calculateOverview, calculateAggregated } = get().effects
        const overviewState = await calculateOverview(results)
        const aggregated = await calculateAggregated(results)
  
        set({
          ...aditionalState,
          ...overviewState,
          results,
          namedPipes,
          selected: {},
          firstTimeLoad: false,
          loadingResults: false,
          columns: [],
          aggregated
        });
      },
      changeDates(fn, onError = false, update = true) {
  
          const state = get()
          const { filters, showReport } = state;
          const { timespan } = filters;
          const { fromDate, toDate } = fn(filters);
          const report = showReport || {};
          if ('dateBounds' in report && report.dateBounds) {
            const { from, to } = timespanDate(timespan);
            const { dateBounds: bounds } = report;
            const todays = to(new Date());
            const previous = backwardDate({
              timespan,
              fromDate: from(new Date()),
              toDate: todays,
            });
            if (bounds === 'previousPeriod' && isAfter(toDate, previous.toDate)) {
              if (onError) {
                onError('PREVIOUS_PERIOD_ERROR');
              }
              return state;
            }
            if (bounds === 'currentPeriod' && isAfter(toDate, todays)) {
              if (onError) {
                onError('CURRENT_PERIOD_ERROR');
              }
              return state;
            }
          }
          const result = {
            filters: {
              ...state.filters,
              fromDate,
              toDate,
            },
          };
  
        update && set({ ...result, firstTimeLoad: true });
        return result
      },
      getColumns(showReport) {
  
        function extractColumns(block) {
  
          if (!block) {
            return []
          }
  
          if (block.block || block.statement) {
            return extractColumns(block.block || block.statement)
          }
  
          if (!Array.isArray(block.field)) {
            return []
          }
  
          const item = block.field.reduce((initial, { _attributes, _text }) => ({ ...initial, [_attributes.name]: _text }), {})
          const next = extractColumns(block.next);
          return [item, ...next]
        }
  
        if (showReport.blocks && showReport.blocks.columns) {
          return extractColumns(showReport.blocks.columns.xml)
        }
  
        return showReport.columns || []
      },
      onRawChange(name, raw, update = true) {
        const { filters } = get();
        const mutations = { [name]: raw };
        if(name === 'productCategory') {
          mutations['products'] = 'false'
        }
        // Timespan change recomputes date range.
        const dateChanges = name === 'timespan' || name === 'date';
        if (dateChanges) {
          const { from, to } = timespanDate(
            name === 'timespan' ? raw : filters.timespan,
          );
  
          mutations.fromDate = from(name === 'date' ? raw : filters.fromDate);
          mutations.toDate = to(name === 'date' ? raw : filters.fromDate);
        }
  
        if (name === 'timespan') {
          const order = ['day', 'week', 'month', 'year'];
          const groupedIdx = order.indexOf(filters.groupDate);
          const timespanIdx = order.indexOf(raw);
          if (groupedIdx > timespanIdx) {
            mutations.groupDate = raw;
          }
        }
  
        const result = {
          filters: {
            ...get().filters,
            ...mutations,
          },
        }
  
        update && set({ ...result, firstTimeLoad: true })
        return result
      },
      rawToggleSelected(status, update = true) {
        const { results } = get()
        const selected = results.reduce((acc, item) => ({ ...acc, [item.id]: status }),{});
        update && set({ selected })
        return { selected }
      },
      updateFilter(name, value = []) {
        const { updateReport, onRawChange } = get().effects;
        onRawChange(name, value);
        updateReport();
      },
      todayDate() {
        const { updateReport, changeDates, onChangeDateError } = get().effects;
  
        changeDates(({ timespan }) => {
          const { from, to } = timespanDate(timespan);
          return { fromDate: from(new Date()), toDate: to(new Date()) };
        }, onChangeDateError);
  
        updateReport();
      },
      _showReport: (showReport = false, additionFilters = {}) =>
        set({ ...additionFilters, showReport, firstTimeLoad: true }),
      loading: (loading) => set({ loading }),
      suggestions: (suggestions = []) => set({ suggestions }),
      loadingOverview: (loadingOverview) => set({ loadingOverview }),
      loadingAggregation: (loadingAggregation) => set({ loadingAggregation }),
      rawSelected: (id, status, update = true) => {
          const selected = { ...get().selected, [id]: status }
          update && set({ selected })
          return { selected }
      },
      addGlobalVar: (id, value) =>
        set((state) => ({
          globalVar: { ...state.globalVar, [id]: value },
        })),
      filterAndUpdateOverview(aditionState = {}) {
        const { updateOverview } = get().effects;
        const results = get().results.filter(
          (item) => get().selected[item.id] || false,
        );
        if (results.length > 0) {
          return updateOverview(results, aditionState);
        }
        return updateOverview(get().results, aditionState);
      },
      async toggleSelected(status) {
        const { rawToggleSelected, filterAndUpdateOverview } = get().effects;
        rawToggleSelected(status)
        filterAndUpdateOverview();
      },
      async selected(id, status) {
        const { rawSelected, filterAndUpdateOverview } = get().effects;
        rawSelected(id, status)
        filterAndUpdateOverview();
      },
      forwardDate() {
        const { updateReport, changeDates, onChangeDateError } = get().effects;
        changeDates(forwardDate, onChangeDateError);
        updateReport();
      },
      backwardDate() {
        const { updateReport, changeDates, onChangeDateError } = get().effects;
        changeDates(backwardDate, onChangeDateError);
        updateReport();
      },
    };
  }
  
  const computed = {
    selection({ selected }) {
      return Object.keys(selected).filter((id) => selected[id] || false);
    },
    employees({ dbcache }) {
      if (dbcache.Employee) {
        return dbcache.Employee.findWhoIsEnabled().toJSON();
      }
  
      return [];
    },
    workStates() {
      return [
        'accepted',
        'finished',
        'res_client_rejected',
        'res_late_cancel'
      ]
    },
    subscriptions({ dbcache }) {
      if (dbcache.Subscription) {
        return dbcache.Subscription.toJSON();
      }
  
      return [];
    },
    treatments({ dbcache }) {
      if (dbcache.Treatment) {
        return dbcache.Treatment.toJSON();
      }
  
      return [];
    },
    products({ dbcache }) {
      if (dbcache.Product) {
        return dbcache.Product.toJSON();
      }
  
      return [];
    },
    opticas() {
      return []
    },
    packs({ dbcache }) {
      if (dbcache.Discount) {
        return dbcache.Discount.toJSON().filter(
          (discount) => discount.type === 'pack' && discount.enabled === 'true',
        );
      }
  
      return [];
    },
    treatmentsByCategory({ treatments }) {
      const categories = treatments.reduce((m, treat) => {
        const list = m[treat.category] || [];
        m[treat.category] = list.concat({ type: 'treatment', item: treat });
        return m;
      }, {});
      return Object.keys(categories)
        .map((category) =>
          [{ type: 'category', item: category }].concat(categories[category]),
        )
        .flat();
    },
    productsByCategory({ products }) {
      const categories = products.reduce((m, product) => {
        const list = m[product.category] || [];
        m[product.category] = list.concat({ type: 'product', item: product });
        return m;
      }, {});
      return Object.keys(categories)
        .map((category) =>
          [{ type: 'category', item: category }].concat(categories[category]),
        )
        .flat();
    },
    clientGroups({ dbcache }) {
      if (dbcache.Client) {
        const groups = dbcache.Client.filter((c) => {
          const clientGroups = c.get('groups') || [];
          return clientGroups.length > 0;
        })
          .map((client) => client.get('groups'))
          .flatten();
        return Array.from(new Set(groups));
      }
  
      return [];
    },
    subscriptionStates() {
      const states = [
        'all_subscriptions',
        'old-subscriptions',
        'new-subscriptions',
      ];
      return Array.from(new Set(states));
    },
    extraAttrs({ dbcache }) {
      if (dbcache.BHAccountExtended) {
        const attrs = dbcache.BHAccountExtended.models.length
          ? dbcache.BHAccountExtended.models[0]
            .toJSON()
            .clientDataViews.map((view) => view.attributes)
            .flat()
          : [];
        const aditionalsAttrs = [
          {
            name: 'fullName',
            special: 'true',
          },
        ];
  
        return [...aditionalsAttrs, ...attrs];
      }
  
      return [];
    },
  };
  
  export default {
    DEFAULT_FILTERS,
    initialState,
    effects,
    computed,
  };
  