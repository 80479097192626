import React from 'react';
import General from './general';
import Pipeline from './pipeline';
import Overview from './overview';
import Columns from './columns';
import Preview from './preview';
import Translate from './translate';


// eslint-disable-next-line react/prop-types
const StepsContent = ({ current }) => {
  switch (current) {
    case 0:
      return <General />;
    case 1:
      return <Pipeline />;
    case 2:
      return <Columns />;
    case 3:
      return <Overview />;
    case 4:
      return <Translate />;
    case 5:
      return <Preview />;
    default:
      return null;
  }
};

export default StepsContent;
