/* eslint-disable no-console */
import React from 'react';
import usePipelineStore from '../store';
import './blocks/customblocks';
import './generator/generator';
import { Button } from './styles';
import { useHistory } from 'react-router-dom';

const Preview = () => {
  const { effects, type } = usePipelineStore();
  const history = useHistory();

  const handleSave = async () => {
    await effects.saveReport();
    history.push('/backoffice/reports');
  };

  const handleDelete = async () => {
    await effects.deleteReport();
    history.push('/backoffice/reports');
  };

  return (
    <div className="App">
      <header className="App-header">
        <Button onClick={handleSave}>Guardar</Button>
        <br/>
        { type === 'update' && <Button onClick={handleDelete}>Delete</Button>}
      </header>
    </div>
  );
};

export default Preview;
