import Axios from 'axios';
import config from '../../config';
// eslint-disable-next-line import/no-cycle
import { auth } from '../services/auth';

const axios = Axios.create({
  baseURL: config.stargate,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  // withCredentials: true //only for cookies
});

axios.interceptors.request.use((conf) => {
  const token = auth.getToken();
  return {
    ...conf,
    headers: {
      ...conf.headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url === 'account/logingoogle'
    ) {
      // Redirect
      return Promise.reject(error);
    }

    const { _retry } = originalRequest;
    if (error.response && error.response.status === 401 && !_retry) {
      // originalRequest._retry = true;
      // const token = auth.getToken();
      // return axios.post('/account/regreshtoken',
      //     {
      //         "refresh_token": refreshToken
      //     })
      //     .then(res => {
      //         if (res.status === 200 || es.status === 201) {
      //             localStorageService.setToken(res.data);
      //             axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
      //             return axios(originalRequest);
      //         }
      //     })
    }
    return Promise.reject(error);
  },
);

export default axios;
