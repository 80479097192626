import React from 'react';
import { hot } from 'react-hot-loader/root';
import { ThemeProvider } from 'styled-components';
import { Route, Switch, Redirect } from 'react-router-dom';
import { theme } from './theme';
import { GlobalStyle } from './assets/css/globalStyle';
import './assets/css/main.less';

import UnauthorizedLayout from './components/Layout/Unauthorized'
import AuthorizedLayout from './components/Layout/Authorized'
import SesssionProvider from './routes/Session'
import AuthorizedRoute from './routes/AuthorizedRoute'
import Router from './routes/Router'


function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <SesssionProvider>
            <Switch>
              <Route path="/login" component={UnauthorizedLayout} />
              <AuthorizedRoute path="/backoffice" component={AuthorizedLayout} />
              <Redirect to="/backoffice" />
            </Switch>
        </SesssionProvider>
      </Router>
    </ThemeProvider>
  );
}

export default hot(App);
