import * as Blockly from 'blockly/core';

const color = 0;

Blockly.Blocks.toArray = {
  init() {
    this.appendDummyInput().appendField('toArray');
    this.setPreviousStatement(true, 'Object');
    this.setNextStatement(true, 'Array');
    this.setColour(color);
  },
};

Blockly.Blocks.orderBy = {
  init() {
    this.appendDummyInput()
      .appendField('order by')
      .appendField(new Blockly.FieldTextInput(''), 'field')
      .appendField(
        new Blockly.FieldDropdown([
          ['Ascendente', 'asc'],
          ['Descendente', 'desc'],
        ]),
        'ordering',
      );
    this.setPreviousStatement(true, 'Array');
    this.setNextStatement(true, 'Array');
    this.setColour(color);
  },
};

Blockly.Blocks.groupBy = {
    init() {
      this.appendDummyInput()
        .appendField('group by')
        .appendField(new Blockly.FieldTextInput(''), 'field')
      this.setPreviousStatement(true, 'Array');
      this.setNextStatement(true, 'Array');
      this.setColour(color);
    },
  };
