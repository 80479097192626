import React, { useEffect } from 'react';
import { Table, Topbar } from 'buwi';
import { i18n } from 'common/reports/libs/i18n';
import { useHistory } from 'react-router-dom';
import useReportStore from './store';

const columnList = [
  {
    field: 'i18n.es.name',
    label: 'Nombre',
  },
  {
    field: 'i18n.es.description',
    label: 'Descripción',
  },
];

function Reports() {
  const { effects, reports = [] } = useReportStore();
  const history = useHistory();

  const buttonsRight = [
    {
      text: `Nuevo informe`,
      func: () => history.push('/backoffice/report/new'),
      icon: 'icon-plus',
    },
  ];

  useEffect(() => {
    effects.loadReports();
  }, []);

  return (
    <div style={{ padding: '0 1rem' }}>
      <Topbar
        type="detail"
        placeholder="Buscar por nombre o descripción"
        buttonsRight={buttonsRight}
        // setFilteredList={effects.setFilteredClientsByWord}
        // filteredList={[]}
        keys={['i18n.es.name', 'i18n.es.description']}
      />
      <Table
        i18n={i18n}
        title="Informes"
        onClick={({ _id }) => history.push('/backoffice/report/' + _id)}
        columns={columnList}
        rows={reports}
        withCheck={false}
        paginatorContent={({ totalPages, currentPage, length }) => (
          <span>
            {'Pagina'} {currentPage} {'de'} {totalPages} ( {length}{' '}
            {'registros'} )
          </span>
        )}
      />
    </div>
  );
}

export default Reports;
