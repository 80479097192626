import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from './routes';

export function renderRoutes() {
    return routes.map(({ link, component, children }, i) => {
      return (children || []).length
        ? children.map(
            (su, k) =>
              su.link && (
                <Route
                  key={`menu-${String(i)}${String(k)}`}
                  path={`${su.link}`}
                  component={su.component}
                />
              ),
          )
        : link && (
            // eslint-disable-next-line react/jsx-indent
            <Route key={`menu-${String(i)}`} path={`${link}`} component={component} />
          );
    });
  }
  