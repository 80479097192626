define([], function() {
    return {
        "path": "Ruta",
        "id_center": "ID Centro",
        "id_account": "ID Cuenta",
        "account_active": "Activar Cuenta",
        "account_disable": "Deshabilitar Cuenta",
        "sure_disable_account": "¿Seguro desea deshabilitar esta cuenta?",
        "sure_active_account": "¿Seguro desea activar esta cuenta?",
        "discontinue_plan": "Suspender Plan",
        "activate_plan": "Activar Plan",
        "supplant": "Suplantar",
        "info_account": "Información de la Cuenta",
        "change_plan": "Cambiar Plan",
        "nameCenter_required": "Nombre del centro es requerido",
        "id_account_required": "Id de la cuenta es requerido",
        "choose_type": "Escoja un tipo de centro",
        "name_required": "Nombre es requerido",
        "lastname_required": "Apellido es requerido",
        "username_required": "Nombre de Usuario es requerido",
        "email_required": "Email es requerido",
        "email_valid": "No es valido con el formato email",
        "password_required": "Contraseña es requerida",
        "min_char": "Mínimo 5 caracteres",
        "max_char": "Máximo 5 caracteres",
        "type_center": "Tipo de Centro",
        "username": "Nombre de Usuario",
        "isMulticenter": "Es Multicentro",
        "newCenter": "Nuevo Centro",
        "adminUser": "Usuario administrador",
        "siteName_required": "SiteName es requerido",
        "siteName": "SiteName",
        "plan_required": "Escoja un tipo de plan",
        "list": "Listado",
        "saved_changes": "Se guardaron los cambios",
        "state_search": "Buscar por estado",
        "password_not_match": "La contraseña no coincide",
        "change_Pwd": "Cambiar Contraseña",
        "sure_password": "¿Seguro desea cambiar la contraseña?",
        "new_password": "Nueva contraseña",
        "confirm_password": "Confirmar contraseña",
        "activate": "Activar",
        "integrations": "Integraciones",
        "save_integrations": "¿Seguro desea guardar los cambios de integraciones?",
        "save_change_plan": "¿Seguro desea cambiar el plan?",
        "boost_your_business": "¡Disfruta gratis por 15 días el panel de redes sociales y potencia la presencia online de tu negocio!",
        "content_your_schedule": "Crea tus contenidos, programa tus publicaciones, analiza y lleva un seguimiento de lo que sucede en tus perfiles.",
        "connect_your_networks": "Conecta tus redes con solo unos clics y explora todo el poder que tiene esta funcionalidad para ti y tu negocio.",
        "learn_more": "Conoce más",
        "skip": "Omitir",
        "mass_deletion": "Eliminación masiva",
        "alert_message_clients_delete": "Antes de eliminar clientes masivamente, descárgalos, ya que la acción es irreversible y perderás su información asociada. ",
        "elimination_question": "¿Deseas continuar con la eliminación?",
        "confirmation_client_deletion": "Si deseas confirmar la eliminación debes escribir la siguiente frase:",
        "confirmation_text_checker_client_deletion": "Sí, quiero eliminar los CLIENTES",
        "error_delete_clients": "Error al eliminar los clientes",
        "clients_deleted_sussesfully": "Los clientes han sido eliminados correctamente, tu sesion sera cerrada.",
        "error_elimination_text_checker_incorrect": "Para completar el proceso, la frase debe de ser exacta a la indicada",
        "waiting_message_clients_eliminations_on_process": "Estamos procesando la eliminación, este proceso puede tardar unos minutos, por favor, espere.",
        "integration_analytics": "redes sociales",
        "manage_your_networks": "Gestiona fácil y rápidamente todas tus redes sociales desde un único lugar... ¡Te enseñamos cómo hacerlo!",
        "connect_your_first_network": "En menos de 2 minutos conectá tu primera red y empezá a sacar provecho de todas las funcionalidades de tu Panel de Redes Sociales.",
        "create_content_on_your_networks": "Crea y programa el contenido de tus redes sociales, con el apoyo de nuestra herramienta de inteligencia artificial",
        "buy_now": "Cómpralo ahora",
        "integration_web_editor": "web editor",
        "expect_it_soon": "Espéralo pronto",
        "working_new_features": "Estamos trabajando en nuevas funcionalidades que te ayudarán a conectar tu negocio y tus Clientes al mundo digital.",
        "install": "Instalar",
        "uninstall": "Desinstalar",
        "_inactive": "Inactivo",
        "onepage_st": "One page sin dominio",
        "onepage-domain": "One page con dominio",
        "multipage_st": "Multipage sin dominio",
        "multipage-domain": "Multipage con dominio",
        "important": "Importante",
        "warning_downgrade_integration": "Al eliminar la integración de Web Editor, se reestablecerá el sitio web de esta cuenta y perderá la configuración que tenga realizada hasta el momento",
        "warning_uninstall_integration": "Si decides desinstalar la integración, la información se restablecerá. Al momento de reinstalarla, conservarás tu sitio web si activas el mismo plan o uno superior. En caso contrario, perderás la información.",
        "warning_upgrade_integration": "¿Listo para dar el siguiente paso? Confirma tu cambio de plan a Multipage y disfruta de las nuevas funcionalidades y beneficios.",
        "warning_select": "Debe seleccionar una opción.",
        "integration_yext_editor": "Rankings",
        "popup_upgrade_title_1": "¡<span>Mejora tu plan para beneficiarte </span> de nuevas funcionalidades!",
        "popup_upgrade_paragraph_1": "Si mejoras tu plan actual, podrás beneficiarte de funcionalidades que te ayudarán en el día a día de tu negocio: realiza campañas de email marketing a tus clientes, gestiona tus reservas o citas de manera sencilla o incluso haz mucho más fácil toda tu gestión administrativa. ¡No lo dudes! 🌠",
        "popup_upgrade_paragraph_2": "Si mejoras tu plan actual, podrás gestionar tus cobros, controlar el inventario de productos, programar y ver el histórico de tus gastos. ¡Simplifica, potencia y avanza hacia el éxito! 🌠",
        "center_management": "Gestión de centros",
        "successfully_saved": "Guardado con exito",
        "center_type_Dance Academy": "Dance Academy",
        "center_type_arquitectura e interiorismo": "arquitectura e interiorismo",
        "center_type_automotriz": "automotriz",
        "center_type_construcción": "construcción",
        "center_type_hoteleria": "hoteleria",
        "center_type_industria": "industria",
        "center_type_inmobiliaria": "inmobiliaria",
        "center_type_servicios legales": "servicios legales",
        "center_type_servicio de mudanza": "servicio de mudanza",
        "center_type_servicios para el hogar": "servicios para el hogar",
        "center_type_belleza": "belleza",
        "center_type_depilación": "depilación",
        "center_type_hair": "cabello",
        "center_type_uñas": "uñas",
        "center_type_crossfit": "crossfit",
        "center_type_entrenador_personal": "entrenador personal",
        "center_type_dentistas": "dentistas",
        "center_type_salud": "salud",
        "center_type_salud ocular": "salud ocular",
        "center_type_educacion": "educacion",
        "center_type_educación": "educación",
        "center_type_escuela_deportiva": "escuelas deportivas",
        "center_type_escuelas deportivas": "escuelas deportivas",
        "center_type_estetica": "estética",
        "center_type_peluquerias": "peluquerias",
        "center_type_barberias": "barberias",
        "center_type_spa": "spa",
        "center_type_bienestar": "bienestar",
        "center_type_yoga": "yoga",
        "center_type_pilates": "pilates",
        "center_type_masajes": "masajes",
        "center_type_fitness": "fitness",
        "center_type_massage": "masaje",
        "center_type_baile": "baile",
        "center_type_musica": "música",
        "center_type_dojo": "dojo",
        "center_type_optica": "optica",
        "center_type_consultas": "consultas",
        "center_type_veterinaria": "veterinaria",
        "center_type_fisioterapeutas": "fisioterapeutas",
        "center_type_psicologos": "psicologos",
        "center_type_nutricion": "nutrición",
        "center_type_clinicas": "clinicas",
        "center_type_comidas": "comidas",
        "center_type_hoteleria y turismo": "hoteleria y turismo",
        "center_type_otro": "otro",
        "center_type_domicilio": "domicilio",
        "center_type_cadenas": "cadenas",
        "center_type_retail": "minorista",
        "accounts": "Cuentas",
        "message_confirmation_account_editing": "Estás a punto de guardar cambios que afectarán a tu cuenta hija. ¿Deseas continuar?"
};
});