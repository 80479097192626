import * as Blockly from 'blockly/core';

const color = 300;

Blockly.Blocks.each = {
  init() {
    this.appendStatementInput('each').setCheck(['Fetch']).appendField('each');
    this.setPreviousStatement(true, 'Array');
    this.setNextStatement(true, 'Array');
    this.setColour(color);
  },
};
